<template>
  <div class="loader" v-if="isLoading">
    <div class="loader-spinner"></div>
  </div>
</template>

<script>
export default {
  name: 'loaderElement',

  computed: {
    isLoading() {
      return this.$store.state.users.loading
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/index.scss';
@import './loader';
</style>
