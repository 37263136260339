<template>
  <div id="myModal" class="modal" @click="closeModal">
    <div id="modal__dropdown">
      <div class="modal__title">
        <h3>{{ $t('modals.edit_chapter') }}</h3>
        <span id="close" @click="closeModal">&times;</span>
      </div>
      <form class="modal__form" @submit.prevent="editChapter">
        <div class="modal__group">
          <baseInput
            id="name"
            type="text"
            :placeholder="$t('modals.chapter_name')"
            v-model="newChapter.name"
            customClass="input--modal"
            :applyErrorClass="$v.newChapter.name.$error"
            @blur="$v.newChapter.name.$touch()"
          ></baseInput>
          <template v-if="$v.newChapter.name.$error">
            <p class="error-msg" v-if="!$v.newChapter.name.required">
              {{ $t('modals.chapter_name_error') }}
            </p>
          </template>
        </div>
        <div class="modal__group">
          <baseTextArea
            id="description"
            type="text"
            :placeholder="$t('modals.chapter_desc')"
            v-model="newChapter.description"
            customClass="input--modal"
            :applyErrorClass="$v.newChapter.description.$error"
            @blur="$v.newChapter.description.$touch()"
            style="margin: 10px 20px; margin-bottom: 0"
          ></baseTextArea>
          <template v-if="$v.newChapter.description.$error">
            <p class="error-msg" v-if="!$v.newChapter.description.required">
              {{ $t('modals.chapter_desc_error') }}
            </p>
          </template>
        </div>
        <div class="modal__group">
          <baseButton
            type="submit"
            buttonClass="button--primary button--modal"
            :disabled="isChanged || isDisabled"
            >{{ $t('modals.submit') }}</baseButton
          >
          <p v-if="error" class="error-msg">{{ generalError }}</p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import baseInput from '@/components/baseInput/baseInput.vue'
import baseButton from '@/components/baseButton/baseButton.vue'
import baseTextArea from '@/components/baseTextarea/baseTextarea.vue'
import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

export default {
  components: {
    baseInput,
    baseButton,
    baseTextArea,
  },
  data() {
    return {
      isDisabled: false,
      newChapter: {
        name: '',
        description: '',
      },
      error: false,
      generalError: this.$t('errors.something_went_wrong'),
    }
  },
  props: {
    chapterID: {
      type: String,
      default: '',
    },
    chapter: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isChanged() {
      if (
        this.chapter &&
        this.chapter.name &&
        this.chapter.description &&
        this.newChapter
      ) {
        return (
          this.chapter.name === this.newChapter.name &&
          this.chapter.description === this.newChapter.description
        )
      }
      return false
    },
  },
  validations() {
    return {
      newChapter: {
        name: {
          required,
        },
        description: {
          required,
        },
      },
    }
  },
  methods: {
    ...mapActions(['fetchChapters']),
    async fetchChapterData() {
      await this.$store.dispatch('fetchChapter', this.chapterID)
    },
    populateNewChapterData() {
      if (this.chapter && this.chapter.name && this.chapter.description) {
        this.newChapter.name = this.chapter.name
        this.newChapter.description = this.chapter.description
      }
    },
    async editChapter() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      const updatedData = {}
      const userProperties = Object.keys(this.newChapter)
      userProperties.forEach((property) => {
        if (this.newChapter[property]) {
          updatedData[property] = this.newChapter[property]
        }
      })
      try {
        this.isDisabled = true
        await this.$store.dispatch('editChapter', {
          chapterID: this.chapterID,
          chapterData: updatedData,
        })
        await this.fetchChapters(this.$route.params.id)
        this.closeModalAfterSubmit()
      } catch (err) {
        this.error = true
        this.isDisabled = false
      }
    },
    closeModal(event) {
      const isModalElement = event?.target?.id === 'myModal'
      const isCloseButtonElement = event?.target?.id === 'close'
      if (isModalElement || isCloseButtonElement) {
        this.$emit('close-modal')
        this.newChapter = {
          name: '',
          description: '',
        }
        this.isDisabled = false
      }
    },
    closeModalAfterSubmit() {
      this.$emit('close-modal')
      this.newChapter = {
        name: '',
        description: '',
      }
      this.isDisabled = false
    },
  },
  mounted() {
    this.populateNewChapterData()
  },
}
</script>

<style lang="scss" scoped>
@import './editChapterModal';
</style>
