import api from '@/api/api'
import { successMessage, errorMessage } from '@/composables/useMessages'
import i18n from '@/main'

const courses = {
  state: {
    courses: [],
    allCourses: 0,
    dropdownCourses: [],
    lastPage: 1,
    filteredCourses: [],
    course: null,
    allCompletedCourses: 0,
    allIncompletedCourses: 0,
    instructorCourses: [],
    dropdownInstructorCourses: [],
    instructorCoursesCount: 0,
    courseVideo: [],
  },

  getters: {
    getCourses: (state) => state.courses,
    getAllCourses: (state) => state.allCourses,
    getDropdownCourses: (state) => state.dropdownCourses,
    getLastPage: (state) => state.lastPage,
    getFilteredCourses: (state) => state.filteredCourses,
    getCourse: (state) => state.course,
    getCompletedCourses: (state) => state.completedCourses,
    getAllCompletedCourses: (state) => state.allCompletedCourses,
    getIncompletedCourses: (state) => state.incompletedCourses,
    getAllIncompletedCourses: (state) => state.allIncompletedCourses,
    getInstructorCourses: (state) => state.instructorCourses,
    getDropdownInstructorCourses: (state) => state.dropdownInstructorCourses,
    getInstructorCoursesCount: (state) => state.instructorCoursesCount,
    getCourseVideo: (state) => state.courseVideo,
  },

  mutations: {
    SET_COURSES(state, { courses, lastPage, totalCount }) {
      state.courses = courses
      state.lastPage = lastPage
      state.allCourses = totalCount
    },
    SET_DROPDOWN_COURSES(state, courses) {
      state.dropdownCourses = courses
    },
    SET_FILTERED_COURSES(state, { courses, lastPage }) {
      state.filteredCourses = courses
      state.lastPage = lastPage
    },
    SET_COURSE(state, course) {
      state.course = course
    },
    SET_COMPLETED_COURSES(state, completedCourses) {
      state.completedCourses = completedCourses
    },
    SET_ALLCOMPLETED_COURSES(state, allCompletedCourses) {
      state.allCompletedCourses = allCompletedCourses
    },
    SET_INCOMPLETED_COURSES(state, incompletedCourses) {
      state.incompletedCourses = incompletedCourses
    },
    SET_ALLINCOMPLETED_COURSES(state, allIncompletedCourses) {
      state.allIncompletedCourses = allIncompletedCourses
    },
    SET_INSTRUCTOR_COURSES(state, { courses, lastPage, totalCount }) {
      state.instructorCourses = courses
      state.lastPage = lastPage
      state.instructorCoursesCount = totalCount
    },
    SET_DROPDOWN_INSTRUCTOR_COURSES(state, courses) {
      state.dropdownInstructorCourses = courses
    },
    SET_COURSE_VIDEO(state, courseVideo) {
      state.courseVideo = courseVideo
    },
  },

  actions: {
    async fetchCourses({ commit }, { searchText = '', page = 1, limit = '' }) {
      try {
        const res = await api({ requiresAuth: true }).get(
          `/course?name=${searchText}&page=${page}&limit=${limit}`
        )
        const { data, lastPage, totalCount } = res.data
        const mutationType =
          searchText === '' ? 'SET_COURSES' : 'SET_FILTERED_COURSES'
        commit(mutationType, { courses: data, lastPage, totalCount })
        return res.data
      } catch (error) {
        console.error('GETTING COURSES ERROR:', error.response.data.message)
        throw error
      }
    },
    async fetchCourse({ commit }, courseID) {
      try {
        const res = await api({ requiresAuth: true }).get(`/course/${courseID}`)
        commit('SET_COURSE', res.data)
        return res.data
      } catch (error) {
        console.error('GETTING COURSE ERROR:', error.response.data.message)
        throw error
      }
    },
    async fetchCoursesByCategory({ commit }, { id, searchText, page, limit }) {
      try {
        const res = await api({ requiresAuth: true }).get(
          `/course/searchCoursesByCategory/${id}?name=${searchText}&page=${page}&limit=${limit}`
        )
        commit('SET_FILTERED_COURSES', {
          courses: res.data.data,
          lastPage: res.data.lastPage,
        })
        return res.data.data
      } catch (error) {
        console.error('GETTING COURSES ERROR:', error.response.data.message)
        throw error
      }
    },
    async createCourse(_, { categoryID, courseData }) {
      try {
        const res = await api({
          requiresAuth: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).post(`/course/create/${categoryID}`, courseData)
        successMessage.call(this, i18n.t('store.create_course_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.create_course_failed'))
        console.error('CREATING COURSE ERROR:', error.response.data.message)
        throw error
      }
    },
    async createDiscount(_, { courseID, discountPercentage }) {
      try {
        const res = await api({
          requiresAuth: true,
        }).post(`/course/createDiscount/${courseID}`, discountPercentage)
        successMessage.call(this, i18n.t('store.add_discount_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.add_discount_failed'))
        console.error('ADDING DISCOUNT ERROR:', error.response.data.message)
        throw error
      }
    },
    async removeDiscount(_, courseID) {
      try {
        const res = await api({
          requiresAuth: true,
        }).post(`/course/removeDiscount/${courseID}`)
        successMessage.call(this, i18n.t('store.remove_discount_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.remove_discount_failed'))
        console.error('REMOVING DISCOUNT ERROR:', error.response.data.message)
        throw error
      }
    },
    async updateCourse(_, { courseID, courseData }) {
      try {
        const res = await api({
          requiresAuth: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).patch(`/course/${courseID}`, courseData)
        successMessage.call(this, i18n.t('store.update_course_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.update_course_failed'))
        console.error('UPDATING COURSE ERROR:', error.response.data.message)
        throw error
      }
    },
    async deleteCourse(_, courseID) {
      try {
        const res = await api({ requiresAuth: true }).delete(
          `/course/${courseID}`
        )
        successMessage.call(this, i18n.t('store.delete_course_success'))
        return res.data
      } catch (error) {
        if (
          error.response.data.message ===
          'Cannot delete this course because there are students enrolled in it.'
        ) {
          errorMessage.call(this, i18n.t('store.delete_course_enrolled_error'))
        } else {
          errorMessage.call(this, i18n.t('store.delete_course_failed'))
        }
        console.error('DELETE COURSE ERROR:', error.response.data.message)
        throw error
      }
    },
    async fetchInstructorCourses(
      { commit },
      { id, categoryID, searchText, page, limit }
    ) {
      try {
        if (id) {
          let res
          if (categoryID) {
            res = await api({ requiresAuth: true }).get(
              `/course/searchInstructorCoursesByNameAndCategory/${id}?categoryId=${categoryID}&name=${searchText}&page=${page}&limit=${limit}`
            )
            commit('SET_FILTERED_COURSES', {
              courses: res.data.data,
              lastPage: res.data.lastPage,
              totalCount: res.data.totalCount,
            })
          } else {
            res = await api({ requiresAuth: true }).get(
              `/course/searchInstructorCoursesByNameAndCategory/${id}?page=${page}&limit=${limit}&name=${searchText}`
            )
            const mutationType =
              searchText === ''
                ? 'SET_INSTRUCTOR_COURSES'
                : 'SET_FILTERED_COURSES'
            commit(mutationType, {
              courses: res.data.data,
              lastPage: res.data.lastPage,
              totalCount: res.data.totalCount,
            })
          }

          return res
        }
      } catch (error) {
        console.error('GETTING COURSES ERROR:', error.response.data.message)
        throw error
      }
    },
    async fetchCourseVideo({ commit }, courseID) {
      try {
        const res = await api({ requiresAuth: true }).get(
          `/video/course/${courseID}`
        )
        commit('SET_COURSE_VIDEO', res.data)
        return res.data
      } catch (error) {
        console.error(
          'GETTING COURSE VIDEO ERROR:',
          error.response.data.message
        )
        throw error
      }
    },
    async embedVideo(_, { courseID = '', chapterID = '', videoID }) {
      try {
        const res = await api({
          requiresAuth: true,
        }).get(
          `/youtube/video/${videoID}?courseId=${courseID}&chapterId=${chapterID}`
        )
        successMessage.call(this, i18n.t('singleCourse.video_upload_succes'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('singleCourse.video_upload_error'))
        console.error('UPLOADING YOUTUBE VIDEO ERROR:', error)
        throw error
      }
    },
    async updateEmbedVideo(_, { videoID, youTubeVideoId }) {
      try {
        const res = await api({
          requiresAuth: true,
        }).get(`/youtube/updateVideo/${videoID}/${youTubeVideoId}`)
        successMessage.call(this, i18n.t('store.update_video_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.update_video_error'))
        console.error('UPDATING YOUTUBE VIDEO ERROR:', error)
        throw error
      }
    },
    async deleteEmbedVideo(_, videoID) {
      try {
        const res = await api({
          requiresAuth: true,
        }).delete(`/youtube/deleteVideo/${videoID}`)
        successMessage.call(this, i18n.t('store.delete_video_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.delete_video_error'))
        console.error('DELETING VIDEO ERROR:', error)
        throw error
      }
    },
    async uploadVideo(_, { courseID, video }) {
      try {
        const res = await api({
          requiresAuth: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).post(`/video/course/${courseID}`, video)
        successMessage.call(this, i18n.t('store.upload_course_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.upload_course_failed'))
        console.error('UPLOAD VIDEO ERROR:', error)
        throw error
      }
    },
    async updateVideo(_, { videoID, video }) {
      try {
        const res = await api({
          requiresAuth: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).patch(`/video/course/${videoID}`, video)
        successMessage.call(this, i18n.t('store.update_video_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.update_video_failed'))
        console.error('UPDATING VIDEO ERROR:', error.response.data.message)
        throw error
      }
    },
    async deleteVideo(_, videoID) {
      try {
        const res = await api({ requiresAuth: true }).delete(
          `/video/${videoID}`
        )
        successMessage.call(this, i18n.t('store.delete_video_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.delete_video_failed'))
        console.error('DELETE VIDEO ERROR:', error.response.data.message)
        throw error
      }
    },
    async addLearning(_, { courseID, learning }) {
      try {
        const res = await api({
          requiresAuth: true,
        }).post(`/course/courseLearning/${courseID}`, learning)
        successMessage.call(this, i18n.t('store.add_learning_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.add_learning_failed'))
        console.error('ADD LEARNING ERROR:', error)
        throw error
      }
    },
    async deleteLearning(_, learningID) {
      try {
        const res = await api({ requiresAuth: true }).delete(
          `/course/learning/${learningID}`
        )
        successMessage.call(this, i18n.t('store.delete_learning_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.delete_learning_failed'))
        console.error('DELETE LEARNING ERROR:', error.response.data.message)
        throw error
      }
    },
    async addRequirement(_, { courseID, requirement }) {
      try {
        const res = await api({
          requiresAuth: true,
        }).post(`/course/courseRequirements/${courseID}`, requirement)
        successMessage.call(this, i18n.t('store.add_requirement_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.add_requirement_failed'))
        console.error('ADD REQUIREMENT ERROR:', error)
        throw error
      }
    },
    async deleteRequirement(_, requirementID) {
      try {
        const res = await api({ requiresAuth: true }).delete(
          `/course/requirements/${requirementID}`
        )
        successMessage.call(this, i18n.t('store.delete_requirement_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.delete_requirement_failed'))
        console.error('DELETE REQUIREMENT ERROR:', error.response.data.message)
        throw error
      }
    },
  },
}

export default courses
