export default {
  computed: {
    isSuperAdmin() {
      return parseInt(localStorage.getItem('role')) === 1
    },
    isAdmin() {
      return parseInt(localStorage.getItem('role')) === 2
    },
    isInstructor() {
      return parseInt(localStorage.getItem('role')) === 3
    },
    isStudent() {
      return parseInt(localStorage.getItem('role')) === 4
    },
  },
}
