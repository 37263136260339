import api from '@/api/api'
import { successMessage, errorMessage } from '@/composables/useMessages'
import i18n from '@/main'
const reviews = {
  state: {
    courseID: '',
    courseReview: [],
    hasMoreReviews: true,
    instructorInfo: null,
    reviewCount: 0,
    studentCount: 0,
    studentFeedback: {},
    openSurvey: true,
  },

  getters: {
    getCourseReviews: (state) => state.courseReview,
    hasMoreReviews: (state) => state.hasMoreReviews,
    getInstructorInfo: (state) => state.instructorInfo,
    getReviewCount: (state) => state.reviewCount,
    getStudentCount: (state) => state.studentCount,
    getStudentFeedback: (state) => state.studentFeedback,
  },

  mutations: {
    SET_COURSE_REVIEWS(state, courseReview) {
      state.courseReview = [...state.courseReview, ...courseReview]
    },
    SET_COURSE_REVIEWS_NOTIFICATION(state, courseReview) {
      state.courseReview = courseReview
    },
    SET_NO_MORE_REVIEWS(state) {
      state.hasMoreReviews = false
    },
    SET_INSTRUCTOR_INFO(state, instructorInfo) {
      state.instructorInfo = instructorInfo
      state.studentCount = instructorInfo.studentCount
      state.reviewCount = instructorInfo.reviewCount
    },
    SET_FEEDBACK(state, feedbackData) {
      state.studentFeedback = feedbackData
    },
  },

  actions: {
    async fetchCourseReviews({ commit, state }, { courseID, page = 1 }) {
      try {
        const res = await api({ requiresAuth: true }).get(
          `/review/findByCourse/${courseID}?page=${page}`
        )
        if (page === 1) {
          state.courseReview = []
          commit('SET_COURSE_REVIEWS', res.data.data)
          state.hasMoreReviews = true
        } else {
          commit('SET_COURSE_REVIEWS', res.data.data)
        }
        if (res.data.lastPage <= page) {
          commit('SET_NO_MORE_REVIEWS')
        }
        return res.data
      } catch (error) {
        console.error(
          'GETTING COURSE REVIEW ERROR:',
          error.response.data.message
        )
        throw error
      }
    },
    async fetchSingleReview({ commit, state }, reviewId) {
      try {
        const res = await api({ requiresAuth: true }).get(`/review/${reviewId}`)
        let updatedReviews = state.courseReview.filter((review) => {
          return review.uuid !== reviewId
        })
        commit('SET_COURSE_REVIEWS_NOTIFICATION', [res.data, ...updatedReviews])
      } catch (error) {
        console.error(
          'GETTING COURSE REVIEW ERROR:',
          error.response.data.message
        )
        throw error
      }
    },
    async fetchInstructorReviewInfo({ commit }, instructorID) {
      try {
        const res = await api({ requiresAuth: true }).get(
          `/review/getInstructorInformation/${instructorID}`
        )
        commit('SET_INSTRUCTOR_INFO', res.data)
        return res.data
      } catch (error) {
        console.error(
          'GETTING INSTRUCTOR INFO ERROR:',
          error.response.data.message
        )
        throw error
      }
    },
    async addStudentFeedback(_, data) {
      try {
        const res = await api({
          requiresAuth: true,
        }).post(`/survey`, data)
        successMessage.call(this, i18n.t('store.add_feedback_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.add_feedback_failed'))
        throw error
      }
    },
    async updateStudentFeedback(_, data) {
      try {
        const res = await api({
          requiresAuth: true,
        }).patch(`/survey`, data)
        successMessage.call(this, i18n.t('store.update_feedback_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.update_feedback_failed'))
        throw error
      }
    },
    async getStudentFeedback({ commit }) {
      try {
        const res = await api({
          requiresAuth: true,
        }).get(`/survey/current/user`)
        commit('SET_FEEDBACK', res.data)
      } catch (error) {
        commit('SET_FEEDBACK', null)
        console.error(error)
      }
    },
    async addReview(_, payload) {
      try {
        const { id, data } = payload
        let res = await api({ requiresAuth: true }).post(`/review/${id}`, data)
        successMessage.call(this, i18n.t('store.submit_review_success'))
        return res
      } catch (error) {
        errorMessage.call(this, i18n.t('store.submit_review_failed'))
        console.error(error)
        throw error
      }
    },
  },
}

export default reviews
