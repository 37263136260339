import api from '@/api/api'
import { errorMessage } from '@/composables/useMessages'
import i18n from '@/main'
const survey = {
  state: {
    surveys: [],
    page: 1,
    limit: 5,
    totalCount: 0,
  },

  getters: {
    getSurveys: (state) => state.surveys,
    getSurveyTotalCount: (state) => state.totalCount,
  },

  mutations: {
    SET_SURVEYS(state, surveys) {
      state.surveys = surveys
    },
    SET_PAGE(state, page) {
      state.page = page
    },
    SET_LIMIT(state, limit) {
      state.limit = limit
    },
    SET_TOTAL_COUNT(state, totalCount) {
      state.totalCount = totalCount
    },
  },

  actions: {
    setPage({ commit }, page) {
      commit('SET_PAGE', page)
    },
    setLimit({ commit }, limit) {
      commit('SET_LIMIT', limit)
    },
    async fetchSurveys({ commit, state }) {
      try {
        const { page, limit } = state
        const res = await api({ requiresAuth: true }).get(
          `/survey?page=${page}&limit=${limit}`
        )
        commit('SET_SURVEYS', res.data.data)
        commit('SET_TOTAL_COUNT', res.data.totalCount)
        return res.data
      } catch (error) {
        console.error('GETTING SURVEY ERROR:', error.response.data.message)
        throw error
      }
    },
    async chooseSurvey(_, surveyID) {
      try {
        const res = await api({
          requiresAuth: true,
        }).post(`/survey/adminChoose/${surveyID}`)
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.chose_survey_failed'))
        console.error('CHOOSING SURVEY ERROR:', error.response.data.message)
        throw error
      }
    },
  },
}

export default survey
