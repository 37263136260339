var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"comment",class:_vm.baseCommentClass},[_c('div',{staticClass:"comment__section"},[_c('div',{staticClass:"comment__img"},[_c('img',{staticClass:"comment__img",attrs:{"src":_vm.getImagePath,"alt":"user"}})]),_c('div',{staticClass:"comment__section-info"},[_c('div',{staticClass:"comment__section-top"},[_c('h5',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showEditCommentInputs),expression:"!showEditCommentInputs"}],class:{ 'comment__section-top--h5': _vm.title }},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('p',{staticClass:"comment__section-top--text"},[_vm._v(" "+_vm._s(_vm.formattedEventDate)+" ")])]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showEditCommentInputs),expression:"!showEditCommentInputs"}]},[_vm._v(_vm._s(_vm.description))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showEditCommentInputs),expression:"showEditCommentInputs"}],staticClass:"comment__section-info--inputs"},[_c('div',{staticClass:"inputWrapper"},[(!_vm.answer)?_c('baseInput',{attrs:{"id":"questionTitle","type":"text","customClass":"input-comment"},model:{value:(_vm.newTitle),callback:function ($$v) {_vm.newTitle=$$v},expression:"newTitle"}}):_vm._e()],1),_c('div',{staticClass:"inputWrapper"},[_c('baseInput',{attrs:{"id":"questionDescription","type":"text","customClass":"input-comment"},model:{value:(_vm.newDescription),callback:function ($$v) {_vm.newDescription=$$v},expression:"newDescription"}})],1)]),_c('div',{staticClass:"comment__section-info--flex"},[_c('h6',[_vm._v(_vm._s(_vm.firstName)+" "+_vm._s(_vm.lastName))]),_c('div',{staticClass:"comment__section-info--likes"},[_c('p',[_vm._v(_vm._s(_vm.localVotes))]),_c('img',{attrs:{"src":_vm.isLiked
                ? require('@/assets/icons/unlikeComment.svg')
                : require('@/assets/icons/likeComment.svg'),"disabled":!_vm.isLoading},on:{"click":_vm.like,"mouseover":function($event){return _vm.showHover('like')},"mouseleave":function($event){return _vm.hideHover('like')}}})]),(_vm.isHovered.like && !_vm.isLiked)?_c('div',{staticClass:"comment__section-info--hover",class:{
            'comment__section-info--al': _vm.language == 'al',
          }},[_vm._v(" "+_vm._s(_vm.$t('singleCourse.like'))+" ")]):_vm._e(),(_vm.isHovered.like && _vm.isLiked)?_c('div',{staticClass:"comment__section-info--hover comment__section-info--left",class:{
            'comment__section-info--al': _vm.language == 'al',
          }},[_vm._v(" "+_vm._s(_vm.$t('singleCourse.unlike'))+" ")]):_vm._e(),(_vm.isReply)?_c('p',{staticClass:"comment__section-info--comment",on:{"click":_vm.clickReply}},[_vm._v(" "+_vm._s(_vm.$t('singleCourse.reply'))+" ")]):_vm._e()])])]),_c('div',{staticClass:"comment__section--features"},[(_vm.showEditCommentInputs)?_c('buttonElement',{class:{
        'button--updateComment button--updateComment__save':
          _vm.title !== _vm.newTitle || _vm.description !== _vm.newDescription,
        'button--updateComment button--updateComment__disable':
          _vm.title == _vm.newTitle || _vm.description == _vm.newDescription,
      },on:{"click":_vm.saveChanges}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isHovered.edit),expression:"isHovered.edit"}],staticClass:"comment__section--edit",class:{
        'comment__section--en': _vm.language == 'en',
      }},[_vm._v(" "+_vm._s(_vm.$t('singleCourse.edit_comment'))+" ")]),(_vm.showEditAndDeleteButtons && !_vm.showEditCommentInputs)?_c('buttonElement',{attrs:{"buttonClass":"button--updateComment button--updateComment__edit"},on:{"mouseover":function($event){return _vm.showHover('edit')},"mouseleave":function($event){return _vm.hideHover('edit')},"click":_vm.showEditComment}}):_vm._e(),(_vm.showEditAndDeleteButtons)?_c('buttonElement',{attrs:{"buttonClass":"button--updateComment button--updateComment__delete"},on:{"mouseover":function($event){return _vm.showHover('delete')},"mouseleave":function($event){return _vm.hideHover('delete')},"click":_vm.showDeleteCommentModal}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isHovered.delete),expression:"isHovered.delete"}],staticClass:"comment__section--edit comment__section--delete"},[_vm._v(" "+_vm._s(_vm.$t('singleCourse.delete_comment'))+" ")])],1),_c('transition',{attrs:{"name":"modal-fade"}},[(_vm.showDeleteComment)?_c('baseModal',{attrs:{"content":_vm.showModalContent},on:{"confirm-action":function($event){return _vm.deleteComment()},"close-modal":function($event){_vm.showDeleteComment = false}}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }