<template>
  <div class="card__wrapper" :class="customClass">
    <div class="card" :class="customChildClass">
      <p
        class="card__count"
        :class="customGrandChildClass"
        :style="{ color: countColor }"
      >
        {{ countFormat }}
      </p>
      <h4 class="card__title" :class="customTitle">
        {{ cardTitle }}
      </h4>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      countFormat: 0,
      ranges: [
        { divider: 1e18, suffix: 'E' },
        { divider: 1e15, suffix: 'P' },
        { divider: 1e12, suffix: 'T' },
        { divider: 1e9, suffix: 'G' },
        { divider: 1e6, suffix: 'M' },
        { divider: 1e3, suffix: 'k' },
      ],
    }
  },
  props: {
    cardTitle: String,
    count: {
      type: Number,
      default: 0,
    },
    countColor: {
      type: String,
      default: '#000000',
    },
    customClass: {
      type: String,
      required: false,
    },
    customChildClass: {
      type: String,
      required: false,
    },
    customGrandChildClass: {
      type: String,
      required: false,
    },
    customTitle: {
      type: String,
      required: false,
    },
  },

  methods: {
    formatCount(n) {
      for (var i = 0; i < this.ranges.length; i++) {
        if (n >= this.ranges[i].divider) {
          return (
            (n / this.ranges[i].divider).toFixed(1).toString() +
            this.ranges[i].suffix
          )
        }
      }
      return n.toString()
    },
  },
  created() {
    this.countFormat = this.formatCount(this.count)
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/statsCard/statsCard';
</style>
