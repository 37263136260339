<template>
  <div>
    <label v-if="label" :for="id" class="label">{{ label }}</label>
    <span
      v-if="customClass.includes('input--price')"
      class="input-icon"
      :class="iconClass"
      >{{ currency }}</span
    >
    <input
      :id="id"
      :value="value"
      @input="updateValue"
      @keydown.enter.prevent="handleEnter"
      v-bind="$attrs"
      v-on="listeners"
      class="input"
      :class="[{ error: applyErrorClass }, customClass]"
    />
    <span
      v-if="
        customClass === 'input--search' ||
        customClass === 'input--search input--search-lang'
      "
      class="input-icon"
      :class="iconClass"
      @click="$emit('enter')"
    ></span>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    value: [String, Number],
    applyErrorClass: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: '',
    },
    iconClass: {
      type: String,
      default: '',
    },
    currency: {
      type: String,
      default: '€',
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
      }
    },
  },
  methods: {
    updateValue(event) {
      this.$emit('input', event.target.value)
      this.$emit('clear-error')
    },
    handleEnter() {
      this.$emit('enter-pressed')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/baseInput/baseInput';
</style>
