<template>
  <div class="orders layout">
    <div class="orders__title main-title">
      <h2>{{ $t('sideMenu.orders') }}</h2>
    </div>
    <div class="orders__content content-layout">
      <div class="participants__content--filter" @click="hideOrder">
        <filterCard
          :fetch="
            isInstructor ? filterInstructorFunctions : filterStudentFunctions
          "
        ></filterCard>
      </div>
      <div class="orders__content--table">
        <loaderElement v-if="isLoading" />
        <baseTable
          v-if="!isLoading"
          :tableData="ordersData"
          :columns="
            isInstructor
              ? ordersColumns
              : ordersColumns.filter((column) => column.field !== 'amount')
          "
          name="orders"
          :search="search"
          :fetchFunction="
            isInstructor ? 'fetchInstructorOrders' : 'fetchStudentOrders'
          "
          :noDataMessage="noDataMessage"
        ></baseTable>
      </div>
    </div>
    <div v-show="showSingleOrder" class="orders__content orders__details">
      <orderCard ref="orderCard" :order="order" @close="hideOrder"></orderCard>
    </div>
  </div>
</template>

<script>
import loaderElement from '@/components/loader/loaderElementVuex.vue'
import baseTable from '@/components/baseTable/baseTable.vue'
import orderCard from '@/components/orderCard/orderCard.vue'
import { format } from 'date-fns'
import filterCard from '@/components/filterCard/filterCardByDate.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    baseTable,
    orderCard,
    filterCard,
    loaderElement,
  },
  data() {
    return {
      order: {},
      search: false,
      showSingleOrder: false,
      isTabletMode: false,
      noDataMessage: this.$t('tables.no_orders_found'),
      filterInstructorFunctions: {
        filterFunction: 'fetchInstructorOrders',
        get: 'getInstructorOrders',
        paymentsFunction: 'fetchInstructorPayments',
      },
      filterStudentFunctions: {
        filterFunction: 'fetchStudentOrders',
        get: 'getInstructorOrders',
        paymentsFunction: null,
      },
      ordersColumns: [
        {
          field: 'paymentIntentId',
          key: 'b',
          title: this.$t('tables.order'),
          align: 'left',
          width: '35%',
        },
        {
          field: 'created_at',
          key: 'c',
          title: this.$t('tables.date'),
          align: 'left',
          width: '15%',
          renderBodyCell: ({ rowIndex }) => {
            const order = this.ordersData[rowIndex]
            const formattedDate = format(
              new Date(order.created_at),
              'MMM dd, yyyy'
            )
            return <p>{formattedDate}</p>
          },
        },
        {
          field: 'paymentStatus',
          key: 'd',
          title: this.$t('tables.status'),
          align: 'left',
          width: '15%',
          renderBodyCell: ({ rowIndex }) => {
            const order = this.ordersData[rowIndex]
            const status = order.paymentStatus
            const statusClass = {
              succeeded: 'status-completed',
              // pending: 'status-pending',
              // cancelled: 'status-cancelled',
            }
            const statusStyle = statusClass[status.toLowerCase()] || ''
            const capitalizedStatus = this.capitalizeEveryWord(status)

            return (
              <span class={`status-cell ${statusStyle}`}>
                {capitalizedStatus}
              </span>
            )
          },
        },
        {
          field: 'amount',
          key: 'e',
          title: this.$t('tables.total'),
          align: 'left',
          width: '11%',
          renderBodyCell: ({ rowIndex }) => {
            if (this.isInstructor) {
              const order = this.ordersData[rowIndex]
              const totalWithEuroSign = `${order.amount} €`
              return <span>{totalWithEuroSign}</span>
            } else {
              return null
            }
          },
        },
        {
          field: 'action',
          key: 'f',
          title: this.$t('tables.actions'),
          align: 'left',
          width: '14%',
          renderBodyCell: ({ rowIndex }) => {
            const user = this.ordersData[rowIndex]
            return (
              <span
                class="button button--edit"
                on-click={() => this.viewOrder(user)}
              >
                {this.isTabletMode ? '...' : this.$t('tables.click_for_more')}
              </span>
            )
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'getInstructorOrders',
      'getLoading',
      'getUsersTotalCount',
      'getUserId',
    ]),
    isLoading() {
      return this.getLoading
    },
    ordersData() {
      if (Array.isArray(this.getInstructorOrders)) {
        return this.getInstructorOrders
      } else {
        return []
      }
    },
    userId() {
      return this.getUserId
    },
  },
  methods: {
    capitalizeEveryWord(string) {
      return string
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    },
    closeOrderOnOutsideClick(event) {
      if (!this.$refs.orderCard.$el.contains(event.target)) {
        this.hideOrder()
      }
    },
    checkTabletMode() {
      this.isTabletMode = window.innerWidth <= 1024
    },

    viewOrder(order) {
      this.order = order
      this.showSingleOrder = true
      this.$nextTick(() => {
        const orderCardElement = this.$refs.orderCard.$el
        if (orderCardElement) {
          orderCardElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }
      })
    },
    hideOrder() {
      this.showSingleOrder = false
    },
  },
  async mounted() {
    this.checkTabletMode()
    window.addEventListener('resize', this.checkTabletMode)
    if (this.isInstructor) {
      await this.$store.dispatch('fetchInstructorOrders', {
        year: null,
        month: null,
      })
      await this.$store.dispatch('fetchTotalPayments', {
        year: null,
        month: null,
      })
    } else {
      await this.$store.dispatch('fetchStudentOrders', {
        year: null,
        month: null,
      })
    }
  },
  created() {
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('resize', this.checkTabletMode)
    })
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/pages/orderView';
</style>
