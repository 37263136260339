<template>
  <div id="app">
    <nav>
      <router-link to="/"></router-link>
    </nav>
    <router-view />
  </div>
</template>

<script>
export default {
  computed: {
    currentLanguage() {
      return this.$store.state.language.currentLanguage
    },
  },
  watch: {
    currentLanguage() {
      this.$i18n.locale = this.currentLanguage
      localStorage.setItem('lang', this.$i18n.locale)
    },
  },
}
</script>
<style lang="scss">
@import '@/assets/scss/index.scss';
</style>
