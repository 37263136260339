export default {
  bind(el, binding) {
    el.addEventListener('input', (event) => {
      const maxLength = binding.value
      const inputText = event.target.value
      if (inputText.length > maxLength) {
        event.target.value = inputText.slice(0, maxLength)
        event.target.dispatchEvent(new Event('input'))
      }
    })
  },
}
