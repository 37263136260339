<template>
  <div class="custom-select">
    <label v-if="label" :for="id" class="label">{{ label }}</label>
    <div class="select-wrapper">
      <div
        class="custom-dropdown"
        :class="[{ error: applyErrorClass }, customClass]"
      >
        <div
          class="selected-option"
          @click="toggleDropdown"
          @blur="handleBlur"
          :style="{ '--before-text-width': beforeTextWidth + 'px' }"
          v-bind="$attrs"
          v-on="$listeners"
        >
          <span v-if="text" class="before-text">{{ text }}</span>
          <p>{{ selectedOptionName }}</p>
        </div>
        <transition name="fade">
          <ul v-if="isDropdownOpen" class="options">
            <base-input
              v-if="searchFilter"
              type="text"
              v-model="searchText"
              placeholder="Search languages..."
              class="options-search"
              customClass="input--search input--search-lang"
              iconClass="input-icon--language"
            />
            <li
              value=""
              key=""
              v-if="!disablePlaceholder"
              @click="selectOption(placeholder)"
            >
              {{ placeholder.name }}
            </li>
            <div v-if="!searchFilter">
              <li
                v-for="option in options"
                :key="option.id"
                @click="selectOption(option)"
              >
                {{ option.name }}
              </li>
            </div>
            <div v-if="searchFilter">
              <li
                v-for="option in filteredOptions"
                :key="option.id"
                @click="selectOption(option)"
              >
                {{ option.name }}
              </li>
            </div>
          </ul>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import baseInput from '../../components/baseInput/baseInput.vue'
export default {
  inheritAttrs: false,
  components: {
    baseInput,
  },
  props: {
    id: Number,
    label: String,
    options: {
      type: Array,
      required: true,
    },
    optionValue: {
      type: String,
      default: 'uuid',
    },
    value: [String, Number],
    placeholder: {
      default: { name: 'Select an option', uuid: '' },
    },
    disablePlaceholder: {
      type: Boolean,
      default: false,
    },
    searchFilter: {
      type: Boolean,
      default: false,
    },
    beforeTextWidth: {
      type: Number,
      default: 0,
    },
    text: {
      type: String,
      default: '',
    },
    applyErrorClass: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isDropdownOpen: false,
      selectedOption: null,
      searchText: '',
    }
  },
  computed: {
    selectedOptionName() {
      const selectedOption = this.options.find(
        (option) => this.getOptionValue(option) === this.value
      )
      return selectedOption
        ? this.getOptionName(selectedOption)
        : this.placeholder.name
    },
    selectedValue() {
      const selectedOption = this.options.find(
        (option) => this.getOptionValue(option) === this.value
      )
      return selectedOption ? this.getOptionValue(selectedOption) : ''
    },
    filteredOptions() {
      const search = this.searchText.toLowerCase()
      return this.options.filter((option) =>
        option.name.toLowerCase().includes(search)
      )
    },
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen
    },
    selectOption(option) {
      this.selectedOption = option
      this.isDropdownOpen = false
      if (this.searchFilter) {
        this.searchText = ''
      }
      this.$emit('input', this.getOptionValue(option))
    },
    getOptionValue(option) {
      return option[this.optionValue]
    },
    getOptionName(option) {
      return option?.name
    },
    handleDocumentClick(event) {
      const dropdownElement = this.$el.querySelector('.custom-dropdown')
      if (!dropdownElement.contains(event.target)) {
        this.isDropdownOpen = false
      }
    },
    handleBlur() {
      this.$emit('blur')
    },
  },
  mounted() {
    document.addEventListener('click', this.handleDocumentClick)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleDocumentClick)
  },
}
</script>

<style lang="scss" scoped>
@import './customSelect';
</style>
