export default {
  namespaced: true,
  state: {
    currentLanguage: localStorage.getItem('lang')
      ? localStorage.getItem('lang')
      : 'al',
  },
  mutations: {
    setCurrentLanguage(state, language) {
      state.currentLanguage = language
      location.reload()
    },
  },
}
