import api from '@/api/api'
import { successMessage, errorMessage } from '@/composables/useMessages'
import i18n from '@/main'
const quizzes = {
  state: {
    quizzes: [],
    quizId: null,
    quizInfo: null,
    quizInfoSt: null,
    noQuiz: false,
    quizScoreData: null,
    certificate: null,
    certificateBoolean: null,
  },

  getters: {
    getCoursesQuizzes: (state) => {
      return state.quizzes
    },
    getQuizId: (state) => {
      return state.quizId
    },
    getNoQuiz: (state) => {
      return state.noQuiz
    },
    getQuizInfo: (state) => {
      if (state.quizInfo) {
        return state.quizInfo
      } else {
        return {
          title: '',
          description: '',
        }
      }
    },
    getQuizInfoSt: (state) => {
      if (state.quizInfoSt) {
        return state.quizInfoSt
      } else {
        return {
          title: '',
          description: '',
        }
      }
    },
    getQuizScoreData: (state) => {
      return state.quizScoreData
    },
    getCertificate: (state) => {
      return state.certificate
    },
    getCertificateBoolean: (state) => {
      return state.certificateBoolean
    },
  },
  mutations: {
    SET_COURSE_QUIZZES(state, quizzes) {
      state.quizzes = quizzes
    },
    SET_QUIZ_ID(state, quizId) {
      state.quizId = quizId
    },
    SET_QUIZ_INFO(state, quizInfo) {
      state.quizInfo = quizInfo
    },
    SET_QUIZ_INFO_STUDENT(state, quizInfoSt) {
      state.quizInfoSt = quizInfoSt
    },
    SET_QUIZ_SCORE(state, data) {
      state.quizScoreData = data
    },
    SET_CERTIFICATE(state, data) {
      state.certificate = data
    },
    SET_CERTIFICATE_BOOLEAN(state, data) {
      state.certificateBoolean = data
    },
  },

  actions: {
    async fetchCourseQuizzes({ commit, dispatch }, courseId) {
      try {
        const res = await api({ requiresAuth: true }).get(
          `/quiz/course/${courseId}`
        )
        commit('SET_COURSE_QUIZZES', res.data)
        if (res.data.length >= 0) {
          const quizUuid = res?.data[0]?.uuid
          commit('SET_QUIZ_ID', quizUuid)
        }
        if (localStorage.getItem('role') == 4) {
          await dispatch('fetchQuizInfoStudent')
        } else {
          await dispatch('fetchQuizInfo')
        }
        return res
      } catch (error) {
        console.error('fetchCourseQuizzes ERROR:', error)
        throw error
      }
    },
    async fetchQuizInfo({ commit, state }) {
      try {
        let quizId = state.quizId
        if (quizId) {
          const res = await api({ requiresAuth: true }).get(
            `/quiz/instructor/${quizId}`
          )
          commit('SET_QUIZ_INFO', res.data)
          state.noQuiz = false
          return res
        } else {
          state.noQuiz = true
        }
      } catch (error) {
        console.error('fetchQuizInfo ERROR:', error)
        throw error
      }
    },
    async fetchQuizInfoStudent({ commit, state }) {
      try {
        let quizId = state.quizId
        if (quizId) {
          const res = await api({ requiresAuth: true }).get(`/quiz/${quizId}`)
          commit('SET_QUIZ_INFO_STUDENT', res.data)
          state.noQuiz = false
          return res
        } else {
          state.noQuiz = true
        }
      } catch (error) {
        console.error('fetchQuizInfoSt ERROR:', error)
        throw error
      }
    },
    async postQuizScore(_, { quizId, credentials }) {
      try {
        const res = await api({ requiresAuth: true }).post(
          `/quiz/users/takeQuiz/${quizId}`,
          credentials
        )
        successMessage.call(this, i18n.t('store.complete_quiz_success'))
        return res
      } catch (error) {
        console.error('postQuizScore ERROR:', error)
        throw error
      }
    },
    async fetchQuizScore({ commit }, quizId) {
      try {
        const res = await api({ requiresAuth: true }).get(
          `/quiz/quizResults/${quizId}`
        )
        commit('SET_QUIZ_SCORE', res.data.score)
        return res
      } catch (error) {
        console.error('fetchQuizScore ERROR:', error)
        throw error
      }
    },
    async createQuiz(_, { courseId, credentials }) {
      try {
        const res = await api({ requiresAuth: true }).post(
          `/quiz/${courseId}`,
          credentials
        )
        successMessage.call(this, i18n.t('store.create_quiz_success'))
        return res
      } catch (error) {
        errorMessage.call(this, i18n.t('store.create_quiz_failed'))
        console.error('createQuiz ERROR:', error)
        throw error
      }
    },
    async updateQuiz({ commit, state }, credentials) {
      try {
        let quizId = state.quizId
        const res = await api({ requiresAuth: true }).patch(
          `/quiz/${quizId}`,
          credentials
        )
        commit('SET_QUIZ_INFO', res.data)
        successMessage.call(this, i18n.t('store.update_quiz_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.update_quiz_failed'))
        console.error('UPDATED QUIZ ERROR:', error.response.data.message)
        throw error
      }
    },
    async deleteQuiz({ state }) {
      try {
        let quizId = state.quizId
        const res = await api({ requiresAuth: true }).delete(`/quiz/${quizId}`)
        successMessage.call(this, i18n.t('store.delete_quiz_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.delete_quiz_failed'))
        console.error('DELETE QUIZ ERROR:', error.response.data.message)
        throw error
      }
    },
    async updateQuestion(_, { questionId, credentials }) {
      try {
        const res = await api({ requiresAuth: true }).patch(
          `/question/${questionId}`,
          credentials
        )
        successMessage.call(this, i18n.t('store.update_question_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.update_question_error'))
        console.error('UPDATED QUESTION ERROR:', error.response.data.message)
        throw error
      }
    },
    async deleteQuestion(_, { questionId }) {
      try {
        const res = await api({ requiresAuth: true }).delete(
          `/question/${questionId}`
        )
        successMessage.call(this, i18n.t('store.delete_question_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.delete_question_failed'))
        console.error('DELETE QUESTION ERROR:', error.response.data.message)
        throw error
      }
    },
    async addQuestion({ state }, credentials) {
      try {
        let quizId = state.quizId
        const res = await api({ requiresAuth: true }).post(
          `/quiz/addQuestionsToQuiz/${quizId}`,
          credentials
        )
        successMessage.call(this, i18n.t('store.add_question_success'))
        return res.data
      } catch (error) {
        console.error('ADD QUESTION ERROR:', error.response?.data?.message)
        throw error
      }
    },
    async fetchCertificate({ commit }, courseId) {
      try {
        const res = await api({ requiresAuth: true }).get(
          `/quiz/generateCertificate/${courseId}`
        )
        commit('SET_CERTIFICATE', res.data)
        return res.data
      } catch (error) {
        console.error('fetchCertificate ERROR:', error)
        throw error
      }
    },
    async fetchBooleanCertificate({ commit }, courseId) {
      try {
        const res = await api({ requiresAuth: true }).get(
          `/course/hasCertificate/${courseId}`
        )
        commit('SET_CERTIFICATE_BOOLEAN', res.data)
        return res
      } catch (error) {
        console.error('fetchCertificate Boolean Value ERROR:', error)
        throw error
      }
    },
  },
}

export default quizzes
