import api from '@/api/api'
import { successMessage, errorMessage } from '@/composables/useMessages'
import i18n from '@/main'

const categories = {
  state: {
    categories: [],
    category: {},
    allCategories: [],
    searchQuery: '',
    page: 1,
    limit: 5,
    totalCount: 0,
    totalPages: null,
  },

  getters: {
    getCategories: (state) => state.categories,
    getCategory: (state) => state.category,
    getAllCategories: (state) => state.allCategories,
    getCategoriesTotalCount: (state) => state.totalCount,
  },

  mutations: {
    SET_CATEGORIES(state, categories) {
      state.categories = categories
    },
    SET_CATEGORY(state, category) {
      state.category = category
    },
    SET_ALL_CATEGORIES(state, categories) {
      state.allCategories = categories
    },
    SET_SEARCH_QUERY(state, query) {
      state.searchQuery = query
    },
    SET_PAGE(state, page) {
      state.page = page
    },
    SET_LIMIT(state, limit) {
      state.limit = limit
    },
    SET_TOTAL_COUNT(state, totalCount) {
      state.totalCount = totalCount
    },
    SET_TOTAL_PAGES(state, total) {
      state.totalPages = total
    },
  },

  actions: {
    setSearchQuery({ commit }, query) {
      commit('SET_SEARCH_QUERY', query)
    },
    setPage({ commit }, page) {
      commit('SET_PAGE', page)
    },
    setLimit({ commit }, limit) {
      commit('SET_LIMIT', limit)
    },
    async fetchAllCategories({ commit }) {
      try {
        let allCategories = []
        let page = 1
        let pageSize = 10
        while (page <= pageSize) {
          const res = await api({ requiresAuth: true }).get(
            `/category?page=${page}`
          )
          const categories = res.data.data
          pageSize = res.data.lastPage

          if (categories.length === 0) {
            break
          }
          allCategories = allCategories.concat(categories)
          page++
        }
        commit('SET_ALL_CATEGORIES', allCategories)
        return allCategories
      } catch (error) {
        console.error('GETTING CATEGORIES ERROR:', error.response.data.message)
        throw error
      }
    },

    async fetchCategories({ commit, state }, payload) {
      try {
        const { page, limit } = state
        let res
        if (payload && payload.searchQuery) {
          const { searchQuery } = payload
          res = await api({ requiresAuth: true }).get(
            `/category?name=${searchQuery}&page=${page}&limit=${limit}`
          )
        } else {
          const { searchQuery } = state
          if (!limit) {
            res = await api({ requiresAuth: true }).get(
              `/category?page=${page}`
            )
          } else {
            res = await api({ requiresAuth: true }).get(
              `/category?name=${searchQuery}&page=${page}&limit=${limit}`
            )
          }
        }
        const data = JSON.parse(JSON.stringify(res.data.data))
        commit('SET_CATEGORIES', data)
        commit('SET_TOTAL_COUNT', res.data.totalCount)
        let totalPages
        totalPages = Math.ceil(res.data.totalCount / limit)
        commit('SET_TOTAL_PAGES', totalPages)
        return data
      } catch (error) {
        console.error('GETTING CATEGORIES ERROR:', error.response.data.message)
        throw error
      }
    },
    async addCategory({ commit }, categoryData) {
      try {
        const res = await api({
          requiresAuth: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).post('/category', categoryData)
        commit('SET_CATEGORIES', res.data)
        successMessage.call(this, i18n.t('store.add_category_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.add_category_failed'))
        console.error('ADDING CATEGORY ERROR:', error.response.data.message)
        throw error
      }
    },
    async fetchCategory({ commit }, categoryID) {
      try {
        const res = await api({ requiresAuth: true }).get(
          `/category/${categoryID}`
        )
        commit('SET_CATEGORY', res.data)
        return res.data
      } catch (error) {
        console.error('GETTING CATEGORY ERROR:', error.response.data.message)
        throw error
      }
    },
    async editCategory(_, { categoryID, categoryData }) {
      try {
        const res = await api({ requiresAuth: true }).patch(
          `/category/${categoryID}`,
          categoryData
        )
        successMessage.call(this, i18n.t('store.update_category_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.update_category_failed'))
        console.error('UPDATING CATEGORY ERROR:', error.response.data.message)
        throw error
      }
    },
    async deleteCategory(_, categoryID) {
      try {
        const res = await api({ requiresAuth: true }).delete(
          `/category/${categoryID}`
        )
        successMessage.call(this, i18n.t('store.delete_category_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.delete_category_failed'))
        console.error('DELETING CATEGORY ERROR:', error.response.data.message)
        throw error
      }
    },
  },
}

export default categories
