<template>
  <div>
    <Modal v-show="isModalVisible" @close-modal="closeModal">
      <template v-slot:modalContent>
        <div class="modal__title">
          <ul class="modal__tabs">
            <li
              v-for="(tab, index) in filteredTabs"
              :key="index"
              class="modal__tab"
              :class="{ 'modal__tab--active': activeTab === index }"
              @click.prevent="setActiveTab(index)"
            >
              {{ tab }}
            </li>
          </ul>
        </div>

        <div
          v-show="activeTab === filteredTabs.indexOf($t('modals.edit_user'))"
          @click.stop
        >
          <form class="modal__form" @submit.prevent="updateUsers">
            <div class="modal__group">
              <baseInput
                id="firstNameEdit"
                type="text"
                placeholder="First Name"
                v-model="updatedUser.firstName"
                customClass="input--modal"
                :applyErrorClass="$v.updatedUser.firstName.$error"
                @clear-error="clearGeneralError"
              ></baseInput>
              <template v-if="$v.updatedUser.firstName.$error">
                <p
                  class="modal__errorMsg error"
                  v-if="!$v.updatedUser.firstName.required"
                >
                  {{ $t('modals.first_name_req') }}
                </p>
              </template>
            </div>
            <div class="modal__group">
              <baseInput
                id="lastNameEdit"
                type="text"
                placeholder="Last Name"
                v-model="updatedUser.lastName"
                customClass="input--modal"
                :applyErrorClass="$v.updatedUser.lastName.$error"
                @clear-error="clearGeneralError"
              ></baseInput>
              <template v-if="$v.updatedUser.lastName.$error">
                <p
                  class="modal__errorMsg error"
                  v-if="!$v.updatedUser.lastName.required"
                >
                  {{ $t('modals.last_name_req') }}
                </p>
              </template>
            </div>
            <div class="modal__group">
              <baseInput
                id="usernameEdit"
                type="text"
                placeholder="Username"
                v-model="updatedUser.username"
                customClass="input--modal"
                :applyErrorClass="$v.updatedUser.username.$error"
                @clear-error="clearGeneralError"
              ></baseInput>
              <template v-if="$v.updatedUser.username.$error">
                <p
                  class="modal__errorMsg error"
                  v-if="!$v.updatedUser.username.required"
                >
                  {{ $t('modals.user_req') }}
                </p>
              </template>
            </div>
            <div class="modal__group">
              <baseInput
                id="emailEdit"
                type="text"
                placeholder="Email"
                v-model="updatedUser.email"
                customClass="input--modal"
                :applyErrorClass="$v.updatedUser.email.$error"
                @clear-error="clearGeneralError"
              ></baseInput>
              <template v-if="$v.updatedUser.email.$error">
                <p
                  class="modal__errorMsg error"
                  v-if="!$v.updatedUser.email.required"
                >
                  {{ $t('modals.email_req') }}
                </p>
                <p
                  class="modal__errorMsg error"
                  v-if="!$v.updatedUser.email.email"
                >
                  {{ $t('modals.email_req_valid') }}
                </p>
              </template>
            </div>
            <div class="modal__group">
              <baseButton
                type="submit"
                buttonClass="button--primary button--modal"
                :disabled="submitting"
              >
                {{ $t('createQuiz.update') }}</baseButton
              >
              <p v-if="error" class="modal__errorMsg error">
                {{ generalError }}
              </p>
            </div>
          </form>
        </div>
        <!-- <div
          v-show="activeTab === filteredTabs.indexOf('Change Role')"
          @click.stop
        >
          <div class="modal__changeRole">
            <form action="#" class="modal__radio">
              <p>
                <input
                  type="radio"
                  id="admin"
                  name="radio-group"
                  value="2"
                  v-model="selectedRole"
                />
                <label for="admin">Admin</label>
              </p>
              <p>
                <input
                  type="radio"
                  id="Instructor"
                  name="radio-group"
                  value="3"
                  v-model="selectedRole"
                />
                <label for="Instructor">Instructor</label>
              </p>
              <p>
                <input
                  type="radio"
                  id="Student"
                  name="radio-group"
                  value="4"
                  v-model="selectedRole"
                />
                <label for="Student">Student</label>
              </p>
            </form>
            <span
              v-if="isUserRoleMatched"
              class="modal__errorMsgRole error-msg"
            >
              Can't choose the same role. Please pick another one</span
            >
            <baseButton
              v-if="isSuperAdmin"
              buttonClass="button--primary u-btn btn--admin"
              @click="showUserRoleModal"
              :disabled="isUserRoleMatched"
              >UPDATE</baseButton
            >
          </div>
        </div> -->
      </template>
    </Modal>
    <!-- <transition name="modal-fade">
      <baseModal
        v-if="showUserRole"
        :content="showModalContent"
        @confirm-action="updateUserRole()"
        @close-modal="showUserRole = false"
      />
    </transition> -->
  </div>
</template>

<script>
import baseInput from '@/components/baseInput/baseInput.vue'
import baseButton from '@/components/baseButton/baseButton.vue'
import { required, email } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
// import baseModal from '@/components/baseModal/baseModal.vue'
import Modal from '@/components/modalBase/modalBase.vue'

export default {
  components: {
    baseInput,
    baseButton,
    Modal,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
    userRole: {
      type: Number,
      required: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isModalVisible: false,
      // tabs: [this.$t('modals.edit_user'), 'Change Role'],
      tabs: [this.$t('modals.edit_user')],
      activeTab: 0,
      updatedUser: {
        firstName: '',
        lastName: '',
        username: '',
        email: '',
      },
      userID: null,
      userEmail: '',
      // selectedRole: '',
      // showUserRole: false,
      generalError: '',
      error: false,
      submitting: false,
    }
  },
  validations() {
    return {
      updatedUser: {
        firstName: {
          required,
        },
        lastName: {
          required,
        },
        username: {
          required,
        },
        email: {
          required,
          email,
        },
      },
    }
  },
  watch: {
    user: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.updatedUser = { ...newValue }
        this.userEmail = newValue.email
      },
    },
  },

  computed: {
    anyError() {
      return this.$v.$anyError
    },
    filteredTabs() {
      // if (this.isSuperAdmin) {
      //   return this.tabs
      // } else {
      //   return this.tabs.filter((tab) => tab !== 'Change Role')
      // }
      return this.tabs
    },
    // isUserRoleMatched() {
    //   return this.selectedRole === this.userRole.toString()
    // },
  },

  methods: {
    ...mapActions([
      'fetchAdmins',
      'updateUserRole',
      'fetchApprovedInstructors',
    ]),
    setActiveTab(index) {
      this.activeTab = index
    },
    async updateUsers() {
      try {
        if (Object.values(this.updatedUser).every((value) => !value)) {
          this.generalError = 'Please fill in at least one field.'
          return
        }
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }
        const updatedData = {}
        const userProperties = Object.keys(this.updatedUser)

        userProperties.forEach((property) => {
          if (property === 'email') {
            if (this.updatedUser[property] !== this.userEmail) {
              updatedData[property] = this.updatedUser[property]
            }
          } else {
            if (this.updatedUser[property] !== this.user[property]) {
              updatedData[property] = this.updatedUser[property]
            }
          }
        })

        if (Object.keys(updatedData).length === 0) {
          this.closeModal()
          return
        }
        this.submitting = true
        await this.$store.dispatch('updateUser', {
          userId: this.userId,
          updateUser: updatedData,
        })
        this.submitting = false
        this.closeModal()
        if (this.isSuperAdmin) {
          this.fetchAdmins({ page: 1, limit: 10 })
        } else if (this.isAdmin) {
          await this.fetchApprovedInstructors()
        }
      } catch (err) {
        this.submitting = false
        this.error = true
        let message = err.response?.data?.message
        if (err.response && err.response.status === 400) {
          if (message == 'username already exists') {
            this.generalError = ' Username already exists! Please try again'
          } else if (message == 'email already exists') {
            this.generalError = ' Email already exists! Please try again'
          } else {
            this.generalError = 'Please enter valid inputs'
          }
        } else {
          this.generalError = 'Something went wrong.Please try again later'
        }
      }
    },
    // showUserRoleModal() {
    //   this.showModalContent = {
    //     message: this.$t('showModalContent.change_instructor_role'),
    //     submit: this.$t('showModalContent.change_role'),
    //   }
    //   this.showUserRole = !this.showUserRole
    // },
    // async updateUserRole() {
    //   try {
    //     const selectedRole = document.querySelector(
    //       'input[name="radio-group"]:checked'
    //     ).value
    //     await this.$store.dispatch('updateUserRole', {
    //       userId: this.userId,
    //       newRole: selectedRole,
    //     })
    //     this.fetchAdmins({ page: 1, limit: 10 })
    //     this.closeModal()
    //   } catch (error) {
    //     console.error('update User Role modali ERRORI', error)
    //   }
    // },
    clearGeneralError() {
      this.generalError = ''
      this.error = false
    },

    startCloseTimer() {
      setTimeout(() => {
        this.closeModal()
      }, 1500)
    },
    openModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
      this.clearGeneralError()
      this.$emit('close-modal')
    },
  },
}
</script>

<style lang="scss" scoped>
@import './editUserModal.scss';
</style>
