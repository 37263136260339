import api from '@/api/api'
const auth = {
  state: {
    user: null,
    role: 0,
  },

  getters: {
    loggedIn: (state) => !!state.user,
    role: (state) => state.role,
  },

  mutations: {
    SET_USER_DATA(state, userData) {
      state.user = userData
    },
    SET_USER_ROLE(state, role) {
      state.role = role
    },
    CLEAR_USER_DATA() {
      localStorage.removeItem('user')
      localStorage.removeItem('role')
      location.reload()
    },
  },

  actions: {
    async register(_, credentials) {
      try {
        const res = await api({ requiresAuth: false }).post(
          '/auth/register',
          credentials
        )
        return res.data
      } catch (error) {
        console.error('LOGIN ERROR:', error.response.data.message)
        throw error
      }
    },
    async login({ commit }, credentials) {
      try {
        const res = await api({ requiresAuth: false }).post(
          '/auth/login',
          credentials
        )
        commit('SET_USER_DATA', res.data)
        commit('SET_USER_ROLE', res?.data.user.role)
        return res.data
      } catch (error) {
        console.error('LOGIN ERROR:', error.response.data.message)
        throw error
      }
    },
    logout({ commit }) {
      commit('CLEAR_USER_DATA')
    },
    async forgetPassword(_, data) {
      try {
        const response = await api({ requiresAuth: false }).post(
          '/user/forgot',
          data
        )
        return response
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    async resetPassword(_, payload) {
      try {
        const { token, data } = payload
        let res = await api({ requiresAuth: true }).post(
          `/user/reset/${token}`,
          data
        )
        return res
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    async recoverAccount(_, data) {
      try {
        const response = await api({ requiresAuth: false }).post(
          '/user/enableAccountRequest',
          data
        )
        return response
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    async verifyAccount(_, payload) {
      try {
        const response = await api({ requiresAuth: false }).post(
          `/auth/verifyAccount/${payload.token}`,
          payload.data
        )
        return response
      } catch (error) {
        console.error(error)
        throw error
      }
    },
  },
}

export default auth
