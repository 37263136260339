<template>
  <div class="chapters">
    <div v-for="(chapter, index) in chapters" :key="index">
      <div class="chapters-card" @click="toggleChapter(chapter)">
        <div class="chapters-card--flex">
          <p>
            {{ $t('singleCourse.section') }} {{ index + 1 }}:
            {{ chapter.name }}
          </p>
          <img
            src="../../assets/icons/arrow-down.svg"
            :class="{ 'chapters-card__img': isChapterOpen(chapter.uuid) }"
          />
        </div>
        <div class="chapters-card__text">
          <div v-if="chapter.duration !== null">
            <img src="../../assets/icons/time.svg" />{{ chapter.duration }}
          </div>
          <loaderElement
            v-if="isLoading[chapter.uuid] == true"
            :customClass="'loader--small'"
            :custom-spinner="'loader-spinner--small'"
          />
          <p v-else @click="initiateAI(chapter.uuid)">
            {{ $t('singleCourse.want_to_learn_more') }}?
          </p>
        </div>
      </div>
      <div v-if="isChapterOpen(chapter.uuid)">
        <div
          class="chapters__text"
          v-for="(video, videoIndex) in chapter.videos"
          :key="videoIndex"
          @click="emitUuid(video.uuid)"
          :class="{ selected: isActiveVideo(video.uuid) }"
        >
          <div class="chapters__text-content">
            <img
              v-if="isStudent"
              :src="
                getBeenWatchedArray(video.uuid)
                  ? require('../../assets/icons/checkbox-checked-filled.svg')
                  : require('../../assets/icons/checkbox-unchecked-unfilled.svg')
              "
            />
            <p class="chapters__text--padding">{{ video.name }}</p>
          </div>
          <p
            class="chapters__text--flex"
            :class="{ 'chapters__text--remove': !isStudent }"
          >
            {{ video.duration }}
          </p>
        </div>
      </div>
    </div>
    <div class="chapters--found" v-if="chapters?.length == 0">
      <p>{{ $t('singleCourse.no_chapters') }}.</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import loaderElement from '../../components/loader/loaderElement.vue'
export default {
  components: {
    loaderElement,
  },
  data() {
    return {
      openChapters: [],
      activeVideoUuid: null,
      isLoading: [],
    }
  },
  computed: {
    ...mapGetters([
      'getChapters',
      'getChaptersAIInfo',
      'getLastWatched',
      'getBeenWatchedArray',
    ]),
    chapters() {
      return this.getChapters
    },
    chaptersAIInfo() {
      return this.$store.getters.getChaptersAIInfo
    },
    lastWatched() {
      return this.getLastWatched
    },
  },
  methods: {
    async toggleChapter(chapter) {
      const index = this.openChapters.indexOf(chapter.uuid)
      if (index !== -1) {
        this.openChapters.splice(index, 1)
      } else {
        this.openChapters.push(chapter.uuid)
      }
    },
    isChapterOpen(id) {
      return this.openChapters.includes(id)
    },
    async emitUuid(videoUuid) {
      this.$store.commit('UPDATE_BEEN_WATCHED', {
        uuid: videoUuid,
        boolean: true,
      })
      await this.$store.dispatch('postWatchVideo', videoUuid)
      this.activeVideoUuid = videoUuid
      if (this.$route.params.videoId !== videoUuid) {
        this.$router.push(
          `/my-courses/single/${this.$route.params.id}/${videoUuid}`
        )
        this.$emit('emit-uuid')
      }
    },
    async initiateAI(chapterId) {
      event.stopPropagation()
      this.$set(this.isLoading, chapterId, true)
      await this.$store.dispatch('getAIGeneratedInfo', chapterId)
      this.$nextTick(() => {
        this.$set(this.isLoading, chapterId, false)
      })
      this.$emit('AI-popup', chapterId)
    },
    isActiveVideo(videoUuid) {
      return this.activeVideoUuid == videoUuid
    },
  },
  async created() {
    if (this.$route.query.chapterId) {
      this.activeVideoUuid = this.$route.params.videoId
      this.openChapters.push(this.$route.query.chapterId)
    } else {
      this.activeVideoUuid = this.lastWatched.uuid
        ? this.lastWatched.uuid
        : null
      this.openChapters.push(this.lastWatched.chapter?.uuid)
      for (let chapter of this.chapters) {
        for (let video of chapter.videos) {
          let response = await this.$store.dispatch(
            'hasBeenWatched',
            video.uuid
          )
          this.$store.commit('UPDATE_BEEN_WATCHED', {
            uuid: video.uuid,
            boolean: response,
          })
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import './chapterCard';
</style>
