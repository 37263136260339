<template>
  <div class="single">
    <div class="singleCourse">
      <loaderElement
        :class="{ singleCourse__loader: isOpen }"
        v-if="isLoading"
      />
      <video
        v-if="videoData?.videoFile !== null && showVideo"
        class="singleCourse__video"
        :class="{ 'singleCourse__video--width': isOpen }"
        controls
        controlsList="nodownload"
      >
        <source
          :src="`https://api.lms.k-clients.com/public/uploads/${videoData.videoFile}`"
        />
      </video>
      <div
        class="singleCourse__video"
        :class="{ 'singleCourse__video--width': isOpen }"
        v-if="videoData?.videoLink !== null && showVideo"
      >
        <iframe
          :src="`https://www.youtube.com/embed/${videoData.youtubeId}`"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
      <Sidebar
        @fetch-video="fetchData"
        @AI-show="conversationAI($event)"
        @quiz-popup="popupShow($event)"
        @status-popup="popupStatus($event)"
      />
      <transition name="modal-fade">
        <baseModal
          v-if="quizResults"
          :modalClass="'modal__content--quiz'"
          :content="showModalContent()"
          :are-you-sure="false"
          :submit-value="hasCertificate()"
          :score="score"
          @close-modal="quizResults = false"
          @confirm-action="goToCertificate()"
        />
      </transition>
      <transition name="modal-fade">
        <baseModal
          v-if="courseCompletion"
          :modalClass="'modal__content--quiz'"
          :content="courseCompletionModal"
          :are-you-sure="false"
          :show-score="false"
          :submit-value="false"
          @close-modal="courseCompletion = false"
        />
      </transition>
      <transition name="modal-fade">
        <openAIModal
          ref="openAI"
          :chapterId="chapterId"
          @close-modal="aiModel = false"
        />
      </transition>
    </div>
    <ul class="single__tabs" v-if="isStudent">
      <li
        v-for="(tab, index) in tabs"
        :key="index"
        class="single__tab"
        :class="{ 'single__tab--active': activeTab === index }"
        @click="changeTab(index)"
      >
        {{ tab }}
      </li>
    </ul>
    <ul class="single__tabs" v-else>
      <li
        v-for="(tab, index) in tabs.shift"
        :key="index"
        class="single__tab"
        :class="{ 'single__tab--active': activeTab === index }"
        @click="changeTab(index)"
      >
        {{ tab }}
      </li>
    </ul>
    <div class="container">
      <div
        class="single__section"
        v-if="activeTab === 0"
        :class="{ 'single__section--open': isOpen }"
      >
        <div class="single__section-content">
          <base-input
            :customClass="'input--height'"
            :placeholder="$t('singleCourse.add_title_comment')"
            v-model="title"
            v-limit-characters="200"
            v-if="isStudent"
          />
          <div class="single__section-content--characters" v-if="isStudent">
            {{ remainingPositionCharacters }}
          </div>
          <div v-if="isStudent">
            <text-area
              :placeholder="$t('singleCourse.add_desc_comment')"
              v-model="description"
            />
            <buttonElement
              buttonClass="button--comment"
              @click="postComment"
              :disabled="isCommentButtonDisabled"
              >{{ $t('singleCourse.comment') }}</buttonElement
            >
          </div>
          <div class="single__section-filter">
            <div class="single__section-content--length">
              {{ $t('singleCourse.all_comment_in_course') }}
              <p>({{ questionsTotalCount }})</p>
            </div>
            <base-select
              v-if="isStudent"
              :options="filters"
              :placeholder="$t('singleCourse.filter_by')"
              :customClass="'select-wrapper--comments'"
              :value="filterValue"
              :optionValue="'name'"
              @change="setFilter($event.target.value)"
            />
          </div>
          <commentSection
            v-if="showComments"
            :notification="hasNotificationComment"
            :questions="
              filterValue == this.$t('singleCourse.my_comments')
                ? myQuestions
                : questions
            "
            :questionsLastPage="questionsLastPage"
            :questionsTotalCount="questionsTotalCount"
            :filterValue="filterValue"
          />
        </div>
      </div>
      <reviewsSingle v-if="activeTab === 1"></reviewsSingle>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Sidebar from '../components/singleCourseSideBar/singleCourseSideBar.vue'
import baseModal from '@/components/baseModal/baseModal.vue'
import openAIModal from '@/components/openAIModal/openAIModal.vue'
import loaderElement from '@/components/loader/loaderElement.vue'
import buttonElement from '@/components/baseButton/baseButton.vue'
import commentSection from '@/components/studentCommentSection/studentCommentSection.vue'
import baseInput from '@/components/baseInput/baseInput.vue'
import textArea from '@/components/baseTextarea/textAreaComments.vue'
import baseSelect from '@/components/baseSelect/baseSelect.vue'
import reviewsSingle from '@/components/reviewsSingle/reviewsSingle.vue'

export default {
  components: {
    Sidebar,
    baseModal,
    openAIModal,
    loaderElement,
    buttonElement,
    commentSection,
    textArea,
    baseSelect,
    reviewsSingle,
    baseInput,
  },
  data() {
    return {
      hasNotificationComment: true,
      questionsToFilter: [],
      showComments: false,
      videoData: null,
      showVideo: false,
      quizResults: false,
      disable: null,
      courseCompletion: false,
      aiModel: false,
      showModalContent() {
        if (this.certificateBoolean) {
          return {
            message: this.$t('singleCourse.course_complete_evaluation'),
            submit: this.$t('singleCourse.get_certificate'),
          }
        } else {
          return {
            message: this.$t('singleCourse.course_complete_evaluation'),
          }
        }
      },
      courseCompletionModal: {
        message: this.$t('singleCourse.course_completion_modal'),
      },
      hasCertificate() {
        if (this.certificateBoolean) {
          return true
        } else {
          return false
        }
      },
      filters: [
        { name: this.$t('singleCourse.all_comments') },
        { name: this.$t('singleCourse.my_comments') },
      ],
      isLoading: false,
      title: '',
      description: '',
      filterValue: '',
      tabs: [
        this.$t('singleCourse.comment_section_2'),
        this.$t('singleCourse.reviews'),
      ],
      activeTab: 0,
      chapterId: null,
    }
  },
  computed: {
    remainingPositionCharacters() {
      const maxLength = 200
      return maxLength - this.title.length
    },
    ...mapGetters([
      'getVideos',
      'getChapters',
      'getQuizScoreData',
      'getQuestions',
      'getMyQuestions',
      'getQuestionsLastPage',
      'getQuestionsTotalCount',
      'getSingleComment',
      'getFilterType',
      'getLastWatched',
      'getCertificateBoolean',
    ]),
    videos() {
      return this.getVideos
    },
    singleComment() {
      return this.getSingleComment
    },
    isOpen() {
      return this.$store.state.videos.isOpen
    },
    chapters() {
      return this.getChapters
    },
    score() {
      return this.getQuizScoreData
    },
    questions() {
      return this.getQuestions
    },
    myQuestions() {
      return this.getMyQuestions
    },
    isCommentButtonDisabled() {
      return this.title.trim() == '' || this.disable == true
    },
    questionsTotalCount() {
      return this.getQuestionsTotalCount
    },
    questionsLastPage() {
      return this.getQuestionsLastPage
    },
    filterType() {
      return this.getFilterType
    },
    lastWatched() {
      return this.getLastWatched
    },
    certificateBoolean() {
      return this.getCertificateBoolean
    },
  },
  async mounted() {
    await this.$nextTick()
    await this.$store.dispatch('fetchChapters', this.$route.params.id)
    await this.$store.dispatch('getLastWatched', this.$route.params.id)
    this.isLoading = true
    this.showVideo = false
    if (this.$route.query.chapterId) {
      await this.$store.dispatch('fetchVideos', this.$route.params.videoId)
    } else if (this.lastWatched.uuid) {
      await this.$store.dispatch('fetchVideos', this.lastWatched.uuid)
    } else {
      if (
        this.chapters.length !== 0 &&
        this.chapters[0].videos &&
        this.chapters[0].videos.length > 0
      ) {
        await this.$store.dispatch(
          'fetchVideos',
          this.getFirstVideoFromChapter()
        )
      }
    }
    this.isLoading = false
    this.showComments = false
    const fetchBooleanCertificatePromise = this.$store.dispatch(
      'fetchBooleanCertificate',
      this.$route.params.id
    )

    this.$store.commit('SET_QUESTIONS', [])

    const fetchQuestionsPromise = this.$store.dispatch('fetchQuestions', {
      courseId: this.$route.params.id,
      page: 1,
    })

    let fetchAllQuestionsPromise

    if (this.$route.query.commentId) {
      fetchAllQuestionsPromise = this.$store.dispatch('fetchAllQuestions', {
        commentId: this.$route.query.commentId,
      })
    }
    await Promise.all([
      fetchBooleanCertificatePromise,
      fetchQuestionsPromise,
      fetchAllQuestionsPromise,
    ])
    this.showComments = true
  },
  methods: {
    async fetchData() {
      this.showVideo = false
      if (this.showVideo == false) {
        this.isLoading = true
      }
      await this.$store.dispatch('fetchVideos', this.$route.params.videoId)
      this.isLoading = false
    },
    getFirstVideoFromChapter() {
      try {
        return this.chapters[0].videos[0].uuid
      } catch (error) {
        console.error('Error fetching the 1st video of the 1st chapter:', error)
        throw error
      }
    },
    openAI() {
      this.$refs.openAI.openModal()
    },
    goToCertificate() {
      this.$router.push(`/getCertificate/${this.$route.params.id}`)
    },
    async popupShow(quizId) {
      await this.$store.dispatch('fetchQuizScore', quizId)
      this.quizResults = true
    },
    popupStatus() {
      this.courseCompletion = true
    },
    async conversationAI(chapterId) {
      this.openAI()
      this.chapterId = chapterId
      this.aiModel = true
    },
    async postComment() {
      this.disable = true
      if (this.title.trim() == '') {
        return
      }
      await this.$store.dispatch('postQuestion', {
        courseId: this.$route.params.id,
        data: { title: this.title, description: this.description },
      })
      this.title = ''
      this.description = ''
      this.hasNotificationComment = false
      this.disable = false
    },
    async setFilter(filterValue) {
      this.showComments = false
      this.filterValue = filterValue
      this.$store.commit('SET_FILTER_TYPE', filterValue)
      if (this.filterValue == this.$t('singleCourse.my_comments')) {
        this.$store.commit('SET_MY_QUESTIONS', [])
        await this.$store.dispatch('fetchMyQuestions', {
          courseId: this.$route.params.id,
          page: 1,
        })
      } else {
        this.$store.commit('SET_QUESTIONS', [])
        await this.$store.dispatch('fetchQuestions', {
          courseId: this.$route.params.id,
          page: 1,
        })
      }
      this.showComments = true
    },
    changeTab(index) {
      this.activeTab = index
    },
  },
  watch: {
    '$i18n.locale': function (newLocale) {
      if (newLocale)
        this.tabs = [
          this.$t('singleCourse.comment_section_2'),
          this.$t('singleCourse.reviews'),
        ]
    },
    videos(newValue) {
      if (newValue) {
        this.videoData = newValue
        this.showVideo = true
      }
    },
    '$route.query.commentId'(newVal) {
      if (newVal !== '' && newVal !== undefined && newVal !== null)
        location.reload()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/pages/studentSingleCourse';
</style>
