import api from '@/api/api'

const videos = {
  state: {
    isOpen: true,
    isArrow: false,
    videosList: {},
    isMobile: false,
    lastWatched: {},
    watchedBoolean: null,
    beenWatchedArray: [],
  },
  getters: {
    getVideos: (state) => state.videosList,
    isMobile: (state) => state.isMobile,
    getLastWatched: (state) => state.lastWatched,
    getHasBeenWatched: (state) => state.watchedBoolean,
    getBeenWatchedArray: (state) => (uuid) => {
      return state.beenWatchedArray[uuid]
    },
  },
  mutations: {
    toggleSidebar(state) {
      state.isOpen = !state.isOpen
      state.isArrow = !state.isArrow
    },
    SET_VIDEOS(state, videosList) {
      state.videosList = videosList
    },
    SET_IS_MOBILE(state, isMobile) {
      state.isMobile = isMobile
    },
    LAST_WATCHED(state, lastWatched) {
      state.lastWatched = lastWatched
    },
    HAS_BEEN_WATCHED(state, watchedBoolean) {
      state.watchedBoolean = watchedBoolean
    },
    UPDATE_BEEN_WATCHED(state, payload) {
      const { uuid, boolean } = payload
      state.beenWatchedArray[uuid] = boolean
    },
  },
  actions: {
    async fetchVideos({ commit }, id) {
      try {
        const res = await api({ requiresAuth: true }).get(`/video/${id}`)
        commit('SET_VIDEOS', res.data)
        return res.data
      } catch (error) {
        console.error('GETTING VIDEOS ERROR:', error.response.data.message)
        throw error
      }
    },
    async postWatchVideo(_, id) {
      try {
        const res = await api({ requiresAuth: true }).post(`/video/watch/${id}`)
        return res.data
      } catch (error) {
        console.error('Posting video watch error', error.response.data.message)
        throw error
      }
    },
    async getLastWatched({ commit }, id) {
      try {
        const res = await api({ requiresAuth: true }).get(
          `/video/lastWatched/${id}`
        )
        commit('LAST_WATCHED', res.data)
        return res.data
      } catch (error) {
        console.error('Getting last watched error', error.response.data.message)
        throw error
      }
    },
    async hasBeenWatched({ commit }, id) {
      try {
        const res = await api({ requiresAuth: true }).get(
          `/video/hasBeenWatched/${id}`
        )
        commit('HAS_BEEN_WATCHED', res.data)
        return res.data
      } catch (error) {
        console.error('Has been watched error:', error.response.data.message)
        throw error
      }
    },
  },
}

export default videos
