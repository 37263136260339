import api from '@/api/api'
// import { roleAliases } from '../store/modules/roleAliases'
import user from './user'
import { successMessage, errorMessage } from '@/composables/useMessages'
import i18n from '@/main'

const instructors = {
  state: {
    approvedInstructors: [],
    pendingInstructors: [],
    disapprovedInstructors: [],
    searchQuery: '',
    page: 1,
    limit: 5,
    totalCount: 0,
    topInstructorLimit: 1,
    topInstructorInfo: {},
    loading: false,
    filterValue: '',
    totalViews: null,
    totalPages: null,
  },

  getters: {
    getApprovedInstructors: (state) => {
      if (state.approvedInstructors.length > 0) {
        return JSON.parse(JSON.stringify(state.approvedInstructors))
      } else {
        return {
          firstName: '',
          lastName: '',
          username: '',
          bio: '',
        }
      }
    },
    getPendingInstructors: (state) => {
      if (state.pendingInstructors.length > 0) {
        return JSON.parse(JSON.stringify(state.pendingInstructors))
      } else {
        return {
          firstName: '',
          lastName: '',
          username: '',
          bio: '',
        }
      }
    },
    getDisapprovedInstructors: (state) => {
      if (state.disapprovedInstructors.length > 0) {
        return JSON.parse(JSON.stringify(state.disapprovedInstructors))
      } else {
        return {
          firstName: '',
          lastName: '',
          username: '',
          bio: '',
        }
      }
    },
    getInstructorsPage: (state) => state.page,
    getInstructorsLimit: (state) => state.limit,
    getInstructorsTotalCount: (state) => state.totalCount,
    getTopInstructorInfo: (state) => state.topInstructorInfo,
    getLoadingInstructors: (state) => state.loading,
    getTotalViews: (state) => state.totalViews,
    getTotalPages: (state) => state.totalPages,
  },

  mutations: {
    SET_APPROVED_INSTRUCTORS(state, instructors) {
      state.approvedInstructors = instructors.data
    },
    SET_PENDING_INSTRUCTORS(state, instructors) {
      state.pendingInstructors = instructors.data
    },
    SET_DISAPPROVED_INSTRUCTORS(state, instructors) {
      state.disapprovedInstructors = instructors.data
    },
    SET_SEARCH_QUERY(state, query) {
      state.searchQuery = query
    },
    SET_PAGE(state, page) {
      state.page = page
    },
    SET_LIMIT(state, limit) {
      state.limit = limit
    },
    SET_TOTAL_COUNT(state, totalCount) {
      state.totalCount = totalCount
    },
    SET_TOP_INSTRUCTOR_INFO(state, instructorInfo) {
      state.topInstructorInfo = instructorInfo
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_FILTER_VALUE(state, filterValue) {
      state.filterValue = filterValue
    },
    SET_TOTAL_VIEWS(state, totalViews) {
      state.totalViews = totalViews
    },
    SET_TOTAL_PAGES(state, total) {
      state.totalPages = total
    },
  },

  actions: {
    setSearchQuery({ commit }, query) {
      commit('SET_SEARCH_QUERY', query)
    },
    setPage({ commit }, page) {
      commit('SET_PAGE', page)
    },
    setLimit({ commit }, limit) {
      commit('SET_LIMIT', limit)
    },
    async fetchApprovedInstructors({ commit, state }, payload) {
      try {
        const role = user.state.userRole
        if (role == 2) {
          commit('SET_TOTAL_COUNT', 0)
          const { page, limit } = state

          let res
          if (payload && payload.searchQuery) {
            const { searchQuery } = payload
            res = await api({ requiresAuth: true }).get(
              `/user/instructors/approved?firstName=${searchQuery}&username=${searchQuery}&isActive=${state.filterValue}&page=${page}&limit=${limit}`
            )
          } else if (state.searchQuery) {
            const { searchQuery } = state
            res = await api({ requiresAuth: true }).get(
              `/user/instructors/approved?isActive=${state.filterValue}&firstName=${searchQuery}&page=${page}&limit=${limit}`
            )
          } else {
            commit('SET_LOADING', true)
            res = await api({ requiresAuth: true }).get(
              `/user/instructors/approved?isActive=${state.filterValue}&page=${page}&limit=${limit}`
            )
          }
          const data = JSON.parse(JSON.stringify(res.data))
          commit('SET_APPROVED_INSTRUCTORS', data)
          commit('SET_TOTAL_COUNT', res.data.totalCount)
          let totalPages
          totalPages = Math.ceil(res.data.totalCount / limit)
          commit('SET_TOTAL_PAGES', totalPages)
          commit('SET_LOADING', false)
          return data
        }
      } catch (error) {
        console.error('GETTING instructors ERROR:', error)
        throw error
      }
    },
    async fetchPendingInstructors({ commit, state }, payload) {
      try {
        const role = user.state.userRole
        if (role == 2) {
          commit('SET_TOTAL_COUNT', 0)
          const { page, limit } = state
          let res
          if (payload && payload.searchQuery) {
            const { searchQuery } = payload
            res = await api({ requiresAuth: true }).get(
              `/user/pendingInstructors?firstName=${searchQuery}&username=${searchQuery}&page=${page}&limit=${limit}`
            )
          } else if (state.searchQuery) {
            const { searchQuery } = state
            res = await api({ requiresAuth: true }).get(
              `/user/pendingInstructors?firstName=${searchQuery}&username=${searchQuery}&page=${page}&limit=${limit}`
            )
          } else {
            commit('SET_LOADING', true)
            res = await api({ requiresAuth: true }).get(
              `/user/pendingInstructors?page=${page}&limit=${limit}`
            )
          }
          const data = JSON.parse(JSON.stringify(res.data))
          commit('SET_PENDING_INSTRUCTORS', data)
          commit('SET_TOTAL_COUNT', res.data.totalCount)
          let totalPages
          totalPages = Math.ceil(res.data.totalCount / limit)
          commit('SET_TOTAL_PAGES', totalPages)
          commit('SET_LOADING', false)
          return data
        }
      } catch (error) {
        console.error('GETTING instructors ERROR:', error)
        throw error
      }
    },
    async fetchDisapprovedInstructors({ commit, state }, payload) {
      try {
        const role = user.state.userRole
        if (role == 2) {
          commit('SET_TOTAL_COUNT', 0)
          const { page, limit } = state
          let res
          if (payload && payload.searchQuery) {
            const { searchQuery } = payload
            res = await api({ requiresAuth: true }).get(
              `/user/instructors/disapproved?firstName=${searchQuery}&username=${searchQuery}&page=${page}&limit=${limit}`
            )
          } else if (state.searchQuery) {
            const { searchQuery } = state
            res = await api({ requiresAuth: true }).get(
              `/user/instructors/disapproved?firstName=${searchQuery}&username=${searchQuery}&page=${page}&limit=${limit}`
            )
          } else {
            commit('SET_LOADING', true)
            res = await api({ requiresAuth: true }).get(
              `/user/instructors/disapproved?page=${page}&limit=${limit}`
            )
          }
          const data = JSON.parse(JSON.stringify(res.data))
          commit('SET_DISAPPROVED_INSTRUCTORS', data)
          commit('SET_TOTAL_COUNT', res.data.totalCount)
          let totalPages
          totalPages = Math.ceil(res.data.totalCount / limit)
          commit('SET_TOTAL_PAGES', totalPages)
          commit('SET_LOADING', false)
          return data
        }
      } catch (error) {
        console.error('GETTING instructors ERROR:', error)
        throw error
      }
    },
    async approveInstructor(_, userId) {
      try {
        const res = await api({ requiresAuth: true }).post(
          `/user/approveInstructor/${userId}`
        )
        successMessage.call(this, i18n.t('store.approve_user_success'))
        return res
      } catch (error) {
        errorMessage.call(this, i18n.t('store.approve_user_failed'))
        console.error('approveInstrucor ERROR:', error)
        throw error
      }
    },
    async disapproveInstructor(_, userId) {
      try {
        const res = await api({ requiresAuth: true }).post(
          `/user/disapproveInstructor/${userId}`
        )
        successMessage.call(this, i18n.t('store.disapprove_user_success'))
        return res
      } catch (error) {
        errorMessage.call(this, i18n.t('store.disapprove_user_failed'))
        console.error('disapproveInstrucor ERROR:', error)
        throw error
      }
    },
    async enableInstructor(_, userId) {
      try {
        const res = await api({ requiresAuth: true }).post(
          `/user/enableInstructorAccount/${userId}`
        )
        successMessage.call(this, i18n.t('store.enable_user_success'))
        return res
      } catch (error) {
        errorMessage.call(this, i18n.t('store.enable_user_failed'))
        console.error('enableInstructor ERROR:', error)
        throw error
      }
    },
    async disableInstructor(_, userId) {
      try {
        const res = await api({ requiresAuth: true }).post(
          `/user/disableInstructorAccount/${userId}`
        )
        successMessage.call(this, i18n.t('store.disable_user_success'))
        return res
      } catch (error) {
        errorMessage.call(this, i18n.t('store.disable_user_failed'))
        console.error('disableInstructor ERROR:', error)
        throw error
      }
    },
    async fetchTopInstructor({ commit, state }) {
      try {
        const { topInstructorLimit } = state
        const res = await api({ requiresAuth: true }).get(
          `/user/topRatingInstructor/${topInstructorLimit}`
        )
        if (res.data.length > 0) {
          const data = JSON.parse(JSON.stringify(res.data[0]))
          commit('SET_TOP_INSTRUCTOR_INFO', data)
          return data
        } else {
          const emptyData = {
            firstName: '',
            lastName: '',
            username: '',
            bio: '',
          }
          commit('SET_TOP_INSTRUCTOR_INFO', emptyData)
          return emptyData
        }
      } catch (error) {
        console.error('GETTING instructors ERROR:', error)
        throw error
      }
    },
    async fetchTotalViews({ commit }) {
      const res = await api({ requiresAuth: true }).get(
        'enroll/totalUniqueViews'
      )
      commit('SET_TOTAL_VIEWS', res.data)
      return res.data
    },
  },
}

export default instructors
