<template>
  <div class="courses layout">
    <div class="courses__content">
      <h2 class="courses__title main-title">{{ $t('courses') }}</h2>
      <div class="courses__inner content-layout">
        <ul class="courses__tabs">
          <li
            v-for="(tab, index) in tabs"
            :key="index"
            class="courses__tab"
            :class="{ 'courses__tab--active': activeTab === index }"
            @click="changeTab(index)"
          >
            {{ tab }}
          </li>
        </ul>

        <div class="courses__filter">
          <div class="courses__filter-left">
            <baseInput
              id="search"
              type="text"
              :placeholder="$t('search_courses')"
              v-model="searchText"
              customClass="input--search"
              @input="handleSearch"
            ></baseInput>
          </div>
          <div class="courses__filter-right">
            <custom-select
              class="courses__filter-select"
              :placeholder="{ name: $t('all_categories'), uuid: '' }"
              :disable-placeholder="false"
              :options="categories"
              :value="selectedCategory"
              @input="setCategory($event)"
            ></custom-select>
            <baseButton
              buttonClass="button--primary courses__button"
              @click.prevent="navigateToCreateCourse"
              v-if="isInstructor"
              >{{ $t('baseButtons.add_new_course') }}</baseButton
            >
          </div>
        </div>
        <loaderElement v-if="isLoading" />
        <div class="courses__loader" v-else>
          <div class="courses__wrapper">
            <div
              class="courses__list"
              v-if="
                showFilteredCourses ? filteredCourses.length : courses.length
              "
            >
              <courseItem
                v-for="course in showFilteredCourses
                  ? filteredCourses
                  : courses"
                :key="course.id"
                :course="course"
                :optionList="courseOptions"
              ></courseItem>
              <transition name="modal-fade"
                ><baseModal
                  v-if="showDeleteCourse"
                  :user-firstname="selectedCourseName"
                  :content="showModalContent"
                  @close-modal="showDeleteCourse = false"
                  @confirm-action="deleteCourse()"
              /></transition>
            </div>
            <div class="courses__not-found" v-else>
              <div style="height: 400px">
                <img src="@/assets/pictures/no-data-found.jpg" />
              </div>
            </div>
          </div>
          <div
            class="courses__pagination"
            v-if="showFilteredCourses ? filteredCourses.length : courses.length"
          >
            <basePagination
              :current-page="currentPage"
              :total-pages="totalPages"
              @update-page="handleUpdatePage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import baseInput from '@/components/baseInput/baseInput.vue'
import baseButton from '@/components/baseButton/baseButton.vue'
import courseItem from '@/components/courseItem/courseItem.vue'
import basePagination from '@/components/basePagination/basePagination.vue'
import baseModal from '@/components/baseModal/baseModal.vue'
import loaderElement from '@/components/loader/loaderElement.vue'
import { mapGetters } from 'vuex'
import { roleAliases } from '@/store/modules/roleAliases'
import CustomSelect from '@/components/baseSelect/customSelect.vue'

export default {
  components: {
    baseInput,
    baseButton,
    courseItem,
    basePagination,
    baseModal,
    loaderElement,
    CustomSelect,
  },
  data() {
    return {
      isLoading: false,
      activeTab: 0,
      tabs: [this.$t('coursesPage.all_courses')],
      currentPage: parseInt(this.$route.query.page) || 1,
      searchText: this.$route.query.name || '',
      selectedCategory: this.$route.query.category || '',
      showFilteredCourses: false,
      showModal: false,
      courseOptions: [
        {
          label: this.$t('coursesPage.delete_course'),
          action: this.handleDelete,
        },
        {
          label: this.$t('coursesPage.remove_from_view'),
          action: this.handleRemove,
        },
      ],
      showDeleteCourse: false,
      selectedCourseID: '',
      selectedCourseName: '',
      showModalContent: {
        message: 'delete this user',
        submit: 'DELETE',
      },
    }
  },
  watch: {
    '$i18n.locale': function (newLocale) {
      if (newLocale) this.tabs = [this.$t('coursesPage.all_courses')]
    },
    currentPage: {
      handler(newValue) {
        this.$router
          .replace({
            query: {
              ...this.$route.query,
              page: newValue,
            },
          })
          .catch(() => {})
      },
    },
    selectedCategory: {
      handler(newValue) {
        this.$router
          .replace({
            query: {
              ...this.$route.query,
              category: newValue,
            },
          })
          .catch(() => {})
      },
    },
  },
  computed: {
    ...mapGetters(['getUserInfo']),
    userInfo() {
      return this.getUserInfo
    },
    displayedPageNumbers() {
      const range = 3
      const middleIndex = Math.ceil(range / 2)
      const startIndex = Math.max(1, this.currentPage - middleIndex + 1)
      const endIndex = Math.min(startIndex + range - 1, this.totalPages)
      const displayedPages = []
      for (let i = startIndex; i <= endIndex; i++) {
        displayedPages.push(i)
      }
      return displayedPages
    },
    ...mapGetters(['getLastPage']),
    totalPages() {
      return this.getLastPage
    },
    ...mapGetters(['getCourses']),
    ...mapGetters(['getInstructorCourses']),
    courses() {
      return this.userInfo.role === 3
        ? this.getInstructorCourses
        : this.getCourses
    },
    ...mapGetters(['getAllCategories']),
    categories() {
      return this.getAllCategories
    },
    ...mapGetters(['getFilteredCourses']),
    filteredCourses() {
      return this.getFilteredCourses
    },
  },
  methods: {
    getRole(role) {
      return roleAliases[role]
    },
    changeTab(index) {
      this.activeTab = index
    },
    handleSearch() {
      this.$router
        .replace({
          query: {
            ...this.$route.query,
            name: this.searchText,
          },
        })
        .catch(() => {})
      this.currentPage = 1
      this.fetchCourses()
    },
    setCategory(selectedCategory) {
      if (this.selectedCategory !== selectedCategory) {
        this.selectedCategory = selectedCategory
        this.currentPage = 1
        this.searchText = ''
        this.$router
          .replace({
            query: {
              ...this.$route.query,
              name: this.searchText,
            },
          })
          .catch(() => {})
        this.fetchCourses()
      }
    },
    handleUpdatePage(pageNumber) {
      this.currentPage = pageNumber
      this.fetchCourses()
    },
    async fetchCourses() {
      const role = parseInt(localStorage.getItem('role'))
      this.searchText || this.selectedCategory
        ? (this.showFilteredCourses = true)
        : (this.showFilteredCourses = false)
      try {
        this.isLoading = true
        await this.$store.dispatch('fetchUserInfo')
        if (role === 3) {
          await this.$store.dispatch('fetchInstructorCourses', {
            id: this.userInfo.uuid,
            categoryID: this.selectedCategory,
            searchText: encodeURIComponent(this.searchText),
            page: this.currentPage,
            limit: 6,
          })
        } else {
          if (this.selectedCategory) {
            await this.$store.dispatch('fetchCoursesByCategory', {
              id: this.selectedCategory,
              searchText: encodeURIComponent(this.searchText),
              page: this.currentPage,
              limit: 6,
            })
          } else {
            await this.$store.dispatch('fetchCourses', {
              searchText: encodeURIComponent(this.searchText),
              page: this.currentPage,
              limit: 6,
            })
          }
        }
        this.isLoading = false
      } catch (error) {
        console.error('GETTING COURSES ERROR')
      }
    },
    navigateToCreateCourse() {
      this.$router.push({ name: 'createCourse' })
    },
    createCourse() {
      this.showModal = true
    },
    closeModal() {
      this.showModal = false
    },
    handleRemove(course) {
      if (this.showFilteredCourses === true) {
        const index = this.filteredCourses.findIndex((item) => item === course)
        if (index !== -1) {
          this.filteredCourses.splice(index, 1)
        }
      } else {
        const index = this.courses.findIndex((item) => item === course)
        if (index !== -1) {
          this.courses.splice(index, 1)
        }
      }
    },
    handleDelete(course) {
      this.selectedCourseID = course.uuid
      this.selectedCourseName = course.name
      this.showModalContent = {
        message: this.$t('showModalContent.delete_this_course'),
        submit: this.$t('showModalContent.delete'),
      }
      this.showDeleteCourse = !this.showDeleteCourse
    },
    async deleteCourse() {
      try {
        await this.$store.dispatch('deleteCourse', this.selectedCourseID)
        await this.$store.dispatch('fetchInstructorCourses', {
          id: this.userInfo.uuid,
          categoryID: this.selectedCategory,
          searchText: this.searchText,
          page: this.currentPage,
          limit: 6,
        })
        if (this.totalPages < this.currentPage) {
          this.currentPage -= 1
        }
      } catch (err) {
        console.error(err)
      }
    },
    updatePageQuery(pageNumber) {
      const query = { ...this.$route.query, page: pageNumber }
      this.$router.replace({ query }).catch(() => {})
    },
  },
  async created() {
    await this.$store.dispatch('fetchAllCategories')
  },
  async mounted() {
    const pageQueryParam = parseInt(this.$route.query.page)
    if (isNaN(pageQueryParam) || pageQueryParam <= 0) {
      this.currentPage = 1
      this.updatePageQuery(1)
    } else {
      this.currentPage = pageQueryParam
    }
    await this.fetchCourses()
    if (pageQueryParam > this.totalPages) {
      this.updatePageQuery(this.totalPages)
      this.currentPage = this.totalPages
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/pages/coursesView.scss';
</style>
