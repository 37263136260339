<template>
  <div>
    <label v-if="label">{{ label }}</label>
    <textarea
      :value="value"
      @input="updateValue"
      v-bind="$attrs"
      v-on="listeners"
      :contenteditable="true"
      :class="[{ error: applyErrorClass }, customClass]"
    ></textarea>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    value: [String, Number],
    applyErrorClass: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
      }
    },
  },
  methods: {
    updateValue(event) {
      this.$emit('input', event.target.value)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/baseTextarea/_baseTextarea.scss';
</style>
