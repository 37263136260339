import Vue from 'vue'
import VueRouter from 'vue-router'
import dashboard from '@/views/dashboardView.vue'
import courses from '@/views/coursesView.vue'
import categories from '@/views/categoriesView.vue'
import survey from '@/views/adminSurvey.vue'
import singleCourse from '@/views/singleCourse.vue'
import createQuiz from '@/views/createQuiz.vue'
import order from '@/views/orderView.vue'
import settings from '@/views/settingsView.vue'
import userManagement from '@/views/userManagement.vue'
import events from '@/views/eventsView.vue'
import viewQuiz from '@/views/viewQuiz.vue'
import createCourse from '@/views/createCourse.vue'
import updateCourse from '@/views/updateCourse.vue'
import studentQuizzes from '@/views/studentQuizzes.vue'
import studentCourses from '@/views/studentCourses.vue'
import studentSingleCourse from '@/views/studentSingleCourse'
import participants from '@/views/participantsView'
import approveInstructor from '@/views/approveInstructor'
import enableInstructor from '@/views/enableInstructor'
import fullPageLoader from '@/views/fullPageLoader'
import verifyAccount from '@/views/verifyAccount'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'landing',
    component: () => import('../views/landingPage.vue'),
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: dashboard,
        meta: { roles: [1, 2, 3, 4] },
      },
      {
        path: '/courses',
        name: 'courses',
        component: courses,
        meta: { roles: [1, 2, 3] },
      },
      {
        path: '/categories',
        name: 'categories',
        component: categories,
        meta: { roles: [1, 2] },
      },
      {
        path: '/survey',
        name: 'survey',
        component: survey,
        meta: { roles: [2] },
      },
      {
        path: '/course/create',
        name: 'createCourse',
        component: createCourse,
        meta: { roles: [3] },
      },
      {
        path: '/course/:id/update',
        name: 'updateCourse',
        component: updateCourse,
        meta: { roles: [3] },
      },
      {
        path: '/course/:id',
        name: 'singleCourse',
        component: singleCourse,
        meta: { roles: [1, 2, 3] },
      },
      {
        path: 'quiz/view/:id',
        name: 'view-quiz',
        component: viewQuiz,
        meta: { roles: [3] },
      },
      {
        path: 'quiz/create/:id',
        name: 'create-quiz',
        component: createQuiz,
        meta: { roles: [3] },
      },
      {
        path: '/order',
        name: 'order',
        component: order,
        meta: { roles: [3, 4] },
      },
      {
        path: '/settings',
        name: 'settings',
        component: settings,
        meta: { roles: [1, 2, 3, 4] },
      },
      {
        path: '/users',
        name: 'userManagement',
        component: userManagement,
        meta: { roles: [1, 2] },
      },
      {
        path: '/user/approveInstructor/:userId',
        name: 'approveInstructor',
        component: approveInstructor,
        meta: { roles: [2] },
      },
      {
        path: '/user/enableInstructorAccount/:userId',
        name: 'enableInstructor',
        component: enableInstructor,
        meta: { roles: [2] },
      },
      {
        path: '/participants',
        name: 'participants',
        component: participants,
        meta: { roles: [3] },
      },
      {
        path: '/events',
        name: 'events',
        component: events,
        meta: { roles: [1, 2] },
      },
      {
        path: 'quiz/student/:id',
        name: 'studentQuizzes',
        component: studentQuizzes,
        meta: { role: [4] },
      },
      {
        path: '/my-courses',
        name: 'studentCourses',
        component: studentCourses,
        meta: { roles: [4] },
      },
      {
        path: '/my-courses/single/:id/:videoId?',
        name: 'studentSingleCourse',
        component: studentSingleCourse,
        meta: { roles: [3, 4] },
      },
    ],
    meta: { requiresAuth: true, roles: [1, 2, 3] },
  },
  {
    path: '/login/:id?',
    name: 'login',
    component: () => import('../views/loginView.vue'),
    meta: { requiresGuest: true },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/registerView.vue'),
    meta: { requiresGuest: true },
  },
  {
    path: '/forget-password',
    name: 'forgetPassword',
    component: () => import('../views/forgetPassword.vue'),
    meta: { requiresGuest: true },
  },
  {
    path: '/reset-password/:id',
    name: 'resetPassword',
    component: () => import('../views/resetPassword.vue'),
    meta: { requiresGuest: true },
  },
  {
    path: '/recover-account',
    name: 'recoverAccount',
    component: () => import('../views/recoverAccount.vue'),
    meta: { requiresGuest: true },
  },
  {
    path: '/getCertificate/:id',
    name: 'certificatePage',
    component: () => import('../views/certificatePage.vue'),
    meta: { requiresGuest: true },
  },
  {
    path: '/redirecting/:token/:role?/:courseId?/',
    name: 'redirectingLoader',
    component: fullPageLoader,
    meta: { requiresGuest: true },
  },
  {
    path: '/verifyAccount/:token',
    name: 'verifyAccount',
    component: verifyAccount,
    meta: { requiresGuest: true },
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
})

router.beforeEach((to, from, next) => {
  window.scroll(0, 0)
  const loggedIn = localStorage.getItem('user')
  const role = parseInt(localStorage.getItem('role'))
  const auth = to.matched.some((record) => record.meta.requiresAuth)

  const getPathRoles = to.meta.roles
  if (
    (to.name === 'approveInstructor' || to.name === 'enableInstructor') &&
    auth &&
    !loggedIn
  ) {
    next({ path: '/login', query: { redirect: to.fullPath } })
  } else if (auth && !loggedIn) {
    next('/login')
  } else if (loggedIn && getPathRoles && !getPathRoles.includes(role)) {
    next('/dashboard')
  } else {
    next()
  }
})

export default router
