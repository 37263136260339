<template>
  <div class="table">
    <div class="table__search">
      <baseInput
        v-if="search === true"
        v-model="searchValue"
        :placeholder="$t('tables.search_by_name')"
        :customClass="'input--search'"
      ></baseInput>
      <base-select
        v-if="select === true"
        :beforeTextWidth="50"
        :text="'Status:'"
        :placeholder="$t('tables.all')"
        :options="filters"
        :optionValue="'name'"
        :value="filterValue"
        :customClass="'select__table'"
        @change="setFilter($event.target.value)"
      />
    </div>
    <ve-table
      class="tables"
      :border-around="false"
      :border-x="false"
      :border-y="false"
      :columns="columns"
      :table-data="filteredTableData"
      :sort-option="sortOption"
    />
    <div class="table__empty" v-if="filteredTableData.length === 0">
      <div class="table__no-content">
        <img src="@/assets/pictures/no-data-found.jpg" />
      </div>
    </div>
    <div class="table__pagination">
      <ve-pagination
        v-if="pagination === true"
        :total="totalCount"
        :page-index="page"
        :page-size="limit"
        :page-size-option="[5, 10, 15]"
        @on-page-number-change="pageNumberChange"
        @on-page-size-change="pageSizeChange"
      />
    </div>
  </div>
</template>

<script>
import baseInput from '@/components/baseInput/baseInput.vue'
// import { mapGetters, mapActions } from 'vuex'
import baseSelect from '@/components/baseSelect/baseSelect.vue'
import debounce from 'lodash.debounce'
export default {
  components: {
    baseInput,
    baseSelect,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    tableData: {
      type: Array,
      required: true,
    },
    name: String,
    fetchFunction: String,
    search: {
      type: Boolean,
      default: true,
    },
    select: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Boolean,
      default: true,
    },
    noDataMessage: {
      type: String,
      default: 'No data found',
    },
  },
  data() {
    return {
      searchValue: '',
      debouncedFetch: null,
      filters: [{ name: 'Active' }, { name: 'Inactive' }],
      filterValue: '',
      sortOption: {
        multipleSort: true,
        sortAlways: true,
        sortChange: (params) => {
          this.sortChange(params)
        },
      },
    }
  },
  watch: {
    async searchValue(newValue) {
      if (this.search) {
        this.$store.dispatch('setSearchQuery', newValue)
        this.$store.dispatch('setPage', 1)
        this.page = 1
        await this.debouncedFetch(newValue)
      }
    },
  },
  computed: {
    totalPages: {
      get() {
        return this.$store.state[this.name].totalPages
      },
    },
    totalCount: {
      get() {
        return this.$store.state[this.name].totalCount
      },
    },
    searchQuery: {
      get() {
        return this.$store.state[this.name].searchQuery
      },
      set(value) {
        this.$store.dispatch('setSearchQuery', value)
      },
    },
    page: {
      get() {
        return this.$store.state[this.name].page
      },
      set(value) {
        this.$store.dispatch('setPage', value)
      },
    },
    limit: {
      get() {
        return this.$store.state[this.name].limit
      },
      set(value) {
        this.$store.dispatch('setLimit', value)
      },
    },
    filteredTableData() {
      return JSON.parse(JSON.stringify(this.tableData))
    },
  },
  mounted() {
    window.addEventListener('error', this.handleResizeObserverError)
  },
  beforeUnmount() {
    if (this.debouncedFetch) {
      this.debouncedFetch.cancel()
    }
    window.removeEventListener('error', this.handleResizeObserverError)
  },
  methods: {
    async setFilter(filterValue) {
      this.filterValue = filterValue
      if (filterValue == 'Active') {
        this.$store.commit('SET_FILTER_VALUE', true)
      } else if (filterValue == 'Inactive') {
        this.$store.commit('SET_FILTER_VALUE', false)
      } else {
        this.$store.commit('SET_FILTER_VALUE', '')
      }
      await this.$store.dispatch('fetchApprovedInstructors')
    },
    sortChange(params) {
      this.filteredTableData.sort((a, b) => {
        if (params.firstName) {
          const firstNameA = a.firstName.toLowerCase()
          const firstNameB = b.firstName.toLowerCase()

          if (params.firstName === 'asc') {
            return firstNameA.localeCompare(firstNameB)
          } else if (params.firstName === 'desc') {
            return firstNameB.localeCompare(firstNameA)
          }
        } else if (params.lastName) {
          const lastNameA = a.lastName.toLowerCase()
          const lastNameB = b.lastName.toLowerCase()

          if (params.lastName === 'asc') {
            return lastNameA.localeCompare(lastNameB)
          } else if (params.lastName === 'desc') {
            return lastNameB.localeCompare(lastNameA)
          }
        }
        return 0
      })
    },
    pageNumberChange(pageIndex) {
      if (this.page !== pageIndex) {
        this.$store.dispatch('setPage', pageIndex)
        this.$router.push({ query: { page: pageIndex } })
        this.fetchDataWithSearch()
        this.page = pageIndex
      }
    },
    pageSizeChange(pageSize) {
      this.$store.dispatch('setLimit', pageSize)
      this.limit = pageSize
      this.$store.dispatch('setPage', 1)
      this.page = 1
      if (this.$route.query.page !== '1') {
        this.$router.push({ query: { page: 1 } })
      }
      this.fetchDataWithSearch()
    },
    handleResizeObserverError(event) {
      if (
        event.message === 'ResizeObserver loop limit exceeded' ||
        event.message === 'Script error.' ||
        event.message ===
          'ResizeObserver loop completed with undelivered notifications.'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        )
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        )
        if (resizeObserverErr) {
          resizeObserverErr.style.display = 'none'
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.style.display = 'none'
        }
      }
    },
    async fetchDataWithSearch(searchQuery) {
      searchQuery =
        encodeURIComponent(this.searchQuery) ||
        encodeURIComponent(this.searchValue)
      if (searchQuery !== '') {
        await this.$store.dispatch(this.fetchFunction, { searchQuery })
      } else if (this.fetchFunction == 'fetchInstructorOrders') {
        await this.$store.dispatch(this.fetchFunction, {
          year: null,
          month: null,
        })
      } else if (this.fetchFunction == 'fetchStudentOrders') {
        await this.$store.dispatch(this.fetchFunction, {
          year: null,
          month: null,
        })
      } else {
        await this.$store.dispatch(this.fetchFunction)
      }
    },
    setupDebouncedFetch() {
      this.debouncedFetch = debounce(this.fetchDataWithSearch, 500)
    },
  },
  async created() {
    const queryPage = parseInt(this.$route.query.page)
    if (queryPage != this.page) {
      if (!isNaN(queryPage) && queryPage > 0 && queryPage <= this.totalPages) {
        this.page = queryPage

        this.$router.replace({ query: { page: this.page } })
      } else {
        this.page = 1
        this.$router.push({ query: { page: this.page } })
      }
    }
    await this.setupDebouncedFetch()
  },
}
</script>
<style lang="scss" scoped>
@import './baseTable';
</style>
