<template>
  <div id="myModal" class="modal" @click="closeModal">
    <div id="modal__dropdown">
      <div class="modal__title">
        <h3>{{ $t('modals.edit_lesson_title') }}</h3>
        <span id="close" @click="closeModal">&times;</span>
      </div>
      <form
        v-if="video.videoFile"
        class="modal__form"
        @submit.prevent="editLesson"
      >
        <div class="modal__group">
          <baseInput
            id="name"
            type="text"
            :placeholder="$t('modals.lesson_name')"
            v-model="newLesson.name"
            customClass="input--modal"
            :applyErrorClass="$v.newLesson.name.$error"
            @blur="$v.newLesson.name.$touch()"
          ></baseInput>
          <template v-if="$v.newLesson.name.$error"
            ><p class="error-msg" v-if="!$v.newLesson.name.required">
              {{ $t('modals.lesson_name_error') }}
            </p></template
          >
        </div>
        <div class="modal__group">
          <input
            type="file"
            ref="fileInput"
            accept="video/mp4,video/x-m4v,video/*"
            @change="handleFileSelect($event)"
            class="file--modal inputFile"
          />
          <template v-if="$v.newLesson.videoFile.$error">
            <p class="error-msg" v-if="!$v.newLesson.videoFile.required">
              {{ $t('singleCourse.video_file_error') }}
            </p>
          </template>
        </div>
        <div class="modal__group">
          <baseButton
            type="submit"
            buttonClass="button--primary button--modal"
            :disabled="isChanged || isDisabled"
            >{{ $t('modals.submit') }}</baseButton
          >
          <p v-if="error" class="error-msg">{{ generalError }}</p>
        </div>
      </form>
      <form v-else class="modal__form" @submit.prevent="updateVideoLink">
        <div class="modal__group">
          <baseInput
            id="file"
            type="text"
            v-model="updatedVideoTwo.youTubeVideoId"
            :placeholder="'Video Link'"
            customClass="input--modal"
            :applyErrorClass="$v.updatedVideoTwo.youTubeVideoId.$error"
            @blur="$v.updatedVideoTwo.youTubeVideoId.$touch()"
          ></baseInput>
          <template v-if="$v.updatedVideoTwo.youTubeVideoId.$error">
            <p
              class="error-msg"
              v-if="!$v.updatedVideoTwo.youTubeVideoId.required"
            >
              {{ $t('singleCourse.video_link_error') }}
            </p>
          </template>
        </div>
        <div class="modal__group">
          <baseButton
            type="submit"
            buttonClass="button--primary button--modal"
            :disabled="isChangedTwo || isDisabled"
            >{{ $t('modals.submit') }}</baseButton
          >
          <p v-if="error" class="error-msg">{{ generalError }}</p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import baseInput from '@/components/baseInput/baseInput.vue'
import baseButton from '@/components/baseButton/baseButton.vue'
import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

export default {
  components: {
    baseInput,
    baseButton,
  },
  data() {
    return {
      newLesson: {
        name: '',
        videoFile: null,
      },
      updatedVideoTwo: {
        youTubeVideoId: null,
      },
      isDisabled: false,
      error: false,
      generalError: this.$t('errors.something_went_wrong'),
    }
  },
  props: {
    lessonID: {
      type: String,
      default: '',
    },
    video: {
      type: Object,
      default: () => {},
    },
  },
  validations() {
    return {
      newLesson: {
        name: {
          required,
        },
        videoFile: {},
      },
      updatedVideoTwo: {
        youTubeVideoId: {},
      },
    }
  },
  computed: {
    isChanged() {
      if (this.video)
        return (
          this.video.name === this.newLesson.name &&
          this.newLesson.videoFile === null
        )
      else return false
    },
    isChangedTwo() {
      return this.updatedVideoTwo.youTubeVideoId === this.video.videoLink
    },
  },
  methods: {
    ...mapActions(['fetchChapters']),
    populateNewVideoData() {
      if (this.video) {
        this.newLesson.name = this.video.name
        this.updatedVideoTwo.youTubeVideoId = this.video.videoLink
      }
    },
    async handleFileSelect(event) {
      this.newLesson.videoFile = event.target.files[0]
    },
    async editLesson() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      const formData = new FormData()
      const name = this.newLesson.name
      const videoFile = this.newLesson.videoFile
      formData.append('name', name)
      if (this.newLesson.videoFile) {
        formData.append('videoFile', videoFile)
      }
      try {
        this.isDisabled = true
        await this.$store.dispatch('editLesson', {
          lessonID: this.lessonID,
          lessonData: formData,
        })
        await this.fetchChapters(this.$route.params.id)
        this.closeModalAfterSubmit()
      } catch (err) {
        this.error = true
        this.isDisabled = false
      }
    },
    async updateVideoLink() {
      /* this.$v.$touch()
      if (this.$v.$invalid) {
        return
      } */
      const videoUrl = this.updatedVideoTwo.youTubeVideoId
      const startIndex = videoUrl.lastIndexOf('=') + 1
      const videoLink = videoUrl.substring(startIndex)
      try {
        this.isDisabled = true
        await this.$store.dispatch('updateEmbedVideo', {
          youTubeVideoId: videoLink,
          videoID: this.video.uuid,
        })
        await this.fetchChapters(this.$route.params.id)
        this.closeModalAfterSubmit()
      } catch (err) {
        this.error = true
        this.isDisabled = false
      }
    },
    closeModal(event) {
      const isModalElement = event?.target?.id === 'myModal'
      const isCloseButtonElement = event?.target?.id === 'close'
      if (isModalElement || isCloseButtonElement) {
        this.$emit('close-modal')
        this.newLesson = {
          name: '',
        }
        this.isDisabled = false
      }
    },
    closeModalAfterSubmit() {
      this.$emit('close-modal')
      this.newLesson = {
        name: '',
      }
      this.isDisabled = false
    },
  },
  mounted() {
    this.populateNewVideoData()
  },
}
</script>

<style lang="scss" scoped>
@import './editLessonModal';
</style>
