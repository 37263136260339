export default {
  methods: {
    closeModal(event) {
      if (
        event.target.id === 'myModal' ||
        event.target.id === 'close' ||
        event.target.id === 'closed'
      ) {
        this.$emit('close-modal')
      }
    },
  },
}
