<template>
  <div class="course" ref="courseRef">
    <div
      class="course__wrapper"
      @click.prevent="navigateToSingleCourse(course.uuid)"
    >
      <div class="course__photo">
        <img v-if="this.course.imagePath" :src="imagePath" alt="Course Image" />
        <img v-else src="@/assets/pictures/avatar.png" />
      </div>
      <div class="course__wrap-info">
        <div class="course__information">
          <p class="course__start">{{ startDate }}</p>
          <h3 class="course__title">
            {{ title }}
          </h3>
          <p v-if="isSuperAdmin || isAdmin" class="course__instructor">
            {{ course.instructor.firstName }} {{ course.instructor.lastName }}
          </p>
        </div>
        <div class="course__progress">
          <div class="course__stats">
            <div class="course__students" v-if="course.enrolled !== 1">
              {{ course.enrolled }} {{ $t('singleCourse.of_students') }}
            </div>
            <div class="course__students" v-else>
              {{ course.enrolled }} student
            </div>
            <starRating :rating="course.averageRating"></starRating>
          </div>
          <!-- <div class="course__progress-info">
          <p>{{ isCompleted(status) }}</p>
          <p>{{ progress + '%' }}</p>
        </div>
        <div class="course__progress-bar">
          <div
            class="course__progress-bar--update"
            :class="{ 'course__progress-bar--active': progress > 0 }"
          ></div>
        </div> -->
        </div>
      </div>
    </div>
    <div
      class="course__hover"
      @click="showHoverInner = !showHoverInner"
      v-if="isInstructor"
    ></div>
    <div class="course__hover-inner" v-if="showHoverInner">
      <ul class="course__hover-list">
        <li v-for="(option, index) in optionList" :key="index">
          <button @click="option.action(course)">{{ option.label }}</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import starRating from '@/components/starRating/starRating.vue'
export default {
  components: {
    starRating,
  },
  data() {
    return {
      id: this.course.id,
      startDate: new Date(this.course.created_at).toLocaleDateString(),
      title: this.course.name,
      status: this.course.isCompleted,
      imagePath: `https://api.lms.k-clients.com/public/images/${this.course.imagePath}`,
      rating: this.course.averageRating,
      progress: 25,
      showHoverInner: false,
    }
  },
  props: {
    course: {
      type: Object,
      required: true,
    },
    optionList: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick)
  },
  methods: {
    handleOutsideClick(event) {
      if (!this.$refs.courseRef?.contains(event.target)) {
        this.showHoverInner = false
      }
    },
    isCompleted(status) {
      if (status === true) {
        return 'Completed'
      } else {
        return 'In progress'
      }
    },
    async navigateToSingleCourse(id) {
      await this.$store.dispatch('fetchCourse', id)
      this.$router.push(`/course/${id}`)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/courseItem/courseItem.scss';
</style>
