<template>
  <div class="quizzes layout">
    <div class="quizzes__title main-title">
      <h2>{{ $t('createQuiz.create_quiz_title') }}</h2>
    </div>
    <div class="quizzes__content content-layout">
      <loaderElement v-if="isLoading" />
      <form v-else class="modal__form" @submit.prevent="createQuiz">
        <div class="quizzes__wrap">
          <p class="quizzes__p">
            {{ $t('createQuiz.quiz_title') }}:
            <baseInput
              v-model="quizForm.title"
              id="quizTitle"
              type="text"
              :placeholder="$t('createQuiz.enter_quiz_title')"
              customClass="input--quiz"
              @input="clearError"
            ></baseInput>
          </p>
        </div>
        <createQuestionCard
          v-for="count in questionCardCount"
          :key="count"
          @question-submitted="handleQuestion"
        ></createQuestionCard>
      </form>
      <div v-if="!isLoading" class="quizzes__align">
        <span v-show="generalError" class="error-msg">{{ generalError }}</span>
        <div class="quizzes__button">
          <baseButton
            buttonClass="button--primary button--question button--add "
            @click="addQuestionCard"
            >{{ $t('createQuiz.add_question') }}
          </baseButton>
          <baseButton
            type="submit"
            buttonClass="button--primary button--question"
            @click="createQuiz"
            :disabled="isSubmitButtonDisabled"
            >{{ $t('createQuiz.submit_btn') }}</baseButton
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import createQuestionCard from '../components/questionCardComponents/createQuestionCard'
import baseButton from '../components/baseButton/baseButton.vue'
import baseInput from '../components/baseInput/baseInput.vue'
import loaderElement from '@/components/loader/loaderElement.vue'
import { mapActions } from 'vuex'
export default {
  components: {
    createQuestionCard,
    baseInput,
    baseButton,
    loaderElement,
  },
  data() {
    return {
      isLoading: false,
      questionCardCount: 3,
      addingQuestions: true,
      quizForm: {
        title: '',
        questions: [],
      },
      generalError: '',
    }
  },
  computed: {
    isSubmitButtonDisabled() {
      return (
        !this.quizForm.title ||
        this.quizForm.questions.every((question) => !question.question)
      )
    },
  },
  methods: {
    addQuestionCard() {
      this.questionCardCount++
    },
    handleQuestion(questionData) {
      const existingQuestionIndex = this.quizForm.questions.findIndex(
        (question) => question.question === questionData.question
      )

      if (existingQuestionIndex !== -1) {
        this.quizForm.questions.splice(existingQuestionIndex, 1, questionData)
      } else {
        this.quizForm.questions.push(questionData)
      }
      this.addingQuestions = true
    },
    validateForm() {
      if (!this.quizForm.title) {
        this.generalError = this.$t('authentication.fill_all_fields')
        return false
      }
      return true
    },

    clearError() {
      this.generalError = ''
    },

    ...mapActions(['createQuiz']),
    async createQuiz() {
      try {
        this.addingQuestions = false
        if (!this.validateForm()) {
          return
        }
        if (this.quizForm.questions.length < 3) {
          this.generalError = this.$t('createQuiz.min_three_questions')
          return
        }
        if (!this.addingQuestions) {
          const credentials = {
            title: this.quizForm.title,
            questions: this.quizForm.questions,
          }
          this.isLoading = true
          await this.$store.dispatch('createQuiz', {
            courseId: this.$route.params.id,
            credentials: credentials,
          })
          this.sendToCourse()
          this.isLoading = false
        }
      } catch (err) {
        let message = err.response?.data?.message[0]
        if (message === 'questions should not be empty') {
          this.generalError = this.$t('write_correctly')
        } else console.error('CREATE QUIZ Error', err)
      }
    },
    sendToCourse() {
      this.$router.push(`/course/${this.$route.params.id}`)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/pages/createQuiz.scss';
</style>
