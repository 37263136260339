<template>
  <div id="myModal" class="modal" @click="closeModal">
    <div id="modal__dropdown">
      <div class="modal__title">
        <h3>{{ $t('modals.add_chapter') }}</h3>
        <span id="close" @click="closeModal">&times;</span>
      </div>
      <form class="modal__form" @submit.prevent="addChapter">
        <div class="modal__group">
          <baseInput
            id="name"
            type="text"
            :placeholder="$t('modals.chapter_name')"
            v-model="newChapter.name"
            customClass="input--modal"
            :applyErrorClass="$v.newChapter.name.$error"
            @blur="$v.newChapter.name.$touch()"
          ></baseInput>
          <template v-if="$v.newChapter.name.$error">
            <p class="error-msg" v-if="!$v.newChapter.name.required">
              {{ $t('modals.chapter_name_error') }}
            </p>
          </template>
        </div>
        <div class="modal__group">
          <baseTextArea
            id="description"
            type="text"
            :placeholder="$t('modals.chapter_desc')"
            v-model="newChapter.description"
            customClass="input--modal"
            :applyErrorClass="$v.newChapter.description.$error"
            @blur="$v.newChapter.description.$touch()"
            style="margin: 10px 20px; margin-bottom: 0"
          ></baseTextArea>
          <template v-if="$v.newChapter.description.$error">
            <p class="error-msg" v-if="!$v.newChapter.description.required">
              {{ $t('modals.chapter_desc_error') }}
            </p>
          </template>
        </div>
        <div class="modal__group">
          <baseButton
            type="submit"
            buttonClass="button--primary button--modal"
            :disabled="isDisabled"
            >{{ $t('modals.submit') }}</baseButton
          >
          <p v-if="error" class="error-msg">{{ generalError }}</p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import baseInput from '@/components/baseInput/baseInput.vue'
import baseButton from '@/components/baseButton/baseButton.vue'
import baseTextArea from '@/components/baseTextarea/baseTextarea.vue'
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    baseInput,
    baseButton,
    baseTextArea,
  },
  data() {
    return {
      isDisabled: false,
      newChapter: {
        name: '',
        description: '',
      },
      error: false,
      generalError: this.$t('errors.something_went_wrong'),
    }
  },
  validations() {
    return {
      newChapter: {
        name: {
          required,
        },
        description: {
          required,
        },
      },
    }
  },
  methods: {
    ...mapActions(['fetchChapters']),
    async addChapter() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      const name = this.newChapter.name
      const description = this.newChapter.description
      try {
        this.isDisabled = true
        await this.$store.dispatch('addChapter', {
          courseID: this.$route.params.id,
          chapterData: { name, description },
        })
        await this.fetchChapters(this.$route.params.id)
        this.closeModalAfterSubmit()
      } catch (err) {
        this.error = true
        this.isDisabled = false
      }
    },
    closeModal(event) {
      const isModalElement = event?.target?.id === 'myModal'
      const isCloseButtonElement = event?.target?.id === 'close'
      if (isModalElement || isCloseButtonElement) {
        this.$emit('close-modal')
        this.newChapter = {
          name: '',
          description: '',
        }
        this.$v.$reset()
        this.isDisabled = false
      }
    },
    closeModalAfterSubmit() {
      this.$emit('close-modal')
      this.newChapter = {
        name: '',
        description: '',
      }
      this.$v.$reset()
      this.isDisabled = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import './addChapterModal';
</style>
