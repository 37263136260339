import api from '@/api/api'
import { successMessage, errorMessage } from '@/composables/useMessages'
import i18n from '@/main'
const user = {
  state: {
    userInfo: null,
    userId: null,
    instrInfo: [],
    userRole: null,
    instrLinks: [],
  },

  getters: {
    getUserInfo: (state) => {
      if (state.userInfo) {
        return state.userInfo
      } else {
        return {
          firstName: '',
          lastName: '',
          username: '',
          bio: '',
          imageFile: '',
          role: 0,
        }
      }
    },
    getInstrInfo: (state) => state.instrInfo,
    getInstrLinks: (state) => state.instrLinks,
  },

  mutations: {
    SET_USER_INFO(state, userInfo) {
      state.userInfo = userInfo
      state.userId = userInfo.uuid
      state.userRole = userInfo.role
    },
    SET_INSTRUCTOR_INFO(state, instrInfo) {
      state.instrInfo = instrInfo
    },
    SET_INSTRUCTOR_LINKS(state, instrLinks) {
      state.instrLinks = instrLinks
    },
  },

  actions: {
    async fetchUserInfo({ commit }) {
      try {
        const res = await api({ requiresAuth: true }).get('/user/me')
        commit('SET_USER_INFO', res.data)
        return res.data
      } catch (error) {
        console.error('GET USER INFO ERROR:', error.response.data.message)
        throw error
      }
    },
    async updateUserInfo({ commit }, updateUserInfo) {
      try {
        const res = await api({
          requiresAuth: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).patch('/user/{me}', updateUserInfo)
        commit('SET_USER_INFO', res.data)
        successMessage.call(this, i18n.t('store.save_changes_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.save_changes_failed'))
        console.error('UPDATE USER INFO ERROR:', error)
        throw error
      }
    },
    async changePassword(_, changePassword) {
      try {
        const res = await api({ requiresAuth: true }).post(
          '/user/changePassword',
          changePassword
        )
        successMessage.call(this, i18n.t('store.change_password_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.change_password_failed'))
        console.error('CHANGE PASSWORD ERROR:', error.response.data.message)
        throw error
      }
    },
    async fetchInstrInfo({ commit }, id) {
      try {
        const res = await api({ requiresAuth: true }).get(`user/${id}`)
        commit('SET_INSTRUCTOR_INFO', res.data)
        return res.data
      } catch (error) {
        console.error(
          'FETCHING INSTRUCTOR INFO ERROR:',
          error.response.data.message
        )
        throw error
      }
    },
    async fetchInstructorLinks({ commit, state }) {
      try {
        let id = state.userId
        const res = await api({ requiresAuth: true }).get(
          `user/instructorLinks/${id}`
        )
        commit('SET_INSTRUCTOR_LINKS', res.data)
        return res.data
      } catch (error) {
        console.error(
          'FETCHING INSTRUCTOR LINK ERROR:',
          error.response.data.message
        )
        throw error
      }
    },
    async updateInstructorLinks({ commit }, updateUserLinks) {
      try {
        const res = await api({
          requiresAuth: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).patch('/user/update/instructorLinks', updateUserLinks)
        commit('SET_INSTRUCTOR_LINKS', res.data)
        successMessage.call(this, i18n.t('store.save_changes_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.save_changes_failed'))
        console.error('UPDATE USER LINKS ERROR:', error)
        throw error
      }
    },
  },
}
export default user
