<template>
  <div id="myModal" class="modal" @click="closeModal">
    <div id="modal__dropdown">
      <div class="modal__title">
        <ul class="modal__tabs">
          <li
            v-for="(tab, index) in lessonOptions"
            :key="index"
            class="modal__tab"
            :class="{ 'modal__tab--active': activeTab === index }"
            @click.prevent="setActiveTab(index)"
          >
            {{ tab }}
          </li>
        </ul>
        <span id="close" @click="closeModal">&times;</span>
      </div>
      <div v-if="activeTab === 0">
        <form class="modal__form" @submit.prevent="addLesson">
          <div class="modal__group">
            <baseInput
              id="name"
              type="text"
              :placeholder="$t('modals.lesson_name')"
              v-model="newLessonOne.name"
              customClass="input--modal"
              :applyErrorClass="$v.newLessonOne.name.$error"
              @blur="$v.newLessonOne.name.$touch()"
            ></baseInput
            ><template v-if="$v.newLessonOne.name.$error"
              ><p class="error-msg" v-if="!$v.newLessonOne.name.required">
                {{ $t('modals.lesson_name_error') }}
              </p></template
            >
          </div>
          <div class="modal__group">
            <input
              type="file"
              ref="fileInput"
              accept="video/mp4,video/x-m4v,video/*"
              @change="handleFileSelect($event)"
              class="file--modal"
            />
            <template v-if="$v.newLessonOne.videoFile.$error"
              ><p class="error-msg" v-if="!$v.newLessonOne.videoFile.required">
                {{ $t('modals.lesson_file_error') }}
              </p></template
            >
          </div>
          <div class="modal__group">
            <baseButton
              type="submit"
              buttonClass="button--primary button--modal"
              :disabled="isDisabledOne"
              >{{ $t('modals.submit') }}</baseButton
            >
            <p v-if="errorOne" class="error-msg">{{ generalError }}</p>
          </div>
        </form>
      </div>

      <div v-if="activeTab === 1">
        <form class="modal__form" @submit.prevent="uploadYoutubeVideo">
          <div class="modal__group">
            <baseInput
              id="file"
              type="text"
              v-model="newLessonTwo.videoId"
              :placeholder="'Video Link'"
              customClass="input--modal"
              :applyErrorClass="$v.newLessonTwo.videoId.$error"
              @blur="$v.newLessonTwo.videoId.$touch()"
            ></baseInput>
            <template v-if="$v.newLessonTwo.videoId.$error"
              ><p class="error-msg" v-if="!$v.newLessonTwo.videoId.required">
                {{ $t('modals.lesson_link_error') }}
              </p></template
            >
          </div>
          <div class="modal__group">
            <baseButton
              type="submit"
              buttonClass="button--primary button--modal"
              >{{ $t('modals.submit') }}</baseButton
            >
            <p v-if="errorTwo" class="error-msg">{{ generalError }}</p>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import baseInput from '@/components/baseInput/baseInput.vue'
import baseButton from '@/components/baseButton/baseButton.vue'
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    baseInput,
    baseButton,
  },
  data() {
    return {
      lessonOptions: [this.$t('singleCourse.video_yours'), 'Youtube Link'],
      activeTab: 0,
      newLessonOne: {
        name: '',
        videoFile: '',
      },
      newLessonTwo: {
        videoId: '',
      },
      isDisabledOne: false,
      isDisabledTwo: false,
      errorOne: false,
      errorTwo: false,
      generalError: this.$t('errors.something_went_wrong'),
    }
  },
  props: {
    chapterID: {
      type: String,
      default: '',
    },
  },
  validations() {
    return {
      newLessonOne: {
        name: {
          required,
        },
        videoFile: {
          required,
        },
      },
      newLessonTwo: {
        videoId: {
          required,
        },
      },
    }
  },
  methods: {
    setActiveTab(index) {
      this.activeTab = index
      this.newLessonOne = {
        videoFile: null,
        name: '',
      }
      this.newLessonTwo = {
        videoId: '',
      }
      this.$v.$reset()
      this.errorOne = false
      this.errorTwo = false
    },
    ...mapActions(['fetchVideos']),
    async addLesson() {
      this.$v.newLessonOne.$touch()
      if (this.$v.newLessonOne.$invalid) {
        return
      }
      const formData = new FormData()
      formData.append('name', this.newLessonOne.name)
      formData.append('videoFile', this.newLessonOne.videoFile)
      try {
        this.isDisabledOne = true
        await this.$store.dispatch('addLesson', {
          chapterID: this.chapterID,
          lessonData: formData,
        })
        await this.$store.dispatch('fetchChapters', this.$route.params.id)
        this.closeModalAfterSubmit()
      } catch (err) {
        this.error = true
        this.isDisabledOne = false
      }
    },
    async uploadYoutubeVideo() {
      this.$v.newLessonTwo.$touch()
      if (this.$v.newLessonTwo.$invalid) {
        return
      }
      const videoUrl = this.newLessonTwo.videoId
      const startIndex = videoUrl.lastIndexOf('=') + 1
      const videoId = videoUrl.substring(startIndex)
      try {
        this.isDisabledTwo = true
        await this.$store.dispatch('embedVideo', {
          courseID: '',
          chapterID: this.chapterID,
          videoID: videoId,
        })
        await this.$store.dispatch('fetchChapters', this.$route.params.id)
        this.closeModalAfterSubmit()
      } catch (err) {
        this.error = true
        this.isDisabledTwo = false
      }
    },
    closeModal(event) {
      const isModalElement = event?.target?.id === 'myModal'
      const isCloseButtonElement = event?.target?.id === 'close'
      if (isModalElement || isCloseButtonElement) {
        this.$emit('close-modal')
        this.newLessonOne = {
          name: '',
          videoFile: '',
        }
        this.newLessonTwo = {
          videoId: '',
        }
        this.$v.$reset()
        this.$refs.fileInput.value = ''
        this.isDisabledOne = false
        this.isDisabledTwo = false
      }
    },
    closeModalAfterSubmit() {
      this.$emit('close-modal')
      this.newLessonOne = {
        name: '',
        videoFile: '',
      }
      this.newLessonTwo = {
        videoId: '',
      }
      this.$v.$reset()
      this.$refs.fileInput.value = ''
      this.isDisabledOne = false
      this.isDisabledTwo = false
    },
    handleFileSelect(event) {
      const file = event.target.files[0]
      this.newLessonOne.videoFile = file
    },
  },
}
</script>

<style lang="scss" scoped>
@import './addLessonModal.scss';
</style>
