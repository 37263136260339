<template>
  <div class="orderCard">
    <div class="orderCard__content content-layout">
      <div class="orderCard__title">
        <h3>{{ this.$t('tables.order_details') }}</h3>
        <h3 @click="closeCard" class="closeCard">x</h3>
      </div>
      <div class="orderCard__content--table">
        <table class="orderCard__content--table">
          <thead>
            <tr>
              <th>{{ this.$t('courses') }}</th>
              <th>{{ this.$t('tables.total') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ order.course?.name }}</td>
              <td>{{ order.amount }} €</td>
            </tr>
          </tbody>
        </table>
        <span class="status-completed">{{ order.paymentStatus }}</span>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex'
export default {
  components: {},
  props: {
    order: { type: Object, required: true },
  },
  data() {
    return {}
  },
  computed: {
    orderCardData() {
      return this.order
    },
  },
  methods: {
    closeCard() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
@import './_orderCard.scss';
</style>
