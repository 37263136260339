<template>
  <div class="container layout">
    <div class="userManagement">
      <div class="userManagement__title main-title">
        <h2>{{ $t('survey.survey_title') }}</h2>
      </div>
      <div class="userManagement__content content-layout">
        <loaderElement v-if="isLoading" />
        <div v-if="!isLoading">
          <baseTable
            :tableData="surveysData"
            :columns="filteredSurveyColumns"
            name="survey"
            fetchFunction="fetchSurveys"
            :noDataMessage="noDataMessage"
            :search="false"
          ></baseTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import baseTable from '@/components/baseTable/baseTable.vue'
import loaderElement from '@/components/loader/loaderElement.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    baseTable,
    loaderElement,
  },
  data() {
    return {
      noDataMessage: this.$t('survey.no_surveys_found'),
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters(['getSurveys']),
    surveysData() {
      return this.getSurveys
    },
    filteredSurveyColumns() {
      return [
        {
          field: 'username',
          key: 'a',
          title: this.$t('tables.user_name'),
          align: 'left',
          renderBodyCell: ({ rowIndex }) => {
            const survey = this.surveysData[rowIndex]
            return <p>{survey.user.username}</p>
          },
        },
        {
          field: 'email',
          key: 'e',
          title: this.$t('tables.email'),
          align: 'left',
          renderBodyCell: ({ rowIndex }) => {
            const survey = this.surveysData[rowIndex]
            return <p>{survey.user.email}</p>
          },
        },
        {
          field: 'description',
          key: 'b',
          title: this.$t('tables.comment'),
          align: 'left',
        },
        {
          field: 'adminChosen',
          key: 'c',
          title: this.$t('tables.approved'),
          align: 'left',
          renderBodyCell: ({ rowIndex }) => {
            const survey = this.surveysData[rowIndex]
            if (survey.adminChosen) {
              return <p>{this.$t('tables.yes')}</p>
            } else {
              return <p>{this.$t('tables.no')}</p>
            }
          },
        },
        {
          field: 'choose',
          key: 'f',
          title: this.$t('tables.actions'),
          align: 'center',
          width: '22%',
          renderBodyCell: ({ rowIndex }) => {
            const survey = this.surveysData[rowIndex]
            if (!survey.adminChosen) {
              return (
                <span class="span--survey">
                  <button
                    class="button button--edit"
                    on-click={() => this.chooseSurveyToShow(survey)}
                  >
                    {survey ? this.$t('tables.approve_btn') : ' Approve'}
                  </button>
                </span>
              )
            } else {
              return (
                <span class="span--survey">
                  <button
                    class="button button--delete"
                    on-click={() => this.chooseSurveyToShow(survey)}
                  >
                    {survey ? this.$t('tables.disapprove_btn') : ' Disapprove'}
                  </button>
                </span>
              )
            }
          },
        },
      ]
    },
  },
  methods: {
    ...mapActions(['fetchSurveys', 'chooseSurvey']),
    async chooseSurveyToShow(survey) {
      try {
        await this.chooseSurvey(survey.uuid)
        await this.fetchSurveys()
      } catch (error) {
        console.error(error)
      }
    },
  },
  async mounted() {
    try {
      this.isLoading = true
      await this.$store.dispatch(
        'setPage',
        parseInt(this.$route.query.page) || 1
      )
      await this.fetchSurveys()
      this.isLoading = false
    } catch (error) {
      console.error(error)
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/pages/userManagement';
</style>
