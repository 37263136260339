import api from '@/api/api'

const notifications = {
  state: {
    notifications: [],
    unSeenNotifications: 0,
  },

  getters: {
    getNotifications: (state) => state.notifications,
    getUnseenNotifications: (state) => state.unSeenNotifications,
  },

  mutations: {
    SET_NOTIFICATIONS(state, notifications) {
      state.notifications = notifications
    },
    UNSEEN_NOTIFICATIONS(state, unSeenNotifications) {
      state.unSeenNotifications = unSeenNotifications
    },
  },

  actions: {
    async fetchNotifications({ commit }) {
      try {
        const res = await api({ requiresAuth: true }).get(`/notification`)
        commit('SET_NOTIFICATIONS', res.data.data)
      } catch (error) {
        console.error('GETTING NOTIFICATIONS:', error.response.data.message)
        throw error
      }
    },
    async countUnseenNotifications({ commit }) {
      try {
        const res = await api({ requiresAuth: true }).get(
          `/notification/countUnseenNotifications`
        )
        commit('UNSEEN_NOTIFICATIONS', res.data)
      } catch (error) {
        console.error(
          'GETTING NOTIFICATIONS COUNT:',
          error.response.data.message
        )
        throw error
      }
    },
  },
}

export default notifications
