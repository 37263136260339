<template>
  <div class="dropdown__wrapper">
    <div class="dropdown__courses" v-for="course in courses" :key="course.id">
      <dropdownCard
        :uuid="course.uuid"
        :title="course.name"
        :price="course.price"
        :time="course.duration"
        :src="course.imagePath"
        :rating="course.averageRating"
        @card-click="handleCardClick"
      />
    </div>
  </div>
</template>

<script>
import dropdownCard from '../dropdownCard/dropdownCard.vue'
export default {
  components: {
    dropdownCard,
  },
  props: {
    courses: Array,
  },
  methods: {
    handleCardClick(payload) {
      this.$emit('card-click', payload)
    },
    contains(targetElement) {
      return this.$el.contains(targetElement)
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/index.scss';
@import './dropdownSuggestion';
</style>
