<template>
  <div class="participants layout">
    <div class="participants__title main-title">
      <h2>{{ this.$t('sideMenu.participants') }}</h2>
    </div>
    <div class="participants__content content-layout">
      <div class="participants__content--filter">
        <filterCard :total="total" :content="courses"></filterCard>
      </div>
      <div class="participants__content--table">
        <loaderElement v-if="isLoading" />
        <baseTable
          v-if="!isLoading"
          :tableData="usersData"
          :columns="filteredUsersColumns"
          :search="search"
          name="users"
          fetchFunction="fetchInstructorStudents"
          :noDataMessage="noDataMessage"
        ></baseTable>
      </div>
    </div>
  </div>
</template>

<script>
import loaderElement from '@/components/loader/loaderElementVuex.vue'
import filterCard from '@/components/filterCard/filterCard.vue'
import baseTable from '@/components/baseTable/baseTable.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
    baseTable,
    filterCard,
    loaderElement,
  },
  data() {
    return {
      search: false,
      usersColumns: [
        {
          field: 'firstName',
          key: 'b',
          title: this.$t('tables.first_name'),
          align: 'left',
          width: '15%',
          sortBy: 'asc',
        },
        {
          field: 'lastName',
          key: 'c',
          title: this.$t('tables.last_name'),
          align: 'left',
          width: '17%',
        },
        {
          field: 'email',
          key: 'e',
          title: this.$t('tables.email'),
          align: 'left',
          width: '15%',
        },
        {
          field: 'courses',
          key: 'participants_course_names',
          title: this.$t('tables.courses'),
          align: 'left',
          width: '40%',
        },
        {
          field: 'formattedEnrolledDate',
          key: 'g',
          title: this.$t('tables.enrolled'),
          align: 'left',
          width: '30%',
        },
      ],
      noDataMessage: this.$t('tables.no_students_found'),
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (
      from.fullPath == '/participants' &&
      to.fullPath == '/participants?page=1'
    ) {
      this.fetchInstructorStudents()
      next()
    } else if (
      from.fullPath.startsWith('/participants') &&
      to.fullPath.startsWith('/participants')
    ) {
      next()
    }
  },

  computed: {
    ...mapGetters([
      'getUserInfo',
      'getInstructorCourses',
      'getInstructorStudents',
      'getLoading',
      'getUsersTotalCount',
    ]),
    isLoading() {
      return this.getLoading
    },
    total() {
      return this.getUsersTotalCount
    },
    courses() {
      return this.getInstructorCourses
    },
    usersData() {
      if (Array.isArray(this.getInstructorStudents)) {
        return this.getInstructorStudents.map((user) => ({
          ...user,
          formattedEnrolledDate: this.formatEnrolledDate(user?.created_at),
          firstName: user ? user.firstname : '',
          lastName: user ? user.lastname : '',
          email: user ? user.email : '',
          courses: user?.course_names ? user.course_names.join('\n') : '',
        }))
      } else {
        return []
      }
    },
    userInfo() {
      return this.getUserInfo
    },
    filteredUsersColumns() {
      return this.usersColumns
    },
  },
  methods: {
    ...mapActions([
      'fetchInstructorStudents',
      'fetchUserInfo',
      'fetchInstructorCourses',
    ]),
    formatEnrolledDate(enrolledDate) {
      const now = new Date()
      const enrolled = new Date(enrolledDate)

      const timeDiff = now.getTime() - enrolled.getTime()

      const millisecondsPerDay = 24 * 60 * 60 * 1000
      const millisecondsPerHour = 60 * 60 * 1000

      const daysDiff = Math.floor(timeDiff / millisecondsPerDay)
      const hoursDiff = Math.floor(
        (timeDiff % millisecondsPerDay) / millisecondsPerHour
      )
      let formattedDate = ''
      if (daysDiff > 0) {
        formattedDate +=
          daysDiff +
          (daysDiff === 1 ? this.$t('time.day') : this.$t('time.days'))
      }
      if (hoursDiff > 0) {
        formattedDate +=
          hoursDiff +
          (hoursDiff === 1 ? this.$t('time.hour') : this.$t('time.hours'))
      }
      if (daysDiff === 0 && hoursDiff === 0) {
        formattedDate = this.$t('time.less_than_an_hour')
      }

      return formattedDate.trim()
    },
  },
  mounted() {},
  async created() {
    try {
      await this.$store.dispatch('setPage', 1)
      await this.fetchUserInfo()
      if (!this.getInstructorStudents) {
        await this.fetchInstructorStudents()
      }
      await this.$store.dispatch('fetchInstructorCourses', {
        id: this.userInfo.uuid,
        categoryID: '',
        searchText: '',
        page: 1,
        limit: 60,
      })
    } catch (error) {
      console.error(error)
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/pages/participantsView';
</style>
