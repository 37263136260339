<template>
  <div class="comment">
    <textarea
      class="comment-box"
      :value="value"
      :placeholder="placeholder"
      v-on="listeners"
      @input="adjustHeight"
      ref="commentBox"
      rows="1"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: 'CommentBox',
  props: {
    value: [String, Number],
    placeholder: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.adjustHeight()
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
      }
    },
  },
  methods: {
    adjustHeight() {
      this.$refs.commentBox.style.height = 'auto'
      this.$refs.commentBox.style.height =
        this.$refs.commentBox.scrollHeight + 'px'
    },
    updateValue(event) {
      this.$emit('input', event.target.value)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/index.scss';
.comment-box {
  min-height: 3rem;
  line-height: 1.4;
  overflow: hidden;
  width: 100%;
  border: toRem(1) solid $borderColor;
  border-radius: toRem(4);
  resize: none;
  padding: toRem(16) toRem(22) toRem(14) toRem(22);
  font-family: $fontfamily;
  @include font(400, toRem(14), toRem(30), $textColor);
}
</style>
