import api from '@/api/api'
import user from './user'
import { errorMessage } from '@/composables/useMessages'
import i18n from '@/main'

const orders = {
  state: {
    instructorOrders: [],
    page: 1,
    limit: 5,
    totalCount: 0,
    totalPayments: 0,
    totalPaymentsByDate: 0,
    totalPages: null,
  },
  getters: {
    getInstructorOrders: (state) => state.instructorOrders,
    getInstructorOrdersPage: (state) => state.page,
    getInstructorOrdersLimit: (state) => state.limit,
    getInstructorOrdersTotalCount: (state) => state.totalCount,
    getInstructorOrdersLoading: (state) => state.loading,
    getInstructorTotalPayments: (state) => state.totalPayments,
    getInstructorTotalPaymentsByDate: (state) => state.totalPaymentsByDate,
  },
  mutations: {
    SET_INSTRUCTOR_ORDERS(state, data) {
      state.instructorOrders = data
    },
    SET_TOTAL_PAYMENTS(state, total) {
      state.totalPayments = total
    },
    SET_TOTAL_PAYMENTS_BY_DATE(state, total) {
      state.totalPaymentsByDate = total
    },
    SET_PAGE(state, page) {
      state.page = page
    },
    SET_LIMIT(state, limit) {
      state.limit = limit
    },
    SET_TOTAL_COUNT(state, totalCount) {
      state.totalCount = totalCount
    },
    SET_TOTAL_PAGES(state, total) {
      state.totalPages = total
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
  },
  actions: {
    setPage({ commit }, page) {
      commit('SET_PAGE', page)
    },
    setLimit({ commit }, limit) {
      commit('SET_LIMIT', limit)
    },

    async fetchInstructorOrders(
      { commit, state },
      { year = null, month = null }
    ) {
      try {
        commit('SET_LOADING', true)
        const { page, limit } = state
        let data = {}
        if (year === null && month === null) {
          data = `/user/instructorOrders?page=${page}&limit=${limit}`
        } else {
          data = `/user/instructorOrders?year=${year}&month=${month}&page=${page}&limit=${limit}`
        }
        const res = await api({ requiresAuth: true }).get(data)
        if (res?.data?.data?.length == 0) {
          commit('SET_TOTAL_COUNT', 0)
          commit('SET_INSTRUCTOR_ORDERS', [])
        } else {
          commit('SET_INSTRUCTOR_ORDERS', res.data?.data)
          commit('SET_TOTAL_COUNT', res.data?.totalCount)
        }

        let totalPages
        totalPages = Math.ceil(res.data.totalCount / limit)
        commit('SET_TOTAL_PAGES', totalPages)
        commit('SET_LOADING', false)
        return res
      } catch (error) {
        errorMessage.call(this, i18n.t('store.get_orders_failed'))
        console.error('GETTING USERS ERROR:', error.response.data.message)
        if (error.response.status === 404) {
          console.error('Resource not found (404)')
        }
        throw error
      }
    },
    async fetchStudentOrders({ commit, state }, { year = null, month = null }) {
      try {
        commit('SET_LOADING', true)
        const { page, limit } = state
        if (year === null || month === null) {
          const res = await api({ requiresAuth: true }).get(
            `/user/studentOrders?page=${page}&limit=${limit}`
          )
          commit('SET_INSTRUCTOR_ORDERS', res.data?.data)
          commit('SET_TOTAL_COUNT', res.data?.totalCount)
          let totalPages
          totalPages = Math.ceil(res.data.totalCount / limit)
          commit('SET_TOTAL_PAGES', totalPages)
          commit('SET_LOADING', false)
          return res
        } else {
          const res = await api({ requiresAuth: true }).get(
            `/user/studentOrders?year=${year}&month=${month}&page=${page}&limit=${limit}`
          )
          commit('SET_INSTRUCTOR_ORDERS', res.data?.data)
          commit('SET_TOTAL_COUNT', res.data?.totalCount)
          let totalPages
          totalPages = Math.ceil(res?.data?.totalCount / limit)
          commit('SET_TOTAL_PAGES', totalPages)
          commit('SET_LOADING', false)
          return res
        }
      } catch (error) {
        errorMessage.call(this, i18n.t('store.get_orders_failed'))
        console.error('GETTING USERS ERROR:', error.response.data.message)
        throw error
      }
    },
    async fetchTotalPayments({ commit }, { year, month }) {
      try {
        const instructorId = user.state.userId
        if (year == null || month == null) {
          const res = await api({ requiresAuth: true }).get(
            `/user/instructors/paymentCount/${instructorId}`
          )
          commit('SET_TOTAL_PAYMENTS', res?.data)
          return res
        } else {
          const res = await api({ requiresAuth: true }).get(
            `/user/instructors/paymentCount/${instructorId}?year=${year}&month=${month}`
          )
          commit('SET_TOTAL_PAYMENTS', res.data)
          commit('SET_LOADING', false)
          return res
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          commit('SET_TOTAL_PAYMENTS', 0)
          commit('SET_LOADING', false)
          return { data: 0 }
        } else {
          console.error(
            'GETTING TOTAL PAYMENTS ERROR:',
            error.response.data.message
          )
          throw error
        }
      }
    },
  },
}

export default orders
