<template>
  <div class="filterCard">
    <div class="filterCard__filters">
      <div class="customSelect">
        <div class="searchInputContainer">
          <input
            type="text"
            v-model="searchCourses"
            class="smallSelect"
            :placeholder="
              selectedName ? selectedName : this.$t('tables.search_by_course')
            "
            @input="handleSearchInput"
            @focus="inputFocused = true"
            @blur="inputFocused = false"
          />
          <button class="searchButton" @click="toggleSearchDropdown"></button>
        </div>
        <dropdownSuggestion
          v-if="showSearchDropdown && showCourses"
          :courses="filteredCourses"
          class="header__dropdownSearch"
          @card-click="handleCardClick"
        ></dropdownSuggestion>
      </div>
      <baseButton
        type="submit"
        buttonClass="button--filters--apply"
        @click="applyFilters"
        :disabled="!isItemSelected"
        >{{ this.$t('tables.apply') }}</baseButton
      >
      <baseButton
        type="submit"
        buttonClass="button--filters--clear"
        @click="clearFilters"
      ></baseButton>
    </div>
    <div class="filterCard__total">
      <h3>{{ total }}</h3>
      <span>{{ totalItems }}</span>
    </div>
  </div>
</template>

<script>
// import baseSelect from '@/components/baseSelect/baseSelect.vue'
import baseButton from '@/components/baseButton/baseButton.vue'
// import { mapGetters } from 'vuex'
import dropdownSuggestion from '@/components/dropdownSuggestion/dropdownSuggestion.vue'
export default {
  components: {
    baseButton,
    // baseSelect,
    dropdownSuggestion,
  },
  props: {
    total: {
      type: Number,
    },
    content: {
      type: Array,
    },
  },
  data() {
    return {
      showCourses: false,
      showSearchDropdown: false,
      searchCourses: '',
      filteredCourses: [],
      inputFocused: false,
      selectedUuid: '',
      selectedName: '',
      placeholder: this.$t('tables.select_course'),
      totalItems: this.$t('dashboard.students'),
      isCardClick: false,
      previousSelectedUuid: '',
    }
  },
  watch: {
    async searchCourses(newValue) {
      if (!this.isCardClick) {
        if (newValue !== '') {
          this.showCourses = true
          this.filteredCourses = this.content.filter((course) =>
            course.name.toLowerCase().includes(newValue.toLowerCase())
          )
          this.showSearchDropdown = true
        } else {
          this.showCourses = false
          this.showSearchDropdown = false
          this.filteredCourses = []
        }
      } else {
        this.isCardClick = false
      }
    },
  },
  computed: {
    isItemSelected() {
      return (
        (this.selectedUuid !== null && this.selectedUuid !== '') ||
        this.searchCourses !== ''
      )
    },
    selectPlaceholder() {
      return this.placeholder ? this.placeholder : 'Select'
    },
  },
  methods: {
    fetchItems() {
      this.$store.dispatch(`fetchInstructorCourses`, {
        id: null,
        searchText: '',
        page: 1,
        limit: 100,
      })
    },
    applyFilters() {
      if (this.selectedUuid !== '' && this.selectedName !== '') {
        if (this.selectedUuid === this.previousSelectedUuid) {
          return
        }
        this.previousSelectedUuid = this.selectedUuid
        this.$store.dispatch(`fetchFilteredInstructorStudents`, {
          courseId: this.selectedUuid,
          name: this.selectedName,
        })
        this.updateQuery()
      } else {
        this.removeCourseQueryParameter()
      }
    },
    clearFilters() {
      if (
        this.selectedUuid == '' &&
        this.selectedName == '' &&
        this.searchCourses == ''
      ) {
        return
      }
      this.selectedUuid = ''
      this.selectedName = ''
      this.searchCourses = ''
      this.$store.dispatch(`fetchInstructorStudents`)
      this.removeCourseQueryParameter()
    },
    updateQuery() {
      this.$router
        .replace({
          query: {
            ...this.$route.query,
            course: this.selectedName,
          },
        })
        .catch(() => {})
    },
    removeCourseQueryParameter() {
      const query = { ...this.$route.query }
      delete query.course

      this.$router
        .replace({
          query,
        })
        .catch(() => {})
    },
    handleSearchInput() {
      this.filteredCourses = this.content.filter((course) =>
        course.name.toLowerCase().includes(this.searchCourses.toLowerCase())
      )
    },
    toggleSearchDropdown() {
      if (this.inputFocused) {
        this.showSearchDropdown = false
        this.showCourses = false
      } else {
        this.showSearchDropdown = !this.showSearchDropdown
        this.showCourses = !this.showCourses
        if (this.showCourses) {
          this.filteredCourses = this.content
        } else {
          this.filteredCourses = []
        }
      }
    },
    handleCardClick(payload) {
      const { uuid, name } = payload
      this.selectedUuid = uuid
      this.selectedName = name
      this.isCardClick = true
      this.searchCourses = name
      this.showSearchDropdown = false
      this.showCourses = false
    },
    handleDocumentClick(event) {
      const dropdownElement = this.$el.querySelector('.customSelect')
      if (dropdownElement && !dropdownElement.contains(event.target)) {
        this.showSearchDropdown = false
        this.showCourses = false
      }
    },
  },
  created() {
    this.fetchItems()
  },
  mounted() {
    document.addEventListener('click', this.handleDocumentClick)
  },

  beforeDestroy() {
    document.removeEventListener('click', this.handleDocumentClick)
  },
}
</script>

<style lang="scss" scoped>
@import './_filterCard.scss';
.customSelect {
  position: relative;
}
</style>
