<template>
  <div id="myModal" class="modal" @click="closeModal">
    <div id="modal__dropdown">
      <div class="modal__title">
        <h3>{{ $t('singleCourse.add_discount_title') }}</h3>
        <span id="close" @click="closeModal">&times;</span>
      </div>
      <form class="modal__form" @submit.prevent="addDiscount">
        <div class="modal__group">
          <baseInput
            id="discountPercentage"
            type="number"
            max="100"
            min="0"
            :placeholder="0"
            v-model="newDiscount.discountPercentage"
            customClass="input--modal"
            :applyErrorClass="$v.newDiscount.discountPercentage.$error"
            @blur="$v.newDiscount.discountPercentage.$touch()"
          ></baseInput
          ><template v-if="$v.newDiscount.discountPercentage.$error"
            ><p
              class="error-msg"
              v-if="!$v.newDiscount.discountPercentage.required"
            >
              {{ $t('singleCourse.discount_error') }}
            </p></template
          >
        </div>
        <div class="modal__group">
          <baseButton type="submit" buttonClass="button--primary button--modal">
            {{ $t('modals.submit') }}
          </baseButton>
          <p v-if="error" class="error-msg">{{ generalError }}</p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import baseInput from '@/components/baseInput/baseInput.vue'
import baseButton from '@/components/baseButton/baseButton.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    baseInput,
    baseButton,
  },
  data() {
    return {
      newDiscount: {
        discountPercentage: '',
      },
      error: false,
      generalError: this.$t('errors.something_went_wrong'),
    }
  },
  props: {
    courseID: {
      type: String,
      default: '',
    },
  },
  validations() {
    return {
      newDiscount: {
        discountPercentage: {
          required,
        },
      },
    }
  },
  methods: {
    async addDiscount() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      try {
        await this.$store.dispatch('createDiscount', {
          courseID: this.courseID,
          discountPercentage: this.newDiscount,
        })
        await this.$store.dispatch('fetchCourse', this.$route.params.id)
        this.closeModalAfterSubmit()
      } catch (err) {
        this.error = true
      }
    },
    closeModal(event) {
      const isModalElement = event?.target?.id === 'myModal'
      const isCloseButtonElement = event?.target?.id === 'close'
      if (isModalElement || isCloseButtonElement) {
        this.$emit('close-modal')
        this.newDiscount = {
          discountPercentage: '',
        }
        this.$v.$reset()
      }
    },
    closeModalAfterSubmit() {
      this.$emit('close-modal')
      this.newDiscount = {
        discountPercentage: '',
      }
      this.$v.$reset()
    },
  },
}
</script>

<style lang="scss" scoped>
@import './addDiscountModal';
</style>
