import api from '@/api/api'
import user from './user'
import { successMessage, errorMessage } from '@/composables/useMessages'
import i18n from '@/main'
const users = {
  state: {
    data: [],
    searchQuery: '',
    page: 1,
    limit: 5,
    totalCount: 0,
    allAdmins: 0,
    allInstructors: 0,
    allStudents: 0,
    instructorStudents: 0,
    loading: false,
    totalPages: null,
  },
  getters: {
    getUsers: (state) => {
      if (state.data.length > 0) {
        return state.data
      } else {
        return {
          firstName: '',
          lastName: '',
          username: '',
          bio: '',
        }
      }
    },
    getAllAdmins: (state) => state.allAdmins,
    getAllInstructors: (state) => state.allInstructors,
    getAllStudents: (state) => state.allStudents,
    getInstructorStudents: (state) => {
      return state.instructorStudents
    },
    getUsersPage: (state) => state.page,
    getUsersLimit: (state) => state.limit,
    getUsersTotalCount: (state) => state.totalCount,
    getLoading: (state) => state.loading,
  },

  mutations: {
    SET_USERS(state, users) {
      state.data = users.data
    },
    SET_COUNT_USERS(state, data) {
      state.allAdmins = data.admins
      state.allInstructors = data.instructors
      state.allStudents = data.students
    },
    SET_INSTRUCTOR_STUDENTS(state, data) {
      state.instructorStudents = data
    },
    SET_SEARCH_QUERY(state, query) {
      state.searchQuery = query
    },
    SET_PAGE(state, page) {
      state.page = page
    },
    SET_LIMIT(state, limit) {
      state.limit = limit
    },
    SET_TOTAL_COUNT(state, totalCount) {
      state.totalCount = totalCount
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_TOTAL_PAGES(state, total) {
      state.totalPages = total
    },
  },

  actions: {
    setSearchQuery({ commit }, query) {
      commit('SET_SEARCH_QUERY', query)
    },
    setPage({ commit }, page) {
      commit('SET_PAGE', page)
    },
    setLimit({ commit }, limit) {
      commit('SET_LIMIT', limit)
    },
    async fetchAdmins({ commit, state }, payload) {
      try {
        const { page, limit } = state
        let res
        if (payload && payload.searchQuery) {
          const { searchQuery } = payload
          res = await api({ requiresAuth: true }).get(
            `/user/getUserAndCount?firstName=${searchQuery}&page=${page}&limit=${limit}`
          )
        } else if (state.searchQuery) {
          const { searchQuery } = state
          res = await api({ requiresAuth: true }).get(
            `/user/getUserAndCount?firstName=${searchQuery}&page=${page}&limit=${limit}`
          )
        } else {
          commit('SET_LOADING', true)
          res = await api({ requiresAuth: true }).get(
            `/user/getUserAndCount?page=${page}&limit=${limit}`
          )
        }
        const { data } = JSON.parse(JSON.stringify(res.data))
        const users = {
          data,
        }
        commit('SET_USERS', users)
        commit('SET_TOTAL_COUNT', res.data.totalCount)
        let totalPages
        totalPages = Math.ceil(res.data.totalCount / limit)
        commit('SET_TOTAL_PAGES', totalPages)
        commit('SET_LOADING', false)
        return users
      } catch (error) {
        console.error('GETTING USERS ERROR:', error.response.data.message)
        throw error
      }
    },
    async countUsers({ commit }) {
      try {
        const res = await api({ requiresAuth: true }).get('/user/countUsers')
        commit('SET_COUNT_USERS', res.data)
        return res.data
      } catch (error) {
        console.error('COUNT USER ERROR', error)
        throw error
      }
    },
    async createAdmin(_, { credentials, categoryId }) {
      try {
        const res = await api({ requiresAuth: true }).post(
          `/user/createAdmin/${categoryId}`,
          credentials
        )
        successMessage.call(this, i18n.t('store.create_admin_success'))
        return res
      } catch (error) {
        errorMessage.call(this, i18n.t('store.create_admin_failed'))
        console.error('CREATING ADMIN ERROR:', error.response.data.message)
        throw error
      }
    },
    async updateUserRole(_, { userId, newRole }) {
      try {
        const res = await api({ requiresAuth: true }).post(
          `/user/changeRole/${userId}/${newRole}`
        )
        successMessage.call(this, i18n.t('store.change_role_success'))
        return res
      } catch (error) {
        errorMessage.call(this, i18n.t('store.change_role_failed'))
        console.error('UPDATE USERS ROLE ERROR:', error)
        throw error
      }
    },
    async updateUser(_, { userId, updateUser }) {
      try {
        const res = await api({ requiresAuth: true }).patch(
          `/user/updateUser/${userId}`,
          updateUser
        )
        successMessage.call(this, i18n.t('store.update_user_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.update_user_failed'))
        console.error('UPDATE USERS ERROR:', error)
        throw error
      }
    },
    async deleteUser(_, userId) {
      try {
        const res = await api({ requiresAuth: true }).delete(`/user/${userId}`)
        successMessage.call(this, i18n.t('store.delete_user_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.delete_user_failed'))
        console.error('DELETE USER ERROR:', error.response.data.message)
        throw error
      }
    },
    async fetchInstructorStudents({ commit, state }) {
      try {
        commit('SET_LOADING', true)
        const { page, limit } = state
        const instructorId = user.state.userId
        await instructorId
        if (instructorId) {
          const res = await api({ requiresAuth: true }).get(
            `/user/instructorStudents/${instructorId}?page=${page}&limit=${limit}`
          )
          const data = JSON.parse(JSON.stringify(res?.data?.data))
          commit('SET_INSTRUCTOR_STUDENTS', data)
          commit('SET_TOTAL_COUNT', res?.data?.totalCount)

          let totalPages
          totalPages = Math.ceil(res?.data?.totalCount / limit)
          commit('SET_TOTAL_PAGES', totalPages)
          commit('SET_LOADING', false)
          return res
        }
      } catch (error) {
        console.error('GETTING USERS ERROR:', error.response.data.message)
        throw error
      }
    },
    async fetchFilteredInstructorStudents(
      { state, commit },
      { courseId, name }
    ) {
      try {
        commit('SET_LOADING', true)
        const { page, limit } = state
        const res = await api({ requiresAuth: true }).get(
          `/enroll/getEnrolledStudents?courseId=${courseId}&name=${name}&page=${page}&limit=${limit}`
        )
        const data = JSON.parse(JSON.stringify(res.data))
        commit('SET_INSTRUCTOR_STUDENTS', data.data)
        commit('SET_TOTAL_COUNT', data.totalCount)
        let totalPages
        totalPages = Math.ceil(res.data.totalCount / limit)
        commit('SET_TOTAL_PAGES', totalPages)
        commit('SET_LOADING', false)
        return users
      } catch (error) {
        console.error(
          'GETTING ENROLLED USERS ERROR:',
          error.response.data.message
        )
        throw error
      }
    },
  },
}

export default users
