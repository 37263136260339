<template>
  <div>
    <loaderElement
      v-if="loading && isStudent"
      customClass="dashboard__loader"
    />
    <div class="dashboard" v-else>
      <div class="dashboard__statistics">
        <statsCard
          v-for="(card, index) in statsCards"
          :key="index"
          :cardTitle="card.title"
          :count="stats[index]"
          :countColor="card.color"
          customClass="card__wrapper--student"
          customChildClass="card--student"
          customGrandChildClass="card__count--student"
          customTitle="card__title--student"
        ></statsCard>
        <div class="dashboard__student--profile">
          <img
            v-if="userInfo.imageFile"
            class="dashboard__studentImg"
            :src="`https://api.lms.k-clients.com/public/images/${userInfo.imageFile}`"
            alt=""
          />
          <img
            class="dashboard__studentImg"
            v-else
            src="@/assets/pictures/avatar.png"
          />
          <h4>{{ userInfo.firstName }}</h4>
          <h5>{{ userInfo.email }}</h5>
        </div>
      </div>
      <div class="dashboard__student">
        <div class="dashboard__left">
          <div class="dashboard__courses">
            <h5>{{ $t('courses') }}</h5>
            <ul class="dashboard__tabs">
              <li
                v-for="(tab, index) in tabs"
                :key="index"
                class="dashboard__tab"
                :class="{ 'dashboard__tab--active': activeTab === index }"
                @click.prevent="setActiveTab(index)"
              >
                {{ tab }}
              </li>
            </ul>
            <div class="dashboard__filters">
              <baseInput
                id="search"
                type="text"
                :placeholder="$t('search_courses')"
                customClass="input--search"
                class="dashboard__filters-1"
                v-model="searchCourse"
              ></baseInput>
              <div class="dashboard__dropdowns">
                <custom-select
                  :text="$t('show')"
                  :beforeTextWidth="0"
                  :placeholder="{ name: '2', uuid: '' }"
                  :disable-placeholder="false"
                  :options="numbersToShow"
                  :optionValue="'name'"
                  :value="numberToSet"
                  @input="setNumber($event)"
                ></custom-select>
                <custom-select
                  :text="$t('sort_by')"
                  :beforeTextWidth="0"
                  :placeholder="{ name: 'Default', uuid: '' }"
                  :disable-placeholder="false"
                  :options="sortCourses"
                  :optionValue="'name'"
                  :value="sortValue"
                  @input="setSort($event)"
                ></custom-select>
              </div>
            </div>
            <loaderElement v-if="isLoading" />
            <div v-else>
              <div
                class="dashboard__course"
                v-if="courses && courses.length > 0"
              >
                <studentCourseCard
                  v-for="course in courses"
                  :key="course.id"
                  :course="course"
                  customClass="customClass__1"
                ></studentCourseCard>
              </div>
              <div v-else class="dashboard__noCourses">
                <p>{{ $t('coursesPage.no_courses_available') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import statsCard from '../statsCard/statsCard.vue'
import baseInput from '../baseInput/baseInput.vue'
import customSelect from '../baseSelect/customSelect.vue'
import studentCourseCard from '../studentCourseCard/studentCourseCard.vue'
import loaderElement from '../loader/loaderElement.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
  props: {
    userInfo: Object,
    courses: Array,
  },
  components: {
    statsCard,
    baseInput,
    customSelect,
    studentCourseCard,
    loaderElement,
  },
  data() {
    return {
      functionToBeCalled: 'fetchEnrolledCourses',
      functionsToBeCalled: [
        'fetchEnrolledCourses',
        'fetchCompletedCourses',
        'fetchProgressedCourses',
      ],
      isLoading: false,
      loading: false,
      searchCourse: '',
      tabs: [
        this.$t('dashboard.all'),
        this.$t('dashboard.completed'),
        this.$t('dashboard.in_progress'),
      ],
      activeTab: 0,
      numberToSet: 2,
      numbersToShow: [
        { name: '4' },
        { name: '6' },
        { name: '8' },
        { name: '12' },
      ],
      sortValue: 'Default',
      sortCourses: [{ name: 'A-Z' }, { name: 'Z-A' }],
      statsCards: [
        { title: this.$t('dashboard.courses_completed'), color: '#FFC78B' },
        { title: this.$t('dashboard.courses_in_progress'), color: '#7FECCB' },
        { title: this.$t('dashboard.quizzes_completed'), color: '#B8B2FD' },
      ],
    }
  },
  computed: {
    ...mapState(['enrolledCourses']),
    ...mapGetters([
      'getCompletedQuizzes',
      'getCoursesCompleted',
      'getCoursesInProgress',
    ]),
    getCompletedQuizzesCount() {
      return this.getCompletedQuizzes
    },
    getCompletedCoursesCount() {
      return this.getCoursesCompleted
    },
    getInCompletedCoursesCount() {
      return this.getCoursesInProgress
    },
    stats() {
      let stats = [
        this.getCompletedCoursesCount,
        this.getInCompletedCoursesCount,
        this.getCompletedQuizzesCount,
      ]
      return stats
    },
  },
  methods: {
    async baseFunction(searchParams, page, limit) {
      searchParams = encodeURIComponent(searchParams)
      await this.$store.dispatch(this.functionToBeCalled, {
        searchParams: searchParams,
        page: page,
        limit: limit,
      })
    },
    async setActiveTab(index) {
      this.functionToBeCalled = this.functionsToBeCalled[index]
      this.numberToSet === '' ? (this.numberToSet = 2) : this.numberToSet
      this.searchCourse = ''
      this.baseFunction('', 1, this.numberToSet)
      this.activeTab = index
    },
    async setNumber(number) {
      try {
        this.isLoading = true
        this.numberToSet = number
        if (this.numberToSet > 2) {
          this.baseFunction(this.searchCourse, 1, this.numberToSet)
        } else {
          this.baseFunction(this.searchCourse, 1, 2)
        }
        this.isLoading = false
      } catch (error) {
        console.error(error)
      }
    },
    ...mapActions(['setEnrolledCourses']),
    setSort(sortValue) {
      this.sortValue = sortValue
      const sortedCourses = [...this.enrolledCourses.enrolledCourses]
      if (sortValue === 'A-Z') {
        sortedCourses.sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        )
      } else if (sortValue === 'Z-A') {
        sortedCourses.sort((a, b) =>
          b.name.toLowerCase().localeCompare(a.name.toLowerCase())
        )
      } else {
        this.baseFunction(this.searchCourse, 1, this.numberToSet)
      }

      this.setEnrolledCourses(sortedCourses)
    },
  },
  watch: {
    async searchCourse(newValue) {
      try {
        this.isLoading = true
        this.baseFunction(newValue, 1, this.numberToSet)
        this.isLoading = false
      } catch (error) {
        console.error(error)
      }
    },
  },
  async created() {
    this.loading = true
    await Promise.all([
      this.$store.dispatch('getAllCompletedCourses'),
      this.$store.dispatch('getInProgressCourses'),
      this.$store.dispatch('getAllCompletedQuizzes'),
    ])
    this.loading = false
  },
}
</script>

<style scoped lang="scss">
@import './studentDashboard.scss';
</style>
