export default {
  student_role: 'Student',
  teacher_role: 'Instructor',
  admin_role: 'Admin',
  super_admin_role: 'Super Admin',
  landing_page: 'Landing Page',
  log_out: 'Logout',
  log_in: 'Login',
  settings: 'Settings',
  courses: 'Courses',
  search_courses: 'Search Courses',
  all_categories: 'All Categories',
  sort_by: 'Sort by:',
  show: 'Show:',
  sign_up: 'Sign up',
  profile: 'Profile',
  see_all: 'See all',
  footer: 'Copyright © 2020 CreativeLayers. All Right Reserved',
  errors: {
    something_went_wrong: 'Something went wrong. Please try again',
    something_went_wrong_2: 'Something went wrong',
    empty_email: 'Email cannot be empty.',
    valid_email: 'Please enter a valid email',
    not_sent_email: 'Email could not be sent',
    nonexistent_user: 'This user does not exist.',
    active_acc: 'This account is already active.',
    error_ocurred: 'An error occurred. Please try again.',
  },
  appHeader: {
    howdy: 'Howdy',
    count: 'Count',
    you_have: 'You have',
    new_notifications: 'new notifications',
    new_notification: 'new notification',
    profile: 'Profile',
  },
  sideMenu: {
    dashboard: 'Dashboard',
    participants: 'Participants',
    categories: 'Categories',
    events: 'Events',
    orders: 'Orders',
    admins: 'Admins',
    instructors: 'Instructors',
    survey: 'Survey',
  },
  modals: {
    first_name_req: ' First Name is required',
    last_name_req: ' Last Name is required',
    user_req: 'Username is required',
    email_req: 'Email is required',
    email_req_valid: 'Not a valid email',
    add_new_admin: 'Add new admin',
    not_valid_email: 'Email is not valid',
    password_req: 'Password is required',
    password_longer: 'Password should be at least 6 characters long',
    password_syntax:
      ' Password should contain an uppercase letter, a lowercase letter, a number, and a special character',
    passwords_match: 'Passwords should match',
    submit: 'SUBMIT',
    add_chapter: 'Add chapter',
    chapter_name: 'Chapter name',
    chapter_desc: 'Chapter description',
    chapter_name_error: 'Chapter name is required',
    chapter_desc_error: 'Chapter description is required',
    add_category_title: 'Add New Category',
    category_name: 'Category Name',
    category_name_error: 'Category name is required',
    create_this_category: 'create this category',
    create_this_category_btn: 'CREATE',
    edit_chapter: 'Edit chapter',
    add_lesson_title: 'Add New Lesson',
    edit_lesson_title: 'Change video',
    lesson_name: 'Video Name',
    lesson_name_error: 'Video name is required',
    lesson_file_error: 'Video file is required',
    lesson_link_error: 'Video link is required',
    edit_user: 'Edit User',
    change_role: 'Change Role',
  },
  baseButtons: {
    add_new_course: 'ADD NEW COURSE',
  },
  coursesPage: {
    no_courses_available: 'No courses available',
    started_at: 'Started at',
    completed: 'Completed',
    all_courses: 'All courses',
    delete_course: 'Delete course',
    remove_from_view: 'Remove from view',
  },
  dashboard: {
    all: 'All',
    in_progress: 'In Progress',
    completed: 'Completed',
    course: 'Course',
    courses: 'Courses',
    admins: 'Admins',
    instructors: 'Instructors',
    students: 'Students',
    review: 'Review',
    reviews: 'Reviews',
    courses_completed: 'Completed courses',
    courses_in_progress: 'Courses in progress',
    quizzes_completed: 'Completed quizzes',
    top_rating_instructor: 'Top Rating Instructor',
    total_views: 'Total views',
  },
  categories: {
    categories: 'Categories',
    add_new_category: 'ADD NEW CATEGORY',
    delete: 'DELETE',
    delete_this_category: 'Delete this category',
    no_categories_found: 'No categories found',
    edit_category: 'Edit Category',
    category_required: 'Category Name is required',
    edit_category_button: 'EDIT CATEGORY',
  },
  settingsPage: {
    settings_title: 'Settings',
    bio_info: 'Biographical Info',
    first_name: 'First Name',
    last_name: 'Last Name',
    username: 'Username',
    email: 'Email',
    female: ' Female',
    male: ' Male',
    gender: 'Select Gender',
    save_btn: 'Save Changes',
    just_password: 'Password',
    old_password: 'Old password',
    new_password: 'New password',
    confirm_password: 'Confirm password',
    my_dashboard: 'My dashboard',
    public_your_profile: 'Public your profile dashboard',
    my_courses: 'My courses',
    email_user_exist:
      'Email and username already exist. Please choose different ones.',
    email_exist: 'Email already exists. Please choose a different email.',
    user_exist: 'Username already exists. Please choose a different username.',
    fill_password_fields: 'Please fill in all password fields',
    old_pass_incorrect: 'Old password is incorrect!',
    password_requirement:
      ' Password should contain an uppercase letter, a lowercase letter, a number and a special character',
    password_should_match: 'Password should match',
    confirm_pass_not_empty: 'Confirm Password should not be empty',
    new_pass_not_same: 'New password cannot be the same as old password',
    invalid_file_type: 'Invalid file type. Only image files are allowed.',
    general: 'General',
    avatar: 'Avatar',
    password: 'Password',
    socials: 'Socials',
  },
  eventsPage: {
    events_title: 'Events',
    add_new_event: 'Add New Event',
    add_new_event_btn: 'ADD NEW EVENT',
    no_events: 'No events available',
    load_more: 'LOAD MORE',
    event_title: 'Event Title',
    event_link: 'Event Link',
    event_time: 'Event Time',
    event_place: 'Event Place',
    edit_event: 'Edit Event',
    submit_btn: 'Submit',
    title_req: 'Event title is required',
    url_req: 'Event url is required',
    date_req: 'Event date is required',
    time_req: 'Event time is required',
    place_req: 'Event place is required',
    image_req: 'Event image is required',
    delete_event: 'Delete Event',
    update_event: 'Update Event',
    go_to_event: 'Go To Event',
    no_info: 'No Info',
  },
  authentication: {
    are_your_sure: 'Are you sure you want to log out',
    log_out: 'Logout',
    cancel: 'Cancel',
    first_name: 'First Name',
    last_name: 'Last Name',
    user_name: 'Username',
    password: 'Password',
    confirm_password: 'Confirm Password',
    email: 'Email',
    genders: {
      male: 'Male',
      female: 'Female',
      other: 'Other',
    },
    enter_email: 'Enter your email to reset your password',
    go_to_login: 'Go to login?',
    no_account: 'Do not have an account?',
    already_have_account: 'Already have an account?',
    remember_me: 'Remember Me',
    forgot_password_1: 'Forgot',
    forgot_password_2: 'password?',
    log_in_title: 'Log in to your account',
    sign_up_title: 'Sign up and start learning',
    email_cannot_be_empty: 'Email cannot be empty',
    password_cannot_be_empty: 'Password cannot be empty',
    confirm_password_cannot_be_empty: 'Confirm password cannot be empty',
    passwords_not_match: 'Passwords do not match',
    password_could_not_update: 'Password could not be updated',
    enter_valid_email: 'Please enter a valid email',
    wrong_credentials: 'Wrong credentials!',
    error_occurred: 'An error occurred. Please try again.',
    send_email: 'Send',
    email_sent: 'Email sent successfully',
    email_not_sent: 'Email could not be sent',
    reset_password: 'Reset your password',
    enter_new_password: 'Enter your new password',
    password_changed_success: 'Password changed sent succesfully',
    firstname_empty: 'First name cannot be empty.',
    surname_empty: 'Surname cannot be empty.',
    username_empty: 'Username cannot be empty.',
    password_empty: 'Password cannot be empty.',
    email_empty: 'Email cannot be empty.',
    confirmPassword_empty: 'Confirm password cannot be empty.',
    signup__error_password:
      'Password must have uppercase, numbers and symbols, from 6 up to 15 characters...',
    signup__error_password_match: 'Passwords do not match',
    signup__error_email_exists: 'Email or user already exists',
    email_exist: 'Email already exist',
    username_exists: 'Username already exist',
    fill_all_fields: 'Please fill in all required fields',
    account_not_approved_yet: 'Your application as Instructor is pending.',
    please_wait_for_approval: 'Please wait for approval!',
    i_understand: 'I understand',
    recover_your_account: 'Recover your account',
    account_disabled: 'This account has been disabled. ',
    register_as_instructor: 'Instructor',
    register_as_student: 'Student',
    select_category: 'Please select a category',
    invalid_token: 'Invalid token for verifying account!',
    invalid_code: 'Invalid code for verifying account!',
    enter_code: 'Enter code',
    enter_4_digit_code:
      'Enter the 4-digit verification code sent to your email to verify your account',
    verify_acc: 'Please verify your account!',
    check_inbox: 'Check your inbox',
    clear: 'Clear',
  },
  createCourse: {
    createCourse_title: 'Create Course',
    updateCourse_title: 'Update Course',
    course_title: 'Course title',
    course_title_error: 'Course name is required',
    description: 'Description',
    description_error: 'Description is required',
    learning_description: 'Learning Description',
    level: 'Level',
    level_error: 'Level is required',
    language: 'Language',
    language_error: 'Language is required',
    categories: 'Categories',
    category_error: 'Category is required',
    course_thumbnail: 'Course thumbnail',
    course_thumbnail_error: 'Course thumbnail is required',
    course_learnings: 'Course learnings',
    course_learnings_error: 'Course learnings are required',
    course_learnings_empty: 'No learnings (Add learning in Single Course page)',
    delete: 'Delete',
    add_learning: 'Add another learning',
    course_requirements: 'Course requirements',
    course_requirements_error: 'Course requirements are required',
    course_requirements_empty:
      'No requirements (Add requirements in Single Course page)',
    req_desc: 'Requirement description',
    add_req: 'Add another requirement',
    deadline: 'Deadline',
    certificate: 'Certificate',
    price: 'Price',
    submit: 'Submit',
    edit: 'Edit',
    save: 'Save',
  },
  singleCourse: {
    edit_course: 'Edit course',
    delete_course: 'delete this course',
    course_trailer: 'Course Video Trailer',
    upload_video: 'Upload Video',
    video_name: 'Video Name',
    video_yours: 'Your Device',
    video_upload_succes: 'Successfully uploaded youtube video!',
    video_upload_error: 'Failed to upload youtube video!',
    add_discount: 'Add Discount',
    add_discount_title: 'Add Discount Percentage',
    remove_discount: 'Remove Discount',
    discount_error: 'Discount percentage required',
    video_name_error: 'Video name is required',
    video_file_error: 'Video file is required',
    video_link_error: 'Video link is required',
    course_desc: 'Course Description',
    what_you_will_learn: 'What you will learn',
    add_lesson: 'Add video',
    no_videos: 'This chapter has no videos',
    no_chapters: 'This course has no chapters',
    add_chapter: 'ADD CHAPTER',
    student_reviews: 'Student reviews',
    no_reviews: 'This course has no reviews',
    comment_section: 'Go to comment section',
    view_quiz: 'View Quiz',
    create_quiz: 'Create Quiz',
    duration: 'Duration',
    enrolled: 'Enrolled',
    students: 'Students',
    student: 'Student',
    deadline: 'Deadline',
    language: 'Language',
    skill_level: 'Level',
    certificate: 'Certificate',
    yes: 'Yes',
    no: 'No',
    overview: 'Overview',
    chapters: 'Chapters',
    reviews: 'Reviews',
    update_trailer: 'Update trailer video',
    delete_trailer: 'Delete trailer video',
    delete_trailer_content: 'delete trailer video',
    delete_learning: 'delete orientation',
    delete_requirement: 'delete requirement',
    delete_chapter: 'delete this chapter',
    delete_lesson: 'delete this lesson',
    delete_btn: 'DELETE',
    delete_msg: 'Successfully deleted course',
    free: 'Free',
    off: 'Off',
    add_title_comment: 'Add a Title to your Comment...',
    add_desc_comment: 'Add a Description to your Comment...',
    comment: 'Comment',
    all_comment_in_course: 'All comments in this course',
    filter_by: 'Filter by',
    my_comments: 'My Comments',
    all_comments: 'All Comments',
    comment_section_2: 'Comment Section',
    course_complete_evaluation: 'Course Completion Evaluation',
    get_certificate: 'Get certificate',
    cancel: 'Cancel',
    reply: 'Reply',
    replies: 'replies',
    reply_2: 'reply',
    show_more_replies: 'Show more replies',
    load_more: 'Load more',
    add_a_reply: 'Add a reply...',
    course_content: 'Course content',
    want_to_learn_more: 'Want to learn more',
    of_students: 'students',
    no_orientation: 'This course has no orientation',
    no_requirements: 'This course has no requirements',
    no_trailer_video: 'This course has no trailer video',
    add_orientation: 'Add Orientations',
    requirements: 'Requirements',
    add_requirement: 'Add Requirement',
    go_to_comment_section: 'Go to Comment Section',
    learning_description: 'Learning Description',
    learning_error: ' Learning description is required',
    requirement_description: 'Requirement Description',
    requirement_error: 'Requirement description is required',
    add_reviews_and_rate: 'Add Reviews & Rate',
    add_reviews_and_rate_p: 'Please rate this course:',
    no_comment_section: 'No Comments Found.',
    review_title: 'Review Title',
    review_content: 'Review Content',
    review_submit: 'SUBMIT REVIEW',
    review_title_error: 'Please write the title review',
    review_content_error: 'Please write the review content',
    section: 'Section',
    like: 'Like',
    unlike: 'Unlike',
    edit_comment: 'Edit',
    delete_comment: 'Delete',
    course_completion_modal:
      'Ensure that you complete this Course before attempting to take the Quiz.',
  },
  createQuiz: {
    title: 'Quiz',
    create_quiz_title: 'Create Quiz',
    quiz_title: 'Quiz Title',
    enter_quiz_title: 'Enter Quiz Title',
    passing_percentage: 'Passing Percentage',
    passing_percentage_of_this_quiz: 'Passing Percentage of this quiz is:',
    enter_passing_percentage: 'Enter Passing Percentage',
    add_question: 'ADD ANOTHER QUESTION',
    submit_btn: 'SUBMIT',
    fill_all_fields: 'Please fill in all required fields',
    percentage_number: 'Passing percentage should be a number',
    write_correctly: 'Please write your questions and answers correctly',
    similar_question: 'A similar question already exists for the course.',
    finish_attempt: 'Finish attempt',
    min_three_questions: 'Quiz must have minimum 3 questions',
    question: 'Question',
    select_one: 'Select one',
    clear_choice: 'Clear my choice',
    quiz_error: 'Please provide answers for all questions',
    edit_btn: 'Update',
    delete_btn: 'Delete',
    save_changes: 'Save changes',
    cancel_changes: 'Cancel changes',
    edit_quiz: 'EDIT QUIZ',
    delete_quiz: 'DELETE QUIZ',
    create_quiz: 'CREATE QUIZ',
    no_quiz_available: 'No Quizzes Available',
    add_new_question: 'Add New Question',
    submit_changes: 'SUBMIT CHANGES',
    update: 'UPDATE',
    no_questions_quiz: 'This Quiz Has No Questions',
  },
  tables: {
    admins: 'Admins',
    instructors: 'Instructors',
    students: 'Students',
    all: 'All',
    approved: 'Approved',
    pending: 'Pending',
    disapproved: 'Disapproved',
    first_name: 'First Name',
    last_name: 'Last Name',
    user_name: 'Username',
    email: 'Email',
    courses: 'Courses',
    enrolled: 'Enrolled',
    actions: 'Actions',
    edit: 'Edit',
    delete: 'Delete',
    image: 'Image',
    category_name: 'Category Name',
    edit_btn: 'Edit',
    delete_btn: 'Delete',
    comment: 'Comment',
    choose: 'Choose',
    yes: 'Yes',
    no: 'No',
    search_by_name: 'Search by name',
    search_by_course: 'Search by course...',
    select_course: 'Select Course',
    order: 'Order',
    date: 'Date',
    status: 'Status',
    total: 'Total',
    no_orders_found: 'No orders found',
    add_new_admin: 'ADD NEW ADMIN',
    select_month: 'Select Month',
    select_year: 'Select Year',
    apply: 'Apply',
    active: 'Active',
    inactive: 'Inactive',
    enable_btn: 'Enable',
    disable_btn: 'Disable',
    approve_btn: 'Approve',
    disapprove_btn: 'Disapprove',
    no_students_found: 'No students found',
    no_data_found: 'No data found',
    no_users_found: 'No users found',
    click_for_more: 'Click For More',
    order_details: 'Order Details',
  },
  survey: {
    survey_title: 'Survey',
    no_surveys_found: 'No surveys found',
    feedback: 'Feedback',
    your_feedback: 'your feedback',
    your_position: 'Your position',
    submit_btn: 'Submit',
    update_btn: 'Update',
  },
  showModalContent: {
    are_you_sure: 'Are you sure you want to',
    out_of: 'out of',
    delete: 'DELETE',
    cancel: 'CANCEL',
    change_role: 'CHANGE ROLE',
    delete_this_question: 'delete this question',
    delete_this_comment: 'delete this comment',
    delete_this_user: 'delete this user',
    delete_this_course: 'delete this course',
    delete_this_event: 'delete this event',
    delete_this_quiz: 'delete this quiz',
    quiz_deleted_success: 'Successfully deleted quiz',
    change_instructor_role: "change this instructor's role",
    log_out: 'logout',
    log_out_btn: 'LOGOUT',
    approve_this_instructor: 'approve this isntructor',
    approve: 'APPROVE',
    disapprove_this_instructor: 'disapprove this isntructor',
    disapprove: 'DISAPPROVE',
    edit_quiz: 'Edit Quiz Name',
    quiz_name: 'Quiz Name',
    quiz_percentage: 'Quiz Percentage',
    disable_this_user: 'disable this user',
    enable_this_user: 'enable this user',
    disable_btn: 'DISABLE',
    enable_btn: 'ENABLE',
  },
  time: {
    month: 'Month',
    day: ' day ',
    days: ' days ',
    hour: ' hour ',
    hours: ' hours ',
    less_than_an_hour: ' Less than an hour ',
  },
  instructorActions: {
    instructor: 'Instructor',
    first_name: 'First Name',
    last_name: 'Last Name',
    username: 'Username',
    email: 'Email',
    category: 'Category',
    action: 'Action',
    actionApprove: 'Approve',
    actionEnable: 'Enable',
  },
  store: {
    add_category_success: 'Successfully added new category!',
    add_category_failed: 'Failed to add new category!',
    update_category_success: 'Successfully updated category!',
    update_category_failed: 'Failed to update category!',
    delete_category_success: 'Successfully deleted category!',
    delete_category_failed: 'Failed to delete category!',
    add_chapter_success: 'Successfully added new chapter!',
    add_chapter_failed: 'Failed to add new chapter!',
    update_chapter_success: 'Successfully updated chapter!',
    update_chapter_failed: 'Failed to update chapter!',
    delete_chapter_success: 'Successfully deleted chapter!',
    delete_chapter_failed: 'Failed to delete chapter!',
    add_lesson_success: 'Successfully added new lesson!',
    add_lesson_failed: 'Failed to add new lesson!',
    update_lesson_success: 'Successfully updated lesson!',
    update_lesson_failed: 'Failed to update lesson!',
    delete_lesson_success: 'Successfully deleted lesson!',
    delete_lesson_failed: 'Failed to delete lesson!',
    add_comment_success: 'Successfully added comment!',
    add_comment_failed: 'Failed to add comment!',
    add_reply_success: 'Successfully added reply!',
    add_reply_failed: 'Failed to add reply!',
    fetch_comments_failed: 'Failed to fetch comments!',
    update_comment_success: 'Comment updated successfully!',
    update_comment_failed: 'Failed to update comment!',
    delete_comment_success: 'Comment deleted successfully!',
    delete_comment_failed: 'Failed to delete comment!',
    update_reply_success: 'Reply updated successfully!',
    update_reply_failed: 'Failed to update reply!',
    delete_reply_success: 'Reply deleted successfully!',
    delete_reply_failed: 'Failed to delete reply!',
    create_course_success: 'Successfully created course!',
    create_course_failed: 'Failed to create course!',
    add_discount_success: 'Successfully added discount',
    add_discount_failed: 'Failed to add discount',
    remove_discount_success: 'Successfully removed discount',
    remove_discount_failed: 'Failed to remove discount',
    update_course_success: 'Successfully updated course',
    update_course_failed: 'Failed to update course',
    delete_course_success: 'Successfully deleted course',
    delete_course_failed: 'Failed to delete course',
    delete_course_enrolled_error:
      'This course can not be deleted because there are students enrolled in it',
    upload_course_success: 'Successfully uploaded course',
    upload_course_failed: 'Failed to upload course',
    update_video_success: 'Successfully updated video',
    update_video_failed: 'Failed to update video',
    delete_video_success: 'Successfully deleted video',
    delete_video_failed: 'Failed to delete video',
    add_learning_success: 'Successfully added learning',
    add_learning_failed: 'Failed to add learning',
    delete_learning_success: 'Successfully deleted learning',
    delete_learning_failed: 'Failed to delete learning',
    add_requirement_success: 'Successfully added requirement',
    add_requirement_failed: 'Failed to add requirement',
    delete_requirement_success: 'Successfully deleted requirement',
    delete_requirement_failed: 'Failed to delete requirement',
    add_event_success: 'Successfully added event',
    add_event_failed: 'Failed to add event',
    add_event_name_error: 'An event with this name already exists',
    update_event_success: 'Successfully updated event',
    update_event_failed: 'Failed to update event',
    delete_event_success: 'Successfully deleted event',
    delete_event_failed: 'Failed to delete event',
    approve_user_success: 'User approved successfully!',
    approve_user_failed: 'Failed to approve user!',
    disapprove_user_success: 'User disapproved successfully!',
    disapprove_user_failed: 'Failed to disapprove user!',
    enable_user_success: 'User enabled successfully!',
    enable_user_failed: 'Failed to enable user!',
    disable_user_success: 'User disabled successfully!',
    disable_user_failed: 'Failed to disable user!',
    get_orders_failed: 'Failed to get orders!',
    complete_quiz_success: 'Quiz completed successfully',
    create_quiz_success: 'Quiz created successfully',
    create_quiz_failed: 'Failed to create quiz',
    update_quiz_success: 'Quiz updated successfully',
    update_quiz_failed: 'Failed to update quiz',
    delete_quiz_success: 'Quiz deleted successfully',
    delete_quiz_failed: 'Failed to delete quiz',
    update_question_success: 'Successfully updated question',
    update_question_failed: 'Failed to update question',
    delete_question_success: 'Successfully deleted question',
    delete_question_failed: 'Failed to delete question',
    add_question_success: 'Successfully added question',
    add_feedback_success: 'Successfully added feedback',
    add_feedback_failed: 'Failed to add feedback',
    update_feedback_success: 'Successfully updated feedback',
    update_feedback_failed: 'Failed to update feedback',
    submit_review_success: 'Review submitted succesfully',
    submit_review_failed: "Review could't get submitted",
    chose_survey_success: 'Successfully chose survey!',
    chose_survey_failed: 'Failed to choose survey!',
    save_changes_success: 'Successfully saved changes!',
    save_changes_failed: 'Failed to save changes',
    change_password_success: 'Successfully changed password',
    change_password_failed: 'Failed to change password',
    create_admin_success: 'Created admin successfully',
    create_admin_failed: 'Failed to create admin',
    change_role_success: 'Successfully changed user role',
    change_role_failed: 'Failed to change user role',
    update_user_success: 'Successfully updated user',
    update_user_failed: 'Failed to update user',
    delete_user_success: 'Successfully deleted user',
    delete_user_failed: 'Failed to delete user',
  },
  password: {
    reset_your_password: 'Reset Your Password',
    enter_new_password: 'Enter your new password',
    send: 'Send',
    go_to_login: 'Go to login?',
    forgot_password: 'Forgot Password',
    enter_email_to_reset_pw: 'Enter your email to reset it',
    email_success_msg: 'Email sent successfully',
    pw_change_success_msg: 'Password changed sent succesfully',
  },
}
