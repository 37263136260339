<template>
  <div class="create-course layout">
    <div class="create-course__title main-title">
      <h2>{{ $t('createCourse.createCourse_title') }}</h2>
    </div>
    <form class="create-course__form" @submit.prevent="createCourse">
      <div class="create-course__group">
        <baseInput
          id="name"
          :label="$t('createCourse.course_title')"
          type="text"
          :placeholder="$t('createCourse.course_title')"
          v-model="newCourse.name"
          customClass="input--row"
          class="create-course__input"
          :applyErrorClass="$v.newCourse.name.$error"
          @blur="$v.newCourse.name.$touch()"
        ></baseInput>
        <template v-if="$v.newCourse.name.$error">
          <p class="error-msg" v-if="!$v.newCourse.name.required">
            {{ $t('createCourse.course_title_error') }}
          </p>
        </template>
      </div>
      <div class="create-course__group">
        <baseTextarea
          id="description"
          :label="$t('createCourse.description')"
          type="text"
          :placeholder="$t('createCourse.description')"
          v-model="newCourse.description"
          class="create-course__input"
          :applyErrorClass="$v.newCourse.description.$error"
          @blur="$v.newCourse.description.$touch()"
        ></baseTextarea>
        <template v-if="$v.newCourse.description.$error">
          <p class="error-msg" v-if="!$v.newCourse.description.required">
            {{ $t('createCourse.description_error') }}
          </p>
        </template>
      </div>
      <div class="create-course__group2 create-course__select">
        <label class="create-course__label">{{
          $t('createCourse.level')
        }}</label>
        <div class="create-course__file">
          <custom-select
            class="create-course__input"
            :customClass="'full-dropdown'"
            :placeholder="{ name: $t('createCourse.level'), uuid: '' }"
            :disable-placeholder="true"
            :options="levels"
            :optionValue="'name'"
            :value="newCourse.level"
            @input="setLevel($event)"
            :applyErrorClass="$v.newCourse.level.$error"
            @blur="$v.newCourse.level.$touch()"
          ></custom-select>
          <template v-if="$v.newCourse.level.$error">
            <p class="error-msg" v-if="!$v.newCourse.level.required">
              {{ $t('createCourse.level_error') }}
            </p>
          </template>
        </div>
      </div>
      <div class="create-course__group2 create-course__select2">
        <label class="create-course__label">{{
          $t('createCourse.language')
        }}</label>
        <div class="create-course__file">
          <custom-select
            class="create-course__input"
            :customClass="'full-dropdown'"
            :placeholder="{ name: $t('createCourse.language'), uuid: '' }"
            :disable-placeholder="true"
            :options="languages"
            :optionValue="'name'"
            :value="newCourse.language"
            :search-filter="true"
            @input="setLanguage($event)"
            :applyErrorClass="$v.newCourse.language.$error"
            @blur="$v.newCourse.language.$touch()"
          ></custom-select>
          <template v-if="$v.newCourse.language.$error">
            <p class="error-msg" v-if="!$v.newCourse.language.required">
              {{ $t('createCourse.language_error') }}
            </p>
          </template>
        </div>
      </div>
      <div class="create-course__group2">
        <label class="create-course__label">{{
          $t('createCourse.categories')
        }}</label>
        <div class="create-course__file">
          <custom-select
            class="create-course__input"
            :customClass="'full-dropdown'"
            :placeholder="{ name: $t('all_categories'), uuid: '' }"
            :disable-placeholder="true"
            :options="categories"
            :value="category"
            @input="setCategory($event)"
            :applyErrorClass="$v.category.$error"
            @blur="$v.category.$error"
          ></custom-select>
          <template v-if="$v.category.$error">
            <p class="error-msg" v-if="!$v.category.required">
              {{ $t('createCourse.category_error') }}
            </p>
          </template>
        </div>
      </div>
      <div class="create-course__group2 thumbnail">
        <label class="create-course__label">{{
          $t('createCourse.course_thumbnail')
        }}</label>
        <div class="create-course__file">
          <input
            type="file"
            accept=".jpg, .jpeg, .png"
            ref="fileInput"
            class="inputFile"
            @change="handleFileSelect($event)"
          />
          <template v-if="$v.newCourse.image.$error">
            <p class="error-msg" v-if="!$v.newCourse.image.required">
              {{ $t('createCourse.course_thumbnail_error') }}
            </p>
          </template>
        </div>
      </div>
      <div class="create-course__group2">
        <label class="create-course__label">{{
          $t('createCourse.course_learnings')
        }}</label>
        <div class="create-course__item">
          <div class="create-course__item-name">
            <span>{{ $t('createCourse.course_learnings') }}</span>
            <div>
              <img
                src="@/assets/icons/arrow-down.svg"
                class="create-course__icon"
              />
            </div>
          </div>
          <ul class="dropdown-list">
            <li
              v-for="(learning, index) in newCourse.learningList"
              :key="index"
              class="learning"
            >
              <div class="learning--show">
                <p>{{ learning.description }}</p>
                <span @click="deleteLearning(learning)">{{
                  $t('createCourse.delete')
                }}</span>
              </div>
            </li>
            <li class="learning">
              <baseInput
                type="text"
                :placeholder="$t('createCourse.learning_description')"
                v-model="inputValue"
                customClass="input--row"
                class="create-course__input"
                @enter-pressed="addLearning"
              ></baseInput>
            </li>
            <li class="add-learning" @click="addLearning">
              <span>{{ $t('createCourse.add_learning') }}</span>
            </li>
          </ul>
        </div>
      </div>
      <template v-if="$v.newCourse.learningList.$error">
        <p
          class="error-msg error-msg2"
          v-if="!$v.newCourse.learningList.required"
        >
          {{ $t('createCourse.course_learnings_error') }}
        </p>
      </template>
      <div class="create-course__group2">
        <label class="create-course__label">{{
          $t('createCourse.course_requirements')
        }}</label>
        <div class="create-course__item">
          <div class="create-course__item-name">
            <span>{{ $t('createCourse.course_requirements') }}</span>
            <div>
              <img
                src="@/assets/icons/arrow-down.svg"
                class="create-course__icon"
              />
            </div>
          </div>
          <ul class="dropdown-list">
            <li
              v-for="(requirement, index) in newCourse.requirementList"
              :key="index"
              class="learning"
            >
              <div class="learning--show">
                <p>{{ requirement.field }}</p>
                <span @click="deleteRequirement(requirement)">{{
                  $t('createCourse.delete')
                }}</span>
              </div>
            </li>
            <li class="learning">
              <baseInput
                type="text"
                :placeholder="$t('createCourse.req_desc')"
                v-model="requirementValue"
                customClass="input--row"
                class="create-course__input"
                @enter-pressed="addRequirement"
              ></baseInput>
            </li>
            <li class="add-learning" @click="addRequirement">
              <span>{{ $t('createCourse.add_req') }}</span>
            </li>
          </ul>
        </div>
      </div>
      <template v-if="$v.newCourse.requirementList.$error">
        <p
          class="error-msg error-msg2"
          v-if="!$v.newCourse.requirementList.required"
        >
          {{ $t('createCourse.course_requirements_error') }}
        </p>
      </template>
      <!-- <div class="modal__group">
        <baseInput
          id="deadline"
          :label="$t('createCourse.deadline')"
          type="date"
          :placeholder="$t('createCourse.deadline')"
          v-model="newCourse.deadline"
          customClass="input--row"
          class="create-course__input"
        ></baseInput>
      </div> -->
      <div class="create-course__group2">
        <label class="create-course__label">{{
          $t('createCourse.certificate')
        }}</label>
        <toggle-button
          @change="toggleCertificate"
          color="#196ecd"
          class="create-course__file"
        />
      </div>
      <div class="modal__group">
        <baseInput
          id="price"
          :label="$t('createCourse.price')"
          type="number"
          min="0"
          placeholder="0"
          v-model="newCourse.price"
          customClass="input--row input--price"
          iconClass="input-icon--price"
          class="create-course__input"
        ></baseInput>
      </div>
      <div class="create-course__group">
        <baseButton
          type="submit"
          buttonClass="button--primary create-course__button"
          :disabled="isDisabled"
          >{{ $t('createCourse.submit') }}</baseButton
        >
      </div>
    </form>
  </div>
</template>

<script>
import baseInput from '@/components/baseInput/baseInput.vue'
import baseTextarea from '@/components/baseTextarea/baseTextarea.vue'
import customSelect from '@/components/baseSelect/customSelect.vue'
import baseButton from '@/components/baseButton/baseButton.vue'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  components: {
    baseInput,
    baseTextarea,
    baseButton,
    customSelect,
  },
  data() {
    return {
      newCourse: {
        name: '',
        description: '',
        level: '',
        language: '',
        image: '',
        certificate: false,
        price: 0,
        learningList: [],
        requirementList: [],
      },
      inputValue: '',
      requirementValue: '',
      category: '',
      levels: [
        { name: 'Beginner' },
        { name: 'Intermediate' },
        { name: 'Advanced' },
        { name: 'Expert' },
        { name: 'Other' },
      ],
      languages: [
        { name: 'Afrikaans' },
        { name: 'Albanian' },
        { name: 'Amharic' },
        { name: 'Arabic' },
        { name: 'Armenian' },
        { name: 'Azerbaijani' },
        { name: 'Basque' },
        { name: 'Belarusian' },
        { name: 'Bengali' },
        { name: 'Bosnian' },
        { name: 'Bulgarian' },
        { name: 'Catalan' },
        { name: 'Cebuano' },
        { name: 'Chinese (Simplified)' },
        { name: 'Chinese (Traditional)' },
        { name: 'Corsican' },
        { name: 'Croatian' },
        { name: 'Czech' },
        { name: 'Danish' },
        { name: 'Dutch' },
        { name: 'English' },
        { name: 'Esperanto' },
        { name: 'Estonian' },
        { name: 'Filipino' },
        { name: 'Finnish' },
        { name: 'French' },
        { name: 'Frisian' },
        { name: 'Galician' },
        { name: 'Georgian' },
        { name: 'German' },
        { name: 'Greek' },
        { name: 'Gujarati' },
        { name: 'Haitian Creole' },
        { name: 'Hausa' },
        { name: 'Hawaiian' },
        { name: 'Hebrew' },
        { name: 'Hindi' },
        { name: 'Hmong' },
        { name: 'Hungarian' },
        { name: 'Icelandic' },
        { name: 'Igbo' },
        { name: 'Indonesian' },
        { name: 'Irish' },
        { name: 'Italian' },
        { name: 'Japanese' },
        { name: 'Javanese' },
        { name: 'Kannada' },
        { name: 'Kazakh' },
        { name: 'Khmer' },
        { name: 'Kinyarwanda' },
        { name: 'Korean' },
        { name: 'Kurdish' },
        { name: 'Kyrgyz' },
        { name: 'Lao' },
        { name: 'Latin' },
        { name: 'Latvian' },
        { name: 'Lithuanian' },
        { name: 'Luxembourgish' },
        { name: 'Macedonian' },
        { name: 'Malagasy' },
        { name: 'Malay' },
        { name: 'Malayalam' },
        { name: 'Maltese' },
        { name: 'Maori' },
        { name: 'Marathi' },
        { name: 'Mongolian' },
        { name: 'Myanmar (Burmese)' },
        { name: 'Nepali' },
        { name: 'Norwegian' },
        { name: 'Nyanja (Chichewa)' },
        { name: 'Pashto' },
        { name: 'Persian' },
        { name: 'Polish' },
        { name: 'Portuguese' },
        { name: 'Punjabi' },
        { name: 'Romanian' },
        { name: 'Russian' },
        { name: 'Samoan' },
        { name: 'Scots Gaelic' },
        { name: 'Serbian' },
        { name: 'Sesotho' },
        { name: 'Shona' },
        { name: 'Sindhi' },
        { name: 'Sinhala (Sinhalese)' },
        { name: 'Slovak' },
        { name: 'Slovenian' },
        { name: 'Somali' },
        { name: 'Spanish' },
        { name: 'Sundanese' },
        { name: 'Swahili' },
        { name: 'Swedish' },
        { name: 'Tagalog (Filipino)' },
        { name: 'Tajik' },
        { name: 'Tamil' },
        { name: 'Telugu' },
        { name: 'Thai' },
        { name: 'Turkish' },
        { name: 'Ukrainian' },
        { name: 'Urdu' },
        { name: 'Uzbek' },
        { name: 'Vietnamese' },
        { name: 'Welsh' },
        { name: 'Xhosa' },
        { name: 'Yiddish' },
        { name: 'Yoruba' },
        { name: 'Zulu' },
      ],
      isDisabled: false,
      successMsg: false,
    }
  },
  validations() {
    return {
      newCourse: {
        name: {
          required,
        },
        description: {
          required,
        },
        level: {
          required,
        },
        language: {
          required,
        },
        image: {
          required,
        },
        learningList: {
          required,
        },
        requirementList: {
          required,
        },
      },
      category: {
        required,
      },
    }
  },
  computed: {
    ...mapGetters(['getAllCategories']),
    categories() {
      return this.getAllCategories
    },
  },
  methods: {
    toggleCertificate() {
      this.newCourse.certificate = !this.newCourse.certificate
    },
    handleFileSelect(event) {
      const file = event.target.files[0]
      this.newCourse.image = file
    },
    setCategory(category) {
      this.category = category
    },
    handleCustomSelectBlur() {
      this.$v.category.$touch()
    },
    setLevel(level) {
      this.newCourse.level = level
    },
    setLanguage(language) {
      this.newCourse.language = language
    },
    async createCourse() {
      if (this.inputValue) {
        this.addLearning()
      }
      if (this.requirementValue) {
        this.addRequirement()
      }
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      const formData = new FormData()
      formData.append('name', this.newCourse.name)
      formData.append('description', this.newCourse.description)
      formData.append('level', this.newCourse.level)
      formData.append('language', this.newCourse.language)
      formData.append('image', this.newCourse.image)
      /* formData.append('deadline', this.newCourse.deadline) */
      formData.append('certificate', this.newCourse.certificate)
      formData.append('price', this.newCourse.price)
      for (let i = 0; i < this.newCourse.learningList.length; i++) {
        const learning = this.newCourse.learningList[i]
        formData.append(
          `courseLearning[${i}][description]`,
          learning.description
        )
      }
      for (let i = 0; i < this.newCourse.requirementList.length; i++) {
        const requirement = this.newCourse.requirementList[i]
        formData.append(`courseRequirements[${i}][field]`, requirement.field)
      }
      try {
        this.isDisabled = true
        await this.$store.dispatch('createCourse', {
          categoryID: this.category,
          courseData: formData,
        })
        this.successMsg = true
        this.$router.push('/courses')
      } catch (err) {
        console.error(err)
      } finally {
        this.isDisabled = false
      }
    },
    addLearning() {
      this.newCourse.learningList.push({
        description: this.inputValue,
      })
      this.inputValue = ''
    },
    deleteLearning(learning) {
      const index = this.newCourse.learningList.indexOf(learning)
      if (index > -1) {
        this.newCourse.learningList.splice(index, 1)
      }
    },
    addRequirement() {
      this.newCourse.requirementList.push({
        field: this.requirementValue,
      })
      this.requirementValue = ''
    },

    deleteRequirement(requirement) {
      const index = this.newCourse.requirementList.indexOf(requirement)
      if (index > -1) {
        this.newCourse.requirementList.splice(index, 1)
      }
    },
  },
  async created() {
    await this.$store.dispatch('fetchAllCategories')
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/pages/createCourse.scss';
</style>
