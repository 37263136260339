<template>
  <div>
    <Modal v-show="isModalVisible" @close-modal="closeModal">
      <template v-slot:modalContent>
        <div class="modal__title">
          <h3>{{ $t('eventsPage.add_new_event') }}</h3>
        </div>
        <form class="modal__form" @submit.prevent="addNewEvent">
          <div class="modal__group">
            <baseInput
              type="text"
              id="eventTitle"
              v-model="event.title"
              :placeholder="$t('eventsPage.event_title')"
              customClass="input--modal"
              :applyErrorClass="$v.event.title.$error"
            ></baseInput>
            <template v-if="$v.event.title.$error">
              <p class="error-msg" v-if="!$v.event.title.required">
                {{ $t('eventsPage.title_req') }}
              </p>
            </template>
          </div>
          <div class="modal__group">
            <baseInput
              type="text"
              id="eventURL"
              v-model="event.url"
              :placeholder="$t('eventsPage.event_link')"
              :applyErrorClass="$v.event.url.$error"
              customClass="input--modal"
            ></baseInput>
            <template v-if="$v.event.url.$error">
              <p class="error-msg" v-if="!$v.event.url.required">
                {{ $t('eventsPage.url_req') }}
              </p>
            </template>
          </div>
          <div class="modal__group">
            <baseInput
              type="date"
              id="eventDate"
              v-model="event.date"
              customClass="input--modal"
              :applyErrorClass="$v.event.date.$error"
              @blur="$v.event.date.$touch()"
            ></baseInput>
            <template v-if="$v.event.date.$error">
              <p class="error-msg" v-if="!$v.event.date.required">
                {{ $t('eventsPage.date_req') }}
              </p>
            </template>
          </div>
          <div class="modal__group">
            <baseInput
              type="text"
              id="eventTime"
              v-model="event.time"
              :placeholder="$t('eventsPage.event_time')"
              :applyErrorClass="$v.event.time.$error"
              customClass="input--modal"
            ></baseInput>
            <template v-if="$v.event.time.$error">
              <p class="error-msg" v-if="!$v.event.time.required">
                {{ $t('eventsPage.time_req') }}
              </p>
            </template>
          </div>
          <div class="modal__group">
            <baseInput
              type="text"
              id="eventPlace"
              v-model="event.place"
              :placeholder="$t('eventsPage.event_place')"
              :applyErrorClass="$v.event.place.$error"
              customClass="input--modal"
            ></baseInput>
            <template v-if="$v.event.place.$error">
              <p class="error-msg" v-if="!$v.event.place.required">
                {{ $t('eventsPage.place_req') }}
              </p>
            </template>
          </div>
          <div class="modal__group input">
            <img v-if="imagePreview" :src="imagePreview" />
            <input
              type="file"
              accept="image/*"
              id="imageUploads"
              name="imageUploads"
              @change="handleFileSelect($event)"
              class="file--modal"
              :class="{ 'error-border': fileInputError }"
            />
          </div>
          <template v-if="$v.event.image.$error">
            <p class="error-msg" v-if="!$v.event.image.required">
              {{ $t('eventsPage.image_req') }}
            </p>
          </template>
          <div>
            <baseButton class="button--primary button--modal">{{
              $t('eventsPage.add_new_event_btn')
            }}</baseButton>
            <p v-if="error" class="error-msg">{{ generalError }}</p>
          </div>
        </form>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/modalBase/modalBase.vue'
import baseInput from '@/components/baseInput/baseInput.vue'
import baseButton from '@/components/baseButton/baseButton.vue'
import { required } from 'vuelidate/lib/validators'
export default {
  components: {
    Modal,
    baseInput,
    baseButton,
  },
  data() {
    return {
      isModalVisible: false,
      event: {
        title: '',
        date: '',
        time: '',
        place: '',
        image: '',
        url: '',
      },
      imagePreview: null,
      error: false,
      fileInputError: false,
      generalError: this.$t('errors.something_went_wrong'),
    }
  },
  validations() {
    return {
      event: {
        title: {
          required,
        },
        date: {
          required,
        },
        time: {
          required,
        },
        place: {
          required,
        },
        image: {
          required,
        },
        url: {
          required,
        },
      },
    }
  },
  methods: {
    openModal() {
      this.isModalVisible = true
    },
    closeModal() {
      if (this.event.image) {
        const fileInput = document.getElementById('imageUploads')
        if (fileInput) {
          fileInput.value = ''
        }
        this.imagePreview = null
      }
      this.isModalVisible = false
      this.resetForm()
    },
    handleFileSelect(event) {
      const file = event.target.files[0]
      if (file) {
        this.event.image = file
        this.imagePreview = URL.createObjectURL(file)
        this.fileInputError = false
      } else {
        this.event.image = ''
        this.imagePreview = null
        this.fileInputError = true
      }
    },
    async addNewEvent() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      const formData = new FormData()
      formData.append('title', this.event.title)
      formData.append('date', this.event.date)
      formData.append('time', this.event.time)
      formData.append('place', this.event.place)
      formData.append('image', this.event.image)
      formData.append('url', this.event.url)
      try {
        await this.$store.dispatch('addEvent', formData)
        this.closeModal()
      } catch (err) {
        this.error = true
        this.generalError = this.$t('errors.something_went_wrong')
      }
    },
    clearGeneralError() {
      this.generalError = ''
      this.error = false
    },
    resetForm() {
      this.event = {
        title: '',
        date: '',
        time: '',
        place: '',
        image: '',
        url: '',
      }
      this.$v.$reset()
      this.clearGeneralError()
    },
  },
}
</script>

<style lang="scss" scoped>
@import './addEventModal';
</style>
