<template>
  <div>
    <Modal v-show="isModalVisible" @close-modal="closeModal">
      <template v-slot:modalContent>
        <div class="modal__title">
          <h3>{{ $t('eventsPage.edit_event') }}</h3>
        </div>
        <form class="modal__form" @submit.prevent="editEvent">
          <div class="modal__group">
            <baseInput
              type="text"
              id="eventTitle"
              v-model="event.title"
              :placeholder="$t('eventsPage.event_title')"
              customClass="input--modal"
              :applyErrorClass="$v.event.title.$error"
            ></baseInput>
            <template v-if="$v.event.title.$error">
              <p class="error-msg" v-if="!$v.event.title.required">
                {{ $t('eventsPage.title_req') }}
              </p>
            </template>
          </div>
          <div class="modal__group">
            <baseInput
              type="text"
              id="eventURL"
              v-model="event.url"
              :placeholder="$t('eventsPage.event_link')"
              :applyErrorClass="$v.event.url.$error"
              customClass="input--modal"
            ></baseInput>
            <template v-if="$v.event.url.$error">
              <p class="error-msg" v-if="!$v.event.url.required">
                {{ $t('eventsPage.url_req') }}
              </p>
            </template>
          </div>
          <div class="modal__group">
            <baseInput
              type="date"
              id="eventDate"
              v-model="event.date"
              :applyErrorClass="$v.event.date.$error"
              customClass="input--modal"
            ></baseInput>
            <template v-if="$v.event.date.$error">
              <p class="error-msg" v-if="!$v.event.date.required">
                {{ $t('eventsPage.date_req') }}
              </p>
            </template>
          </div>
          <div class="modal__group">
            <baseInput
              type="text"
              id="eventTime"
              v-model="event.time"
              :placeholder="$t('eventsPage.event_time')"
              :applyErrorClass="$v.event.time.$error"
              customClass="input--modal"
            ></baseInput>
            <template v-if="$v.event.time.$error">
              <p class="error-msg" v-if="!$v.event.time.required">
                {{ $t('eventsPage.time_req') }}
              </p>
            </template>
          </div>
          <div class="modal__group">
            <baseInput
              type="text"
              id="eventPlace"
              v-model="event.place"
              :placeholder="$t('eventsPage.event_place')"
              :applyErrorClass="$v.event.place.$error"
              customClass="input--modal"
            ></baseInput>
            <template v-if="$v.event.place.$error">
              <p class="error-msg" v-if="!$v.event.place.required">
                {{ $t('eventsPage.place_req') }}
              </p>
            </template>
          </div>
          <div class="modal__group input">
            <img
              :src="
                imagePreview
                  ? imagePreview
                  : `https://api.lms.k-clients.com/public/images/${event?.imagePath}`
              "
            />
            <input
              type="file"
              accept="image/*"
              ref="imageUpload"
              id="imageUploads"
              name="imageUploads"
              @change="handleFileSelect($event)"
              class="file--modal"
            />
          </div>
          <div>
            <baseButton
              class="button--primary button--modal"
              :disabled="
                submitting ||
                (isEventUnchanged && isDateUnchanged && !imagePreview)
              "
              >{{ $t('eventsPage.submit_btn') }}</baseButton
            >
          </div>
        </form>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/modalBase/modalBase.vue'
import baseInput from '@/components/baseInput/baseInput.vue'
import baseButton from '@/components/baseButton/baseButton.vue'
import { required } from 'vuelidate/lib/validators'
export default {
  components: {
    Modal,
    baseInput,
    baseButton,
  },
  props: {
    eventID: {
      type: String,
      default: '',
    },
    oldEvent: {
      type: Object,
    },
  },
  data() {
    return {
      isModalVisible: false,
      event: {
        title: '',
        date: '',
        time: '',
        place: '',
        image: '',
        url: '',
      },
      imagePreview: null,
      submitting: false,
      error: false,
      generalError: this.$t('errors.something_went_wrong'),
    }
  },
  validations() {
    return {
      event: {
        title: {
          required,
        },
        date: {
          required,
        },
        time: {
          required,
        },
        place: {
          required,
        },
        url: {
          required,
        },
      },
    }
  },
  computed: {
    isEventUnchanged() {
      const oldEventWithoutDate = { ...this.oldEvent }
      const eventWithoutDate = { ...this.event }
      delete oldEventWithoutDate.date
      delete eventWithoutDate.date
      return (
        JSON.stringify(oldEventWithoutDate) === JSON.stringify(eventWithoutDate)
      )
    },
    isDateUnchanged() {
      return (
        this.formatDate(this.oldEvent.date) === this.formatDate(this.event.date)
      )
    },
  },
  watch: {
    oldEvent: {
      handler(newValue) {
        this.event = { ...newValue }
        this.event.date = this.formatDate(newValue.date)
      },
    },
    isModalVisible(newValue) {
      if (newValue === true) {
        this.resetForm()
      }
    },
  },
  methods: {
    openModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
    },
    formatDate(date) {
      const parsedDate = new Date(date)
      const year = parsedDate.getFullYear()
      const month = `${parsedDate.getMonth() + 1}`.padStart(2, '0')
      const day = `${parsedDate.getDate()}`.padStart(2, '0')
      const formatted = `${year}-${month}-${day}`
      return formatted
    },
    handleFileSelect(event) {
      const file = event.target.files[0]
      if (file) {
        this.event.image = file
        this.imagePreview = URL.createObjectURL(file)
      } else {
        this.event.image = ''
        this.imagePreview = null
      }
    },
    async editEvent() {
      this.submitting = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      try {
        const updatedData = new FormData()
        for (const propertyKey in this.event) {
          if (this.event[propertyKey] !== this.oldEvent[propertyKey]) {
            updatedData.append(propertyKey, this.event[propertyKey])
          }
        }
        await this.$store.dispatch('editEvent', {
          eventID: this.eventID,
          eventData: updatedData,
        })
      } catch (err) {
        this.submitting = false
        this.error = true
        this.generalError = this.$t('errors.something_went_wrong')
      }
      this.closeModal()
    },
    clearGeneralError() {
      this.generalError = ''
      this.error = false
    },
    resetForm() {
      setTimeout(() => {
        this.event.title = this.oldEvent.title
        this.event.date = this.formatDate(this.oldEvent.date)
        this.event.time = this.oldEvent.time
        this.event.place = this.oldEvent.place
        this.event.url = this.oldEvent.url
        this.event.image = this.oldEvent.image
        this.imagePreview = null
        const input = this.$refs.imageUpload
        if (input) {
          input.value = ''
        }
        this.clearGeneralError()
        this.submitting = false
      }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
@import './editEventModal';
</style>
