<template>
  <div class="questionCard">
    <div class="questionCard__content" v-show="!showEditQuestion">
      <div class="questionCard__question">
        <div class="questionCard__title">
          <h3>{{ $t('createQuiz.question') }} {{ index + 1 }}:</h3>
          <div class="questionCard__actions" v-if="edit">
            <span class="questionCard__edit edit" @click="editQuestion">{{
              $t('createQuiz.edit_btn')
            }}</span>
            <span
              class="questionCard__flag edit"
              @click="showDeleteQuestionModal()"
              >{{ $t('createQuiz.delete_btn') }}</span
            >
          </div>
        </div>
        <h3 v-show="!showEditQuestion" class="questionCard__description">
          {{ question.question }}
        </h3>
      </div>
      <div class="questionCard__answers">
        <p class="questionCard__answers--text">
          {{ $t('createQuiz.select_one') }}:
        </p>
        <form name="quizAnswers" action="#" class="modal__radio">
          <p v-for="(answer, index) in question.answers" :key="answer.uuid">
            <baseInput
              :id="'answer' + index + '_' + (index + 1)"
              type="radio"
              customClass="input--radio"
              :name="'question' + index"
              :value="answer.answer"
              :checked="answer.rightAnswer"
              :disabled="!showEditQuestion"
            ></baseInput>
            <label :for="'answer' + index + '_' + (index + 1)">
              <b>{{ getOptionLetter(index) }}.</b> {{ answer.answer }}
            </label>
          </p>
        </form>
      </div>
    </div>
    <div class="questionCard__content" v-show="showEditQuestion">
      <div class="questionCard__question">
        <div class="questionCard__title edit">
          <h3>{{ $t('createQuiz.question') }}:</h3>
          <div class="inputWrapper">
            <baseInput
              id="questionTitle"
              type="text"
              customClass="input--question"
              v-model="questionInfo.question"
            ></baseInput>
          </div>
        </div>

        <div class="questionCard__actions edit" v-show="showEditQuestion">
          <span class="questionCard__edit edit" @click="updateQuestion">{{
            $t('createQuiz.save_changes')
          }}</span>
          <span class="questionCard__flag edit" @click="editQuestion">{{
            $t('createQuiz.cancel_changes')
          }}</span>
        </div>
      </div>
      <div class="questionCard__answers edit">
        <p class="questionCard__answers--text">
          {{ $t('createQuiz.select_one') }}:
        </p>
        <form name="quizAnswers" action="#" class="modal__radio">
          <div class="inputWrapper">
            <p
              v-for="(answer, index) in questionInfo.answers"
              :key="answer.uuid"
            >
              <baseInput
                :id="'answer' + index + '_' + (index + 1)"
                type="radio"
                customClass="input--radio"
                :name="'answer' + index"
                :value="answer.answer"
                :checked="answer.rightAnswer"
                @click="changeAnswer(index)"
              ></baseInput>
              <label :for="'answer' + index + '_' + (index + 1)">
                <baseInput
                  :id="'answer' + (index + 1)"
                  type="text"
                  placeholder="Answer"
                  customClass="input--answer"
                  v-model="questionInfo.answers[index].answer"
                ></baseInput>
              </label>
            </p>
          </div>
        </form>
      </div>
    </div>
    <transition name="modal-fade">
      <baseModal
        v-if="showDeleteQuestion"
        :content="showModalContent"
        @confirm-action="deleteQuestion()"
        @close-modal="showDeleteQuestion = false"
      />
    </transition>
  </div>
</template>

<script>
import baseInput from '../baseInput/baseInput.vue'
import { mapActions } from 'vuex'
import baseModal from '@/components/baseModal/baseModal.vue'
export default {
  components: {
    baseInput,
    baseModal,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    index: Number,
  },
  data() {
    return {
      questionTitle: '',
      description: '',
      questionId: null,
      showEditQuestion: false,
      showDeleteQuestion: false,
      questionInfo: {
        question: this.question ? this.question.question : '',
        answers: this.question ? [...this.question.answers] : [],
      },
    }
  },
  methods: {
    ...mapActions(['fetchQuizInfo']),
    getOptionLetter(index) {
      return String.fromCharCode(97 + index)
    },
    changeAnswer(index) {
      this.questionInfo.answers.forEach((answer, i) => {
        if (i === index) {
          answer.rightAnswer = true
        } else {
          answer.rightAnswer = false
        }
      })
    },
    editQuestion() {
      this.questionId = this.question.uuid
      this.showEditQuestion = !this.showEditQuestion
    },
    async updateQuestion() {
      try {
        let credentials = {
          question: this.questionInfo.question,
          answers: this.questionInfo.answers,
        }
        const isQuestionChanged =
          this.questionInfo.question !== this.question.question ||
          JSON.stringify(this.questionInfo.answers) !==
            JSON.stringify(this.question.answers)
        if (isQuestionChanged) {
          await this.$store.dispatch('updateQuestion', {
            questionId: this.questionId,
            credentials: credentials,
          })
          await this.fetchQuizInfo()
        }
        this.showEditQuestion = !this.showEditQuestion
      } catch (err) {
        console.error('Update question Error', err)
      }
    },
    showDeleteQuestionModal() {
      this.showModalContent = {
        message: this.$t('showModalContent.delete_this_question'),
        submit: this.$t('showModalContent.delete'),
      }
      this.showDeleteQuestion = !this.showDeleteQuestion
    },
    async deleteQuestion() {
      try {
        this.questionId = this.question.uuid
        await this.$store.dispatch('deleteQuestion', {
          questionId: this.questionId,
        })

        this.fetchQuizInfo()
      } catch (err) {
        console.error('Delete Question Error', err)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import './questionCard';
</style>
