import Vue from 'vue'
import App from './App.vue'
import store from './store/index'
import router from './router'
import { far } from '@fortawesome/free-regular-svg-icons'
import truncate from './helpers/truncate'
import Vuelidate from 'vuelidate'
import getRoles from '@/helpers/getRoles'
import closeModal from '@/helpers/closeModal'
import limitCharacters from '@/helpers/limitCharacters'
import VueEasytable from 'vue-easytable'
import 'vue-easytable/libs/theme-default/index.css' // import style
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import VueI18n from 'vue-i18n'
import storeLanguage from './store/language'
import en from './locales/en'
import al from './locales/al'
import OtpInput from '@bachdgvn/vue-otp-input'

Vue.component('v-otp-input', OtpInput)
Vue.use(Toast, {
  position: 'top-right',
  timeout: 2500,
  closeOnClick: true,
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  draggable: false,
  draggablePercent: 0.6,
  showCloseButtonOnHover: true,
  hideProgressBar: false,
  closeButton: 'button',
  icon: true,
  rtl: false,
})
Vue.use(VueI18n)
Vue.mixin(truncate)
import {
  VeTable,
  VePagination,
  VeIcon,
  VeLoading,
  VeLocale,
} from 'vue-easytable'
Vue.mixin(getRoles)
Vue.mixin(closeModal)
Vue.directive('limit-characters', limitCharacters)

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faUserGraduate,
  faChalkboardUser,
  faHeart,
  faGear,
  faCircleRight,
  faUser,
  faBookBookmark,
  faComments,
  faArrowRight,
  faArrowLeft,
  faSearch,
  faNewspaper,
  faPeopleRoof,
  faRectangleList,
  faCalendar,
  faClock,
  faHome,
  faCircleCheck,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faNewspaper,
  faUserGraduate,
  faChalkboardUser,
  faHeart,
  faGear,
  faCircleRight,
  faUser,
  faBookBookmark,
  faComments,
  faArrowRight,
  faArrowLeft,
  faSearch,
  faPeopleRoof,
  faRectangleList,
  faCalendar,
  faClock,
  faHome,
  far,
  faCircleCheck
)

import ToggleButton from 'vue-js-toggle-button'

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(Vuelidate)
Vue.mixin(getRoles)
Vue.prototype.$role = null

Vue.use(VeTable)
Vue.use(VePagination)
Vue.use(VeIcon)
Vue.use(VeLoading)
Vue.use(VueEasytable)
Vue.use(ToggleButton)
Vue.config.productionTip = false

Vue.prototype.$veLoading = VeLoading
Vue.prototype.$veLocale = VeLocale

const i18n = new VueI18n({
  locale: storeLanguage.state.currentLanguage,
  // globalInjection: true,
  messages: {
    en,
    al,
  },
})
export default i18n
new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
