import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import user from './user'
import users from './users'
import courses from './courses'
import categories from './categories'
import reviews from './reviews'
import instructors from './instructors'
import quizzes from './quizzes'
import chapters from './chapters'
import enrolledCourses from './enrolledCourses'
import videos from './videos'
import commentSection from './commentSection'
import events from './events'
import orders from './orders'
import notifications from './notifications'
import survey from './survey'
import language from './language'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    user,
    users,
    courses,
    categories,
    reviews,
    instructors,
    quizzes,
    chapters,
    enrolledCourses,
    videos,
    commentSection,
    events,
    orders,
    notifications,
    survey,
    language,
  },
})
