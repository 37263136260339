<template>
  <div id="myModal" class="modal" @click="closeModal">
    <div id="modal__dropdown">
      <div class="modal__title">
        <h3>{{ $t('singleCourse.update_trailer') }}</h3>
        <span id="close" @click="closeModal">&times;</span>
      </div>
      <form
        v-if="courseVideo[0]?.videoFile"
        class="modal__form"
        @submit.prevent="updateVideo"
      >
        <div class="modal__group">
          <baseInput
            id="name"
            type="text"
            :placeholder="$t('singleCourse.video_name')"
            v-model="updatedVideoOne.name"
            customClass="input--modal"
            :applyErrorClass="$v.updatedVideoOne.name.$error"
            @blur="$v.updatedVideoOne.name.$touch()"
          ></baseInput>
          <template v-if="$v.updatedVideoOne.name.$error">
            <p class="error-msg" v-if="!$v.updatedVideoOne.name.required">
              {{ $t('singleCourse.video_name_error') }}
            </p>
          </template>
        </div>
        <div class="modal__group">
          <input
            type="file"
            ref="fileInput"
            accept="video/mp4,video/x-m4v,video/*"
            @change="handleFileSelect($event)"
            class="file--modal inputFile"
          />
          <template v-if="$v.updatedVideoOne.videoFile.$error">
            <p class="error-msg" v-if="!$v.updatedVideoOne.videoFile.required">
              {{ $t('singleCourse.video_file_error') }}
            </p>
          </template>
        </div>
        <div class="modal__group">
          <baseButton
            type="submit"
            buttonClass="button--primary button--modal"
            :disabled="isChanged || isDisabled"
            >{{ $t('modals.submit') }}</baseButton
          >
          <p v-if="error" class="error-msg">{{ generalError }}</p>
        </div>
      </form>
      <form v-else class="modal__form" @submit.prevent="updateVideoLink">
        <div class="modal__group">
          <baseInput
            id="file"
            type="text"
            v-model="updatedVideoTwo.youTubeVideoId"
            :placeholder="'Video Link'"
            customClass="input--modal"
            :applyErrorClass="$v.updatedVideoTwo.youTubeVideoId.$error"
            @blur="$v.updatedVideoTwo.youTubeVideoId.$touch()"
          ></baseInput>
          <template v-if="$v.updatedVideoTwo.youTubeVideoId.$error">
            <p
              class="error-msg"
              v-if="!$v.updatedVideoTwo.youTubeVideoId.required"
            >
              {{ $t('singleCourse.video_link_error') }}
            </p>
          </template>
        </div>
        <div class="modal__group">
          <baseButton
            type="submit"
            buttonClass="button--primary button--modal"
            :disabled="isChangedTwo || isDisabled"
            >{{ $t('modals.submit') }}</baseButton
          >
          <p v-if="error" class="error-msg">{{ generalError }}</p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import baseInput from '@/components/baseInput/baseInput.vue'
import baseButton from '@/components/baseButton/baseButton.vue'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    baseInput,
    baseButton,
  },
  data() {
    return {
      updatedVideoOne: {
        name: '',
        videoFile: null,
      },
      updatedVideoTwo: {
        youTubeVideoId: null,
      },
      isDisabled: false,
      error: false,
      generalError: this.$t('errors.something_went_wrong'),
    }
  },
  validations() {
    return {
      updatedVideoOne: {
        name: {
          required,
        },
        videoFile: {},
      },
      updatedVideoTwo: {
        youTubeVideoId: {},
      },
    }
  },
  props: {
    courseID: {
      type: String,
      default: '',
    },
    videoID: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(['getCourseVideo']),
    courseVideo() {
      return this.getCourseVideo
    },
    isChanged() {
      const changedInfo =
        this.courseVideo[0].name === this.updatedVideoOne.name &&
        this.updatedVideoOne.videoFile === null
      return changedInfo
    },
    isChangedTwo() {
      return (
        this.updatedVideoTwo.youTubeVideoId === this.courseVideo[0].videoLink
      )
    },
  },
  methods: {
    async handleFileSelect(event) {
      this.updatedVideoOne.videoFile = event.target.files[0]
    },
    async updateVideo() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      const formData = new FormData()
      const name = this.updatedVideoOne.name
      const videoFile = this.updatedVideoOne.videoFile
      formData.append('name', name)
      if (this.updatedVideoOne.videoFile) {
        formData.append('videoFile', videoFile)
      }
      try {
        this.isDisabled = true
        await this.$store.dispatch('updateVideo', {
          videoID: this.videoID,
          video: formData,
        })
        await this.$store.dispatch('fetchCourseVideo', this.courseID)
        this.$emit('update-video-path')
        this.closeModalAfterSubmit()
      } catch (err) {
        this.error = true
        this.isDisabled = false
      }
    },
    async updateVideoLink() {
      /* this.$v.$touch()
      if (this.$v.$invalid) {
        return
      } */
      const videoUrl = this.updatedVideoTwo.youTubeVideoId
      const startIndex = videoUrl.lastIndexOf('=') + 1
      const videoLink = videoUrl.substring(startIndex)
      try {
        this.isDisabled = true
        await this.$store.dispatch('updateEmbedVideo', {
          youTubeVideoId: videoLink,
          videoID: this.videoID,
        })
        await this.$store.dispatch('fetchCourseVideo', this.courseID)
        this.closeModalAfterSubmit()
      } catch (err) {
        this.error = true
        this.isDisabled = false
      }
    },
    closeModal(event) {
      const isModalElement = event?.target?.id === 'myModal'
      const isCloseButtonElement = event?.target?.id === 'close'
      if (isModalElement || isCloseButtonElement) {
        this.$emit('close-modal')
        this.updatedVideoOne = {
          name: '',
        }
        this.$v.$reset()
        this.isDisabled = false
      }
    },
    closeModalAfterSubmit() {
      this.$emit('close-modal')
      this.updatedVideoOne = {
        name: '',
      }
      this.$v.$reset()
      this.isDisabled = false
    },
  },
  async created() {
    await this.$store.dispatch('fetchCourseVideo', this.courseID)
  },
  mounted() {
    this.updatedVideoOne.name = this.courseVideo[0].name
    this.updatedVideoTwo.youTubeVideoId = this.courseVideo[0].videoLink
  },
}
</script>

<style lang="scss" scoped>
@import './updateVideoModal';
</style>
