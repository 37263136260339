<template>
  <div>
    <Modal v-show="isModalVisible" @close-modal="closeModal">
      <template v-slot:modalContent>
        <div class="modal__title">
          <h3>{{ $t('categories.edit_category') }}</h3>
        </div>
        <form class="modal__form" @submit.prevent="editCategory">
          <div class="modal__group">
            <baseInput
              type="text"
              id="categoryName"
              v-model="category.name"
              :placeholder="$t('modals.category_name')"
              customClass="input--modal"
              :applyErrorClass="$v.category.name.$error"
              @blur="$v.category.name.$touch()"
            ></baseInput>
            <template v-if="$v.category.name.$error">
              <p class="error-msg" v-if="!$v.category.name.required">
                {{ $t('categories.category_required') }}
              </p>
            </template>
          </div>
          <div class="modal__group input">
            <img
              :src="
                imagePreview
                  ? imagePreview
                  : `https://api.lms.k-clients.com/public/images/${category?.imagePath}`
              "
            />
            <input
              type="file"
              accept="image/*"
              ref="imageUpload"
              id="imageUploads"
              name="imageUploads"
              @change="handleFileSelect($event)"
              class="file--modal"
            />
          </div>
          <div>
            <baseButton
              class="button--primary button--modal"
              :disabled="isCategoryUnchanged || submitting"
              >{{ $t('categories.edit_category_button') }}</baseButton
            >
            <p v-if="error" class="error-msg">{{ generalError }}</p>
          </div>
        </form>
      </template>
    </Modal>
  </div>
</template>

<script>
import baseInput from '@/components/baseInput/baseInput.vue'
import baseButton from '@/components/baseButton/baseButton.vue'
import Modal from '@/components/modalBase/modalBase.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    Modal,
    baseInput,
    baseButton,
  },
  data() {
    return {
      isModalVisible: false,
      category: {
        name: '',
        image: '',
      },
      imagePreview: null,
      submitting: false,
      error: false,
      generalError: this.$t('errors.something_went_wrong'),
    }
  },
  validations() {
    return {
      category: {
        name: {
          required,
        },
      },
    }
  },
  props: {
    categoryID: {
      type: String,
      default: '',
    },
    oldCategory: {
      type: Object,
    },
  },
  computed: {
    anyError() {
      return this.$v.$anyError
    },
    isCategoryUnchanged() {
      return (
        this.oldCategory.name === this.category.name &&
        this.imagePreview === null
      )
    },
  },
  watch: {
    oldCategory: {
      handler(newValue) {
        this.category = { ...newValue }
      },
    },
  },
  methods: {
    openModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
      this.resetForm()
    },
    handleFileSelect(event) {
      const file = event.target.files[0]
      if (file) {
        this.category.image = file
        this.imagePreview = URL.createObjectURL(file)
      } else {
        this.category.image = ''
        this.imagePreview = null
      }
    },
    async editCategory() {
      this.submitting = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      const formData = new FormData()
      formData.append('name', this.category.name)
      formData.append('image', this.category.image)
      try {
        await this.$store.dispatch('editCategory', {
          categoryID: this.categoryID,
          categoryData: formData,
        })
      } catch (err) {
        this.submitting = false
        this.error = true
        this.generalError = this.$t('errors.something_went_wrong')
      }
      await this.$store.dispatch('fetchCategories')
      this.closeModal()
    },
    clearGeneralError() {
      this.generalError = ''
      this.error = false
    },
    resetForm() {
      setTimeout(() => {
        this.category = {
          name: this.oldCategory.name,
          imagePath: this.oldCategory.imagePath,
        }
        this.imagePreview = null
        const input = this.$refs.imageUpload
        if (input) {
          input.value = ''
        }
        this.$v.$reset()
        this.clearGeneralError()
        this.submitting = false
      }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
@import './editCategoryModal';
</style>
