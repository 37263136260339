<template>
  <div class="loader" :class="customClass">
    <div class="loader-spinner" :class="customSpinner"></div>
  </div>
</template>

<script>
export default {
  name: 'loaderElement',
  props: {
    customClass: String,
    customSpinner: String,
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/index.scss';
@import './loader';
</style>
