<template>
  <div class="quizzes layout">
    <div class="quizzes__title main-title">
      <div>
        <h2>{{ $t('createQuiz.title') }} {{ quiz.title }}</h2>
        <p>{{ quiz.description }}</p>
      </div>
    </div>
    <loaderElement v-if="isLoading == true" />
    <div v-else class="quizzes__wrapper">
      <div class="quizzes__content content-layout">
        <div v-for="(question, index) in quiz.questions" :key="question.uuid">
          <questionCard
            :question="question"
            :index="index"
            @select-answer="select($event, question.uuid)"
          ></questionCard>
        </div>
        <div v-if="this.errorMessage !== ''">
          <p class="quizzes__content-error">
            {{ this.errorMessage }}
          </p>
        </div>
        <div class="quizzes__content--flex">
          <baseButton
            buttonClass="button--submit"
            v-if="isStudent"
            @click="postQuizScore(quiz.uuid)"
            >{{ $t('createQuiz.finish_attempt') }} ...</baseButton
          >
        </div>
      </div>
    </div>
    <transition name="modal-fade">
      <baseModal
        v-if="quizResults"
        :modalClass="'modal__content--quiz'"
        :content="showModalContent()"
        :are-you-sure="false"
        :submit-value="hasCertificate()"
        :score="score"
        @close-modal="redirectRoute()"
        @confirm-action="goToCertificate()"
      />
    </transition>
  </div>
</template>

<script>
import questionCard from '../components/questionCardComponents/viewQuestionCard.vue'
import { mapGetters, mapActions } from 'vuex'
import loaderElement from '@/components/loader/loaderElement.vue'
import baseModal from '@/components/baseModal/baseModal.vue'
import baseButton from '@/components/baseButton/baseButton.vue'
export default {
  components: {
    questionCard,
    baseButton,
    baseModal,
    loaderElement,
  },
  data() {
    return {
      selectedAnswers: {},
      quizResults: false,
      isLoading: false,
      clickedCertificate: false,
      showModalContent() {
        if (this.certificateBoolean) {
          return {
            message: this.$t('singleCourse.course_complete_evaluation'),
            submit: this.$t('singleCourse.get_certificate'),
          }
        } else {
          return {
            message: this.$t('singleCourse.course_complete_evaluation'),
          }
        }
      },
      hasCertificate() {
        if (this.certificateBoolean) {
          return true
        } else {
          return false
        }
      },
      errorMessage: '',
    }
  },
  computed: {
    ...mapGetters([
      'getQuizInfoSt',
      'getQuizScoreData',
      'getCertificateBoolean',
    ]),
    quiz() {
      return this.getQuizInfoSt
    },
    score() {
      return this.getQuizScoreData
    },
    certificateBoolean() {
      return this.getCertificateBoolean
    },
  },
  methods: {
    ...mapActions(['fetchCourseQuizzes', 'postQuizScore']),
    select(answerId, questionId) {
      this.selectedAnswers[questionId] = answerId
    },
    goToCertificate() {
      this.clickedCertificate = true
      this.$router.push(`/getCertificate/${this.$route.params.id}`)
    },
    async postQuizScore(quizId) {
      const credentials = { answers: [] }

      for (const question of this.quiz.questions) {
        const selectedAnswer = this.selectedAnswers[question.uuid]
        if (selectedAnswer) {
          credentials.answers.push({
            questionId: question.uuid,
            answerId: selectedAnswer.answerId,
          })
        } else if (
          !selectedAnswer ||
          Object.keys(this.selectedAnswers).length !==
            this.quiz.questions.length
        ) {
          this.errorMessage = this.$t('createQuiz.quiz_error')
          return
        }
      }
      let res = await this.$store.dispatch('postQuizScore', {
        quizId: quizId,
        credentials: credentials,
      })

      if (res) {
        await this.$store.dispatch('fetchQuizScore', quizId)
        this.quizResults = true
      }
    },
    async redirectRoute() {
      this.quizResults = false
      if (!this.clickedCertificate) {
        this.$router.push(`/my-courses/single/${this.$route.params.id}`)
      }
    },
  },
  async created() {
    try {
      this.isLoading = true
      if (this.score) {
        this.$router.push(`/my-courses/single/${this.$route.params.id}`)
      } else {
        await this.fetchCourseQuizzes(this.$route.params.id)
        await this.quiz
      }
      this.isLoading = false
    } catch (err) {
      console.error('Fetch Course Quizzes Error', err)
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/pages/viewQuiz';
</style>
