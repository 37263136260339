<template>
  <div class="filterCard">
    <div class="filterCard__filters">
      <div class="filterCard__filters--select">
        <baseSelect
          :placeholder="$t('tables.select_month')"
          :disablePlaceholder="true"
          :options="months"
          :optionValue="'value'"
          :value="selectedMonth"
          @change="setMonth($event.target.value)"
          customClass="smallSelect small"
          class="create-course__input"
        ></baseSelect>
        <baseSelect
          :placeholder="$t('tables.select_year')"
          :disablePlaceholder="true"
          :options="years"
          :optionValue="'valueYears'"
          :value="selectedYear"
          @change="setYear($event.target.value)"
          customClass="smallSelect small"
          class="create-course__input"
        ></baseSelect>
      </div>
      <baseButton
        type="submit"
        buttonClass="button--filters--apply"
        @click="applyFilters"
        :disabled="areFiltersEmpty || areFiltersSame"
        >{{ $t('tables.apply') }}</baseButton
      >
      <baseButton
        type="submit"
        buttonClass="button--filters--clear"
        @click="clearFilters"
        :disabled="areFiltersEmpty"
      ></baseButton>
    </div>
    <div v-if="!showMonthly && isInstructor" class="filterCard__total">
      <h3>{{ total }} €</h3>
      <span>{{ this.$t('tables.total') }}</span>
    </div>
    <div v-if="showMonthly && isInstructor" class="filterCard__total">
      <h3>{{ total }} €</h3>
      <span>{{ this.$t('time.month') }}</span>
    </div>
  </div>
</template>

<script>
import baseSelect from '@/components/baseSelect/baseSelect.vue'
import baseButton from '@/components/baseButton/baseButton.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    baseButton,
    baseSelect,
  },
  props: {
    fetch: {
      type: Object,
      required: true,
      filterFunction: String,
    },
  },
  data() {
    return {
      selectedMonth: this.$route.query.month || null,
      selectedYear: this.$route.query.year || null,
      lastSelectedMonth: null,
      lastSelectedYear: null,
      months: [
        { name: 'January', value: '1' },
        { name: 'February', value: '2' },
        { name: 'March', value: '3' },
        { name: 'April', value: '4' },
        { name: 'May', value: '5' },
        { name: 'June', value: '6' },
        { name: 'July', value: '7' },
        { name: 'August', value: '8' },
        { name: 'September', value: '9' },
        { name: 'October', value: '10' },
        { name: 'November', value: '11' },
        { name: 'December', value: '12' },
      ],
      monthlyPayments: null,
      showMonthly: false,
    }
  },
  watch: {
    selectedMonth: {
      handler(newValue) {
        this.$router
          .replace({
            query: {
              ...this.$route.query,
              month: newValue,
            },
          })

          .catch(() => {})
      },
    },
    selectedYear: {
      handler(newValue) {
        this.$router
          .replace({
            query: {
              ...this.$route.query,
              year: newValue,
            },
          })

          .catch(() => {})
      },
    },
  },
  computed: {
    ...mapGetters(['getInstructorTotalPayments']),
    years() {
      const currentYear = new Date().getFullYear()
      return Array.from({ length: 10 }, (_, index) => {
        const year = currentYear - index
        return { name: year.toString(), valueYears: year }
      })
    },
    areFiltersEmpty() {
      return (
        this.selectedMonth === null ||
        this.selectedYear === null ||
        (this.selectedMonth === null && this.selectedYear === null)
      )
    },
    areFiltersSame() {
      return (
        this.selectedMonth === this.lastSelectedMonth &&
        this.selectedYear === this.lastSelectedYear
      )
    },
    total() {
      if (this.isInstructor) {
        return this.getInstructorTotalPayments
      } else {
        return 0
      }
    },
  },
  methods: {
    setMonth(month) {
      this.selectedMonth = month
    },
    setYear(year) {
      this.selectedYear = Number(year)
    },
    applyFilters() {
      if (this.selectedMonth != null && this.selectedYear != null) {
        this.fetchItems()
        this.showMonthly = true
        if (this.isInstructor) {
          this.fetchTotalPayments()
        }
        this.lastSelectedMonth = this.selectedMonth
        this.lastSelectedYear = this.selectedYear
      } else this.clearFilters()
    },
    clearFilters() {
      this.selectedMonth = ''
      this.selectedYear = null

      this.$router.replace({ query: '' }).catch(() => {})
      this.$store.dispatch(`${this.fetch.filterFunction}`, {
        year: null,
        month: null,
      })
      this.showMonthly = false
      if (this.isInstructor) {
        this.fetchTotalPayments()
      }
    },
    fetchItems() {
      this.$store.dispatch(`${this.fetch.filterFunction}`, {
        year: this.selectedYear || null,
        month: this.selectedMonth || null,
      })
    },
    async fetchTotalPayments() {
      await this.$store.dispatch(`fetchTotalPayments`, {
        year: this.selectedYear || null,
        month: this.selectedMonth || null,
      })
      if (this.isInstructor) {
        await this.total
      }
    },
  },
  async mounted() {
    if (this.isInstructor) {
      await this.$store.dispatch(`fetchUserInfo`)
      this.fetchTotalPayments()
    }
  },
}
</script>

<style lang="scss" scoped>
@import './_filterCard.scss';
@import '../baseSelect/baseSelect';
.filterCard {
  @include flexBox(row, flex-start, flex-start, toRem(22));
}
.smallSelect {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0 toRem(39) 0 toRem(22);
  width: 150px !important;
}
</style>
