<template>
  <div>
    <Modal v-show="isModalVisible" @close-modal="closeModal">
      <template v-slot:modalContent>
        <div class="modal__title">
          {{ content.modalTitle }}
        </div>
        <form
          class="modal__form"
          @submit.prevent="confirmAction(inputs)"
          @click.stop
        >
          <div class="modal__group">
            <baseInput
              id="firstInput"
              type="text"
              v-model="inputs.firstInput"
              customClass="input--modal"
              @clear-error="clearGeneralError"
            ></baseInput>
          </div>
          <div class="modal__group button">
            <baseButton
              type="submit"
              buttonClass="button--primary button--modal"
              >{{ $t('createQuiz.edit_btn') }}</baseButton
            >
            <p v-if="error && generalError" class="modal__errorMsg">
              {{ generalError }}
            </p>
          </div>
        </form>
      </template>
    </Modal>
  </div>
</template>

<script>
import baseInput from '@/components/baseInput/baseInput.vue'
import baseButton from '@/components/baseButton/baseButton.vue'
import Modal from '@/components/modalBase/modalBase.vue'

export default {
  components: {
    baseInput,
    baseButton,
    Modal,
  },
  props: {
    content: {
      modalTitle: {
        type: String,
      },
      modalLabels: {
        type: Array,
        deafult: ['', ''],
      },
      autofilled: {
        type: Array,
        deafult: ['', ''],
      },
    },
  },
  data() {
    return {
      isModalVisible: false,
      generalError: '',
      error: false,
      inputs: {
        firstInput:
          this.content.autofilled && this.content.autofilled[0]
            ? this.content.autofilled[0]
            : '',
      },
    }
  },
  watch: {
    'content.autofilled': {
      handler(newAutofilledData) {
        if (
          Array.isArray(newAutofilledData) &&
          newAutofilledData.length === 2
        ) {
          this.inputs.firstInput = newAutofilledData[0] || ''
        }
      },
      immediate: true,
    },
  },
  methods: {
    confirmAction() {
      this.$emit('confirm-action', this.inputs)
      this.clearGeneralError()
      this.closeModal()
    },
    clearGeneralError() {
      this.generalError = ''
      this.error = false
    },
    openModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
      this.$emit('close-modal')
    },
  },
}
</script>

<style lang="scss" scoped>
@import './editQuizModal.scss';
</style>
