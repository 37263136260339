<template>
  <div v-show="isModalVisible" @click="closeModalOnOutsideClick">
    <transition name="modal-fade">
      <div id="myModal" class="modal">
        <div class="modal-content" ref="modalContent">
          <div class="modal-content__title">
            <p>XKLearnAI</p>
            <span class="modal-content__close" id="close" @click="closeModal"
              >&times;</span
            >
          </div>
          <div class="modal-content__section">
            <div class="modal-content-user">
              <div class="modal-content-user--comment">
                <p v-for="chapter in chapters" :key="chapter.id">
                  {{ chapter.name }}
                </p>
              </div>
              <img
                v-if="userInfo.imageFile"
                :src="`https://api.lms.k-clients.com/public/images/${userInfo?.imageFile}`"
                alt="user img"
                class="modal-content-user__img"
              />
              <img
                v-else
                src="../../assets/pictures/avatar.png"
                alt="user img"
                class="modal-content-user__img"
              />
            </div>
            <div class="modal-content-user modal-content-bot">
              <img
                src="../../assets/pictures/bot.jpg"
                alt="user img"
                class="modal-content-bot__img"
              />
              <div
                class="modal-content-user--comment modal-content-bot--comment"
                v-if="chaptersAIInfo.info"
              >
                <pre v-html="makeUrlsClickable(chaptersAIInfo.info)"></pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      isModalVisible: false,
    }
  },
  props: { chapterId: String },
  computed: {
    ...mapGetters(['getUserInfo']),
    ...mapGetters(['getChapters']),
    ...mapGetters(['getChaptersAIInfo']),
    userInfo() {
      return this.getUserInfo
    },
    chapters() {
      return this.getChapters.filter(
        (chapter) => chapter.uuid == this.chapterId
      )
    },
    chaptersAIInfo() {
      return this.$store.getters.getChaptersAIInfo
    },
  },
  methods: {
    openModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
    },
    closeModalOnOutsideClick(event) {
      const modalContent = this.$refs.modalContent
      if (modalContent && !modalContent.contains(event.target)) {
        this.closeModal()
      }
    },
    makeUrlsClickable(text) {
      text = text.replace(/^(\n)*/, '')
      const urlRegex = /https:\/\/[^\s)]+/g
      const formattedText = text
        .replace(/\*+/g, '')
        .replace(urlRegex, (url) => {
          return `<a href="${url}" target="_blank">${url}</a>`
        })

      return formattedText
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/index.scss';
@import './openAIModal';
</style>
