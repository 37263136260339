<template>
  <div class="page-loader">
    <loader />
  </div>
</template>

<script>
import loader from '@/components/loader/loaderElement.vue'

export default {
  components: {
    loader,
  },
  async created() {
    await this.$nextTick()
    const token = this.$route.params.token
    const courseId = this.$route.params.courseId
    if (token) {
      localStorage.setItem('user', token)
      localStorage.setItem('role', this.$route.params.role)
      if (courseId && this.$route.params.role == 4) {
        this.$router.push({
          path: `/my-courses/single/${courseId}`,
        })
      } else {
        this.$router.push({ name: 'dashboard' })
      }
    } else {
      this.$router.push({ name: 'login' })
    }
  },
}
</script>

<style scoped lang="scss">
.page-loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
