<template>
  <div id="myModal" class="modal" @click="closeModal">
    <div id="modal__dropdown">
      <div class="modal__close">
        <span id="close" @click="closeModal">&times;</span>
      </div>
      <div
        class="modal__content"
        :class="modalClass"
        @submit.prevent="confirmAction"
      >
        <div class="modal__group">
          <div class="modal__title" v-if="areYouSure">
            <h3>
              {{ $t('showModalContent.are_you_sure') }}
              {{ content.message }}?
            </h3>
            <h3 class="modal__title--user">
              {{ userFirstname }} {{ userLastname }}
            </h3>
          </div>
          <div class="modal__title" v-if="!areYouSure">
            <h3>{{ content.message }}</h3>
            <h3 class="modal__title--user" v-if="showScore">
              {{ score }} {{ $t('showModalContent.out_of') }} 100
            </h3>
          </div>
        </div>
        <div class="modal__group__wrapper">
          <div class="modal__group--buttons">
            <baseButton
              type="submit"
              buttonClass="button--primary button--delete"
              @click="confirmAction"
              v-if="submitValue"
              :disabled="isSubmitted"
            >
              {{ content.submit }}</baseButton
            >
            <baseButton
              id="closed"
              type="submit"
              buttonClass="button--cancel"
              @click="closeModal"
              v-if="areYouSure"
            >
              {{ $t('showModalContent.cancel') }}</baseButton
            >
          </div>
          <div>
            <p v-if="error" class="modal__errorMsg">
              {{ generalError }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import baseButton from '@/components/baseButton/baseButton.vue'
export default {
  components: {
    baseButton,
  },
  props: {
    userLastname: {
      type: String,
    },
    userFirstname: {
      type: String,
    },
    content: {
      type: Object,
      default: () => ({
        message: this.$t('showModalContent.delete_this_user'),
        submit: this.$t('showModalContent.delete'),
      }),
    },
    areYouSure: {
      type: Boolean,
      default: true,
    },
    showScore: {
      type: Boolean,
      default: true,
    },
    submitValue: {
      type: Boolean,
      default: true,
    },
    score: {
      type: Number,
      default: 0,
    },
    modalClass: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isSubmitted: false,
      generalError: '',
      error: false,
      userID: null,
      userFirstName: null,
      userLastName: null,
    }
  },
  methods: {
    confirmAction() {
      this.isSubmitted = true
      this.$emit('confirm-action')
      this.clearGeneralError()
      this.$emit('close-modal')
      setTimeout(() => {
        this.isSubmitted = false
      }, 1000)
    },
    clearGeneralError() {
      this.generalError = ''
      this.error = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import './baseModal';
</style>
