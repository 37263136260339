<template>
  <div class="questionCard">
    <div class="questionCard__question">
      <div class="questionCard__title">
        <h3>
          {{ $t('createQuiz.question') }}
          {{ index + 1 }}:
        </h3>
      </div>
      <h3 class="questionCard__description">
        {{ question.question }}
      </h3>
    </div>
    <div class="questionCard__answers">
      <p class="questionCard__text">{{ $t('createQuiz.select_one') }}:</p>
      <form action="#" class="modal__radio">
        <p v-for="(answer, index) in question.answers" :key="answer.uuid">
          <baseInput
            id="'answer' + (index + 1)"
            type="radio"
            customClass="input--radio"
            name="answer"
            :checked="isAnswerSelected(answer.uuid)"
            @click="selectAnswer(answer.uuid)"
          ></baseInput>
          <label :for="'answer' + (index + 1)">
            <b>{{ getOptionLetter(index) }}.</b> {{ answer.answer }}</label
          >
        </p>
      </form>
      <div
        v-if="isStudent && selectedAnswer !== null"
        class="questionCard__answers--clear"
      >
        <p @click="clearMyChoice">{{ $t('createQuiz.clear_choice') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import baseInput from '../baseInput/baseInput.vue'
export default {
  components: {
    baseInput,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    index: Number,
  },
  data() {
    return {
      selectedAnswer: null,
    }
  },
  methods: {
    getOptionLetter(index) {
      return String.fromCharCode(97 + index)
    },
    selectAnswer(answerId) {
      this.selectedAnswer = answerId
      this.$emit('select-answer', { answerId: this.selectedAnswer })
    },
    clearMyChoice() {
      this.selectedAnswer = null
      this.$emit('select-answer', { answerId: null })
    },
    isAnswerSelected(answerId) {
      return this.selectedAnswer === answerId
    },
  },
}
</script>

<style lang="scss" scoped>
@import './questionCard';
</style>
