<template>
  <div class="update-course layout" v-if="course">
    <div class="update-course__title main-title">
      <h2>{{ $t('createCourse.updateCourse_title') }}</h2>
    </div>
    <form class="update-course__form" @submit.prevent="updateCourse">
      <div class="update-course__group">
        <baseInput
          id="name"
          :label="$t('createCourse.course_title')"
          type="text"
          :placeholder="$t('createCourse.course_title')"
          v-model="updatedCourse.name"
          customClass="input--row"
          class="update-course__input"
          :applyErrorClass="$v.updatedCourse.name.$error"
          @blur="$v.updatedCourse.name.$touch()"
        ></baseInput>
        <template v-if="$v.updatedCourse.name.$error">
          <p class="error-msg" v-if="!$v.updatedCourse.name.required">
            {{ $t('createCourse.course_title_error') }}
          </p>
        </template>
      </div>
      <div class="update-course__group">
        <baseTextarea
          id="description"
          :label="$t('createCourse.description')"
          :placeholder="$t('createCourse.description')"
          v-model="updatedCourse.description"
          class="update-course__input"
          :applyErrorClass="$v.updatedCourse.description.$error"
          @blur="$v.updatedCourse.description.$touch()"
        ></baseTextarea>
        <template v-if="$v.updatedCourse.description.$error">
          <p class="error-msg" v-if="!$v.updatedCourse.description.required">
            {{ $t('createCourse.description_error') }}
          </p>
        </template>
      </div>
      <div class="update-course__group2 update-course__select">
        <label class="update-course__label">{{
          $t('createCourse.level')
        }}</label>
        <custom-select
          class="update-course__input"
          :customClass="'full-dropdown'"
          :placeholder="{ name: $t('createCourse.level'), uuid: '' }"
          :disable-placeholder="true"
          :options="levels"
          :optionValue="'name'"
          :value="updatedCourse.level"
          @input="setLevel($event)"
          :applyErrorClass="$v.updatedCourse.level.$error"
          @blur="$v.updatedCourse.level.$touch()"
        ></custom-select>
      </div>
      <div class="update-course__group2 update-course__select2">
        <label class="update-course__label">{{
          $t('createCourse.language')
        }}</label>
        <custom-select
          class="update-course__input"
          :customClass="'full-dropdown'"
          :placeholder="{ name: $t('createCourse.language'), uuid: '' }"
          :disable-placeholder="true"
          :options="languages"
          :optionValue="'name'"
          :value="updatedCourse.language"
          :search-filter="true"
          @input="setLanguage($event)"
          :applyErrorClass="$v.updatedCourse.language.$error"
          @blur="$v.updatedCourse.language.$touch()"
        ></custom-select>
        <template v-if="$v.updatedCourse.language.$error">
          <p class="error-msg" v-if="!$v.updatedCourse.language.required">
            {{ $t('createCourse.language_error') }}
          </p>
        </template>
      </div>
      <div class="update-course__group2">
        <label class="update-course__label">{{
          $t('createCourse.categories')
        }}</label>
        <custom-select
          class="update-course__input"
          :customClass="'full-dropdown'"
          :placeholder="{ name: $t('all_categories'), uuid: '' }"
          :disable-placeholder="true"
          :options="categories"
          :value="category"
          @input="setCategory($event)"
        ></custom-select>
      </div>
      <div class="update-course__group2">
        <label class="update-course__label">{{
          $t('createCourse.course_thumbnail')
        }}</label>
        <div class="update-course__image">
          <div class="update-course__image--wrap">
            <img
              v-if="updatedCourse.imagePath && showExistingImage"
              :src="`https://api.lms.k-clients.com/public/images/${this.updatedCourse.imagePath}`"
            />
            <img v-if="selectedImage" :src="selectedImage" />
          </div>

          <input
            type="file"
            accept="image/*"
            ref="fileInput"
            @change="handleFileSelect($event)"
            class="update-course__file"
          />
        </div>
      </div>
      <div class="update-course__group2">
        <label class="update-course__label">{{
          $t('createCourse.course_learnings')
        }}</label>
        <div class="update-course__item">
          <div class="update-course__item-name">
            <span>{{ $t('createCourse.course_learnings') }}</span>
            <div>
              <img
                src="@/assets/icons/arrow-down.svg"
                class="update-course__icon"
              />
            </div>
          </div>
          <ul class="dropdown-list">
            <li
              v-for="(learning, index) in updatedCourse.courseLearning"
              :key="index"
              class="learning"
            >
              <div
                class="learning--show"
                v-if="!showEditLearning[learning.uuid]"
              >
                <p>{{ learning.description }}</p>
                <span @click="editLearning(learning)">{{
                  $t('createCourse.edit')
                }}</span>
              </div>
              <div
                class="learning--update"
                v-if="showEditLearning[learning.uuid]"
              >
                <baseInput
                  type="text"
                  :placeholder="$t('createCourse.learning_description')"
                  v-model="updatedCourse.courseLearning[index].description"
                  customClass="input--row"
                  class="update-course__input"
                  @enter-pressed="saveLearning(learning)"
                ></baseInput>
                <span @click="saveLearning(learning)">{{
                  $t('createCourse.save')
                }}</span>
              </div>
            </li>
            <li v-if="!updatedCourse.courseLearning.length" class="learning">
              {{ $t('createCourse.course_learnings_empty') }}
            </li>
          </ul>
        </div>
      </div>
      <template v-if="$v.updatedCourse.courseLearning.$error">
        <p
          class="error-msg error-msg2"
          v-if="!$v.updatedCourse.courseLearning.required"
        >
          {{ $t('createCourse.course_learnings_error') }}
        </p>
      </template>
      <div class="update-course__group2">
        <label class="update-course__label">{{
          $t('createCourse.course_requirements')
        }}</label>
        <div class="update-course__item">
          <div class="update-course__item-name">
            <span>{{ $t('createCourse.course_requirements') }}</span>
            <div>
              <img
                src="@/assets/icons/arrow-down.svg"
                class="update-course__icon"
              />
            </div>
          </div>
          <ul class="dropdown-list">
            <li
              v-for="(requirement, index) in updatedCourse.courseRequirements"
              :key="index"
              class="learning"
            >
              <div
                class="learning--show"
                v-if="!showEditRequirement[requirement.uuid]"
              >
                <p>{{ requirement.field }}</p>
                <span @click="editRequirement(requirement)">{{
                  $t('createCourse.edit')
                }}</span>
              </div>
              <div
                class="learning--update"
                v-if="showEditRequirement[requirement.uuid]"
              >
                <baseInput
                  type="text"
                  placeholder="Requirement Description"
                  v-model="updatedCourse.courseRequirements[index].field"
                  customClass="input--row"
                  class="update-course__input"
                  @enter-pressed="saveRequirement(requirement)"
                ></baseInput>
                <span @click="saveRequirement(requirement)">{{
                  $t('createCourse.save')
                }}</span>
              </div>
            </li>
            <li
              v-if="!updatedCourse.courseRequirements.length"
              class="learning"
            >
              {{ $t('createCourse.course_learnings_empty') }}
            </li>
          </ul>
        </div>
      </div>
      <template v-if="$v.updatedCourse.courseRequirements.$error">
        <p
          class="error-msg error-msg2"
          v-if="!$v.updatedCourse.courseRequirements.required"
        >
          {{ $t('createCourse.course_requirements_error') }}
        </p>
      </template>
      <!-- <div class="modal__group">
        <baseInput
          id="deadline"
          :label="$t('createCourse.deadline')"
          type="date"
          v-model="updatedCourse.deadline"
          customClass="input--row"
          class="update-course__input"
        ></baseInput>
      </div> -->
      <div class="update-course__group2">
        <label class="update-course__label">{{
          $t('createCourse.certificate')
        }}</label>
        <toggle-button
          @change="toggleCertificate"
          :value="updatedCourse.certificate"
          :sync="true"
          color="#196ecd"
          class="update-course__file"
        />
      </div>
      <div class="modal__group">
        <baseInput
          id="price"
          :label="$t('createCourse.price')"
          type="number"
          min="0"
          placeholder="0"
          v-model="updatedCourse.coursePrice.price"
          customClass="input--row"
          class="update-course__input"
        ></baseInput>
      </div>
      <div class="update-course__group">
        <baseButton
          type="submit"
          buttonClass="button--submit update-course__button"
          :disabled="isCourseUnchanged || isDisabled"
          >{{ $t('createCourse.submit') }}</baseButton
        >
      </div>
    </form>
  </div>
</template>

<script>
import baseInput from '@/components/baseInput/baseInput.vue'
import customSelect from '@/components/baseSelect/customSelect.vue'
import baseTextarea from '@/components/baseTextarea/baseTextarea.vue'
import baseButton from '@/components/baseButton/baseButton.vue'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  components: {
    baseInput,
    baseTextarea,
    baseButton,
    customSelect,
  },
  data() {
    return {
      isDisabled: false,
      updatedCourse: {
        name: '',
        description: '',
        level: '',
        language: '',
        image: '',
        deadline: '',
        certificate: false,
        coursePrice: { price: 0 },
        courseLearning: [],
        courseRequirements: [],
        categoryId: '',
      },
      category: '',
      inputValue: '',
      requirementValue: '',
      showEditRequirement: [],
      updatedRequirement: '',
      showEditLearning: [],
      updatedLearning: '',
      levels: [
        { name: 'Beginner' },
        { name: 'Intermediate' },
        { name: 'Advanced' },
        { name: 'Expert' },
        { name: 'Other' },
      ],
      languages: [
        { name: 'Afrikaans' },
        { name: 'Albanian' },
        { name: 'Amharic' },
        { name: 'Arabic' },
        { name: 'Armenian' },
        { name: 'Azerbaijani' },
        { name: 'Basque' },
        { name: 'Belarusian' },
        { name: 'Bengali' },
        { name: 'Bosnian' },
        { name: 'Bulgarian' },
        { name: 'Catalan' },
        { name: 'Cebuano' },
        { name: 'Chinese (Simplified)' },
        { name: 'Chinese (Traditional)' },
        { name: 'Corsican' },
        { name: 'Croatian' },
        { name: 'Czech' },
        { name: 'Danish' },
        { name: 'Dutch' },
        { name: 'English' },
        { name: 'Esperanto' },
        { name: 'Estonian' },
        { name: 'Filipino' },
        { name: 'Finnish' },
        { name: 'French' },
        { name: 'Frisian' },
        { name: 'Galician' },
        { name: 'Georgian' },
        { name: 'German' },
        { name: 'Greek' },
        { name: 'Gujarati' },
        { name: 'Haitian Creole' },
        { name: 'Hausa' },
        { name: 'Hawaiian' },
        { name: 'Hebrew' },
        { name: 'Hindi' },
        { name: 'Hmong' },
        { name: 'Hungarian' },
        { name: 'Icelandic' },
        { name: 'Igbo' },
        { name: 'Indonesian' },
        { name: 'Irish' },
        { name: 'Italian' },
        { name: 'Japanese' },
        { name: 'Javanese' },
        { name: 'Kannada' },
        { name: 'Kazakh' },
        { name: 'Khmer' },
        { name: 'Kinyarwanda' },
        { name: 'Korean' },
        { name: 'Kurdish' },
        { name: 'Kyrgyz' },
        { name: 'Lao' },
        { name: 'Latin' },
        { name: 'Latvian' },
        { name: 'Lithuanian' },
        { name: 'Luxembourgish' },
        { name: 'Macedonian' },
        { name: 'Malagasy' },
        { name: 'Malay' },
        { name: 'Malayalam' },
        { name: 'Maltese' },
        { name: 'Maori' },
        { name: 'Marathi' },
        { name: 'Mongolian' },
        { name: 'Myanmar (Burmese)' },
        { name: 'Nepali' },
        { name: 'Norwegian' },
        { name: 'Nyanja (Chichewa)' },
        { name: 'Pashto' },
        { name: 'Persian' },
        { name: 'Polish' },
        { name: 'Portuguese' },
        { name: 'Punjabi' },
        { name: 'Romanian' },
        { name: 'Russian' },
        { name: 'Samoan' },
        { name: 'Scots Gaelic' },
        { name: 'Serbian' },
        { name: 'Sesotho' },
        { name: 'Shona' },
        { name: 'Sindhi' },
        { name: 'Sinhala (Sinhalese)' },
        { name: 'Slovak' },
        { name: 'Slovenian' },
        { name: 'Somali' },
        { name: 'Spanish' },
        { name: 'Sundanese' },
        { name: 'Swahili' },
        { name: 'Swedish' },
        { name: 'Tagalog (Filipino)' },
        { name: 'Tajik' },
        { name: 'Tamil' },
        { name: 'Telugu' },
        { name: 'Thai' },
        { name: 'Turkish' },
        { name: 'Ukrainian' },
        { name: 'Urdu' },
        { name: 'Uzbek' },
        { name: 'Vietnamese' },
        { name: 'Welsh' },
        { name: 'Xhosa' },
        { name: 'Yiddish' },
        { name: 'Yoruba' },
        { name: 'Zulu' },
      ],
      successMsg: false,
      selectedImage: '',
      showExistingImage: true,
    }
  },
  validations() {
    return {
      updatedCourse: {
        name: {
          required,
        },
        description: {
          required,
        },
        level: {
          required,
        },
        language: {
          required,
        },
        image: {
          required,
        },
        courseLearning: {
          required,
        },
        courseRequirements: {
          required,
        },
      },
      category: {
        required,
      },
    }
  },
  computed: {
    ...mapGetters(['getAllCategories', 'getCourse']),
    categories() {
      return this.getAllCategories
    },
    course() {
      return this.getCourse
    },
    isCourseUnchanged() {
      const nameUnchanged =
        this.course.name === this.updatedCourse.name ||
        this.updatedCourse.name === ''
      const descUnchanged =
        this.course.description == this.updatedCourse.description
      const levelUnchanged = this.course.level === this.updatedCourse.level
      const languageUnchanged =
        this.course.language === this.updatedCourse.language ||
        this.updatedCourse.language === ''
      const categoryUnchanged = this.course.category.uuid === this.category
      const imageUnchanged = this.showExistingImage
      let learningUnchanged = true
      for (let i = 0; i <= this.updatedCourse.courseLearning.length; i++) {
        if (
          this.updatedCourse?.courseLearning[i]?.description !==
            this.course?.courseLearning[i]?.description &&
          this.updatedCourse?.courseLearning[i]?.description !== ''
        ) {
          learningUnchanged = false
        }
      }
      let requirementUnchanged = true
      for (let i = 0; i <= this.updatedCourse.courseRequirements.length; i++) {
        if (
          this.updatedCourse?.courseRequirements[i]?.field !==
            this.course?.courseRequirements[i]?.field &&
          this.updatedCourse?.courseRequirements[i]?.field !== ''
        ) {
          requirementUnchanged = false
        }
      }
      const certificateUnchanged =
        this.course.certificate === this.updatedCourse.certificate
      const priceUnchanged =
        this.course.coursePrice.price == this.updatedCourse.coursePrice.price ||
        (this.updatedCourse.coursePrice.price == 0 &&
          this.course.coursePrice.price != this.updatedCourse.coursePrice.price)
      const allUnchanged =
        nameUnchanged &&
        descUnchanged &&
        levelUnchanged &&
        languageUnchanged &&
        categoryUnchanged &&
        imageUnchanged &&
        learningUnchanged &&
        requirementUnchanged &&
        certificateUnchanged &&
        priceUnchanged
      return allUnchanged
    },
  },
  methods: {
    toggleCertificate() {
      this.updatedCourse.certificate = !this.updatedCourse.certificate
    },
    handleFileSelect(event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.onload = (e) => {
        const imageUrl = e.target.result
        this.selectedImage = imageUrl
        this.showExistingImage = false
      }
      reader.readAsDataURL(file)
      this.updatedCourse.image = file
    },
    setCategory(category) {
      this.category = category
      this.updatedCourse.categoryId = category
    },
    setLevel(level) {
      this.updatedCourse.level = level
    },
    setLanguage(language) {
      this.updatedCourse.language = language
    },
    async updateCourse() {
      const formData = new FormData()
      for (const property in this.updatedCourse) {
        if (
          this.updatedCourse[property] !== this.course[property] &&
          property !== 'instructor' &&
          property !== 'category'
        ) {
          if (property === 'courseLearning') {
            this.updatedCourse.courseLearning.forEach((learning, index) => {
              formData.append(`courseLearning[${index}][uuid]`, learning.uuid)
              formData.append(
                `courseLearning[${index}][description]`,
                learning.description
              )
            })
          } else if (property === 'courseRequirements') {
            this.updatedCourse.courseRequirements.forEach(
              (requirement, index) => {
                formData.append(
                  `courseRequirements[${index}][uuid]`,
                  requirement.uuid
                )
                formData.append(
                  `courseRequirements[${index}][field]`,
                  requirement.field
                )
              }
            )
          } else if (property == 'coursePrice') {
            formData.append('price', this.updatedCourse.coursePrice.price)
          } else {
            formData.append(property, this.updatedCourse[property])
          }
        }
      }
      try {
        this.isDisabled = true
        await this.$store.dispatch('updateCourse', {
          courseID: this.$route.params.id,
          courseData: formData,
        })
        this.successMsg = true
        this.$router.push(`/course/${this.$route.params.id}`)
      } catch (err) {
        console.error(err)
        this.isDisabled = false
      }
    },
    addLearning() {
      this.updatedCourse.courseLearning.push({
        description: this.inputValue,
      })
      this.inputValue = ''
    },
    deleteLearning(learning) {
      const index = this.updatedCourse.courseLearning.indexOf(learning)
      if (index > -1) {
        this.updatedCourse.courseLearning.splice(index, 1)
      }
    },
    addRequirement() {
      this.updatedCourse.courseRequirements.push({
        field: this.requirementValue,
      })
      this.requirementValue = ''
    },
    editRequirement(requirement) {
      this.$set(this.showEditRequirement, requirement.uuid, true)
    },
    saveRequirement(requirement) {
      this.$set(this.showEditRequirement, requirement.uuid, false)
    },
    editLearning(learning) {
      this.$set(this.showEditLearning, learning.uuid, true)
    },
    saveLearning(learning) {
      this.$set(this.showEditLearning, learning.uuid, false)
    },
    deleteRequirement(requirement) {
      const index = this.updatedCourse.courseRequirements.indexOf(requirement)
      if (index > -1) {
        this.updatedCourse.courseRequirements.splice(index, 1)
      }
    },
  },
  async created() {
    await this.$store.dispatch('fetchAllCategories')
    await this.$store.dispatch('fetchCourse', this.$route.params.id)
    if (this.course) {
      this.updatedCourse = JSON.parse(JSON.stringify(this.course))
      this.updatedCourse.certificate = this.course.certificate
      this.category = this.course.category.uuid
    }
    /* if (this.updatedCourse.deadline) {
      const date = new Date(this.updatedCourse.deadline)
      const year = date.getFullYear()
      const month = `${date.getMonth() + 1}`.padStart(2, '0')
      const day = `${date.getDate()}`.padStart(2, '0')
      const formatted = `${year}-${month}-${day}`
      this.updatedCourse.deadline = formatted
    } */
  },
  beforeMount() {
    if (this.course) {
      this.updatedCourse = JSON.parse(JSON.stringify(this.course))
      this.updatedCourse.certificate = this.course.certificate
      this.category = this.course.category.uuid
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/pages/updateCourse.scss';
</style>
