import api from '@/api/api'
import { successMessage, errorMessage } from '@/composables/useMessages'
import i18n from '@/main'
const events = {
  state: {
    events: [],
    eventsCurrentPage: 1,
    eventsLastPage: 1,
    totalCount: 0,
    event: null,
  },

  getters: {
    getEvents: (state) => state.events,
    getEvent: (state) => state.event,
    getEventsCurrentPage: (state) => state.eventsCurrentPage,
    getEventsLastPage: (state) => state.eventsLastPage,
    getTotalCount: (state) => state.totalCount,
    hasMorePages: (state) => state.events.length < state.totalCount,
  },

  mutations: {
    SET_EVENTS(state, { events, totalCount }) {
      state.events = events
      state.totalCount = totalCount
    },
    SET_EVENTS_CURRENT_PAGE(state, page) {
      state.eventsCurrentPage = page
    },
    SET_EVENTS_LAST_PAGE(state, page) {
      state.eventsLastPage = page
    },
    SET_EVENT(state, event) {
      state.event = event
    },
  },

  actions: {
    async fetchEvents({ commit, state }, { numberOfEvents, page = 1 }) {
      try {
        const res = await api({ requiresAuth: true }).get(
          `event?limit=${numberOfEvents}&page=${page}`
        )
        const newQuestions = res.data.data
        const filteredNewQuestions = newQuestions.filter((newQuestion) => {
          return !state.events.some(
            (existingQuestion) => existingQuestion.uuid === newQuestion.uuid
          )
        })
        commit('SET_EVENTS', {
          events:
            page === 1
              ? res.data.data
              : [...state.events, ...filteredNewQuestions],
          totalCount: res.data.totalCount,
        })
        commit('SET_EVENTS_CURRENT_PAGE', page)
        commit('SET_EVENTS_LAST_PAGE', res.data.lastPage)
        return res.data
      } catch (error) {
        console.error('GETTING EVENTS ERROR:', error.response.data.message)
        throw error
      }
    },
    async fetchEvent({ commit }, eventID) {
      try {
        const res = await api({ requiresAuth: true }).get(`event/${eventID}`)
        commit('SET_EVENT', res.data)
        return res.data
      } catch (error) {
        console.error('GETTING EVENT ERROR:', error.response.data.message)
        throw error
      }
    },
    async addEvent({ commit, state }, eventData) {
      try {
        const res = await api({
          requiresAuth: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).post('/event', eventData)
        commit('SET_EVENTS', {
          events: [res.data, ...state.events],
          totalCount: state.totalCount + 1,
        })
        successMessage.call(this, i18n.t('store.add_event_success'))
        return res.data
      } catch (error) {
        if (
          error.response.data.message ===
          'A category with this name already exists!'
        ) {
          errorMessage.call(this, i18n.t('store.add_event_name_error'))
          console.error('ADDING EVENT ERROR:', error.response.data.message)
        } else {
          errorMessage.call(this, i18n.t('store.add_event_failed'))
          console.error('ADDING EVENT ERROR:', error.response.data.message)
        }
        throw error
      }
    },
    async editEvent({ commit, state }, { eventID, eventData }) {
      try {
        const res = await api({ requiresAuth: true }).patch(
          `/event/${eventID}`,
          eventData
        )
        const index = state.events.findIndex((event) => event.uuid === eventID)
        let updatedEvents = []
        if (index !== -1) {
          const updatedEvent = { ...state.events[index], ...res.data }
          updatedEvents = [...state.events]
          updatedEvents[index] = updatedEvent
        }
        commit('SET_EVENTS', {
          events: updatedEvents,
          totalCount: state.totalCount,
        })
        successMessage.call(this, i18n.t('store.update_event_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.update_event_failed'))
        console.error('UPDATING EVENT ERROR:', error.response.data.message)
        throw error
      }
    },

    async deleteEvent({ dispatch, state }, eventID) {
      try {
        await api({ requiresAuth: true }).delete(`/event/${eventID}`)
        successMessage.call(this, i18n.t('store.delete_event_success'))
        const newTotalCount = state.totalCount - 1
        const newLastPage = Math.ceil(newTotalCount / 4)
        const currentPage = Math.min(state.eventsCurrentPage, newLastPage)
        state.events = state.events.filter((event) => event.uuid !== eventID)
        dispatch('fetchEvents', {
          numberOfEvents: 4,
          page: currentPage,
        })
      } catch (error) {
        errorMessage.call(this, i18n.t('store.delete_event_failed'))
        console.error('DELETING EVENT ERROR:', error.response.data.message)
        throw error
      }
    },
  },
}

export default events
