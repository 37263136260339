<template>
  <div class="container">
    <loaderElement
      v-if="isLoading && !isStudent"
      customClass="dashboard__loader"
    />
    <div class="dashboardContent" v-else>
      <div class="dashboardContent__stats" v-if="isAdmin || isSuperAdmin">
        <statsCard
          :cardTitle="$t('dashboard.courses')"
          :count="allCourses"
          countColor="#FFC78B"
        ></statsCard>
        <statsCard
          :cardTitle="$t('dashboard.admins')"
          :count="allAdmins"
          countColor="#7FECCB"
        ></statsCard>
        <statsCard
          :cardTitle="$t('dashboard.instructors')"
          :count="allInstructors"
          countColor="#B8B2FD"
        ></statsCard>
        <statsCard
          :cardTitle="$t('dashboard.students')"
          :count="allStudents"
          countColor="#196ECD"
        ></statsCard>
      </div>
      <div
        class="dashboardContent__stats dashboardContent__stats--instructor"
        v-if="isInstructor"
      >
        <statsCard
          :cardTitle="$t('dashboard.courses')"
          :count="totalCourses"
          countColor="#B8B2FD"
          customChildClass="card--instructor"
        ></statsCard>
        <statsCard
          :cardTitle="$t('dashboard.students')"
          :count="studentCount"
          countColor="#FFC78B"
          customChildClass="card--instructor"
        ></statsCard>
        <statsCard
          :cardTitle="$t('dashboard.reviews')"
          :count="reviewCount"
          countColor="#7FECCB"
          customChildClass="card--instructor"
        ></statsCard>
      </div>
      <div class="dashboardContent__cards" v-if="!isStudent">
        <div class="dashboardContent__profile">
          <profileCard
            :userInfo="userInfo"
            :instrSocials="instructorSocials"
          ></profileCard>
        </div>
        <div class="dashboardContent__section" v-if="isInstructor">
          <h4>{{ $t('dashboard.total_views') }}</h4>
          <p>{{ totalViews.totalUniqueViews }}</p>
        </div>
        <div class="dashboardContent_profile" v-if="isAdmin || isSuperAdmin">
          <topInstructor :userInfo="topInstructorInfo"></topInstructor>
        </div>
      </div>
      <studentDashboard
        v-if="isStudent"
        :userInfo="userInfo"
        :courses="enrolledCourses"
      ></studentDashboard>
    </div>
  </div>
</template>

<script>
import statsCard from '../components/statsCard/statsCard.vue'
import profileCard from '../components/profileCard/profileCard.vue'
import topInstructor from '@/components/topInstructorCard/topInstructorCard.vue'
import studentDashboard from '../components/studentDashboard/studentDashboard.vue'
import loaderElement from '../components/loader/loaderElement.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    statsCard,
    profileCard,
    topInstructor,
    studentDashboard,
    loaderElement,
  },
  data() {
    return {
      isLoading: false,
      instructorID: '',
    }
  },
  computed: {
    ...mapGetters([
      'getUserInfo',
      'getAllCompletedCourses',
      'getAllIncompletedCourses',
      'getAllAdmins',
      'getAllInstructors',
      'getAllStudents',
      'getAllCourses',
      'getTopInstructorInfo',
      'getEnrolledCourses',
      'getInstructorCoursesCount',
      'getReviewCount',
      'getStudentCount',
      'getTotalViews',
      'getInstrLinks',
    ]),
    userInfo() {
      return this.getUserInfo
    },
    allCompletedCourses() {
      return this.getAllCompletedCourses
    },
    allIncompletedCourses() {
      return this.getAllIncompletedCourses
    },
    allAdmins() {
      return this.getAllAdmins
    },
    allInstructors() {
      return this.getAllInstructors
    },
    allStudents() {
      return this.getAllStudents
    },
    allCourses() {
      return this.getAllCourses
    },
    topInstructorInfo() {
      return this.getTopInstructorInfo
    },
    enrolledCourses() {
      return this.getEnrolledCourses
    },
    totalCourses() {
      return this.getInstructorCoursesCount
    },
    reviewCount() {
      return this.getReviewCount
    },
    studentCount() {
      return this.getStudentCount
    },
    totalViews() {
      return this.getTotalViews
    },
    instructorSocials() {
      if (this.isInstructor) {
        return this.getInstrLinks
      } else {
        return []
      }
    },
  },
  async created() {
    try {
      this.isLoading = true
      await this.$store.dispatch('fetchUserInfo')
      if (this.isAdmin || this.isSuperAdmin) {
        await this.$store.dispatch('fetchCourses', {
          searchText: '',
          page: 1,
          limit: '',
        })
        await this.$store.dispatch('countUsers')
        await this.$store.dispatch('fetchTopInstructor')
      } else if (this.isStudent) {
        await this.$store.dispatch('fetchEnrolledCourses', {
          searchParams: '',
          page: 1,
          limit: 2,
        })
      } else if (this.isInstructor) {
        await this.$store.dispatch('fetchInstructorCourses', {
          id: this.userInfo.uuid,
          categoryID: '',
          searchText: '',
          page: 1,
          limit: 6,
        })
        await this.$store.dispatch(
          'fetchInstructorReviewInfo',
          this.userInfo.uuid
        )
        await this.$store.dispatch('fetchInstructorLinks', this.userInfo.uuid)
        await this.$store.dispatch('fetchTotalViews')
      }
      this.isLoading = false
    } catch (error) {
      console.error(error)
    }
  },
}
</script>

<style scoped lang="scss">
@import '../assets/scss/pages/dashboardView.scss';
</style>
