<template>
  <div class="pagination">
    <button @click="previousPage" :disabled="currentPage === 1">
      <font-awesome-icon
        :icon="['fass', 'arrow-left']"
        style="color: #949da6"
      />
    </button>
    <div v-for="pageNumber in displayedPageNumbers" :key="pageNumber">
      <button
        @click="gotoPage(pageNumber)"
        :class="{ active: pageNumber === currentPage }"
      >
        {{ pageNumber }}
      </button>
    </div>
    <button @click="nextPage" :disabled="currentPage === totalPages">
      <font-awesome-icon
        :icon="['fass', 'arrow-right']"
        style="color: #949da6"
      />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
  },
  computed: {
    displayedPageNumbers() {
      const range = 3
      const middleIndex = Math.ceil(range / 2)
      const startIndex = Math.max(1, this.currentPage - middleIndex + 1)
      const endIndex = Math.min(startIndex + range - 1, this.totalPages)
      const displayedPages = []
      for (let i = startIndex; i <= endIndex; i++) {
        displayedPages.push(i)
      }
      return displayedPages
    },
  },
  methods: {
    previousPage() {
      if (this.currentPage > 1) {
        this.$emit('update-page', this.currentPage - 1)
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.$emit('update-page', this.currentPage + 1)
      }
    },
    gotoPage(pageNumber) {
      this.$emit('update-page', pageNumber)
    },
  },
}
</script>

<style lang="scss" scoped>
@import './basePagination';
</style>
