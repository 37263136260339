<template>
  <section class="dropdown">
    <div class="dropdown__course" @click="handleCardClick()">
      <div class="dropdown__img">
        <img :src="`https://api.lms.k-clients.com/public/images/${src}`" />
      </div>
      <div class="dropdown__courseInfo">
        <p class="dropdown__courseCategory">{{ category }}</p>
        <h3 class="dropdown__courseName">
          {{ title }}
        </h3>
        <!-- <div class="dropdown__courseRating">
          <starRating class="dropdown__courseRate" :rating="rating" />
        </div> -->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  props: {
    src: String,
    title: String,
    lessons: String,
    time: String,
    price: Number,
    category: String,
    rating: Number,
    uuid: String,
  },
  methods: {
    handleCardClick() {
      const payload = {
        uuid: this.uuid,
        name: this.title,
      }
      this.$emit('card-click', payload)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/index.scss';
@import './dropdownCard';
</style>
