<template>
  <div class="course" ref="eventRef">
    <div class="course__wrapper" @click="goToEvent">
      <div class="course__photo">
        <img
          v-if="this.eventData.imagePath"
          :src="`https://api.lms.k-clients.com/public/images/${this.eventData?.imagePath}`"
          alt="eventData Image"
        />
        <img v-else src="@/assets/pictures/avatar.png" />
        <div class="course__date">
          {{ this.formatDate(eventData.date) }}
        </div>
      </div>
      <h3 class="course__title">
        {{ eventData.title }}
      </h3>
      <div class="course__info">
        <div class="time">
          <p v-if="eventData.time">{{ eventData.time }}</p>
          <p v-else>{{ $t('eventsPage.no_info') }}</p>
        </div>
        <div class="place">
          <p v-if="eventData.place">{{ eventData.place }}</p>
          <p v-else>{{ $t('eventsPage.no_info') }}</p>
        </div>
      </div>
      <div class="course__progress"></div>
    </div>
    <div class="course__hover" @click="showHoverInner = !showHoverInner"></div>
    <div class="course__hover-inner" v-if="showHoverInner">
      <ul class="course__hover-list">
        <li v-for="(option, index) in optionList" :key="index">
          <button @click="option.action(eventData)">{{ option.label }}</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showHoverInner: false,
    }
  },
  props: {
    eventData: {
      type: Object,
      required: true,
    },
    optionList: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick)
  },
  methods: {
    goToEvent() {
      window.location.href = this.eventData.url
    },
    handleOutsideClick(eventData) {
      if (!this.$refs.eventRef?.contains(eventData.target)) {
        this.showHoverInner = false
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ]
      const day = date.getDate()
      const monthIndex = date.getMonth()
      return `${day.toString().padStart(2, '0')} ${monthNames[monthIndex]}`
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/eventItem/eventItem';
</style>
