<template>
  <div class="profile">
    <div>
      <h4>{{ $t('profile') }}</h4>
      <div class="profile__info">
        <div class="profile__avatar">
          <div class="profile__image">
            <img
              v-if="imgPath"
              :src="`https://api.lms.k-clients.com/public/images/${imgPath}`"
            />
            <img v-else src="@/assets/pictures/avatar.png" />
          </div>
        </div>
        <div class="profile__details">
          <div class="profile__details-wrap">
            <h4>{{ userInfo.firstName }} {{ userInfo.lastName }}</h4>
            <p>{{ $t(getRole(userInfo.role)) }}</p>
            <div class="profile__reviews" v-if="isInstructor">
              <starRating
                :rating="userInfo.instructor.averageInstructorRating"
              ></starRating
              ><span>{{ userInfo.instructor.averageInstructorRating }}</span>
            </div>
            <p class="profile__description" v-if="userInfo.bio">
              {{ userInfo.bio }}
            </p>
            <!-- <p class="profile__description" v-else>
              <router-link to="/settings"> Add bio </router-link>
            </p> -->
          </div>
          <div class="profile__socials-wrap" v-if="isInstructor">
            <ul class="profile__socials">
              <li v-if="instrSocials?.facebookLink">
                <a :href="instrSocials.facebookLink" target="_blank"
                  ><img src="../../assets/icons/f.svg" alt=""
                /></a>
              </li>
              <li v-if="instrSocials?.instagramLink">
                <a :href="instrSocials.instagramLink" target="_blank"
                  ><img src="../../assets/icons/ig.svg" alt=""
                /></a>
              </li>
              <li v-if="instrSocials?.linkedinLink">
                <a :href="instrSocials.linkedinLink" target="_blank"
                  ><img src="../../assets/icons/in.svg" alt=""
                /></a>
              </li>
              <li v-if="instrSocials?.twitterLink">
                <a :href="instrSocials.twitterLink" target="_blank"
                  ><img src="../../assets/icons/t.svg" alt=""
                /></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { roleAliases } from '../../store/modules/roleAliases'
import getRoles from '@/helpers/getRoles'
import starRating from '@/components/starRating/starRating.vue'
export default {
  mixins: [getRoles],
  components: {
    starRating,
  },
  data() {
    return {
      imgPath: '',
    }
  },
  props: {
    userInfo: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    review: {
      type: Number,
      default: 0,
    },
    instrSocials: {
      required: false,
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(newValue) {
        this.imgPath = newValue.imageFile
      },
    },
  },
  methods: {
    getRole(role) {
      return roleAliases[role]
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/profileCard/profileCard';
</style>
