<template>
  <div class="reviews" :class="{ 'reviews--open': isOpen }">
    <div class="reviews__add">
      <div class="reviews__addInfo">
        <h5>{{ $t('singleCourse.add_reviews_and_rate') }}</h5>
        <p>{{ $t('singleCourse.add_reviews_and_rate_p') }}</p>
        <starRating :rating="rating" @update:rating="rating = $event" />
      </div>
      <form action="" @submit.prevent="addReview()">
        <div class="reviews__input">
          <label for="review__title">{{
            $t('singleCourse.review_title')
          }}</label>
          <input type="text" v-model="reviewTitle" />
          <div class="reviews__error" v-if="isError('reviewTitle')">
            {{ getErrorMessage('reviewTitle') }}
          </div>
        </div>
        <div class="reviews__input">
          <label for="review__content">{{
            $t('singleCourse.review_content')
          }}</label>
          <textarea v-model="reviewContent"> </textarea>
          <div class="reviews__error" v-if="isError('reviewContent')">
            {{ getErrorMessage('reviewContent') }}
          </div>
        </div>
        <baseButton :disabled="buttonDisabled" class="button--reviews">{{
          $t('singleCourse.review_submit')
        }}</baseButton>
      </form>
    </div>
  </div>
</template>

<script>
import baseButton from '@/components/baseButton/baseButton.vue'
import starRating from '@/components/starRating/starRating.vue'
export default {
  components: {
    baseButton,
    starRating,
  },
  data() {
    return {
      buttonDisabled: false,
      rating: 5,
      reviewTitle: '',
      reviewContent: '',
      errors: {
        reviewTitle: false,
        reviewContent: false,
        general: false,
      },
      errorMessages: {
        reviewTitle: this.$t('singleCourse.review_title_error'),
        reviewContent: this.$t('singleCourse.review_content_error'),
      },
      reviews: [],
    }
  },
  watch: {
    reviewContent(newVal) {
      if (newVal !== '') {
        this.errors.reviewContent = false
      }
    },
    reviewTitle(newVal) {
      if (newVal !== '') {
        this.errors.reviewTitle = false
      }
    },
  },
  computed: {
    isOpen() {
      return this.$store.state.videos.isOpen
    },
  },
  methods: {
    isError(field) {
      return this.errors[field]
    },
    getErrorMessage(field) {
      return this.errorMessages[field]
    },
    async addReview() {
      this.errors.reviewTitle = this.reviewTitle === ''
      this.errors.reviewContent = this.reviewContent === ''
      if (this.errors.reviewTitle || this.errors.reviewContent) {
        return
      }
      try {
        const data = {
          rating: this.rating,
          title: this.reviewTitle,
          content: this.reviewContent,
        }
        await this.$store.dispatch('addReview', {
          id: this.$route.params.id,
          data: data,
        })
        this.buttonDisabled = true
      } catch (error) {
        this.errors.general = error.response.data.message
        this.buttonDisabled = false
        throw error
      }
      this.reviewTitle = ''
      this.reviewContent = ''
      this.rating = 0
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/index.scss';
@import './reviewsSingle';
</style>
