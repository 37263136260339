<template>
  <div>
    <Modal v-show="isModalVisible" @close-modal="closeModal">
      <template v-slot:modalContent>
        <div class="modal__title">
          <h3>{{ $t('modals.add_new_admin') }}</h3>
        </div>
        <form class="modal__form" @submit.prevent="createAdmin">
          <div class="modal__group">
            <custom-select
              class="create-course__input"
              :customClass="'modal-dropdown'"
              :placeholder="{ name: $t('all_categories'), uuid: '' }"
              :disable-placeholder="true"
              :options="categories"
              :value="userForm.categoryId"
              @input="setCategory($event)"
              :applyErrorClass="categoryError"
            ></custom-select>
            <p v-if="categoryError" class="error-msg">
              {{ $t('authentication.select_category') }}
            </p>
          </div>
          <div class="modal__group">
            <baseInput
              id="firstName"
              type="text"
              :placeholder="$t('settingsPage.first_name')"
              v-model="userForm.firstName"
              :applyErrorClass="$v.userForm.firstName.$error"
              customClass="input--modal"
              @clear-error="clearGeneralError"
              @blur="$v.userForm.firstName.$touch()"
            ></baseInput>
            <template v-if="$v.userForm.firstName.$error">
              <p class="error-msg" v-if="!$v.userForm.firstName.required">
                {{ $t('modals.first_name_req') }}
              </p>
            </template>
          </div>

          <div class="modal__group">
            <baseInput
              id="lastName"
              type="text"
              :placeholder="$t('settingsPage.last_name')"
              v-model="userForm.lastName"
              :applyErrorClass="$v.userForm.lastName.$error"
              customClass="input--modal"
              @clear-error="clearGeneralError"
              @blur="$v.userForm.lastName.$touch()"
            ></baseInput>
            <template v-if="$v.userForm.lastName.$error">
              <p class="error-msg" v-if="!$v.userForm.lastName.required">
                {{ $t('modals.last_name_req') }}
              </p>
            </template>
          </div>
          <div class="modal__group">
            <baseInput
              id="username"
              type="text"
              :placeholder="$t('settingsPage.username')"
              v-model="userForm.username"
              :applyErrorClass="$v.userForm.username.$error"
              customClass="input--modal"
              @clear-error="clearGeneralError"
              @blur="$v.userForm.username.$touch()"
            ></baseInput>
            <template v-if="$v.userForm.username.$error">
              <p class="error-msg" v-if="!$v.userForm.username.required">
                {{ $t('modals.user_req') }}
              </p>
            </template>
          </div>
          <!-- <div class="modal__group">
            <label class="gender">{{ $t('settingsPage.gender') }}:</label>
          </div>
          <div class="modal__group genders">
            <div>
              <input
                type="radio"
                id="male"
                value="male"
                v-model="userForm.gender"
              />
              <label for="male">{{ $t('settingsPage.male') }}</label>
            </div>
            <div>
              <input
                type="radio"
                id="female"
                value="female"
                v-model="userForm.gender"
              />
              <label for="female">{{ $t('settingsPage.female') }}</label>
            </div>
          </div> -->
          <div class="modal__group">
            <baseInput
              id="email"
              type="text"
              :placeholder="$t('settingsPage.email')"
              v-model="userForm.email"
              :applyErrorClass="$v.userForm.email.$error"
              customClass="input--modal"
              @clear-error="clearGeneralError"
              @blur="$v.userForm.email.$touch()"
            ></baseInput>
            <template v-if="$v.userForm.email.$error">
              <p class="error-msg" v-if="!$v.userForm.email.required">
                {{ $t('modals.email_req') }}
              </p>
              <p class="error-msg" v-if="!$v.userForm.email.email">
                {{ $t('modals.not_valid_email') }}
              </p>
            </template>
          </div>
          <div class="modal__group">
            <baseButton
              type="submit"
              buttonClass="button--primary button--modal"
              :disabled="anyError || submitting"
            >
              {{ $t('eventsPage.submit_btn') }}</baseButton
            >
            <p v-if="error" class="error-msg">{{ generalError }}</p>
          </div>
        </form>
      </template>
    </Modal>
  </div>
</template>

<script>
import baseInput from '@/components/baseInput/baseInput.vue'
import baseButton from '@/components/baseButton/baseButton.vue'
import customSelect from '@/components/baseSelect/customSelect.vue'
import Modal from '@/components/modalBase/modalBase.vue'
import { mapActions, mapGetters } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
export default {
  components: {
    Modal,
    baseInput,
    baseButton,
    customSelect,
  },
  data() {
    return {
      isModalVisible: false,
      userForm: {
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        categoryId: null,
      },
      error: false,
      generalError: '',
      categoryError: false,
      submitting: false,
    }
  },
  validations() {
    return {
      userForm: {
        firstName: {
          required,
        },
        lastName: {
          required,
        },
        username: {
          required,
        },
        email: {
          required,
          email,
        },
      },
    }
  },
  computed: {
    anyError() {
      return this.$v.$anyError
    },
    ...mapGetters(['getAllCategories']),
    categories() {
      return this.getAllCategories
    },
  },
  methods: {
    openModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
      this.resetForm()
    },
    setCategory(category) {
      this.userForm.categoryId = category
      this.categoryError = false
    },
    ...mapActions(['fetchAdmins']),
    async createAdmin() {
      this.$v.$touch()
      this.categoryError = !this.userForm.categoryId
      if (this.$v.$invalid || this.categoryError) {
        return
      }
      try {
        const payload = {
          firstName: this.userForm.firstName,
          lastName: this.userForm.lastName,
          username: this.userForm.username,
          email: this.userForm.email,
        }
        this.submitting = true
        await this.$store.dispatch('createAdmin', {
          credentials: payload,
          categoryId: this.userForm.categoryId,
        })
        this.submitting = false
        this.fetchAdmins({ page: 1, limit: 10 })
        this.closeModal()
      } catch (err) {
        this.submitting = false
        this.error = true
        let message = err.response?.data?.message
        if (err.response && err.response.status === 400) {
          if (message == 'username already exists') {
            this.generalError = this.$t('settingsPage.user_exist')
          } else if (message == 'email already exists') {
            this.generalError = this.$t('settingsPage.email_exist')
          } else {
            this.generalError = this.$t('settingsPage.email_user_exist')
          }
        } else {
          this.generalError = this.$t('errors.something_went_wrong')
        }
      }
    },
    clearGeneralError() {
      this.generalError = ''
      this.error = false
    },
    resetForm() {
      this.userForm = {
        firstName: '',
        lastName: '',
        username: '',
        email: '',
      }
      this.$v.$reset()
      this.categoryError = false
      this.clearGeneralError()
    },
  },
  created() {
    this.$store.dispatch('fetchAllCategories')
  },
}
</script>
<style lang="scss" scoped>
@import './addAdminModal';
</style>
