<template>
  <div id="myModal" class="modal" @click="closeModal">
    <div v-if="loading" id="modal__dropdown">
      <loaderElement class="modal__loader"></loaderElement>
    </div>
    <div v-else id="modal__dropdown">
      <div class="modal__title">
        <ul class="modal__tabs">
          <li
            v-for="(tab, index) in videoOptions"
            :key="index"
            class="modal__tab"
            :class="{ 'modal__tab--active': activeTab === index }"
            @click.prevent="setActiveTab(index)"
          >
            {{ tab }}
          </li>
        </ul>
        <span id="close" @click="closeModal">&times;</span>
      </div>
      <div v-if="activeTab === 0">
        <form class="modal__form" @submit.prevent="uploadVideo">
          <div class="modal__group">
            <baseInput
              id="name"
              type="text"
              :placeholder="$t('singleCourse.video_name')"
              v-model="newVideoOne.name"
              customClass="input--modal"
              :applyErrorClass="$v.newVideoOne.name.$error"
              @blur="$v.newVideoOne.name.$touch()"
            ></baseInput>
            <template v-if="$v.newVideoOne.name.$error">
              <p class="error-msg" v-if="!$v.newVideoOne.name.required">
                {{ $t('singleCourse.video_name_error') }}
              </p>
            </template>
          </div>
          <div class="modal__group">
            <input
              type="file"
              ref="fileInput"
              accept="video/mp4,video/x-m4v,video/*"
              @change="handleFileSelectOne($event)"
              class="file--modal inputFile"
            />
            <template v-if="$v.newVideoOne.videoFile.$error">
              <p class="error-msg" v-if="!$v.newVideoOne.videoFile.required">
                {{ $t('singleCourse.video_file_error') }}
              </p>
            </template>
          </div>
          <div class="modal__group">
            <baseButton
              type="submit"
              buttonClass="button--primary button--modal"
              :disabled="isDisabledOne"
              >{{ $t('modals.submit') }}</baseButton
            >
            <p v-if="errorOne" class="error-msg">{{ generalError }}</p>
          </div>
        </form>
      </div>
      <div v-if="activeTab === 1">
        <form class="modal__form" @submit.prevent="uploadYoutubeVideo">
          <div class="modal__group">
            <baseInput
              id="file"
              type="text"
              v-model="newVideoTwo.videoId"
              :placeholder="'Video Link'"
              customClass="input--modal"
              :applyErrorClass="$v.newVideoTwo.videoId.$error"
              @blur="$v.newVideoTwo.videoId.$touch()"
            ></baseInput>
            <template v-if="$v.newVideoTwo.videoId.$error">
              <p class="error-msg" v-if="!$v.newVideoTwo.videoId.required">
                {{ $t('singleCourse.video_link_error') }}
              </p>
            </template>
          </div>
          <div class="modal__group">
            <baseButton
              type="submit"
              buttonClass="button--primary button--modal"
              :disabled="isDisabledTwo"
              >{{ $t('modals.submit') }}</baseButton
            >
            <p v-if="errorTwo" class="error-msg">{{ generalError }}</p>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import baseInput from '@/components/baseInput/baseInput.vue'
import baseButton from '@/components/baseButton/baseButton.vue'
import loaderElement from '../loader/loaderElement.vue'
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    baseInput,
    baseButton,
    loaderElement,
  },
  data() {
    return {
      videoOptions: [this.$t('singleCourse.video_yours'), 'Youtube Link'],
      activeTab: 0,
      newVideoOne: {
        videoFile: null,
        name: '',
        videoId: '',
      },
      newVideoTwo: {
        videoId: '',
      },
      isDisabledOne: false,
      isDisabledTwo: false,
      errorOne: false,
      errorTwo: false,
      loading: false,
      generalError: this.$t('errors.something_went_wrong'),
    }
  },
  validations() {
    return {
      newVideoOne: {
        name: {
          required: (name) => this.activeTab === 0 && required(name),
        },
        videoFile: {
          required: (videoFile) => this.activeTab === 0 && required(videoFile),
        },
      },
      newVideoTwo: {
        videoId: {
          required: (videoId) => this.activeTab === 1 && required(videoId),
        },
      },
    }
  },
  props: {
    courseID: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapActions(['fetchCourseVideo']),
    fetchCourseVideo() {
      return this.fetchCourseVideoAction
    },
  },
  methods: {
    setActiveTab(index) {
      this.activeTab = index
      this.newVideoOne = {
        videoFile: null,
        name: '',
      }
      this.newVideoTwo = {
        videoId: '',
      }
      this.$v.$reset()
      this.errorOne = false
      this.errorTwo = false
    },
    async fetchCourseVideoAction() {
      await this.$store.dispatch('fetchCourseVideo', this.courseID)
    },
    async handleFileSelectOne(event) {
      this.newVideoOne.videoFile = event.target.files[0]
    },
    async uploadVideo() {
      this.$v.newVideoOne.$touch()
      if (this.$v.newVideoOne.$invalid) {
        return
      }
      const formData = new FormData()
      const videoFile = this.newVideoOne.videoFile
      const name = this.newVideoOne.name
      formData.append('name', name)
      formData.append('videoFile', videoFile)
      try {
        this.loading = true
        this.isDisabledOne = true
        await this.$store.dispatch('uploadVideo', {
          courseID: this.courseID,
          video: formData,
        })
        await this.fetchCourseVideo(this.courseID)
        this.loading = false
        this.closeModalAfterSubmit()
      } catch (err) {
        this.errorOne = true
        this.isDisabledOne = false
        this.loading = false
      }
    },
    async uploadYoutubeVideo() {
      this.$v.newVideoTwo.$touch()
      if (this.$v.newVideoTwo.$invalid) {
        return
      }
      const videoUrl = this.newVideoTwo.videoId
      const startIndex = videoUrl.lastIndexOf('=') + 1
      const videoId = videoUrl.substring(startIndex)
      try {
        this.loading = true
        this.isDisabledTwo = true
        await this.$store.dispatch('embedVideo', {
          courseID: this.courseID,
          chapterID: '',
          videoID: videoId,
        })
        await this.fetchCourseVideo(this.courseID)
        this.loading = false
        this.closeModalAfterSubmit()
      } catch (err) {
        this.errorTwo = true
        this.isDisabledTwo = false
        this.loading = false
      }
    },
    closeModal(event) {
      const isModalElement = event?.target?.id === 'myModal'
      const isCloseButtonElement = event?.target?.id === 'close'
      if (isModalElement || isCloseButtonElement) {
        this.$emit('close-modal')
        this.newVideoOne = {
          videoFile: null,
          name: '',
          videoId: '',
        }
        this.newVideoTwo = {
          videoId: '',
        }
        this.$v.$reset()
        this.isDisabledOne = false
        this.isDisabledTwo = false
      }
    },
    closeModalAfterSubmit() {
      this.$emit('close-modal')
      this.newVideoOne = {
        videoFile: null,
        name: '',
        videoId: '',
      }
      this.newVideoTwo = {
        videoId: '',
      }
      this.$v.$reset()
      this.isDisabledOne = false
      this.isDisabledTwo = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import './uploadVideoModal.scss';
</style>
