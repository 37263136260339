<template>
  <div class="item" ref="itemRef">
    <div
      class="item__hover"
      @click="showHoverInner = !showHoverInner"
      v-if="isVisible"
    ></div>
    <div class="item__hover-inner" v-if="showHoverInner">
      <ul class="item__hover-list">
        <li v-for="(option, index) in optionList" :key="index">
          <button @click="option.action(item)">{{ option.label }}</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showHoverInner: false,
    }
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    optionList: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  methods: {
    handleOutsideClick(event) {
      if (!this.$refs.itemRef?.contains(event.target)) {
        this.showHoverInner = false
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick)
  },
}
</script>

<style scoped lang="scss">
.item {
  position: relative;
  &__hover {
    position: relative;
    width: toRem(35);
    height: toRem(35);
    background-color: $accent5;
    border-radius: 50%;
    cursor: pointer;
    transform: rotate(90deg);
    &::after {
      position: absolute;
      content: url(@/assets/icons/dots.svg);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &:hover {
      &::after {
        filter: brightness(0.5);
      }
    }
  }
  &__hover-inner {
    display: block;
    position: absolute;
    z-index: 2;
    background-color: $accent5;
    top: toRem(35);
    right: 0;
    width: toRem(250);
    box-shadow: $boxShadow;
    height: auto;
    padding: toRem(22) toRem(32) toRem(19) toRem(25);
    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-family: $fontfamily;
      @include font(400, toRem(16), toRem(40), $textColor);
      &:hover {
        color: $headColor;
      }
    }
  }
}
</style>
