<template>
  <div class="courses-wrapper layout">
    <div class="courses-wrapper__head">
      <h5 class="courses-wrapper__title main-title">{{ $t('courses') }}</h5>
    </div>
    <div class="courses-wrapper__courses content-layout">
      <div class="courses-wrapper__filters">
        <baseInput
          id="search"
          type="text"
          :placeholder="$t('search_courses')"
          customClass="input--search"
          v-model="courseName"
        ></baseInput>
        <div class="courses-wrapper__dropdowns">
          <custom-select
            :placeholder="{ name: $t('all_categories'), uuid: '' }"
            :disable-placeholder="false"
            :options="setCategories"
            :optionValue="'name'"
            :value="sortValue"
            @input="setSort($event)"
          ></custom-select>
          <!-- <baseSelect
            :text="$t('sort_by')"
            :placeholder="$t('all_categories')"
            :beforeTextWidth="55"
            :options="setCategories"
            :optionValue="'name'"
            :value="sortValue"
            @change="setSort($event.target.value)"
          ></baseSelect> -->
        </div>
      </div>
      <loaderElement v-if="isLoading" />
      <div v-else>
        <div class="courses-wrapper__course" v-if="enrolledCourses.length > 0">
          <studentCourseCard
            v-for="course in enrolledCourses"
            :key="course.id"
            :course="course"
          ></studentCourseCard>
        </div>
        <div v-else class="courses-wrapper__noCourses">
          <p>{{ $t('coursesPage.no_courses_available') }}</p>
        </div>
        <basePagination
          v-if="enrolledCourses.length > 0"
          :current-page="currentPage"
          :total-pages="totalPages"
          @update-page="handleUpdatePage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import baseInput from '../components/baseInput/baseInput.vue'
import studentCourseCard from '../components/studentCourseCard/studentCourseCard.vue'
import customSelect from '../components/baseSelect/customSelect.vue'
import basePagination from '../components/basePagination/basePagination.vue'
import loaderElement from '../components/loader/loaderElement.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    baseInput,
    studentCourseCard,
    customSelect,
    basePagination,
    loaderElement,
  },
  data() {
    return {
      isLoading: false,
      page: 1,
      limit: 1,
      numbersToShow: [{ name: 4 }, { name: 6 }, { name: 8 }, { name: 12 }],
      sortValue: '',
      currentPage: parseInt(this.$route.query.page) || 1,
      courseName: '',
      selectedCategory: null,
      setCategories: [],
    }
  },
  methods: {
    displayedPageNumbers() {
      const range = 3
      const middleIndex = Math.ceil(range / 2)
      const startIndex = Math.max(1, this.currentPage - middleIndex + 1)
      const endIndex = Math.min(startIndex + range - 1, this.totalPages)
      const displayedPages = []
      for (let i = startIndex; i <= endIndex; i++) {
        displayedPages.push(i)
      }
      return displayedPages
    },
    async dispatchFilter(searchParams) {
      await this.$store.dispatch('filterEnrolledCourses', {
        categoryId: this.selectedCategory.id,
        searchParams: searchParams,
        page: this.currentPage,
        limit: 4,
      })
    },
    async dispatchCourses(searchParams) {
      await this.$store.dispatch('fetchEnrolledCourses', {
        searchParams: searchParams,
        page: this.currentPage,
        limit: 4,
      })
    },
    async setSort(sortValue) {
      try {
        this.isLoading = true
        this.sortValue = sortValue
        this.selectedCategory = this.setCategories.find(
          (category) => category.name === sortValue
        )
        this.currentPage = 1
        this.updatePageQuery(this.currentPage)
        if (this.selectedCategory) {
          this.dispatchFilter(this.courseName)
        } else {
          this.dispatchCourses(this.courseName)
        }
        this.isLoading = false
      } catch (error) {
        console.error(error)
      }
    },
    getCategories() {
      this.categories.forEach((category) => {
        this.setCategories.push({ name: category.name, id: category.uuid })
      })
    },
    async handleUpdatePage(pageNumber) {
      this.searchParams || this.selectedCategory
        ? (this.showFilteredCourses = true)
        : (this.showFilteredCourses = false)
      try {
        this.isLoading = true
        this.currentPage = pageNumber
        this.updatePageQuery(pageNumber)
        if (this.selectedCategory) {
          this.dispatchFilter(this.courseName)
        } else {
          this.dispatchCourses(this.courseName)
        }
        this.isLoading = false
      } catch (error) {
        console.error(error)
      }
    },
    updatePageQuery(pageNumber) {
      const query = { ...this.$route.query, page: pageNumber }
      this.$router.replace({ query }).catch(() => {})
    },
  },
  computed: {
    ...mapGetters([
      'getEnrolledCourses',
      'getAllCategories',
      'enrolledCoursesLength',
      'getLastPageStudent',
    ]),
    totalPages() {
      return this.getLastPageStudent
    },
    enrolledCourses() {
      return this.getEnrolledCourses
    },
    categories() {
      return this.getAllCategories
    },
  },
  watch: {
    async courseName(newValue) {
      try {
        this.isLoading = true
        this.currentPage = 1
        this.updatePageQuery(this.currentPage)
        if (this.selectedCategory) {
          this.dispatchFilter(newValue)
        } else {
          this.dispatchCourses(newValue)
        }
        this.isLoading = false
      } catch (error) {
        console.error(error)
      }
    },
  },
  async mounted() {
    try {
      this.isLoading = true
      await this.$store.dispatch('fetchAllCategories')
      await this.$store.dispatch('fetchEnrolledCourses', {
        page: this.currentPage,
        limit: 4,
      })
      const pageQueryParam = parseInt(this.$route.query.page)
      if (
        isNaN(pageQueryParam) ||
        pageQueryParam <= 0 ||
        pageQueryParam > this.totalPages
      ) {
        this.currentPage = 1
        this.updatePageQuery(1)
      } else {
        this.currentPage = pageQueryParam
      }
      await this.getCategories()
      await this.$store.dispatch('fetchEnrolledCourses', {
        page: this.currentPage,
        limit: 4,
      })
      this.isLoading = false
    } catch (error) {
      console.error(error)
    }
  },
}
</script>

<style scoped lang="scss">
@import '../assets/scss/index.scss';
@import '../assets/scss/pages/studentCourses';
</style>
