<template>
  <div class="container layout">
    <div class="userManagement">
      <div class="userManagement__title main-title">
        <h2>{{ $t('categories.categories') }}</h2>
      </div>
      <div class="userManagement__content content-layout">
        <loaderElement v-if="loading" />
        <div v-else>
          <div class="userManagement__tab--add-admin">
            <baseButton
              buttonClass="button--primary u-btn btn--admin"
              @click="addNewCategory"
              >{{ $t('categories.add_new_category') }}</baseButton
            >
            <addCategoryModal
              ref="addCategoryRef"
              class="userManagement__tab--add-admin__dropdown"
              @close-modal="addCategory = false"
            ></addCategoryModal>
          </div>
          <baseTable
            :tableData="categoriesData"
            :columns="filteredCategoriesColumns"
            name="categories"
            fetchFunction="fetchCategories"
            :noDataMessage="noDataMessage"
          ></baseTable>
        </div>
      </div>
    </div>
    <editCategoryModal
      ref="editCategoryRef"
      :categoryID="selectedCategoryID"
      :oldCategory="selectedCategory"
      @close-modal="editCategory = false"
    />
    <!-- <transition name="modal-fade"
      ><baseModal
        v-show="showDeleteCategory"
        :user-firstname="selectedCategoryName"
        :content="showModalContent"
        @close-modal="showDeleteCategory = false"
        @confirm-action="deleteCategory()"
      ></baseModal
    ></transition> -->
  </div>
</template>

<script>
import baseButton from '@/components/baseButton/baseButton.vue'
import baseTable from '@/components/baseTable/baseTable.vue'
import addCategoryModal from '@/components/addCategoryModal/addCategoryModal.vue'
import editCategoryModal from '@/components/editCategoryModal/editCategoryModal.vue'
import loaderElement from '@/components/loader/loaderElement.vue'
// import baseModal from '@/components/baseModal/baseModal.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    baseButton,
    baseTable,
    addCategoryModal,
    editCategoryModal,
    loaderElement,
    // baseModal,
  },
  data() {
    return {
      isModalVisible: false,
      addCategory: false,
      showEditCategory: false,
      // showDeleteCategory: false,
      selectedCategoryName: '',
      selectedCategoryID: '',
      selectedCategory: {},
      // showModalContent: {
      //   message: this.$t('showModalContent.delete_this_user'),
      //   submit: this.$t('showModalContent.delete'),
      // },
      noDataMessage: this.$t('categories.no_categories_found'),
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['getCategories']),
    categoriesData() {
      const data = this.getCategories
      return Array.isArray(data) ? data : []
    },
    filteredCategoriesColumns() {
      return [
        {
          field: 'imagePath',
          key: 'a',
          title: this.$t('tables.image'),
          align: 'left',
          renderBodyCell: ({ rowIndex }) => {
            const category = this.categoriesData[rowIndex]
            const imageUrl = category?.imagePath
              ? `https://api.lms.k-clients.com/public/images/${category.imagePath}`
              : ''
            return (
              <img
                src={imageUrl}
                alt="Category Image"
                style="max-height: 30px; max-width: 30px; vertical-align: middle;"
              />
            )
          },
        },
        {
          field: 'name',
          key: 'b',
          title: this.$t('tables.category_name'),
          align: 'left',
        },
        {
          field: 'edit',
          key: 'f',
          title: this.$t('tables.actions'),
          align: 'center',
          width: '22%',
          renderBodyCell: ({ rowIndex }) => {
            const category = this.categoriesData[rowIndex]
            return (
              <span class="span--categories">
                <button
                  class="button button--edit"
                  on-click={() => this.editCategory(category)}
                >
                  {this.$t('tables.edit_btn')}
                </button>
                &nbsp;
                {/* <button
                  class="button button--delete"
                  on-click={() => this.openDeleteModal(category)}
                >
                  {this.$t('tables.delete_btn')}
                </button> */}
              </span>
            )
          },
        },
      ]
    },
  },
  methods: {
    ...mapActions(['fetchCategories']),
    addNewCategory() {
      if (this.addCategory === true) {
        this.addCategory = false
      } else this.addCategory = true
      this.$refs.addCategoryRef.openModal()
    },
    editCategory(category) {
      this.selectedCategory = category
      this.selectedCategoryID = category.uuid
      /* if (this.showEditCategory === true) {
        this.showEditCategory = false
      } else this.showEditCategory = true */
      this.$refs.editCategoryRef.openModal()
    },
    // openDeleteModal(category) {
    //   this.selectedCategoryID = category.uuid
    //   this.selectedCategoryName = category.name
    //   this.showModalContent = {
    //     message: this.$t('categories.delete_this_category'),
    //     submit: this.$t('categories.delete'),
    //   }
    //   this.showDeleteCategory = !this.showDeleteCategory
    // },
    // async deleteCategory() {
    //   try {
    //     await this.$store.dispatch('deleteCategory', this.selectedCategoryID)
    //     await this.$store.dispatch('fetchCategories')
    //   } catch (err) {
    //     console.error(err)
    //   }
    // },
    async fetchCategories() {
      try {
        this.loading = true
        await this.$store.dispatch(
          'setPage',
          parseInt(this.$route.query.page) || 1
        )
        await this.$store.dispatch('setSearchQuery', '')
        await this.$store.dispatch('setLimit', 5)
        await this.$store.dispatch('fetchCategories')
        this.loading = false
      } catch (error) {
        console.error(error)
      }
    },
  },
  async mounted() {
    try {
      await this.fetchCategories()
    } catch (error) {
      console.error(error)
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/pages/userManagement';
.userManagement__tab--add-admin {
  margin-left: 0 !important;
  left: 16.5rem;
  right: 0;
  @include tablet {
    margin-left: 0 !important;
    left: toRem(225);
    right: 0;
  }
}
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.3s;
}
.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}
</style>
