<template>
  <div class="container layout">
    <div class="settings">
      <div class="settings__title main-title">
        <h2>{{ $t('settingsPage.settings_title') }}</h2>
      </div>
      <div class="settings__content content-layout">
        <ul class="settings__tabs">
          <li
            v-for="(tab, index) in filteredTabs"
            :key="index"
            class="settings__tab"
            :class="{ 'settings__tab--active': activeTab === index }"
            @click.prevent="setActiveTab(index)"
          >
            {{ tab }}
          </li>
        </ul>
        <form class="settings__user-info">
          <div
            class="settings__general"
            v-show="activeTab === tabs.indexOf($t('settingsPage.general'))"
          >
            <div>
              <div class="settings__text-area">
                <baseTextarea
                  :label="$t('settingsPage.bio_info')"
                  v-model="updatedUser.bio"
                ></baseTextarea>
              </div>

              <div class="settings__form-grid">
                <baseInput
                  id="firstName"
                  :label="$t('settingsPage.first_name')"
                  v-model="updatedUser.firstName"
                  @clear-error="clearGeneralError"
                ></baseInput>
                <baseInput
                  id="lastName"
                  :label="$t('settingsPage.last_name')"
                  v-model="updatedUser.lastName"
                  @clear-error="clearGeneralError"
                ></baseInput>
                <baseInput
                  :label="$t('settingsPage.username')"
                  id="userName"
                  v-model="updatedUser.username"
                  @clear-error="clearGeneralError"
                ></baseInput>
                <baseInput
                  :label="$t('settingsPage.email')"
                  id="userEmail"
                  v-model="updatedUser.email"
                  :error="generalError"
                  @clear-error="clearGeneralError"
                ></baseInput>
                <span v-if="generalError" class="error-message">{{
                  generalError
                }}</span>
              </div>
            </div>
            <div class="settings__align-button">
              <baseButton
                type="submit"
                buttonClass="button--primary settings__button"
                @click.prevent="saveChanges"
                :disabled="!hasChanges || submitting"
                >{{ $t('settingsPage.save_btn') }}</baseButton
              >
            </div>
          </div>
          <div
            class="settings__avatar"
            v-show="activeTab === tabs.indexOf('Avatar')"
          >
            <div class="settings__avatar-wrapper">
              <div class="settings__avatar-image">
                <img
                  v-if="showExistingImage && userInfo.imageFile"
                  :src="`https://api.lms.k-clients.com/public/images/${userInfo.imageFile}`"
                  class="settings__avatar-image"
                />
                <img
                  v-if="selectedImage"
                  :src="selectedImage"
                  class="settings__avatar-image"
                />
                <img
                  v-if="!userInfo.imageFile"
                  src="@/assets/pictures/avatar.png"
                  class="settings__avatar-image"
                />
                <label for="image_uploads" class="image-label"
                  ><input
                    type="file"
                    id="image_uploads"
                    name="image_uploads"
                    accept="image/*"
                    @change="handleFileSelect($event)"
                /></label>
              </div>
            </div>
            <span v-if="imageError" class="error-message">{{
              imageError
            }}</span>
            <div class="settings__align-button">
              <baseButton
                type="submit"
                buttonClass="button--primary settings__button"
                @click.prevent="saveChanges"
                :disabled="isInvalidFileType || showExistingImage || submitting"
                >{{ $t('settingsPage.save_btn') }}</baseButton
              >
            </div>
          </div>
        </form>
        <form @submit.prevent="changePassword">
          <div
            class="settings__password"
            v-show="activeTab === tabs.indexOf($t('settingsPage.password'))"
          >
            <baseInput
              id="oldPassword"
              :label="$t('settingsPage.old_password')"
              type="password"
              v-model="passwordData.oldPassword"
              @clear-error="clearGeneralError"
            ></baseInput
            ><baseInput
              id="newPassword"
              :label="$t('settingsPage.new_password')"
              type="password"
              v-model="passwordData.newPassword"
              @clear-error="clearGeneralError"
            ></baseInput
            ><baseInput
              id="confirmPassword"
              :label="$t('settingsPage.confirm_password')"
              type="password"
              v-model="passwordData.newPasswordConfirm"
              @clear-error="clearGeneralError"
            ></baseInput>
            <span v-if="passwordError" class="error-message">{{
              passwordError
            }}</span>
            <div class="settings__align-button">
              <baseButton
                type="submit"
                buttonClass="button--primary settings__button"
                @click.prevent="changePassword"
                :disabled="submitting"
                >{{ $t('settingsPage.save_btn') }}</baseButton
              >
            </div>
          </div>
        </form>
        <div
          class="settings__publicity"
          v-show="activeTab === tabs.indexOf($t('settingsPage.socials'))"
        >
          <ul>
            <li class="settings__socials">
              <a href="#"><img src="@/assets/icons/facebook.svg" alt="" /></a>
              <baseInput
                id="facebook"
                v-model="instructorSocials.facebookLink"
                customClass="input--modal"
                placeholder="Facebook"
                @input="checkSocialLinksChanges"
                @clear-error="clearGeneralError"
              ></baseInput>
            </li>
            <li class="settings__socials">
              <a href="#"><img src="@/assets/icons/instagram.svg" alt="" /></a>
              <baseInput
                id="instagram"
                v-model="instructorSocials.instagramLink"
                customClass="input--modal"
                placeholder="Instagram"
                @input="checkSocialLinksChanges"
                @clear-error="clearGeneralError"
              ></baseInput>
            </li>
            <li class="settings__socials">
              <a href="#"><img src="@/assets/icons/linkedin.svg" alt="" /></a>
              <baseInput
                id="linkedin"
                v-model="instructorSocials.linkedinLink"
                customClass="input--modal"
                placeholder="LinkedIn"
                @input="checkSocialLinksChanges"
                @clear-error="clearGeneralError"
              ></baseInput>
            </li>
            <li class="settings__socials">
              <a href="#"><img src="@/assets/icons/twitter.svg" alt="" /></a>
              <baseInput
                id="twitter"
                v-model="instructorSocials.twitterLink"
                customClass="input--modal"
                placeholder="Twitter"
                @input="checkSocialLinksChanges"
                @clear-error="clearGeneralError"
              ></baseInput>
            </li>
          </ul>
          <div class="settings__align-button">
            <baseButton
              type="submit"
              buttonClass="button--primary settings__button"
              :disabled="!hasSocialsChanges || submitting"
              @click.prevent="updateInstructorSocials"
              >{{ $t('settingsPage.save_btn') }}</baseButton
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import baseTextarea from '@/components/baseTextarea/baseTextarea.vue'
import baseInput from '@/components/baseInput/baseInput.vue'
import baseButton from '@/components/baseButton/baseButton.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    baseTextarea,
    baseInput,
    baseButton,
  },
  data() {
    return {
      tabs: [
        this.$t('settingsPage.general'),
        this.$t('settingsPage.avatar'),
        this.$t('settingsPage.password'),
        this.$t('settingsPage.socials'),
      ],
      activeTab: 0,
      updatedUser: {
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        bio: '',
        image: '',
      },
      showExistingImage: true,
      selectedImage: '',
      generalError: '',
      imageError: '',
      passwordError: '',
      passwordData: {
        oldPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
      },
      userEmail: '',
      userUsername: '',
      originalUser: null,
      isInvalidFileType: false,
      originalInstructorSocials: {},
      instructorSocials: {
        facebookLink: '',
        instagramLink: '',
        linkedinLink: '',
        twitterLink: '',
      },
      hasSocialsChanges: false,
      submitting: false,
    }
  },
  computed: {
    ...mapGetters(['getUserInfo', 'getInstrLinks']),
    userInfo() {
      return this.getUserInfo
    },
    instructorLinks() {
      return this.getInstrLinks
    },
    hasChanges() {
      return (
        this.originalUser &&
        JSON.stringify(this.originalUser) !== JSON.stringify(this.updatedUser)
      )
    },
    filteredTabs() {
      if (this.isInstructor) {
        return this.tabs
      } else {
        return this.tabs.filter(
          (tab) => tab !== this.$t('settingsPage.socials')
        )
      }
    },
  },
  methods: {
    async saveChanges() {
      try {
        const updatedData = new FormData()
        for (const property in this.updatedUser) {
          if (this.updatedUser[property] !== this.originalUser[property]) {
            updatedData.append(property, this.updatedUser[property])
          }
        }
        if (
          updatedData.has('firstName') ||
          updatedData.has('lastName') ||
          updatedData.has('username') ||
          updatedData.has('email') ||
          updatedData.has('bio') ||
          updatedData.has('image')
        ) {
          this.submitting = true
          await this.$store.dispatch('updateUserInfo', updatedData)
          this.showExistingImage = true
          this.submitting = false
          this.originalUser = JSON.parse(JSON.stringify(this.updatedUser))
        }
      } catch (error) {
        this.submitting = false
        let message = error.response.data.message
        if (error.response.request.status === 400) {
          if (Array.isArray(message)) {
            if (
              message.includes('email already exists') &&
              message.includes('username already exists')
            ) {
              this.generalError = this.$t('settingsPage.email_user_exist')
            } else if (message.includes('email already exists')) {
              console.error('Failed to update user info:', message)
              this.generalError = this.$t('settingsPage.email.exist')
            } else if (message.includes('username already exists')) {
              this.generalError = this.$t('settingsPage.user_exist')
              console.error('Failed to update user info:', message)
            } else {
              this.generalError = ''
              console.error('Failed to update user info:', message)
            }
          } else {
            this.generalError = this.$t('errors.something_went_wrong')
            console.error('Failed to update user info:', message)
          }
        } else {
          this.generalError = this.$t('errors.something_went_wrong')
        }
      }
    },
    async changePassword() {
      try {
        if (
          this.passwordData.oldPassword === '' ||
          this.passwordData.newPassword === '' ||
          this.passwordData.newPasswordConfirm === ''
        ) {
          this.passwordError = this.$t('settingsPage.fill_password_fields')
          return
        }
        this.submitting = true
        await this.$store.dispatch('changePassword', {
          oldPassword: this.passwordData.oldPassword,
          newPassword: this.passwordData.newPassword,
          newPasswordConfirm: this.passwordData.newPasswordConfirm,
        })
        this.submitting = false
        this.clearForm()
      } catch (error) {
        this.submitting = false
        let message = error.response.data.message
        if (message === 'Old password is incorrect!') {
          this.passwordError = this.$t('settingsPage.old_pass_incorrect')
        } else if (message === 'newPasswordConfirm should not be empty') {
          this.passwordError = this.$t('settingsPage.confirm_pass_not_empty')
        } else if (
          message === 'New password cannot be the same as old password!'
        ) {
          this.passwordError = this.$t('settingsPage.new_pass_not_same')
        } else {
          this.passwordError = message[0]
        }
      }
    },
    checkSocialLinksChanges() {
      const { facebookLink, instagramLink, linkedinLink, twitterLink } =
        this.instructorSocials
      if (
        facebookLink !== this.originalInstructorSocials.facebookLink ||
        instagramLink !== this.originalInstructorSocials.instagramLink ||
        linkedinLink !== this.originalInstructorSocials.linkedinLink ||
        twitterLink !== this.originalInstructorSocials.twitterLink
      ) {
        this.hasSocialsChanges = true
      } else {
        this.hasSocialsChanges = false
      }
    },
    async updateInstructorSocials() {
      const { facebookLink, instagramLink, linkedinLink, twitterLink } =
        this.instructorSocials
      const {
        facebookLink: originalFacebookLink,
        instagramLink: originalInstagramLink,
        linkedinLink: originalLinkedinLink,
        twitterLink: originalTwitterLink,
      } = this.originalInstructorSocials

      const hasChanges =
        facebookLink !== originalFacebookLink ||
        instagramLink !== originalInstagramLink ||
        linkedinLink !== originalLinkedinLink ||
        twitterLink !== originalTwitterLink
      if (hasChanges) {
        try {
          this.submitting = true
          await this.$store.dispatch(
            'updateInstructorLinks',
            this.instructorSocials
          )
          this.submitting = false
          this.originalInstructorSocials.facebookLink = facebookLink
          this.originalInstructorSocials.instagramLink = instagramLink
          this.originalInstructorSocials.linkedinLink = linkedinLink
          this.originalInstructorSocials.twitterLink = twitterLink
          this.originalInstructorSocials = { ...this.instructorSocials }
          this.hasSocialsChanges = false
        } catch (error) {
          this.submitting = false
          console.error('Failed to update instructor social links:', error)
        }
      }
    },
    clearGeneralError() {
      this.generalError = ''
      this.imageError = ''
      this.passwordError = ''
    },
    clearForm() {
      this.passwordData.oldPassword = ''
      this.passwordData.newPassword = ''
      this.passwordData.newPasswordConfirm = ''
      this.clearGeneralError()
    },
    setActiveTab(index) {
      if (this.activeTab !== index) {
        this.clearForm()
        this.activeTab = index
      }
    },
    handleFileSelect(event) {
      const file = event.target.files[0]
      if (!this.isValidFileType(file)) {
        this.imageError = this.$t('settingsPage.invalid_file_type')
        this.updatedUser.image = ''
        this.isInvalidFileType = true
        console.error('Invalid file type. Only image files are allowed.')
        return
      } else if (this.isValidFileType(file)) {
        this.clearGeneralError()
        this.isInvalidFileType = false
        const reader = new FileReader()
        reader.onload = (e) => {
          const imageUrl = e.target.result
          this.selectedImage = imageUrl
          this.showExistingImage = false
        }
        reader.readAsDataURL(file)
      }
      this.updatedUser.image = file
    },
    isValidFileType(file) {
      if (!file || !file.type) {
        return false
      }
      const allowedTypes = [
        'image/jpeg',
        'image/png',
        'image/gif',
        'image/bmp',
        'image/webp',
        'image/tiff',
        'image/avif',
      ]
      return allowedTypes.includes(file.type)
    },
  },
  async mounted() {
    await this.$store.dispatch('fetchUserInfo')
    const { firstName, lastName, bio, username, email } = this.userInfo
    this.updatedUser.firstName = firstName
    this.updatedUser.lastName = lastName
    this.updatedUser.bio = bio
    this.updatedUser.username = username
    this.updatedUser.email = email
    this.userEmail = email
    this.userUsername = username
    this.originalUser = JSON.parse(JSON.stringify(this.updatedUser))

    if (this.isInstructor) {
      await this.$store.dispatch('fetchInstructorLinks')
      const { facebookLink, instagramLink, linkedinLink, twitterLink } =
        this.instructorLinks
      this.instructorSocials.facebookLink = facebookLink
      this.instructorSocials.instagramLink = instagramLink
      this.instructorSocials.linkedinLink = linkedinLink
      this.instructorSocials.twitterLink = twitterLink
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/pages/settingsView.scss';
</style>
