<template>
  <div class="courses" @click.once="goToSingleCourse(course.uuid)">
    <div class="courses__grid" :class="customClass">
      <div class="courses__icon">
        <img :src="getImagePath" alt="Course Image" />
      </div>
      <div class="courses__wrapper">
        <div class="courses__info">
          <span class="courses__owner">{{ course.category.name }}</span>
          <span class="courses__course"
            >{{ truncateText(courseName, 55, 60, 60) }}
          </span>
          <span class="courses__owner"
            >{{ $t('coursesPage.started_at') }}
            {{
              getDate(
                course.enrolls ? course.enrolls[0].created_at : course.createdAt
              )
            }}</span
          >
        </div>
        <div class="courses__progress">
          <div class="courses__progress-flex">
            <h3>{{ $t('coursesPage.completed') }}</h3>
            <span
              >{{
                course.enrolls && course.enrolls.length > 0
                  ? course.enrolls[0].progressPercentage
                  : course.progressPercentage
              }}%</span
            >
          </div>
          <div class="shell">
            <div
              class="bar"
              :style="{
                width:
                  course.enrolls && course.enrolls.length > 0
                    ? course.enrolls[0].progressPercentage + '%'
                    : course.progressPercentage + '%',
              }"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    customClass: {
      type: String,
      default: '',
    },
    course: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      courseName: this.course.name,
      imgPath: this.course.imagePath,
    }
  },
  computed: {
    ...mapGetters(['getUserInfo']),
    userInfo() {
      return this.getUserInfo
    },
    getImagePath() {
      return this.imgPath
        ? `https://api.lms.k-clients.com/public/images/${this.imgPath}`
        : require('../../assets/pictures/avatar.png')
    },
  },
  methods: {
    async goToSingleCourse(id) {
      await this.$store.dispatch('postView', id)
      this.$router.push(`/my-courses/single/${id}`)
    },
    getDate(dateString) {
      const date = new Date(dateString)
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      const formattedDate = date.toLocaleDateString('en-US', options)
      return formattedDate
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/studentCourseCard/studentCourseCard';
</style>
