<template>
  <div>
    <Modal v-show="isModalVisible" @close-modal="closeModal">
      <template v-slot:modalContent>
        <div class="modal__title">
          <h3>{{ $t('modals.add_category_title') }}</h3>
        </div>
        <form class="modal__form" @submit.prevent="confirmAction()">
          <div class="modal__group">
            <baseInput
              type="text"
              id="categoryName"
              v-model="category.name"
              :placeholder="$t('modals.category_name')"
              customClass="input--modal"
              :applyErrorClass="$v.category.name.$error"
              @blur="$v.category.name.$touch()"
            ></baseInput>
            <template v-if="$v.category.name.$error">
              <p class="error-msg" v-if="!$v.category.name.required">
                {{ $t('modals.category_name_error') }}
              </p>
            </template>
          </div>
          <div class="modal__group input">
            <img v-if="imagePreview" :src="imagePreview" />
            <input
              type="file"
              accept="image/*"
              id="imageUploads"
              name="imageUploads"
              @change="handleFileSelect($event)"
              class="file--modal"
            />
          </div>
          <div>
            <baseButton
              class="button--primary button--modal"
              :disabled="areEmptyInputs || submitting"
              >{{ $t('modals.add_category_title') }}</baseButton
            >
            <p v-if="error" class="error-msg">{{ generalError }}</p>
          </div>
        </form>
      </template>
    </Modal>
    <transition name="modal-fade"
      ><baseModal
        v-show="showCategoryModal"
        :content="showModalContent"
        @close-modal="showCategoryModal = false"
        @confirm-action="addCategory()"
      ></baseModal
    ></transition>
  </div>
</template>

<script>
import baseInput from '@/components/baseInput/baseInput.vue'
import baseButton from '@/components/baseButton/baseButton.vue'
import Modal from '@/components/modalBase/modalBase.vue'
import baseModal from '@/components/baseModal/baseModal.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    Modal,
    baseInput,
    baseButton,
    baseModal,
  },
  data() {
    return {
      isModalVisible: false,
      category: {
        name: '',
        image: '',
      },
      imagePreview: null,
      error: false,
      generalError: this.$t('errors.something_went_wrong'),
      showCategoryModal: false,
      areEmptyInputs: true,
      submitting: false,
      showModalContent: {
        message: this.$t('modals.create_this_category'),
        submit: this.$t('modals.create_this_category_btn'),
      },
    }
  },
  validations() {
    return {
      category: {
        name: {
          required,
        },
      },
    }
  },
  computed: {
    anyError() {
      return this.$v.$anyError
    },
  },
  watch: {
    'category.name': 'updateAreEmptyInputs',
    'category.image': 'updateAreEmptyInputs',
  },
  methods: {
    confirmAction() {
      this.showCategoryModal = !this.showCategoryModal
    },
    openModal() {
      this.isModalVisible = true
    },
    closeModal() {
      if (this.category.image) {
        const fileInput = document.getElementById('imageUploads')
        if (fileInput) {
          fileInput.value = ''
        }
      }
      this.isModalVisible = false
      this.resetForm()
    },
    handleFileSelect(event) {
      const file = event.target.files[0]
      if (file) {
        this.category.image = file
        this.imagePreview = URL.createObjectURL(file)
      } else {
        this.category.image = ''
        this.imagePreview = null
      }
      this.updateAreEmptyInputs()
    },
    async addCategory() {
      this.submitting = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      const formData = new FormData()
      formData.append('name', this.category.name)
      formData.append('image', this.category.image)
      try {
        await this.$store.dispatch('addCategory', formData)
        this.closeModal()
      } catch (err) {
        this.submitting = false
        this.error = true
        this.generalError = this.$t('errors.something_went_wrong')
      }
      await this.$store.dispatch('fetchCategories')
    },
    clearGeneralError() {
      this.generalError = ''
      this.error = false
    },
    resetForm() {
      this.category = {
        name: '',
        image: '',
      }
      this.imagePreview = null
      this.submitting = false
      this.$v.$reset()
      this.clearGeneralError()
      this.updateAreEmptyInputs()
    },
    updateAreEmptyInputs() {
      this.areEmptyInputs = !this.category.name || !this.category.image
    },
  },
}
</script>

<style lang="scss" scoped>
@import './addCategoryModal';
</style>
