<template>
  <div>
    <label v-if="label" :for="id" class="label">{{ label }}</label>
    <div class="select-wrapper">
      <span v-if="text" class="before-text">{{ text }}</span>
      <select
        :id="id"
        :value="selectedValue"
        v-bind="$attrs"
        v-on="$listeners"
        class="select"
        :class="[{ error: applyErrorClass }, customClass]"
        :style="{ '--before-text-width': beforeTextWidth + 'px' }"
      >
        <option value="" key="" :disabled="disablePlaceholder">
          {{ placeholder }}
        </option>
        <option
          v-for="option in options"
          :value="getOptionValue(option)"
          :key="option.id"
        >
          {{ option.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    id: Number,
    options: {
      type: Array,
      required: true,
    },
    value: [String, Number],
    label: String,
    placeholder: {
      type: String,
      default: 'Select an option',
    },
    beforeTextWidth: {
      type: Number,
      default: 0,
    },
    optionValue: {
      type: String,
      default: 'uuid',
    },
    applyErrorClass: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    disablePlaceholder: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selectedValue() {
      const selectedOption = this.options.find(
        (option) => this.getOptionValue(option) === this.value
      )
      return selectedOption ? this.getOptionValue(selectedOption) : ''
    },
  },
  methods: {
    getOptionValue(option) {
      return option[this.optionValue]
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/baseSelect/baseSelect';
</style>
