<template>
  <div class="quizzes layout">
    <div class="quizzes__align">
      <div class="quizzes__title main-title">
        <h2>{{ $t('createQuiz.title') }} {{ quiz.title }}</h2>
      </div>

      <div v-if="!isNoQuiz" class="quizzes__buttons">
        <baseButton
          buttonClass="button--primary button--font--white"
          @click.prevent="showUpdateQuizModal"
          >{{ $t('createQuiz.edit_quiz') }}</baseButton
        >
        <baseButton
          buttonClass="button--font--black"
          @click.prevent="showDeleteQuizModal"
          v-if="isInstructor"
          >{{ $t('createQuiz.delete_quiz') }}</baseButton
        >
      </div>
    </div>
    <div class="quizzes__wrapper">
      <loaderElement v-if="isLoading" />
      <div v-else class="quizzes__content content-layout">
        <div class="quizzes__noQuiz" v-if="isNoQuiz">
          <p>{{ $t('createQuiz.no_quiz_available') }}</p>
          <baseButton
            buttonClass="button--primary  button--createQuiz"
            @click.prevent="navigateToCreateQuiz(courseId)"
            v-if="isInstructor"
            >{{ $t('createQuiz.create_quiz') }}</baseButton
          >
        </div>
        <div class="quizzes__noQuestion" v-else-if="isNoQuestion">
          <div v-if="showCreateQuestion">
            <createQuestionCard
              v-for="count in questionCardCount"
              :key="count"
              @question-submitted="handleQuestion"
            ></createQuestionCard>
          </div>
          <div class="quizzes__noQuestion--info">
            <p v-if="!showCreateQuestion">
              {{ $t('createQuiz.no_questions_quiz') }}
            </p>
            <span v-show="generalError" class="error-msg">{{
              generalError
            }}</span>
            <div class="quizzes__noQuestion--buttons">
              <baseButton
                buttonClass="button--primary  button--createQuiz"
                @click="showCreateQuestionCard"
                v-if="isInstructor"
                >{{ $t('createQuiz.add_new_question') }}</baseButton
              >
              <baseButton
                buttonClass="button--primary  button--createQuiz"
                @click="addQuestion"
                v-if="isInstructor && showCreateQuestion"
                >{{ $t('createQuiz.submit_changes') }}</baseButton
              >
            </div>
          </div>
        </div>
        <div v-else>
          <div v-for="(question, index) in quiz.questions" :key="question.uuid">
            <questionCard
              :question="question"
              :index="index"
              :edit="true"
            ></questionCard>
          </div>
          <div v-if="showCreateQuestion">
            <createQuestionCard
              v-for="count in questionCardCount"
              :key="count"
              @question-submitted="handleQuestion"
              @input="clearGeneralError"
            ></createQuestionCard>
          </div>
          <span v-show="generalError" class="error-msg">{{
            generalError
          }}</span>
          <div class="quizzes__noQuestion--buttons">
            <baseButton
              buttonClass="button--primary  button--createQuiz"
              @click="showCreateQuestionCard"
              v-if="isInstructor && !showCreateQuestion"
              >{{ $t('createQuiz.add_new_question') }}</baseButton
            >
            <baseButton
              buttonClass="button--primary  button--createQuiz"
              @click="addQuestion"
              v-if="isInstructor && showCreateQuestion"
              >{{ $t('createQuiz.submit_changes') }}</baseButton
            >
          </div>
        </div>
      </div>
    </div>
    <transition name="modal-fade">
      <baseModal
        v-if="showDeleteQuiz"
        :content="showModalContent"
        @close-modal="showDeleteQuiz = false"
        @confirm-action="deleteQuiz()"
      />
    </transition>
    <transition name="modal-fade">
      <editModal
        ref="editQuizRef"
        :content="editQuizContent"
        @close-modal="showUpdateQuiz = false"
        @confirm-action="updateQuiz($event)"
      ></editModal>
    </transition>
  </div>
</template>

<script>
import editModal from '../components/editQuizModal/editQuizModal.vue'
import questionCard from '../components/questionCardComponents/questionCard.vue'
import createQuestionCard from '../components/questionCardComponents/createQuestionCard.vue'
import { mapGetters, mapActions } from 'vuex'
import baseButton from '@/components/baseButton/baseButton.vue'
import baseModal from '@/components/baseModal/baseModal.vue'
import loaderElement from '@/components/loader/loaderElement.vue'
export default {
  components: {
    questionCard,
    baseButton,
    baseModal,
    editModal,
    createQuestionCard,
    loaderElement,
  },
  data() {
    return {
      isLoading: false,
      courseId: this.$route.params.id,
      showDeleteQuiz: false,
      showModalContent: {
        message: this.$t('showModalContent.delete_this_quiz'),
        submit: this.$t('showModalContent.delete'),
        notification: this.$t('showModalContent.quiz_deleted_success'),
      },
      showUpdateQuiz: false,
      isNoQuiz: false,
      isNoQuestion: false,
      editQuizContent: {
        modalTitle: this.$t('showModalContent.edit_quiz'),
        modalLabels: [
          this.$t('showModalContent.quiz_name'),
          this.$t('showModalContent.quiz_percentage'),
        ],
        autofilled: [],
      },
      inputs: null,
      showCreateQuestion: false,
      questionCardCount: 0,
      questions: [],
      generalError: '',
    }
  },
  computed: {
    ...mapGetters(['getQuizInfo', 'getNoQuiz']),
    quiz() {
      return this.getQuizInfo
    },
    noQuiz() {
      return this.getNoQuiz
    },
  },
  methods: {
    ...mapActions(['fetchCourseQuizzes', 'fetchQuizInfo']),
    showDeleteQuizModal() {
      this.showDeleteQuiz = !this.showDeleteQuiz
    },
    async deleteQuiz() {
      try {
        this.isLoading = true
        await this.$store.dispatch('deleteQuiz')
        await this.fetchCourseQuizzes(this.courseId)
        this.quiz
        this.noQuiz
        this.isNoQuiz = this.noQuiz
        this.quiz.title = ''
        this.isLoading = false
      } catch (err) {
        console.error('Delete User Error', err)
      }
    },
    showUpdateQuizModal() {
      this.$refs.editQuizRef.openModal()
      this.fetchCourseQuizzes(this.courseId)
    },
    async updateQuiz(inputs) {
      try {
        this.inputs = inputs
        await this.$store.dispatch('updateQuiz', {
          title: this.inputs.firstInput,
          passingPercentage: this.inputs.secondInput,
        })
        await this.fetchCourseQuizzes(this.courseId)
        this.setAutofilledValues()
      } catch (err) {
        console.error('Update quiz Error', err)
      }
    },
    setAutofilledValues() {
      this.editQuizContent.autofilled = [
        this.quiz.title,
        this.quiz.passingPercentage,
      ]
    },
    clearGeneralError() {
      this.generalError = ''
    },

    navigateToCreateQuiz(courseId) {
      this.$router.push(`/quiz/create/${courseId}`)
    },
    showCreateQuestionCard() {
      this.clearGeneralError()
      this.showCreateQuestion = true
      this.questionCardCount++
    },
    handleQuestion(questionData) {
      const existingQuestionIndex = this.questions.findIndex(
        (question) => question.question === questionData.question
      )
      if (existingQuestionIndex !== -1) {
        this.questions.splice(existingQuestionIndex, 1, questionData)
      } else {
        this.questions.push(questionData)
      }
    },
    async addQuestion() {
      try {
        this.clearGeneralError()
        const credentials = {
          questions: this.questions,
        }

        await this.$store.dispatch('addQuestion', credentials)
        await this.fetchCourseQuizzes(this.courseId)
        this.quiz
        this.isNoQuestion
        this.showCreateQuestion = false
        this.questionCardCount = 0
        this.questions = []
      } catch (err) {
        let message = err.response?.data?.message
        if (message[0] === 'questions should not be empty') {
          this.generalError = this.$t('createQuiz.write_correctly')
        } else if (
          message === 'A similar question already exists for the course.'
        ) {
          this.generalError = this.$t('createQuiz.similar_question')
        } else console.error('CREATE QUIZ Error', err)

        this.questions.pop()
      }
    },
  },
  watch: {
    quiz: {
      immediate: true,
      handler(newQuiz) {
        if (newQuiz && newQuiz.questions && newQuiz.questions.length === 0) {
          this.isNoQuestion = true
        } else {
          this.isNoQuestion = false
        }
      },
    },
  },
  async created() {
    try {
      await this.fetchCourseQuizzes(this.courseId)
      this.quiz
      this.noQuiz
      if (this.noQuiz === true) {
        this.isNoQuiz = true
      } else {
        this.isNoQuiz = false
        this.setAutofilledValues()
      }
    } catch (err) {
      console.error('Fetch Course Quizzes Error', err)
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/pages/viewQuiz';
</style>
