<template>
  <div class="star-rating">
    <div
      v-for="star in stars"
      :key="star.index"
      @click="handleClick(star.value)"
    >
      <svg
        :class="star.class"
        width="13"
        height="13"
        viewBox="0 0 13 13"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.80262 0.452219L4.21589 3.81352L0.665805 4.35428C0.0291704 4.45075 -0.225969 5.27076 0.235712 5.74043L2.80412 8.35534L2.19664 12.0492C2.0873 12.7169 2.76038 13.217 3.32412 12.9048L6.5 11.1607L9.67588 12.9048C10.2396 13.2145 10.9127 12.7169 10.8034 12.0492L10.1959 8.35534L12.7643 5.74043C13.226 5.27076 12.9708 4.45075 12.3342 4.35428L8.78411 3.81352L7.19738 0.452219C6.91308 -0.146926 6.08935 -0.154542 5.80262 0.452219Z"
          :fill="star.fill === 'half' ? '#ffc78b' : star.fill"
        />
        <defs>
          <linearGradient
            id="halfStarGradient"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
          >
            <stop :stop-color="star.fill" offset="50%" />
            <stop :stop-color="star.emptyColor" offset="50%" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    clickable: {
      type: Boolean,
      default: true,
    },
    rating: {
      type: Number,
      required: true,
      default: 0,
    },
    starColor: {
      type: String,
      default: '#FFC78B',
    },
    emptyStarColor: {
      type: String,
      default: '#CCCCCC',
    },
  },
  data() {
    return {
      stars: [],
    }
  },
  created() {
    this.updateStars()
  },
  methods: {
    updateStars() {
      const integerPart = Math.floor(this.rating)
      const decimalPart = this.rating % 1
      this.stars = []

      for (let i = 1; i <= 5; i++) {
        let star = {
          index: i,
          value: i,
          fill: this.emptyStarColor,
          emptyColor: this.emptyStarColor,
          class: 'empty-star',
          clipPath: '',
        }

        if (i <= integerPart) {
          star.fill = this.starColor
          star.class = 'full-star'
        } else if (i === integerPart + 1 && decimalPart > 0) {
          star.fill = `url(#halfStarGradient)`
          star.class = 'half-star'
        }

        this.stars.push(star)
      }
    },
    handleClick(clickedRating) {
      if (this.clickable) {
        this.$emit('update:rating', clickedRating)
      }
    },
  },
  watch: {
    rating() {
      this.updateStars()
    },
  },
}
</script>

<style>
.star-rating {
  display: flex;
  gap: 3px;
  cursor: pointer;
}

.full-star {
  fill: #ffc78b;
}

.half-star {
  fill: #ffc78b;
}

.empty-star {
  fill: #cccccc;
}
</style>
