<template>
  <div class="topInstructorProfile">
    <div>
      <h4>{{ $t('dashboard.top_rating_instructor') }}</h4>
      <div class="topInstructorProfile__info">
        <div class="topInstructorProfile__image">
          <img
            style="object-fit: cover"
            v-if="imgPath"
            :src="`https://api.lms.k-clients.com/public/images/${imgPath}`"
            alt=""
          />
          <img v-else src="@/assets/pictures/avatar.png" />
        </div>
        <div class="topInstructorProfile__details">
          <h4>
            {{ topInstructor.firstName }}
            {{ topInstructor.lastName }}
          </h4>
          <div class="topInstructorProfile__ratings">
            <starRating
              :rating="topInstructor.instructor.averageInstructorRating"
            ></starRating>
            <p>
              {{ topInstructor.instructor.averageInstructorRating }} ({{
                topInstructorReviews
              }}
              {{ reviewText }})
            </p>
          </div>
        </div>
      </div>
      <p class="topInstructorProfile__description">
        {{ topInstructor.email }}
      </p>
      <div class="topInstructorProfile__stats">
        <p class="topInstructorProfile__stats--align">
          <span class="number">{{ topInstructorCourses }}</span>
          <span>{{ courseText }}</span>
        </p>
        <p class="topInstructorProfile__stats--align">
          <span class="number">{{ topInstructorStudents }}</span>
          <span>{{ studentText }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import starRating from '@/components/starRating/starRating.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    starRating,
  },
  data() {
    return {
      imgPath: '',
      ratings: 4,
    }
  },
  props: {
    userInfo: {
      type: Object,
      required: true,
      default: null,
    },
    review: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters(['getReviewCount']),
    topInstructor() {
      return this.userInfo?.instructor || 0
    },
    topInstructorStudents() {
      return this.userInfo?.studentCount || 0
    },
    topInstructorCourses() {
      return this.userInfo?.courseCount || 0
    },
    topInstructorReviews() {
      return this.getReviewCount
    },
    courseText() {
      return this.topInstructorCourses === 1
        ? this.$t('dashboard.course')
        : this.$t('dashboard.courses')
    },
    studentText() {
      return this.topInstructorStudents === 1
        ? this.$t('dashboard.student')
        : this.$t('dashboard.students')
    },
    reviewText() {
      return this.topInstructorReviews === 1
        ? this.$t('dashboard.review')
        : this.$t('dashboard.reviews')
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      async handler(newValue) {
        this.imgPath = newValue?.instructor?.imageFile || ''
        if (newValue?.instructor?.uuid) {
          await this.$store.dispatch(
            'fetchInstructorReviewInfo',
            newValue?.instructor?.uuid
          )
        }
      },
    },
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
@import './topInstructorCard';
</style>
