<template>
  <div class="questions">
    <loaderElement
      :customClass="'loader--sideMenu'"
      :custom-spinner="'loader-spinner--sideMenu'"
      v-if="isLoading"
    />
    <div v-else>
      <div
        v-for="question in questions"
        :key="question.uuid"
        :class="notificationComment && notification ? 'notifications' : ''"
      >
        <div v-if="getRepliesforQuestion(question.uuid)">
          <baseComment
            :image="question.user?.imageFile"
            :title="question.title"
            :description="question.description"
            :firstName="question.user?.firstName"
            :lastName="question.user?.lastName"
            :uuid="question.uuid"
            :votes="question.votes"
            :isReply="true"
            :show-edit-and-delete-buttons="
              userInfo.uuid === question.user?.uuid
            "
            :eventDate="question.created_at"
            :baseCommentClass="'comment-question comment-notification'"
            @click-reply="replyInputOpen(question.uuid)"
            @like="likeComment(question.uuid)"
            v-if="!waiting[question.uuid]"
          ></baseComment>
        </div>

        <div class="questions--reply" v-if="isReplyInputOpen(question.uuid)">
          <div class="questions--reply__section">
            <img
              v-if="userInfo.imageFile"
              :src="`https://api.lms.k-clients.com/public/images/${userInfo?.imageFile}`"
              alt="user img"
              class="questions__img"
            />
            <img
              v-else
              src="../../assets/pictures/avatar.png"
              alt="user img"
              class="questions__img"
            />
            <text-area
              class="questions--reply-box"
              :placeholder="$t('singleCourse.add_a_reply')"
              v-model="reply[question.uuid]"
            />
          </div>
          <div class="questions--reply__button">
            <buttonElement
              buttonClass="button--reply button--exit"
              @click="replyInputOpen(question.uuid)"
              >{{ $t('singleCourse.cancel') }}</buttonElement
            >
            <buttonElement
              buttonClass="button--reply"
              @click.once="postReply(question.uuid)"
              :disabled="isReplyButtonDisabled(question.uuid)"
              >{{ $t('singleCourse.reply') }}</buttonElement
            >
          </div>
        </div>
        <loaderElement
          :customClass="'loader--answer'"
          :custom-spinner="'loader-spinner--sideMenu'"
          v-if="loader[question.uuid] == true"
        />
        <div class="questions-answer">
          <div
            class="questions-content"
            v-if="
              showIcon[question.uuid] &&
              questionReplies[question.uuid].length > 0
            "
            @click="toggleReplies(question.uuid)"
          >
            <img
              src="../../assets/icons/blue-arrow-down.svg"
              class="questions-content__arrow"
              :class="{
                'questions-content__arrow--rotate': isReplyOpen(question.uuid),
              }"
            />
            <p>
              {{ repliesTotalCount[question.uuid] }}
              <span v-if="questionReplies[question.uuid].length > 1">{{
                $t('singleCourse.replies')
              }}</span>
              <span v-else>{{ $t('singleCourse.reply_2') }}</span>
            </p>
          </div>
          <div v-if="isReplyOpen(question.uuid) && showReplies">
            <baseComment
              class="questions-answer--fix"
              v-for="answer in getRepliesforQuestion(question.uuid)"
              :key="answer.uuid"
              :image="answer.user?.imageFile"
              :description="answer.comment"
              :firstName="answer.user?.firstName"
              :lastName="answer.user?.lastName"
              :uuid="answer.uuid"
              :votes="answer.votes"
              :isReply="false"
              :show-edit-and-delete-buttons="
                userInfo.uuid === answer.user?.uuid
              "
              :answer="true"
              :question="question.uuid"
              :eventDate="question.created_at"
              :baseCommentClass="'comment-answer'"
              @like="likeReply(answer.uuid)"
              @updateReply="updateReply"
              @deleteReply="deleteReply"
            ></baseComment>
            <div
              class="answers__button"
              v-if="
                repliesCurrentPage[question.uuid] <
                repliesLastPage[question.uuid]
              "
              @click="loadMoreReplies(question.uuid)"
            >
              <img
                src="../../assets/icons/show-more.svg"
                class="answers-content__arrow"
              />
              <p>
                <span>{{ $t('singleCourse.show_more_replies') }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <buttonElement
        buttonClass="button--load-more questions__button"
        @click="loadMore"
        v-if="
          currentPage < questionsLastPage &&
          filterValue != this.$t('singleCourse.my_comments')
        "
      >
        <div class="questions__button-content">
          <div>{{ $t('singleCourse.load_more') }}</div>
        </div>
      </buttonElement>
      <buttonElement
        buttonClass="button--load-more questions__button"
        @click="loadMore"
        v-if="
          myCurrentPage < questionsLastPage &&
          filterValue == this.$t('singleCourse.my_comments')
        "
      >
        <div class="questions__button-content">
          <div>{{ $t('singleCourse.load_more') }}</div>
        </div>
      </buttonElement>
      <loaderElement
        :customClass="'loader--answer'"
        :custom-spinner="'loader-spinner--sideMenu'"
        v-if="loadPagination"
      />
      <div class="questions--none" v-if="questions.length == 0">
        {{ $t('singleCourse.no_comment_section') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import textArea from '@/components/baseTextarea/textAreaComments.vue'
import buttonElement from '@/components/baseButton/baseButton.vue'
import baseComment from '@/components/baseComment/baseComment.vue'
import loaderElement from '@/components/loader/loaderElement.vue'

export default {
  components: {
    textArea,
    buttonElement,
    baseComment,
    loaderElement,
  },
  data() {
    return {
      openReplies: [],
      openRepliesInput: [],
      showIcon: [],
      questionReplies: [],
      repliesTotalCount: [],
      repliesCurrentPage: [],
      repliesLastPage: [],
      showReplies: false,
      reply: {},
      isLoading: false,
      loader: [],
      loadPagination: false,
      waiting: [],
      currentPage: 1,
      myCurrentPage: 1,
      perPage: 2,
      showEditAndDelete: false,
    }
  },
  props: {
    questions: {
      type: Array,
      required: true,
    },
    questionsLastPage: {
      type: Number,
      required: true,
    },
    questionsTotalCount: {
      type: Number,
      required: true,
    },
    filterValue: {
      type: String,
      required: true,
    },
    notification: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'getAnswers',
      'getUserInfo',
      'getFilter',
      'getSingleComment',
      'getNotificationComment',
      'getAnswersTotalCount',
      'getAnswersLastPage',
    ]),
    singleComment() {
      return this.getSingleComment
    },
    answers() {
      return this.getAnswers
    },
    answersTotalCount() {
      return this.getAnswersTotalCount
    },
    userInfo() {
      return this.getUserInfo
    },
    filterLoader() {
      return this.getFilter
    },
    notificationComment() {
      return this.getNotificationComment
    },
  },
  methods: {
    updateReply(question, reply, comment) {
      const foundReply = this.questionReplies[question].find((r) => {
        return r.uuid === reply
      })
      foundReply.comment = comment
    },
    async deleteReply(question, reply) {
      const foundReply = this.questionReplies[question].find((r) => {
        return r.uuid === reply
      })
      if (foundReply) {
        this.questionReplies[question] = this.questionReplies[question].filter(
          (r) => {
            return r.uuid !== reply
          }
        )
        await this.fetchRepliesperQuestion(question)
      }
      this.$forceUpdate()
    },
    async postReply(questionId) {
      if (this.reply[questionId].trim() == '') {
        return
      }
      await this.$store.dispatch('postReply', {
        questionId: questionId,
        comment: this.reply[questionId],
      })
      if (this.showReplies) {
        this.$set(this.loader, questionId, true)
      }
      this.replyInputOpen(questionId)
      this.reply[questionId] = ''
      await this.fetchRepliesperQuestion(questionId)
      this.showIcon[questionId] = true
      this.$nextTick(() => {
        this.$set(this.loader, questionId, false)
      })
    },
    toggle(id, array) {
      const index = array.indexOf(id)
      if (index !== -1) {
        array.splice(index, 1)
        this.reply[id] = ''
      } else {
        array.push(id)
      }
    },
    toggleReplies(questionId) {
      this.toggle(questionId, this.openReplies)
      if (this.openReplies.includes(questionId)) {
        this.showReplies = false
        this.questionReplies[questionId]
        this.showReplies = true
      }
    },
    replyInputOpen(questionId) {
      this.toggle(questionId, this.openRepliesInput)
    },
    isReplyOpen(id) {
      return this.openReplies.includes(id)
    },
    isReplyInputOpen(id) {
      return this.openRepliesInput.includes(id)
    },
    async fetchRepliesperQuestion(questionId) {
      await this.$store.dispatch('fetchAnswers', {
        id: questionId,
        page: 1,
      })
      this.repliesTotalCount[questionId] = this.answersTotalCount
      this.questionReplies[questionId] = this.answers
    },
    getRepliesforQuestion(questionId) {
      return this.questionReplies[questionId] || []
    },
    async loadMoreReplies(questionId) {
      this.repliesCurrentPage[questionId]++
      await this.$store.dispatch('fetchAnswers', {
        id: questionId,
        page: this.repliesCurrentPage[questionId],
      })
      this.questionReplies[questionId] = [
        ...this.questionReplies[questionId],
        ...this.getAnswers,
      ]
      this.showReplies = false
      this.showReplies = true
    },
    async loadMore() {
      this.loadPagination = true
      if (this.filterValue == this.$t('singleCourse.my_comments')) {
        this.myCurrentPage += 1
        await this.$store.dispatch('fetchMyQuestions', {
          courseId: this.$route.params.id,
          page: this.myCurrentPage,
        })
      } else {
        this.currentPage += 1
        await this.$store.dispatch('fetchQuestions', {
          courseId: this.$route.params.id,
          page: this.currentPage,
        })
      }
      await this.showRepliesFunction()
      this.loadPagination = false
    },
    isReplyButtonDisabled(questionId) {
      return !this.reply[questionId] || this.reply[questionId].trim() == ''
    },
    async showRepliesFunction() {
      for (const question of this.questions) {
        await this.$store.dispatch('fetchAnswers', {
          id: question.uuid,
          page: 1,
        })
        this.questionReplies[question.uuid] = this.answers
        if (this.questionReplies[question.uuid].length > 0) {
          this.showIcon[question.uuid] = true
        } else {
          this.showIcon[question.uuid] = false
        }
        this.repliesTotalCount[question.uuid] = this.getAnswersTotalCount
        this.repliesCurrentPage[question.uuid] = 1
        this.repliesLastPage[question.uuid] = this.getAnswersLastPage
      }
    },
  },
  async created() {
    this.isLoading = true
    await this.showRepliesFunction()
    await this.userInfo
    this.isLoading = false
  },
}
</script>

<style lang="scss" scoped>
@import './studentCommentSection';
</style>
