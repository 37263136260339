<template>
  <div class="questionCard">
    <div class="questionCard__questions">
      <div class="questionCard__content">
        <h3 class="questionCard__title">
          <div>
            <baseInput
              id="question"
              type="text"
              :placeholder="$t('createQuiz.question')"
              customClass="input--modal title2"
              v-model="question"
              @blur="emitQuestion"
            ></baseInput>
          </div>
        </h3>
      </div>
    </div>
    <div class="questionCard__answers edit">
      <span class="questionCard__text">{{ $t('createQuiz.select_one') }}:</span>
      <form
        name="createQuizAnswers"
        action="#"
        class="questionCard__answersForm"
      >
        <div class="inputWrapper">
          <p v-for="(answer, index) in answers" :key="index">
            <baseInput
              :id="'answer' + (index + 1)"
              type="radio"
              customClass="input--radio"
              :name="'answer'"
              :value="answer.answer"
              @change="setRightAnswer(index)"
            ></baseInput>
            <label :for="'answer' + (index + 1)">
              <baseInput
                :id="'answer' + (index + 1)"
                type="text"
                placeholder="Answer"
                customClass="input--answer"
                v-model="answers[index].answer"
                @blur="emitQuestion"
              ></baseInput>
            </label>
          </p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import baseInput from '../baseInput/baseInput.vue'
export default {
  components: {
    baseInput,
  },
  data() {
    return {
      question: '',
      answers: [
        { answer: '', rightAnswer: false },
        { answer: '', rightAnswer: false },
        { answer: '', rightAnswer: false },
        { answer: '', rightAnswer: false },
      ],
      selectedAnswer: '',
    }
  },
  methods: {
    emitQuestion() {
      if (this.isFormValid()) {
        const questionData = {
          question: this.question,
          answers: this.answers.map((answer) => ({
            answer: answer.answer.toString(),
            rightAnswer: answer.rightAnswer,
          })),
        }
        this.$emit('question-submitted', questionData)
      }
    },
    setRightAnswer(index) {
      this.answers.forEach((answer, i) => {
        answer.rightAnswer = i === index
      })
      this.emitQuestion()
    },
    isFormValid() {
      return (
        this.question.trim() !== '' &&
        this.answers.every(
          (answer) =>
            answer.answer.trim() !== '' && typeof answer.answer === 'string'
        ) &&
        this.answers.some((answer) => answer.rightAnswer === true)
      )
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/globals/variables';
@import '@/assets/scss/globals/mixins';
@import '@/assets/scss/globals/functions';
@import './questionCard';

.questionCard {
  &__questions {
    background-color: $strokeColor;
    padding: toRem(41) toRem(41);
    @include tablet {
      padding: toRem(41) toRem(31);
    }
  }
  &__title {
    display: block;
    width: 100%;
    margin-bottom: toRem(0);
  }
}
</style>
