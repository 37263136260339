export default {
  methods: {
    truncateText(text, maxLengthLaptop, maxLengthTablet, maxLengthMobile) {
      let maxLength
      const screenWidth = window.innerWidth

      if (screenWidth >= 1024) {
        maxLength = maxLengthLaptop
      } else if (screenWidth >= 768) {
        maxLength = maxLengthTablet
      } else {
        maxLength = maxLengthMobile
      }

      if (text.length > maxLength) {
        return text.substring(0, maxLength - 3) + '...'
      } else {
        return text
      }
    },
  },
}
