import { render, staticRenderFns } from "./editEventModal.vue?vue&type=template&id=ed22b2e8&scoped=true&"
import script from "./editEventModal.vue?vue&type=script&lang=js&"
export * from "./editEventModal.vue?vue&type=script&lang=js&"
import style0 from "./editEventModal.vue?vue&type=style&index=0&id=ed22b2e8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed22b2e8",
  null
  
)

export default component.exports