<template>
  <div id="myModal" class="modal" @click="closeModal">
    <div id="modal__dropdown">
      <div class="modal__title">
        <h3>{{ $t('singleCourse.add_requirement') }}</h3>

        <span id="close" @click="closeModal">&times;</span>
      </div>
      <form class="modal__form" @submit.prevent="addRequirement">
        <div class="modal__group">
          <baseInput
            id="field"
            type="text"
            :placeholder="$t('singleCourse.requirement_description')"
            v-model="newRequirement.field"
            customClass="input--modal"
            :applyErrorClass="$v.newRequirement.field.$error"
            @blur="$v.newRequirement.field.$touch()"
          ></baseInput
          ><template v-if="$v.newRequirement.field.$error"
            ><p class="error-msg" v-if="!$v.newRequirement.field.required">
              {{ $t('singleCourse.requirement_error') }}
            </p></template
          >
        </div>
        <div class="modal__group">
          <baseButton
            type="submit"
            buttonClass="button--primary button--modal"
            :disabled="isDisabled"
            >{{ $t('modals.submit') }}</baseButton
          >
          <p v-if="error" class="error-msg">{{ generalError }}</p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import baseInput from '@/components/baseInput/baseInput.vue'
import baseButton from '@/components/baseButton/baseButton.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    baseInput,
    baseButton,
  },
  data() {
    return {
      isDisabled: false,
      newRequirement: {
        field: '',
      },
      error: false,
      generalError: this.$t('errors.something_went_wrong'),
    }
  },
  props: {
    courseID: {
      type: String,
      default: '',
    },
  },
  validations() {
    return {
      newRequirement: {
        field: {
          required,
        },
      },
    }
  },
  methods: {
    async addRequirement() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      try {
        this.isDisabled = true
        await this.$store.dispatch('addRequirement', {
          courseID: this.courseID,
          requirement: this.newRequirement,
        })
        await this.$store.dispatch('fetchCourse', this.$route.params.id)
        this.closeModalAfterSubmit()
      } catch (err) {
        this.error = true
        this.isDisabled = false
      }
    },
    closeModal(event) {
      const isModalElement = event?.target?.id === 'myModal'
      const isCloseButtonElement = event?.target?.id === 'close'
      if (isModalElement || isCloseButtonElement) {
        this.$emit('close-modal')
        this.newRequirement = {
          field: '',
        }
        this.$v.$reset()
        this.isDisabled = false
      }
    },
    closeModalAfterSubmit() {
      this.$emit('close-modal')
      this.newRequirement = {
        field: '',
      }
      this.$v.$reset()
      this.isDisabled = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import './addRequirementModal';
</style>
