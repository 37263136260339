<template>
  <div class="container layout">
    <div class="userManagement">
      <div class="userManagement__title main-title">
        <h2>{{ $t('eventsPage.events_title') }}</h2>
      </div>
      <div class="userManagement__content content-layout">
        <div class="userManagement__tab--add-event">
          <baseButton class="button--primary u-btn" @click="addNewEvent">{{
            $t('eventsPage.add_new_event_btn')
          }}</baseButton>
        </div>
        <addEventModal ref="addEventRef"></addEventModal>
        <loaderElement v-if="isLoading" />
        <div class="userManagement__wrapper" v-if="!isLoading">
          <div class="userManagement__list" v-if="events.length > 0">
            <eventItem
              v-for="event in events"
              :key="event.uuid"
              :eventData="event"
              :optionList="eventOptions"
            ></eventItem>
          </div>
          <div class="courses__not-found" v-else>
            <div style="height: 400px">
              <img src="@/assets/pictures/no-data-found.jpg" />
            </div>
          </div>
          <transition name="modal-fade"
            ><baseModal
              v-if="showDeleteEvent"
              :user-firstname="selectedEventTitle"
              :content="showModalContent"
              @close-modal="showDeleteEvent = false"
              @confirm-action="deleteEvent()"
          /></transition>
          <transition name="modal-fade"
            ><editEventModal
              ref="editEventRef"
              :oldEvent="selectedEvent"
              :eventID="selectedEventID"
            ></editEventModal
          ></transition>

          <div class="userManagement__pagination" v-if="currentPage < lastPage">
            <baseButton
              class="button--load-more button--load"
              @click="loadMoreEvents"
              :disabled="isLoadingMore"
            >
              <div v-if="isLoadingMore" class="loader"></div>
              <div>{{ $t('eventsPage.load_more') }}</div>
            </baseButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import baseButton from '@/components/baseButton/baseButton.vue'
import addEventModal from '@/components/addEventModal/addEventModal.vue'
import eventItem from '@/components/eventItem/eventItem.vue'
import baseModal from '@/components/baseModal/baseModal.vue'
import editEventModal from '@/components/editEventModal/editEventModal.vue'
import loaderElement from '@/components/loader/loaderElement.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    baseButton,
    addEventModal,
    eventItem,
    baseModal,
    editEventModal,
    loaderElement,
  },
  data() {
    return {
      eventOptions: [
        {
          label: this.$t('eventsPage.delete_event'),
          action: this.handleDelete,
        },
        {
          label: this.$t('eventsPage.update_event'),
          action: this.handleUpdate,
        },
      ],
      showDeleteEvent: false,
      showEditEvent: false,
      selectedEvent: {},
      selectedEventID: '',
      selectedEventTitle: '',
      numberOfEvents: 4,
      page: 1,
      isLoading: false,
      isLoadingMore: false,
    }
  },
  computed: {
    ...mapGetters([
      'getEvents',
      'hasMorePages',
      'getEventsCurrentPage',
      'getEventsLastPage',
    ]),
    events() {
      return this.getEvents
    },
    currentPage() {
      return this.getEventsCurrentPage
    },
    lastPage() {
      return this.getEventsLastPage
    },
    morePages() {
      return this.hasMorePages
    },
  },
  methods: {
    addNewEvent() {
      this.$refs.addEventRef.openModal()
    },
    handleDelete(eventData) {
      this.selectedEventID = eventData.uuid
      this.selectedEventTitle = eventData.title
      this.showModalContent = {
        message: this.$t('showModalContent.delete_this_event'),
        submit: this.$t('showModalContent.delete'),
      }
      this.showDeleteEvent = !this.showDeleteEvent
    },
    async deleteEvent() {
      try {
        await this.$store.dispatch('deleteEvent', this.selectedEventID)
      } catch (err) {
        console.error(err)
      }
    },
    handleUpdate(eventData) {
      this.selectedEventID = eventData.uuid
      this.selectedEvent = eventData
      this.$refs.editEventRef.openModal()
    },
    async loadMoreEvents() {
      if (this.isLoadingMore) return
      this.isLoadingMore = true
      try {
        await this.$store.dispatch('fetchEvents', {
          numberOfEvents: this.numberOfEvents,
          page: ++this.page,
        })
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoadingMore = false
      }
    },
  },
  async created() {
    try {
      this.isLoading = true
      await this.$store.dispatch('fetchEvents', {
        numberOfEvents: this.numberOfEvents,
        page: this.page,
      })
      this.isLoading = false
    } catch (error) {
      console.error(error)
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/pages/eventsView';
</style>
