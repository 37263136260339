export default {
  student_role: 'Student',
  teacher_role: 'Instruktor',
  admin_role: 'Admin',
  super_admin_role: 'Super Admin',
  landing_page: 'Ballina',
  log_out: 'Shkyçu',
  log_in: 'Kyçu',
  settings: 'Përditëso',
  courses: 'Kurset',
  search_courses: 'Kërko kurset',
  all_categories: 'Kategoritë',
  sort_by: 'Sorto:',
  show: 'Shfaq:',
  sign_up: 'Regjistrohu',
  profile: 'Profili',
  see_all: 'Shfaqi të gjitha',
  footer:
    'E drejta e autorit © 2020 CreativeLayers. Të gjitha të drejtat e rezervuara.',
  errors: {
    something_went_wrong: 'Ka ndodhur një gabim. Provo më vonë',
    something_went_wrong_2: 'Diçka shkoi keq',
    empty_email: 'Emaili nuk mund të jetë bosh.',
    valid_email: 'Ju lutemi vendosni një email të vlefshëm',
    not_sent_email: 'Emaili nuk mund të dërgohet',
    nonexistent_user: 'Ky përdorues nuk ekziston.',
    active_acc: 'Kjo llogari është tashmë aktive.',
    error_ocurred: 'Ndodhi një gabim. Ju lutemi provoni përsëri.',
  },
  appHeader: {
    howdy: 'Çkemi',
    landing_page: 'Ballina',
    count: 'Numërimi',
    you_have: 'Ju keni',
    new_notifications: 'mesazhe të reja',
    new_notification: 'mesazh të ri',
    profile: 'Profili',
  },
  sideMenu: {
    dashboard: 'Paneli',
    participants: 'Pjesëmarrësit',
    categories: 'Kategoritë',
    events: 'Eventet',
    orders: 'Porositë',
    admins: 'Administratorët',
    instructors: 'Instruktorët',
    survey: 'Anketë',
  },
  modals: {
    first_name_req: 'Kërkohet emri ',
    last_name_req: 'Kërkohet mbiemri',
    user_req: 'Kërkohet perdoruesi',
    email_req: 'Kërkohet emaili',
    email_req_valid: 'Emaili duhet të jetë valid',
    add_new_admin: 'Shto një administrator të ri',
    not_valid_email: 'Emaili nuk është i vlefshëm',
    password_req: 'Kërkohet fjalëkalimi',
    password_longer: 'Fjalëkalimi duhet të jetë të paktën 6 karaktere i gjatë',
    password_syntax:
      ' Fjalëkalimi duhet të përmbajë një shkronjë të madhe, një shkronjë të vogël, një numër dhe një karakter të veçantë',
    passwords_match: 'Fjalëkalimet duhet të përputhen',
    submit: 'DËRGO',
    add_chapter: 'Shto kapitull',
    edit_chapter: 'Ndrysho kapitullin',
    chapter_name: 'Emri i kapitullit',
    chapter_desc: 'Përshkrimi i kapitullit',
    chapter_name_error: 'Kërkohet emri i kapitullit',
    chapter_desc_error: 'Kërkohet përshkrimi i kapitullit',
    add_category_title: 'Shto Kategori',
    category_name: 'Emri i kategorisë',
    category_name_error: 'Kërkohet emri i kategorisë',
    create_this_category: 'krijoni këtë kategori',
    create_this_category_btn: 'KRIJO',
    add_lesson_title: 'Shto mësim',
    edit_lesson_title: 'Ndrysho videon',
    lesson_name: 'Emri i videos',
    lesson_name_error: 'Kërkohet emri i videos',
    lesson_file_error: 'Kërkohet video file',
    lesson_link_error: 'Kërkohet video link',
    edit_user: 'Përditëso përdoruesin',
    change_role: 'Ndrysho Rolin',
  },
  baseButtons: {
    add_new_course: 'SHTO NJË KURS',
  },
  baseInputs: {},
  coursesPage: {
    no_courses_available: 'Nuk gjetëm kurse',
    started_at: 'Filluar nga',
    completed: 'Progresi',
    all_courses: 'Të gjitha kurset',
    delete_course: 'Fshi kursin',
    remove_from_view: 'Largo nga pamja',
  },
  dashboard: {
    all: 'Të gjitha',
    in_progress: 'Në progres',
    completed: 'Të përfunduara',
    course: 'Kurs',
    courses: 'Kurse',
    admins: 'Administratorë',
    instructors: 'Instruktorë',
    student: 'Student',
    students: 'Studentë',
    review: 'Vlerësim',
    reviews: 'Vlerësime',
    courses_completed: 'Kurset e përfunduara',
    courses_in_progress: 'Kurset në progres',
    quizzes_completed: 'Kuizet e përfunduara',
    top_rating_instructor: 'Instruktori më i vlerësuar',
    total_views: 'Shikimet totale',
  },
  categories: {
    categories: 'Kategoritë',
    add_new_category: 'SHTO NJË KATEGORI',
    delete: 'Fshi',
    delete_this_category: 'fshini këtë kategori:',
    no_categories_found: 'Nuk u gjetën kategoritë',
    edit_category: 'Ndrysho Kategorinë',
    category_required: 'Emri i kategorisë është i nevojshëm',
    edit_category_button: 'NDYSHO KATEGORINË',
  },
  settingsPage: {
    settings_title: 'Përditëso',
    bio_info: 'Biografia',
    first_name: 'Emri',
    last_name: 'Mbiemri',
    username: 'Përdoruesi',
    email: 'Email',
    female: ' Femër',
    male: ' Mashkull',
    gender: 'Zgjidh gjininë',
    save_btn: 'Ruaj ndryshimet',
    just_password: 'Fjalekalimi',
    old_password: 'Fjalëkalimi i vjetër',
    new_password: 'Fjalëkalimi i ri',
    confirm_password: 'Konfirmo fjalëkalimin',
    my_dashboard: 'Paneli im',
    public_your_profile: 'Publikoni panelin tuaj të profilit',
    my_courses: 'Kurset e mia',
    email_user_exist:
      'Emaili dhe përdoruesi tashmë ekzistojnë. Ju lutemi zgjidhni të tjerë.',
    email_exist:
      'Emaili tashmë ekziston. Ju lutemi zgjidhni një email të ndryshëm.',
    user_exist:
      'Përdoruesi tashmë ekziston. Ju lutemi zgjidhni një emër përdoruesi të ndryshëm.',
    fill_password_fields: 'Ju lutemi plotësoni të gjitha fushat e fjalëkalimit',
    old_pass_incorrect: 'Fjalëkalimi i vjetër është i pasaktë!',
    password_requirement:
      'Fjalëkalimi duhet të përmbajë një shkronjë të madhe, një shkronjë të vogël, një numër dhe një karakter të veçantë',
    password_should_match: 'Fjalëkalimi duhet të përputhet',
    confirm_pass_not_empty: 'Konfirmo fjalëkalimin nuk duhet të jetë zbrazët',
    new_pass_not_same:
      'Fjalëkalimi i ri nuk mund të jetë i njëjtë me fjalëkalimin e vjetër',
    invalid_file_type:
      'Lloji i skedarit është i pavlefshëm. Lejohen vetëm skedarët e imazhit.',
    general: 'Përgjithshme',
    avatar: 'Avatar',
    password: 'Fjalëkalimi',
    socials: 'Rrjete sociale',
  },
  eventsPage: {
    events_title: 'Eventet',
    add_new_event: 'Shto Eventin',
    add_new_event_btn: 'SHTO NJË EVENT',
    no_events: 'Nuk ka evente',
    load_more: 'SHFAQ MË SHUMË',
    event_title: 'Titulli i eventit',
    event_link: 'Linku i eventit',
    event_time: 'Koha',
    event_place: 'Vendi',
    edit_event: 'Nrysho eventin',
    submit_btn: 'DËRGO',
    title_req: 'Kërkohet titulli i eventit',
    url_req: 'Kërkohet linku i eventit',
    date_req: 'Kërkohet data e eventit',
    time_req: 'Kërkohet koha e eventit',
    place_req: 'Kërkohet vendi i eventit',
    image_req: 'Kërkohet imazhi i eventit',
    delete_event: 'Fshij eventin',
    update_event: 'Përditëso eventin',
    go_to_event: 'Shko te eventi',
    no_info: 'Nuk ka informacion',
  },
  authentication: {
    are_your_sure: 'A jeni të sigurt që dëshironi të dilni',
    log_out: 'Dil',
    cancel: 'Anulo',
    first_name: 'Emri',
    last_name: 'Mbiemri',
    user_name: 'Përdoruesi',
    password: 'Fjalëkalimi',
    confirm_password: 'Konfirmo fjalëkalimin',
    email: 'Email',
    genders: {
      male: 'Mashkull',
      female: 'Femër',
      other: 'Tjerë',
    },
    enter_email: 'Shkruani email-in tuaj për të rivendosur fjalëkalimin',
    go_to_login: 'Kyçu?',
    no_account: 'Nuk keni llogari?',
    already_have_account: 'Posedoni një llogari?',
    remember_me: 'Më mbaj në mend',
    forgot_password_1: 'Keni harruar',
    forgot_password_2: 'fjalëkalimin?',
    log_in_title: 'Kyçu në llogarinë tuaj',
    sign_up_title: 'Regjistrohu dhe fillo të mësosh',
    email_cannot_be_empty: 'Kërkohet email!',
    password_cannot_be_empty: 'Kërkohet fjalëkalimi!',
    confirm_password_cannot_be_empty: 'Kërkohet konfirmimi i fjalëkalimit!',
    passwords_not_match: 'Fjalëkalimet nuk përshtatën',
    password_could_not_update: 'Ndodhi një gabim. Fjalëkalimi nuk u ndryshua',
    enter_valid_email: 'Sheno email valide!',
    wrong_credentials: 'Kredencialet tuaja nuk janë të sakta!',
    error_occurred: 'Ndodhi një gabim. Ju lutemi provoni përsëri.',
    send_email: 'Dërgo',
    email_sent: 'Email-i është dërguar me sukses',
    email_not_sent: 'Email-i nuk mund të dërgohej',
    reset_password: 'Reseto fjalëkalimim',
    enter_new_password: 'Vendos fjalëkalimin e ri',
    password_changed_success: 'Fjalëkalimi u ndryshua me sukses',
    firstname_empty: 'Emri nuk mund të jetë zbrazët.',
    surname_empty: 'Mbiemri nuk mund të jetë zbrazët.',
    username_empty: 'Përdoruesi nuk mund të jetë zbrazët.',
    password_empty: 'Fjalëkalimi nuk mund të jetë zbrazët.',
    email_empty: 'Email-i nuk mund të jetë zbrazët.',
    confirmPassword_empty:
      'Konfirmimi i fjalëkalimit nuk mund të jetë zbrazët.',
    signup__error_password:
      'Fjalëkalimi duhet të ketë shkronja të mëdha, numra dhe simbole, me gjatësi prej 6 deri në 15 karaktere...',
    signup__error_password_match: 'Fjalëkalimet nuk përputhen',
    signup__error_email_exists: 'Email ose përdoruesi tashmë ekziston',
    email_exist: 'Email tashmë ekziston',
    username_exists: 'Përdoruesi tashmë ekziston',
    fill_all_fields: 'Ju lutemi plotësoni të gjitha fushat e kërkuara',
    account_not_approved_yet: 'Aplikimi juaj si instruktor është në pritje.',
    please_wait_for_approval: 'Ju lutemi prisni për aprovim!',
    i_understand: 'Në rregull',
    recover_your_account: 'Rikthe llogarinë tuaj',
    account_disabled: 'Kjo llogari është çaktivizuar. ',
    register_as_instructor: 'Si instruktor',
    register_as_student: 'Si student',
    select_category: 'Ju lutemi zgjidhni një kategori',
    invalid_token: 'Tokeni i pavlefshm për verifikimin e llogarisë!',
    invalid_code: 'Kodi i pavlefshëm për verifikimin e llogarisë!',
    enter_code: 'Vendoseni kodin',
    enter_4_digit_code:
      'Vendoseni kodin 4-shifror të dërguar në emailin tuaj për të verifikuar llogarinë',
    verify_acc: 'Ju lutemi verifikoni llogarinë tuaj!',
    check_inbox: 'Kontrollo emailin',
    clear: 'Fshij',
  },
  createCourse: {
    createCourse_title: 'Krijo Kursin',
    updateCourse_title: 'Ndrysho Kursin',
    course_title: 'Titulli i Kursit',
    course_title_error: 'Kërkohet emri i kursit',
    description: 'Përshkrimi',
    description_error: 'Kërkohet përshkrimi i kursit',
    learning_description: 'Përshkrimi i udhëzimeve',
    level: 'Niveli',
    level_error: 'Kërkohet përcaktimi i nivelit',
    language: 'Gjuha',
    language_error: 'Kërkohet përcaktimi i gjuhës',
    categories: 'Kategoritë',
    category_error: 'Këkohet përcaktimi i kategorisë',
    course_thumbnail: 'Imazhi i Kursit',
    course_thumbnail_error: 'Kërkohet zgjedhja e imazhit',
    course_learnings: 'Çka do të mësosh',
    course_learnings_error: 'Kërkohet vendosja e udhëzimeve',
    course_learnings_empty: 'Nuk ka udhëzime (Shto udhëzime në faqen e kursit)',
    delete: 'Fshij',
    add_learning: 'Shto një mësim tjetër',
    course_requirements: 'Kërkesat e Kursit',
    course_requirements_error: 'Kërkohet vendosja e kushtëzimeve',
    course_requirements_empty:
      'Nuk ka kushtëzime (Shto kushtëzime në faqen e kursit)',
    req_desc: 'Përshkrimi i Kërkesës',
    add_req: 'Shto një kërkesë tjetër',
    deadline: 'Afati',
    certificate: 'Çertifikata',
    price: 'Çmimi',
    submit: 'Dërgo',
    edit: 'Ndrysho',
    save: 'Ruaj',
  },
  singleCourse: {
    edit_course: 'Ndrysho kursin',
    delete_course: 'fshini kursin',
    course_trailer: 'Video Trailer i kursit',
    upload_video: 'Ngarko Video',
    video_name: 'Emri i Videos',
    video_yours: 'Pajisja jote',
    video_upload_succes: 'Videoja u ngarkua me sukses!',
    video_upload_error: 'Dështoi ngarkimi i videos',
    add_discount: 'Shto Zbritje',
    add_discount_title: 'Shto Përqindjen e Zbritjes',
    remove_discount: 'Largo Zbritjen',
    discount_error: 'Kërkohet përqindja e zbritjes',
    video_name_error: 'Kërkohet emri i videos',
    video_file_error: 'Kërkohet video file',
    video_link_error: 'Kërkohet video link',
    course_desc: 'Përshkrimi i kursit',
    what_you_will_learn: 'Çfarë do të mësosh',
    add_lesson: 'Shto video',
    no_videos: 'Ky kapitull nuk ka video',
    no_chapters: 'Ky kurs nuk ka kapituj',
    add_chapter: 'SHTO KAPITULL',
    student_reviews: 'Vlerësimet nga studentët',
    no_reviews: 'Ky kurs nuk ka vlerësime',
    comment_section: 'Shko në seksionin e komenteve',
    view_quiz: 'Shiko kuizin',
    create_quiz: 'Krijo kuizin',
    duration: 'Kohëzgjatja',
    enrolled: 'Ndjekësit',
    students: 'Studentë',
    student: 'Student',
    deadline: 'Afati',
    language: 'Gjuha',
    skill_level: 'Niveli',
    certificate: 'Çertifikata',
    yes: 'Po',
    no: 'Jo',
    overview: 'Përmbledhje',
    chapters: 'Kapitujt',
    reviews: 'Vlerësime',
    update_trailer: 'Përditëso videon e trailerit',
    delete_trailer: 'Fshi videon e trailerit',
    delete_trailer_content: 'fshini videon e trailerit',
    delete_learning: 'fshini udhëzimin',
    delete_requirement: 'fshini kërkesën',
    delete_chapter: 'fshini këtë kapitull',
    delete_lesson: 'fshini këtë video',
    delete_btn: 'FSHIJ',
    delete_msg: 'Kursi është fshi me sukses',
    free: 'Falas',
    off: 'Zbritje',
    add_title_comment: 'Shtoni një titull te komenti juaj...',
    add_desc_comment: 'Shtoni një përshkrim te komenti juaj...',
    comment: 'Komento',
    all_comment_in_course: 'Të gjitha komentet në këtë kurs',
    filter_by: 'Filtro sipas',
    my_comments: 'Komentet e Mia',
    all_comments: 'Të Gjitha Komentet',
    comment_section_2: 'Komentet',
    course_complete_evaluation: 'Rezultati i kuizit',
    get_certificate: 'Çertifikata',
    cancel: 'Anulo',
    reply: 'Përgjigju',
    replies: 'Përgjigje',
    reply_2: 'përgjigje',
    show_more_replies: 'Shfaq më shumë përgjigje',
    load_more: 'Shfaq më shumë',
    add_a_reply: 'Shto një përgjigje...',
    course_content: 'Përmbajtja e kursit',
    want_to_learn_more: 'Dëshironi të mësoni më shumë',
    of_students: 'studentë',
    no_orientation: 'Ky kurs nuk ka orientim',
    no_requirements: 'Ky kurs nuk ka kërkesa',
    no_trailer_video: 'Ky kurs nuk ka video trailer',
    add_orientation: 'Shto Udhëzime',
    requirements: 'Kërkesat',
    add_requirement: 'Shto Kërkesë',
    go_to_comment_section: 'Shko te komentet',
    learning_description: 'Përshkrimi i udhëzimeve',
    learning_error: ' Kërkohet përshkrimi i udhëzimeve ',
    requirement_description: 'Përshkrimi i Kërkesës',
    requirement_error: 'Përshkrimi i kërkesës është i nevojshëm',
    add_reviews_and_rate: 'Shto Vlerësime & Vlerëso',
    add_reviews_and_rate_p: 'Ju lutemi vlerësoni këtë kurs:',
    no_comment_section: 'Nuk u gjet asnjë koment.',
    review_title: 'Titulli i Vlerësimit',
    review_content: 'Përmbajtja e Vlerësimit',
    review_submit: 'DËRGO VLERËSIMIN',
    review_title_error: 'Ju lutemi shkruani titullin e vlerësimit',
    review_content_error: 'Ju lutemi shkruani përmbajtjen e vlerësimit',
    section: 'Kapitulli',
    like: 'Më pëlqen',
    unlike: "S'më pëlqen",
    edit_comment: 'Ndrysho',
    delete_comment: 'Fshij',
    course_completion_modal:
      'Ju lutem përfundoni Kursin në mënyrë që të mund të plotësoni Kuizin.',
  },
  createQuiz: {
    title: 'Kuizi',
    create_quiz_title: 'Krijo Kuiz',
    quiz_title: 'Titulli i Kuiz-it',
    enter_quiz_title: 'Vendosni Titullin e Kuiz-it',
    passing_percentage: 'Përqindja e Kalueshmërisë',
    enter_passing_percentage: 'Vendosni Përqindjen e Kalueshmërisë',
    passing_percentage_of_this_quiz: 'Përqindja e Kalimit për këtë quiz është:',
    add_question: 'SHTONI PYETJE TJETËR',
    submit_btn: 'DËRGO',
    fill_all_fields: 'Ju lutemi plotësoni të gjitha fushat e kërkuara',
    percentage_number: 'Përqindja e kalimit duhet të jetë një numër',
    write_correctly:
      'Ju lutemi shkruani pyetjet dhe përgjigjet tuaja në mënyrë të saktë',
    similar_question: 'Një pyetje e ngjashme tashmë ekziston për këtë kurs.',
    finish_attempt: 'Përfundo kuizin',
    min_three_questions: 'Kuizi duhet të ketë të paktën 3 pyetje',
    question: 'Pyetja',
    select_one: 'Zgjedh njërin opsion',
    clear_choice: 'Fshi zgjedhjen',
    quiz_error: 'Ju lutem të selektoni një pergjigje për çdo pyetje',
    edit_btn: 'Ndrysho',
    delete_btn: 'Fshi',
    save_changes: 'Ruaj ndryshimet',
    cancel_changes: 'Anulo ndryshimet',
    edit_quiz: 'NDYSHO KUIZIN',
    delete_quiz: 'FSHIJ KUIZIN',
    create_quiz: 'KRIJO KUIZIN',
    no_quiz_available: 'Nuk ka kuiz të disponueshëm',
    add_new_question: 'Shto Pyetje Të Re',
    submit_changes: 'DËRGO NDRYSHIMET',
    update: 'Perditëso',
    no_questions_quiz: 'Ky Kuiz Nuk Ka Pyetje',
  },
  tables: {
    admins: 'Administratorë',
    instructors: 'Instruktorët',
    students: 'Studentët',
    all: 'Të gjithë',
    approved: 'Miratuar',
    pending: 'Në pritje',
    disapproved: 'Pamiratuar',
    first_name: 'Emri',
    last_name: 'Mbiemri',
    user_name: 'Përdoruesi',
    email: 'Email',
    courses: 'Kurset',
    enrolled: 'Regjistruar',
    actions: 'Veprimet',
    edit: 'Perditëso',
    delete: 'Fshij',
    image: 'Foto',
    category_name: 'Emri i kategorisë',
    edit_btn: 'Ndrysho',
    delete_btn: 'Fshi',
    comment: 'Komenti',
    choose: 'Zgjedh',
    yes: 'Po',
    no: 'Jo',
    search_by_name: 'Kërko sipas emrit',
    search_by_course: 'Kërko sipas kursit...',
    select_course: 'Zgjidh Kursin',
    order: 'Porosia',
    date: 'Data',
    status: 'Statusi',
    total: 'Totali',
    no_orders_found: 'Nuk ka porosi',
    add_new_admin: 'SHTO ADMIN',
    select_month: ' Zgjedh muajin',
    select_year: 'Zgjedh vitin',
    apply: 'Apliko',
    active: 'Aktiv',
    inactive: 'Joaktiv',
    enable_btn: 'Aktivizo',
    disable_btn: 'Çaktivizo',
    approve_btn: 'Aprovo',
    disapprove_btn: 'Disaprovo',
    no_students_found: 'Nuk u gjetën studentë',
    no_data_found: 'Nuk u gjetën të dhëna',
    no_users_found: 'Nuk u gjetën përdorues',
    click_for_more: 'Kliko për më shumë',
    order_details: 'Detajet e Porosisë',
  },
  survey: {
    survey_title: 'Anketë',
    no_surveys_found: 'Nuk ka anketa',
    your_feedback: 'vlerësimin e juaj',
    feedback: 'Vlerësimi juaj',
    your_position: 'Profesioni juaj',
    submit_btn: 'Posto',
    update_btn: 'Ndrysho',
  },
  showModalContent: {
    are_you_sure: 'A jeni të sigurt që dëshironi të',
    out_of: 'nga',
    delete: 'FSHIJ',
    cancel: 'KTHEHU',
    change_role: 'NDRYSHO ROLIN',
    delete_this_question: 'fshini këtë pyetje',
    delete_this_comment: 'fshini këtë koment',
    delete_this_user: 'fshini këtë përdorues',
    delete_this_course: 'fshini këtë kurs',
    delete_this_event: 'fshini këtë event',
    delete_this_quiz: 'fshini këtë kuiz',
    quiz_deleted_success: 'Kuizi u fshi me sukses',
    change_instructor_role: 'ndrysho rolin e këtij instruktori',
    log_out: 'dilni nga llogaria',
    log_out_btn: 'DILNI',
    approve_this_instructor: 'miratoni këtë instruktor',
    approve: 'MIRATO',
    disapprove_this_instructor: 'disaprovoni këtë instruktor',
    disapprove: 'DISAPROVO',
    edit_quiz: 'Ndrysho Emrin e Kuizit',
    quiz_name: 'Emri i Kuizit',
    quiz_percentage: 'Perqindja e Kuizit',
    disable_this_user: 'çaktivizoni këtë përdorues',
    enable_this_user: 'aktivizoni këtë përdorues',
    disable_btn: 'ÇAKTIVO',
    enable_btn: 'AKTIVO',
  },
  time: {
    month: 'Muaj',
    day: ' ditë ',
    days: ' ditë ',
    hour: ' orë ',
    hours: ' orë ',
    less_than_an_hour: ' Më pak se një orë ',
  },
  instructorActions: {
    instructor: 'Instruktorin',
    first_name: 'Emri',
    last_name: 'Mbiemri',
    username: 'Përdoruesi',
    email: 'Email',
    category: 'Kategoria',
    action: 'Veprim',
    actionApprove: 'Aprovo',
    actionEnable: 'Mirato',
  },
  store: {
    add_category_success: 'Kategoria e re u shtua me sukses!',
    add_category_failed: 'Dështoi shtimi i kategorisë së re!',
    update_category_success: 'Kategoria u përditësua me sukses!',
    update_category_failed: 'Dështoi përditësimi i kategorisë!',
    delete_category_success: 'Kategoria u fshi me sukses!',
    delete_category_failed: 'Dështoi fshirja e kategorisë!',
    add_chapter_success: 'Kapitulli i ri u shtua me sukses!',
    add_chapter_failed: 'Dështoi shtimi i kapitullit të ri!',
    update_chapter_success: 'Kapitulli u përditësua me sukses!',
    update_chapter_failed: 'Dështoi përditësimi i kapitullit!',
    delete_chapter_success: 'Kapitulli u fshi me sukses!',
    delete_chapter_failed: 'Dështoi fshirja e kapitullit!',
    add_lesson_success: 'Mësimi i ri u shtua me sukses!',
    add_lesson_failed: 'Dështoi shtimi i mësimit të ri!',
    update_lesson_success: 'Mësimi u përditësua me sukses!',
    update_lesson_failed: 'Dështoi përditësimi i mësimit!',
    delete_lesson_success: 'Mësimi u fshi me sukses!',
    delete_lesson_failed: 'Dështoi fshirja e mësimit!',
    add_comment_success: 'Komenti u shtua me sukses!',
    add_comment_failed: 'Dështoi shtimi i komentit!',
    add_reply_success: 'Përgjigja u shtua me sukses!',
    add_reply_failed: 'Dështoi shtimi i përgjigjes!',
    fetch_comments_failed: 'Dështoi marrja e komenteve!',
    update_comment_success: 'Komenti u përditësua me sukses!',
    update_comment_failed: 'Dështoi përditësimi i komentit!',
    delete_comment_success: 'Komenti u fshi me sukses!',
    delete_comment_failed: 'Dështoi fshirja e komentit!',
    update_reply_success: 'Përgjigja u përditësua me sukses!',
    update_reply_failed: 'Dështoi përditësimi i përgjigjes!',
    delete_reply_success: 'Përgjigja u fshi me sukses!',
    delete_reply_failed: 'Dështoi fshirja e përgjigjes!',
    create_course_success: 'Kursi u krijua me sukses!',
    create_course_failed: 'Dështoi krijimi i kursit!',
    add_discount_success: 'Zbritja u shtua me sukses',
    add_discount_failed: 'Dështoi shtimi i zbritjes',
    remove_discount_success: 'Zbritja u hoq me sukses',
    remove_discount_failed: 'Dështoi heqja e zbritjes',
    update_course_success: 'Kursi u përditësua me sukses',
    update_course_failed: 'Dështoi përditësimi i kursit',
    delete_course_success: 'Kursi u fshi me sukses',
    delete_course_failed: 'Dështoi fshirja e kursit',
    delete_course_enrolled_error:
      'Ky kurs nuk mund të fshihet pasi ka studentë që e ndjekin atë',
    upload_course_success: 'Kursi u ngarkua me sukses',
    upload_course_failed: 'Dështoi ngarkimi i kursit',
    update_video_success: 'Videoja u përditësua me sukses',
    update_video_failed: 'Dështoi përditësimi i videos',
    delete_video_success: 'Videoja u fshi me sukses',
    delete_video_failed: 'Dështoi fshirja e videos',
    add_learning_success: 'Mësimi u shtua me sukses',
    add_learning_failed: 'Dështoi shtimi i mësimit',
    delete_learning_success: 'Mësimi u fshi me sukses',
    delete_learning_failed: 'Dështoi fshirja e mësimit',
    add_requirement_success: 'Kërkesa u shtua me sukses',
    add_requirement_failed: 'Dështoi shtimi i kërkesës',
    delete_requirement_success: 'Kërkesa u fshi me sukses',
    delete_requirement_failed: 'Dështoi fshirja e kërkesës',
    add_event_success: 'Ngjarja u shtua me sukses',
    add_event_failed: 'Dështoi shtimi i ngjarjes',
    add_event_name_error: 'Ekziston një ngjarje me këtë emërtim',
    update_event_success: 'Ngjarja u përditësua me sukses',
    update_event_failed: 'Dështoi përditësimi i ngjarjes',
    delete_event_success: 'Ngjarja u fshi me sukses',
    delete_event_failed: 'Dështoi fshirja e ngjarjes',
    approve_user_success: 'Përdoruesi u aprovua me sukses!',
    approve_user_failed: 'Dështoi aprovimi i përdoruesit!',
    disapprove_user_success: 'Përdoruesi u refuzua me sukses!',
    disapprove_user_failed: 'Dështoi refuzimi i përdoruesit!',
    enable_user_success: 'Përdoruesi u aktivizua me sukses!',
    enable_user_failed: 'Dështoi aktivizimi i përdoruesit!',
    disable_user_success: 'Përdoruesi u çaktivizua me sukses!',
    disable_user_failed: 'Dështoi çaktivizimi i përdoruesit!',
    get_orders_failed: 'Dështoi marrja e porosive!',
    complete_quiz_success: 'Kuizi u përfundua me sukses',
    create_quiz_success: 'Kuizi u krijua me sukses',
    create_quiz_failed: 'Dështoi krijimi i kuizit',
    update_quiz_success: 'Kuizi u përditësua me sukses',
    update_quiz_failed: 'Dështoi përditësimi i kuizit',
    delete_quiz_success: 'Kuizi u fshi me sukses',
    delete_quiz_failed: 'Dështoi fshirja e kuizit',
    update_question_success: 'Pyetja u përditësua me sukses',
    update_question_failed: 'Dështoi përditësimi i pyetjes',
    delete_question_success: 'Pyetja u fshi me sukses',
    delete_question_failed: 'Dështoi fshirja e pyetjes',
    add_question_success: 'Pyetja u shtua me sukses',
    add_feedback_success: 'Vlerësimi u shtua me sukses',
    add_feedback_failed: 'Dështoi shtimi i vlerësimit',
    update_feedback_success: 'Vlerësimi u përditësua me sukses',
    update_feedback_failed: 'Dështoi përditësimi i vlerësimit',
    submit_review_success: 'Përshtypja u dërgua me sukses',
    submit_review_failed: 'Dështoi dërgimi i përshtypjes',
    chose_survey_success: 'Anketa u zgjodh me sukses!',
    chose_survey_failed: 'Dështoi zgjedhja e anketeve!',
    save_changes_success: 'Ndryshimet u ruajtën me sukses!',
    save_changes_failed: 'Dështoi ruajtja e ndryshimeve',
    change_password_success: 'Fjalëkalimi u ndryshua me sukses',
    change_password_failed: 'Dështoi ndryshimi i fjalëkalimit',
    create_admin_success: 'Admini u krijua me sukses',
    create_admin_failed: 'Dështoi krijimi i adminit',
    change_role_success: 'Roli i përdoruesit u ndryshua me sukses',
    change_role_failed: 'Dështoi ndryshimi i rolit të përdoruesit',
    update_user_success: 'Përdoruesi u përditësua me sukses',
    update_user_failed: 'Dështoi përditësimi i përdoruesit',
    delete_user_success: 'Përdoruesi u fshi me sukses',
    delete_user_failed: 'Dështoi fshirja e përdoruesit',
  },
  password: {
    reset_your_password: 'Rivendosni Fjalëkalimin Tuaj',
    enter_new_password: 'Vendosni fjalëkalimin tuaj të ri',
    send: 'Dërgo',
    go_to_login: 'Kyçuni?',
    forgot_password: 'Keni harruar fjalëkalimin?',
    enter_email_to_reset_pw: 'Vendosni email-in tuaj për ta rivendosur',
    email_success_msg: 'Email-i u dërgua me sukses',
    pw_change_success_msg: 'Fjalëkalimi u ndryshua, u dërgua me sukses.',
  },
}
