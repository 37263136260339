<template>
  <div class="container layout">
    <div class="enable">
      <div class="enable__title main-title">
        <h2>{{ action }} {{ this.$t('instructorActions.instructor') }}</h2>
      </div>
      <div class="enable__content">
        <loaderElement v-if="isLoading" />
        <div v-show="!isLoading">
          <table>
            <thead>
              <tr class="enable__content-head">
                <th>{{ this.$t('instructorActions.first_name') }}</th>
                <th>{{ this.$t('instructorActions.last_name') }}</th>
                <th>{{ this.$t('instructorActions.username') }}</th>
                <th>{{ this.$t('instructorActions.email') }}</th>
                <th>{{ this.$t('instructorActions.category') }}</th>
                <th>{{ this.$t('instructorActions.action') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr class="enable__content-body">
                <td>{{ firstName }}</td>
                <td>{{ lastName }}</td>
                <td>{{ username }}</td>
                <td>{{ email }}</td>
                <td>
                  {{ categoryName }}
                </td>
                <td>
                  <baseButton
                    class="enable__button"
                    @click="instructor"
                    :disabled="isDisabled"
                    >{{ action }}</baseButton
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loaderElement from '@/components/loader/loaderElementVuex.vue'
import baseButton from '@/components/baseButton/baseButton.vue'

export default {
  components: {
    loaderElement,
    baseButton,
  },
  props: {
    action: String,
    firstName: String,
    lastName: String,
    username: String,
    email: String,
    categoryName: String,
    isDisabled: Boolean,
  },
  computed: {
    isLoading() {
      return this.getLoading
    },
  },
  methods: {
    instructor() {
      this.$emit('actionInstructor')
    },
  },
}
</script>

<style lang="scss" scoped>
@import './instructorActions.scss';
</style>
