<template>
  <div class="comment" :class="baseCommentClass">
    <div class="comment__section">
      <div class="comment__img">
        <img :src="getImagePath" alt="user" class="comment__img" />
      </div>
      <div class="comment__section-info">
        <div class="comment__section-top">
          <h5
            v-show="!showEditCommentInputs"
            :class="{ 'comment__section-top--h5': title }"
          >
            {{ title }}
          </h5>
          <p class="comment__section-top--text">
            {{ formattedEventDate }}
          </p>
        </div>
        <p v-show="!showEditCommentInputs">{{ description }}</p>
        <div
          class="comment__section-info--inputs"
          v-show="showEditCommentInputs"
        >
          <div class="inputWrapper">
            <baseInput
              v-if="!answer"
              id="questionTitle"
              type="text"
              customClass="input-comment"
              v-model="newTitle"
            >
            </baseInput>
          </div>
          <div class="inputWrapper">
            <baseInput
              id="questionDescription"
              type="text"
              customClass="input-comment"
              v-model="newDescription"
            ></baseInput>
          </div>
        </div>
        <div class="comment__section-info--flex">
          <h6>{{ firstName }} {{ lastName }}</h6>
          <div class="comment__section-info--likes">
            <p>{{ localVotes }}</p>
            <img
              :src="
                isLiked
                  ? require('@/assets/icons/unlikeComment.svg')
                  : require('@/assets/icons/likeComment.svg')
              "
              @click="like"
              @mouseover="showHover('like')"
              @mouseleave="hideHover('like')"
              :disabled="!isLoading"
            />
          </div>
          <div
            class="comment__section-info--hover"
            :class="{
              'comment__section-info--al': language == 'al',
            }"
            v-if="isHovered.like && !isLiked"
          >
            {{ $t('singleCourse.like') }}
          </div>
          <div
            class="comment__section-info--hover comment__section-info--left"
            :class="{
              'comment__section-info--al': language == 'al',
            }"
            v-if="isHovered.like && isLiked"
          >
            {{ $t('singleCourse.unlike') }}
          </div>
          <p
            class="comment__section-info--comment"
            @click="clickReply"
            v-if="isReply"
          >
            {{ $t('singleCourse.reply') }}
          </p>
        </div>
      </div>
    </div>
    <div class="comment__section--features">
      <buttonElement
        v-if="showEditCommentInputs"
        :class="{
          'button--updateComment button--updateComment__save':
            title !== newTitle || description !== newDescription,
          'button--updateComment button--updateComment__disable':
            title == newTitle || description == newDescription,
        }"
        @click="saveChanges"
      ></buttonElement>
      <div
        v-show="isHovered.edit"
        class="comment__section--edit"
        :class="{
          'comment__section--en': language == 'en',
        }"
      >
        {{ $t('singleCourse.edit_comment') }}
      </div>
      <buttonElement
        @mouseover="showHover('edit')"
        @mouseleave="hideHover('edit')"
        v-if="showEditAndDeleteButtons && !showEditCommentInputs"
        buttonClass="button--updateComment button--updateComment__edit"
        @click="showEditComment"
      ></buttonElement>
      <buttonElement
        @mouseover="showHover('delete')"
        @mouseleave="hideHover('delete')"
        v-if="showEditAndDeleteButtons"
        buttonClass="button--updateComment button--updateComment__delete"
        @click="showDeleteCommentModal"
      ></buttonElement>
      <div
        v-show="isHovered.delete"
        class="comment__section--edit comment__section--delete"
      >
        {{ $t('singleCourse.delete_comment') }}
      </div>
    </div>
    <transition name="modal-fade">
      <baseModal
        v-if="showDeleteComment"
        :content="showModalContent"
        @confirm-action="deleteComment()"
        @close-modal="showDeleteComment = false"
      />
    </transition>
  </div>
</template>

<script>
import baseModal from '@/components/baseModal/baseModal.vue'
import buttonElement from '@/components/baseButton/baseButton.vue'
import baseInput from '@/components/baseInput/baseInput.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    buttonElement,
    baseInput,
    baseModal,
  },
  data() {
    return {
      isLiked: false,
      localVotes: this.votes,
      isHovered: {
        like: false,
        edit: false,
        delete: false,
      },
      isLoading: false,
      newTitle: this.title,
      newDescription: this.description,
      showEditCommentInputs: false,
      showDeleteComment: false,
      showModalContent: {
        message: this.$t('showModalContent.delete_this_comment'),
        submit: this.$t('showModalContent.delete'),
      },
      language: localStorage.getItem('lang')
        ? localStorage.getItem('lang')
        : 'al',
    }
  },
  props: {
    image: String,
    title: String,
    description: String,
    firstName: String,
    lastName: String,
    uuid: String,
    votes: Number,
    baseCommentClass: String,
    isReply: Boolean,
    showEditAndDeleteButtons: {
      type: Boolean,
      required: true,
    },
    answer: {
      type: Boolean,
      default: false,
    },
    question: String,
    eventDate: String,
  },
  computed: {
    ...mapGetters(['getUserInfo']),
    getImagePath() {
      return this.image
        ? `https://api.lms.k-clients.com/public/images/${this.image}`
        : require('@/assets/pictures/avatar.png')
    },
    formattedEventDate() {
      const date = new Date(this.eventDate)
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      return `${day}/${month}/${year}`
    },
  },
  methods: {
    clickReply() {
      this.$emit('click-reply')
    },
    showHover(action) {
      this.isHovered[action] = true
    },
    hideHover(action) {
      this.isHovered[action] = false
    },
    async like() {
      if (this.isLoading) {
        return
      }
      this.isLoading = true
      try {
        if (this.isReply) {
          await this.$store.dispatch('postVote', this.uuid)
        } else {
          await this.$store.dispatch('postReplyVote', this.uuid)
        }
        this.isLiked = !this.isLiked
        this.isLiked ? this.localVotes++ : this.localVotes--
      } catch (error) {
        console.error('Error while liking/unliking:', error)
      } finally {
        this.isLoading = false
      }
    },
    showEditComment() {
      this.isHovered['edit'] = false
      this.showEditCommentInputs = true
    },
    async saveChanges() {
      try {
        if (this.answer === false) {
          if (
            this.title !== this.newTitle ||
            this.description !== this.newDescription
          ) {
            await this.$store.dispatch('updateCourseQuestion', {
              courseQuestionId: this.uuid,
              credentials: {
                title: this.newTitle,
                description: this.newDescription,
              },
            })
            await this.$store.dispatch('fetchCourseQuestion', this.uuid)
          }
        } else {
          const comment = this.newDescription
          if (this.description !== comment) {
            await this.$store.dispatch('updateCourseAnswer', {
              questionId: this.question,
              courseAnswerId: this.uuid,
              credentials: {
                comment: comment,
              },
            })
            this.$emit('updateReply', this.question, this.uuid, comment)
          }
        }
        this.showEditCommentInputs = false
      } catch (err) {
        console.error('Update question and answers Error', err)
      }
    },
    showDeleteCommentModal() {
      this.showDeleteComment = !this.showDeleteComment
    },
    async deleteComment() {
      try {
        if (this.answer == false) {
          await this.$store.dispatch('deleteCourseQuestion', this.uuid)
        } else {
          await this.$store.dispatch('deleteCourseAnswer', this.uuid)
          this.$emit('deleteReply', this.question, this.uuid)
        }
        this.showEditCommentInputs = false
      } catch (err) {
        console.error('Delete comment Error', err)
      }
    },
    confirmAction() {
      this.$emit('confirm-action')
    },
  },
  watch: {
    '$i18n.locale': function (newLocale) {
      if (newLocale) this.language = newLocale
    },
  },
  async mounted() {
    if (this.isReply) {
      let res = await this.$store.dispatch('isQuestionVoted', this.uuid)
      this.isLiked = res
    } else if (!this.isReply) {
      let res = await this.$store.dispatch('isAnswerVoted', this.uuid)
      this.isLiked = res
    } else {
      this.isLiked = false
    }
  },
}
</script>

<style lang="scss" scoped>
@import './baseComment';
</style>
