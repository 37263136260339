import api from '@/api/api'
import { successMessage, errorMessage } from '@/composables/useMessages'
import i18n from '@/main'

const chapters = {
  state: {
    chapters: [],
    chapter: null,
    chaptersAIInfo: {},
  },

  getters: {
    getChapters: (state) => state.chapters,
    getChapter: (state) => state.chapter,
    getChaptersAIInfo: (state) => state.chaptersAIInfo,
  },

  mutations: {
    SET_CHAPTERS(state, chapters) {
      state.chapters = chapters
    },
    SET_CHAPTER(state, chapter) {
      state.chapter = chapter
    },
    SET_CHAPTER_AI_INFO(state, object) {
      state.chaptersAIInfo = object
    },
  },

  actions: {
    async fetchChapters({ commit }, courseID) {
      try {
        const res = await api({ requiresAuth: true }).get(
          `/chapter/getByCourse/${courseID}`
        )
        commit('SET_CHAPTERS', res.data)
        return res.data
      } catch (error) {
        console.error(
          'GETTING COURSE CHAPTERS ERROR:',
          error.response.data.message
        )
        throw error
      }
    },
    async fetchChapter({ commit }, chapterID) {
      try {
        const res = await api({ requiresAuth: true }).get(
          `/chapter/${chapterID}`
        )
        commit('SET_CHAPTER', res.data)
        return res.data
      } catch (error) {
        console.error('GETTING CHAPTER ERROR:', error.response.data.message)
        throw error
      }
    },
    async addChapter(_, { courseID, chapterData }) {
      try {
        const res = await api({
          requiresAuth: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).post(`/chapter/${courseID}`, chapterData)
        successMessage.call(this, i18n.t('store.add_chapter_success'))
        return res.data
      } catch (error) {
        successMessage.call(this, i18n.t('store.add_chapter_failed'))
        console.error('CREATING CHAPTER ERROR:', error.response.data.message)
        throw error
      }
    },
    async editChapter(_, { chapterID, chapterData }) {
      try {
        const res = await api({ requiresAuth: true }).patch(
          `/chapter/${chapterID}`,
          chapterData
        )
        successMessage.call(this, i18n.t('store.update_chapter_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.update_chapter_success'))
        console.error('UPDATING CHAPTER ERROR:', error.response.data.message)
        throw error
      }
    },
    async deleteChapter(_, chapterID) {
      try {
        const res = await api({ requiresAuth: true }).delete(
          `/chapter/${chapterID}`
        )
        successMessage.call(this, i18n.t('store.delete_chapter_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.delete_chapter_failed'))
        console.error('DELETING CHAPTER ERROR:', error.response.data.message)
        throw error
      }
    },
    async addLesson(_, { chapterID, lessonData }) {
      try {
        const res = await api({
          requiresAuth: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).post(`/video/chapter/${chapterID}`, lessonData)
        successMessage.call(this, i18n.t('store.add_lesson_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.add_lesson_failed'))
        console.error('CREATING LESSON ERROR:', error.response.data.message)
        throw error
      }
    },
    async editLesson(_, { lessonID, lessonData }) {
      try {
        const res = await api({
          requiresAuth: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).patch(`/video/chapter/${lessonID}`, lessonData)
        successMessage.call(this, i18n.t('store.update_lesson_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.update_lesson_failed'))
        console.error('UPDATING LESSON ERROR:', error.response.data.message)
        throw error
      }
    },
    async deleteLesson(_, lessonID) {
      try {
        const res = await api({ requiresAuth: true }).delete(
          `/video/${lessonID}`
        )
        successMessage.call(this, i18n.t('store.delete_lesson_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.delete_lesson_failed'))
        console.error('DELETING LESSON ERROR:', error.response.data.message)
        throw error
      }
    },
    async getAIGeneratedInfo({ commit }, chapterId) {
      try {
        let language =
          localStorage.getItem('lang') == 'en' ? 'english' : 'albanian'
        const res = await api({ requiresAuth: true }).get(
          `/chapter/AIGeneratedInfo/${chapterId}?language=${
            language ? language : 'albanian'
          }`
        )
        const info = res.data
        commit('SET_CHAPTER_AI_INFO', { id: chapterId, info: info })
        return info
      } catch (error) {
        console.error(
          'GETTING AI GENERATED INFO ERROR:',
          error.response.data.message
        )
        throw error
      }
    },
  },
}

export default chapters
