<template>
  <div>
    <instructorActions
      :action="$t('instructorActions.actionApprove')"
      :firstName="instructorInfo.firstName"
      :lastName="instructorInfo.lastName"
      :username="instructorInfo.username"
      :email="instructorInfo.email"
      :categoryName="instructorInfo.instructor?.instructorCategory.name"
      @actionInstructor="approveUser(instructorInfo.uuid)"
      :isDisabled="disabled"
    />
  </div>
</template>

<script>
import instructorActions from '@/components/instructorActions/instructorActions.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    instructorActions,
  },
  data() {
    return {
      disabled: false,
    }
  },
  computed: {
    ...mapGetters(['getInstrInfo']),
    instructorInfo() {
      return this.getInstrInfo
    },
  },
  async created() {
    await this.$store.dispatch('fetchInstrInfo', this.$route.params.userId)
    this.disabled = this.instructorInfo.instructor.isAdminApproved
  },
  methods: {
    async approveUser(uuid) {
      await this.$store.dispatch('approveInstructor', uuid)
      this.disabled = !this.instructorInfo.instructor?.isAdminApproved
    },
  },
}
</script>
