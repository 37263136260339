<template>
  <div>
    <div class="content__language">
      <languageDropdown />
    </div>
    <div class="verify-account">
      <h1>{{ $t('authentication.enter_code') }}</h1>
      <p>
        {{ $t('authentication.enter_4_digit_code') }}
      </p>
      <div class="verify-account__wrapper">
        <v-otp-input
          ref="otpInput"
          input-classes="otp-input"
          separator="-"
          :num-inputs="4"
          :should-auto-focus="true"
          :is-input-num="true"
          @on-change="handleOnChange"
          @on-complete="handleOnComplete"
        />

        <button @click="handleClearInput()" class="verify-account__btn">
          {{ $t('authentication.clear') }}
        </button>
      </div>
      <div class="errors">
        <p v-show="generalError">{{ generalError }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import languageDropdown from '@/components/languageDropdown/languageDropdown.vue'

export default {
  components: {
    languageDropdown,
  },
  data() {
    return {
      generalError: '',
    }
  },
  methods: {
    async handleOnComplete(value) {
      const payload = {
        token: this.$route.params.token,
        data: {
          code: value,
        },
      }

      try {
        let res = await this.$store.dispatch('verifyAccount', payload)
        if (res.status == 201) {
          localStorage.setItem('user', res.data.accessToken)
          localStorage.setItem('role', res.data.user.role)
        }
        setTimeout(() => {
          this.$router.push('/')
        }, 1000)
      } catch (error) {
        if (
          error.response.data.message ==
          'Invalid code for account verification!'
        ) {
          this.generalError = this.$t('authentication.invalid_code')
        } else if (
          error.response.data.message == 'Invalid token for verifying account!'
        ) {
          this.generalError = this.$t('authentication.invalid_token')
        } else if (
          error.response.data.message ==
          'This account has not been approved by admin yet. Please wait for admin approval!'
        ) {
          setTimeout(() => {
            this.$router.push('/login')
          }, 1000)
        }
      }
    },
    handleOnChange() {
      this.generalError = ''
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput()
      this.generalError = ''
    },
  },
}
</script>

<style lang="scss">
@import '../assets/scss/pages/verifyAccount';
</style>
