<template>
  <div class="container layout">
    <div class="userManagement">
      <div class="userManagement__title main-title">
        <h2 v-if="isSuperAdmin">{{ $t('tables.admins') }}</h2>
        <h2 v-if="isAdmin">{{ $t('tables.instructors') }}</h2>
      </div>
      <div class="userManagement__content content-layout">
        <ul class="userManagement__tabs">
          <li
            v-for="(tab, index) in filteredTabs"
            :key="index"
            class="userManagement__tab"
            :class="{ 'userManagement__tab--active': activeTab === index }"
            @click.prevent="setActiveTab(index)"
          >
            {{ tab }}
          </li>
        </ul>
        <loaderElement v-if="isLoading" />
        <div v-else>
          <div v-show="!isLoading">
            <div class="userManagement__tab--add-admin">
              <baseButton
                v-if="isSuperAdmin"
                buttonClass="button--primary u-btn btn--admin"
                @click="addNewAdmin"
                >{{ $t('tables.add_new_admin') }}</baseButton
              >
            </div>
            <div class="userManagement__wrap">
              <baseTable
                :tableData="getTableData(activeTab)"
                :columns="getTableColumns(activeTab)"
                :name="isSuperAdmin ? 'users' : 'instructors'"
                :fetchFunction="getTableFetchFunction(activeTab)"
                :noDataMessage="noDataMessage"
              ></baseTable>
            </div>
          </div>
        </div>
      </div>
    </div>

    <addAdmin
      ref="addAdminRef"
      class="userManagement__tab--add-admin__dropdown"
      @close-modal="addAdmin = false"
    ></addAdmin>

    <transition name="modal-fade">
      <editUser
        ref="editUserRef"
        :user="selectedUser"
        :user-id="selectedUserId"
        :user-role="selectedUserRole"
        @close-modal="showEditUser = false"
      />
    </transition>
    <transition name="modal-fade">
      <baseModal
        v-if="openModal"
        :user-id="selectedUserId"
        :user-firstname="selectedUserFirstName"
        :user-lastname="selectedUserLastName"
        :content="showModalContent"
        :action="action"
        @confirm-action="handleUser(selectedUserId, action)"
        @close-modal="openModal = false"
      />
    </transition>
  </div>
</template>

<script>
import baseButton from '@/components/baseButton/baseButton.vue'
import baseTable from '@/components/baseTable/baseTable.vue'
import addAdmin from '@/components/addAdminModal/addAdminModal.vue'
import editUser from '@/components/editUserModal/editUserModal.vue'
import baseModal from '@/components/baseModal/baseModal.vue'
import loaderElement from '@/components/loader/loaderElementVuex.vue'
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  components: {
    baseButton,
    baseTable,
    editUser,
    addAdmin,
    baseModal,
    loaderElement,
  },
  data() {
    return {
      isLoading: false,
      tabs: [
        this.$t('tables.all'),
        this.$t('tables.approved'),
        this.$t('tables.pending'),
        this.$t('tables.disapproved'),
      ],
      enable: [],
      activeTab: 0,
      addAdmin: false,
      showEditUser: false,
      openModal: false,
      showModalContent: {
        message: 'delete this user',
        submit: 'DELETE',
      },
      action: '',
      selectedUser: {},
      selectedUserId: '',
      selectedUserRole: 0,
      noDataMessage: this.$t('tables.no_users_found'),

      usersColumns: [
        {
          field: 'firstName',
          key: 'b',
          title: this.$t('tables.first_name'),
          align: 'left',
          sortBy: '',
        },
        {
          field: 'lastName',
          key: 'c',
          title: this.$t('tables.last_name'),
          align: 'left',
        },
        {
          field: 'username',
          key: 'd',
          title: this.$t('tables.user_name'),
          align: 'left',
        },
        {
          field: 'email',
          key: 'e',
          title: this.$t('tables.email'),
          align: 'left',
        },
        {
          field: 'edit',
          key: 'f',
          title: this.$t('tables.actions'),
          align: 'center',
          renderBodyCell: ({ rowIndex }) => {
            const user = this.usersData[rowIndex]
            return (
              <span>
                <button
                  class="button button--edit"
                  on-click={() => this.showModal(user, 'edit')}
                >
                  {user ? this.$t('tables.edit') : 'Edit'}
                </button>
                &nbsp;
                <button
                  class="button button--delete"
                  on-click={() => this.showModal(user, 'delete')}
                >
                  {user ? this.$t('tables.delete') : 'Delete'}
                </button>
              </span>
            )
          },
        },
      ],
      pendingInstructorsColumns: [
        {
          field: 'firstName',
          key: 'b',
          title: this.$t('tables.first_name'),
          align: 'left',
          // sortBy: '',
        },
        {
          field: 'lastName',
          key: 'c',
          title: this.$t('tables.last_name'),
          align: 'left',
        },
        {
          field: 'username',
          key: 'd',
          title: this.$t('tables.user_name'),
          align: 'left',
        },
        {
          field: 'email',
          key: 'e',
          title: this.$t('tables.email'),
          align: 'left',
        },
        {
          field: 'edit',
          key: 'f',
          title: this.$t('tables.actions'),
          align: 'center',
          renderBodyCell: ({ rowIndex }) => {
            const user = this.pendingInstructors[rowIndex]
            return (
              <span>
                <button
                  class="button button--edit"
                  on-click={() => this.showModal(user, 'approve')}
                >
                  {user ? this.$t('tables.approve_btn') : ' Approve'}
                </button>
                &nbsp;
                <button
                  class="button button--delete"
                  on-click={() => this.showModal(user, 'disapprove')}
                >
                  {user ? this.$t('tables.disapprove_btn') : ' Disapprove'}
                </button>
              </span>
            )
          },
        },
      ],
      approvedInstructorsColumns: [
        {
          field: 'firstName',
          key: 'b',
          title: this.$t('tables.first_name'),
          align: 'left',
          // sortBy: 'asc',
        },
        {
          field: 'lastName',
          key: 'c',
          title: this.$t('tables.last_name'),
          align: 'left',
        },
        {
          field: 'username',
          key: 'd',
          title: this.$t('tables.user_name'),
          align: 'left',
        },
        {
          field: 'email',
          key: 'e',
          title: this.$t('tables.email'),
          align: 'left',
        },
        {
          field: 'isActive',
          key: 'h',
          title: 'Status',
          align: 'left',
          renderBodyCell: ({ rowIndex }) => {
            const data = this.approvedInstructors[rowIndex].instructor.isActive
            return data ? this.$t('tables.active') : this.$t('tables.inactive')
          },
        },
        {
          field: 'edit',
          key: 'f',
          title: this.$t('tables.actions'),
          align: 'center',
          renderBodyCell: ({ rowIndex }) => {
            const user = this.approvedInstructors[rowIndex]
            const imageUrl = user?.instructor.isActive
              ? require('../assets/icons/toggle_on.png')
              : require('../assets/icons/toggle_off.png')
            const enableText = user?.instructor.isActive
              ? this.$t('tables.disable_btn')
              : this.$t('tables.enable_btn')
            return (
              <span>
                <button
                  class="button--enable"
                  on-click={() =>
                    user.instructor.isActive
                      ? this.showModal(user, 'disable')
                      : this.showModal(user, 'enable')
                  }
                >
                  <span>
                    <img
                      onmouseover={() => this.showHover(user.uuid)}
                      onmouseleave={() => this.hideHover(user.uuid)}
                      src={imageUrl}
                      alt="Category Image"
                    ></img>
                  </span>
                  {this.enable[user.uuid] ? (
                    <div class="button--enable-hover">{enableText} </div>
                  ) : (
                    ''
                  )}
                </button>
                &nbsp;
                <button
                  class="button button--edit-icon"
                  on-click={() => this.showModal(user, 'edit')}
                >
                  <span>{user ? this.$t('tables.edit') : 'Edit'}</span>
                </button>
              </span>
            )
          },
        },
      ],
      disapprovedInstructorsColumns: [
        {
          field: 'firstName',
          key: 'b',
          title: this.$t('tables.first_name'),
          align: 'left',
          // sortBy: '',
        },
        {
          field: 'lastName',
          key: 'c',
          title: this.$t('tables.last_name'),
          align: 'left',
        },
        {
          field: 'username',
          key: 'd',
          title: this.$t('tables.user_name'),
          align: 'left',
        },
        {
          field: 'email',
          key: 'e',
          title: this.$t('tables.email'),
          align: 'left',
        },
        {
          field: 'edit',
          key: 'f',
          title: this.$t('tables.actions'),
          align: 'center',
          renderBodyCell: ({ rowIndex }) => {
            const user = this.disapprovedInstructors[rowIndex]
            return (
              <span>
                <button
                  class="button button--edit"
                  on-click={() => this.showModal(user, 'approve')}
                >
                  {user ? this.$t('tables.approve_btn') : ' Approve'}
                </button>
              </span>
            )
          },
        },
      ],
    }
  },
  computed: {
    ...mapState('language', ['currentLanguage']),
    ...mapGetters([
      'getUsers',
      'getPendingInstructors',
      'getDisapprovedInstructors',
      'getApprovedInstructors',
      'getUserInfo',
      'getLoadingInstructors',
      'getLoading',
    ]),
    userInfo() {
      return this.getUserInfo
    },
    usersData() {
      if (Array.isArray(this.getUsers)) {
        return this.getUsers
      } else {
        return []
      }
    },
    pendingInstructors() {
      if (Array.isArray(this.getPendingInstructors)) {
        return this.getPendingInstructors
      } else {
        return []
      }
    },
    approvedInstructors() {
      if (Array.isArray(this.getApprovedInstructors)) {
        return this.getApprovedInstructors
      } else {
        return []
      }
    },
    disapprovedInstructors() {
      if (Array.isArray(this.getDisapprovedInstructors)) {
        return this.getDisapprovedInstructors
      } else {
        return []
      }
    },
    filteredTabs() {
      if (this.isAdmin) {
        return this.tabs.filter((tab) => tab !== this.$t('tables.all'))
      } else {
        return this.tabs.filter(
          (tab) =>
            tab !== this.$t('tables.pending') &&
            tab !== this.$t('tables.disapproved') &&
            tab != this.$t('tables.approved')
        )
      }
    },
    filteredUsersColumns() {
      return this.usersColumns
    },
    filteredPendingInstructorsColumns() {
      return this.pendingInstructorsColumns
    },
    filteredDisapprovedInstructorsColumns() {
      return this.disapprovedInstructorsColumns
    },
    filteredApprovedInstructorsColumns() {
      return this.approvedInstructorsColumns
    },
  },

  methods: {
    ...mapActions([
      'fetchAdmins',
      'fetchPendingInstructors',
      'fetchApprovedInstructors',
      'fetchDisapprovedInstructors',
      'deleteUser',
    ]),
    showHover(uuid) {
      this.$set(this.enable, uuid, true)
      this.enable[uuid] = true
    },
    hideHover(uuid) {
      this.$set(this.enable, uuid, false)
    },
    async setActiveTab(index) {
      this.activeTab = index
      if (this.$route.query.page != 1) {
        this.$router.push({ query: { page: 1 } })
      }
      await this.$store.dispatch('setPage', 1)
      await this.$store.dispatch('setSearchQuery', '')
      this.fetchDataBasedOnTab()
    },
    async fetchDataBasedOnTab() {
      await this.$store.dispatch('setPage', 1)
      const fetchFunction = this.getTableFetchFunction(this.activeTab)
      if (fetchFunction) {
        await this.$store.dispatch('setPage', 1)
        await this[fetchFunction]()
      }
    },
    getTableData(tabIndex) {
      switch (tabIndex) {
        case this.filteredTabs.indexOf(this.$t('tables.all')):
          return this.usersData
        case this.filteredTabs.indexOf(this.$t('tables.approved')):
          return this.approvedInstructors
        case this.filteredTabs.indexOf(this.$t('tables.pending')):
          return this.pendingInstructors
        case this.filteredTabs.indexOf(this.$t('tables.disapproved')):
          return this.disapprovedInstructors
        default:
          return []
      }
    },
    getTableColumns(tabIndex) {
      switch (tabIndex) {
        case this.filteredTabs.indexOf(this.$t('tables.all')):
          return this.filteredUsersColumns
        case this.filteredTabs.indexOf(this.$t('tables.approved')):
          return this.filteredApprovedInstructorsColumns
        case this.filteredTabs.indexOf(this.$t('tables.pending')):
          return this.pendingInstructorsColumns
        case this.filteredTabs.indexOf(this.$t('tables.disapproved')):
          return this.disapprovedInstructorsColumns
        default:
          return []
      }
    },
    getTableFetchFunction(tabIndex) {
      switch (tabIndex) {
        case this.filteredTabs.indexOf(this.$t('tables.all')):
          return 'fetchAdmins'
        case this.filteredTabs.indexOf(this.$t('tables.approved')):
          return 'fetchApprovedInstructors'
        case this.filteredTabs.indexOf(this.$t('tables.pending')):
          return 'fetchPendingInstructors'
        case this.filteredTabs.indexOf(this.$t('tables.disapproved')):
          return 'fetchDisapprovedInstructors'
        default:
          return ''
      }
    },
    addNewAdmin() {
      this.addAdmin = !this.addAdmin
      this.$refs.addAdminRef.openModal()
    },
    showModal(user, action) {
      this.selectedUser = user
      this.selectedUserId = user.uuid
      this.selectedUserFirstName = user.firstName
      this.selectedUserLastName = user.lastName
      this.selectedUserRole = user.role
      this.action = action
      if (this.action != 'edit') {
        this.openModal = !this.openModal
      }

      switch (action) {
        case 'delete':
          this.showModalContent = {
            message: this.$t('showModalContent.delete_this_user'),
            submit: this.$t('showModalContent.delete'),
          }
          break
        case 'enable':
          this.showModalContent = {
            message: this.$t('showModalContent.enable_this_user'),
            submit: this.$t('showModalContent.enable_btn'),
          }
          break
        case 'disable':
          this.showModalContent = {
            message: this.$t('showModalContent.disable_this_user'),
            submit: this.$t('showModalContent.disable_btn'),
          }
          break
        case 'approve':
          this.showModalContent = {
            message: this.$t('showModalContent.approve_this_instructor'),
            submit: this.$t('showModalContent.approve'),
          }
          break
        case 'disapprove':
          this.showModalContent = {
            message: this.$t('showModalContent.disapprove_this_instructor'),
            submit: this.$t('showModalContent.disapprove'),
          }
          break
        case 'edit':
          this.showEditUser = !this.showEditUser
          this.$refs.editUserRef.openModal()
          break
        default:
          console.error('Unhandled action: ' + action)
      }
    },

    handleUser(user, action) {
      switch (action) {
        case 'delete':
          this.deleteUser(user)
          break
        case 'enable':
          this.enableUser(user)
          break
        case 'disable':
          this.disableUser(user)
          break
        case 'approve':
          this.approveInstructor(user)
          break
        case 'disapprove':
          this.disapproveInstructor(user)
          break
        default:
          console.error('Unhandled action: ' + action)
      }
    },
    async deleteUser(userId) {
      try {
        await this.$store.dispatch('deleteUser', userId)
        if (this.userInfo.role === 2) {
          this.fetchPendingInstructors()
          this.fetchAdmins()
        } else this.fetchAdmins()
      } catch (err) {
        console.error('Delete User Error', err)
      }
    },
    async disableUser(userId) {
      await this.$store.dispatch('disableInstructor', userId)
      this.fetchApprovedInstructors()
    },
    async enableUser(userId) {
      await this.$store.dispatch('enableInstructor', userId)
      this.fetchApprovedInstructors()
    },
    async approveInstructor(userId) {
      try {
        await this.$store.dispatch('approveInstructor', userId)
        this.fetchDataBasedOnTab()
        this.getDisapprovedInstructors
      } catch (err) {
        console.error('Approve Instrucor Error', err)
      }
    },
    async disapproveInstructor(userId) {
      try {
        await this.$store.dispatch('disapproveInstructor', userId)
        this.fetchDataBasedOnTab()
        this.getDisapprovedInstructors
      } catch (err) {
        console.error('Approve Instructor Error', err)
      }
    },
  },
  watch: {
    async currentLanguage() {
      try {
        await this.$store.dispatch('setPage', 1)
        const fetchFunction = this.getTableFetchFunction(this.activeTab)
        if (fetchFunction) {
          await this[fetchFunction]()
        }
      } catch (error) {
        console.error(error)
      }
      this.fetchDataBasedOnTab()
    },
    '$i18n.locale': function (newLocale) {
      if (newLocale)
        this.tabs = [
          this.$t('tables.all'),
          this.$t('tables.approved'),
          this.$t('tables.pending'),
          this.$t('tables.disapproved'),
        ]
    },
  },
  async mounted() {
    try {
      this.isLoading = true
      await this.$store.dispatch(
        'setPage',
        parseInt(this.$route.query.page) || 1
      )
      await this.$store.dispatch('fetchUserInfo')
      const fetchFunction = this.getTableFetchFunction(this.activeTab)
      if (fetchFunction) {
        await this[fetchFunction]()
      }

      this.isLoading = false
    } catch (error) {
      console.error(error)
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/pages/userManagement';
</style>
