<template>
  <transition name="modal-fade">
    <div id="myModal" class="modal" @click="closeModal">
      <div id="modal__dropdown" @click.stop>
        <div class="modal__close">
          <span id="close" @click="closeModal">&times;</span>
        </div>
        <div class="modal__content">
          <slot name="modalContent"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'modalBase',
  data() {
    return {
      generalError: '',
      error: false,
      userID: null,
      userFirstName: null,
      userLastName: null,
    }
  },
  methods: {
    confirmAction() {
      this.$emit('confirm-action')
      this.clearGeneralError()
      this.startCloseTimer()
    },
    clearGeneralError() {
      this.generalError = ''
      this.error = false
    },
    startCloseTimer() {
      setTimeout(() => {
        this.$emit('close-modal')
      }, 3000)
    },
  },
}
</script>

<style lang="scss" scoped>
@import './modalBase';
</style>
