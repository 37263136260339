import api from '@/api/api'

const enrolledCourses = {
  state: {
    enrolledCourses: [],
    enrolledCoursesLength: null,
    lastPage: null,
    countCompletedQuizzes: 0,
    completeCourses: null,
    inProgressCourses: null,
    courseCompletedStatus: null,
  },

  getters: {
    getEnrolledCourses: (state) => state.enrolledCourses,
    enrolledCoursesLength: (state) => state.enrolledCoursesLength,
    getLastPageStudent: (state) => state.lastPage,
    getCompletedQuizzes: (state) => state.countCompletedQuizzes,
    getCoursesCompleted: (state) => state.completeCourses,
    getCoursesInProgress: (state) => state.inProgressCourses,
    getCoursesCompletedStatus: (state) => state.courseCompletedStatus,
  },

  mutations: {
    SET_ENROLLED_COURSES(state, { coursesLength, courses, totalCount = null }) {
      state.enrolledCourses = courses
      state.enrolledCoursesLength = coursesLength
      state.lastPage = totalCount
    },
    SET_COMPLETED_QUIZZES(state, countQuizzes) {
      state.countCompletedQuizzes = countQuizzes
    },
    SET_COURSES_COMPLETED(state, data) {
      state.completeCourses = data
    },
    SET_COURSES_IN_PROGRESS(state, data) {
      state.inProgressCourses = data
    },
    SET_COURSES_COMPLETED_STATUS(state, data) {
      state.courseCompletedStatus = data
    },
  },
  actions: {
    async fetchEnrolledCourses({ commit }, { searchParams, page, limit }) {
      if (searchParams) {
        try {
          const res = await api({ requiresAuth: true }).get(
            `/course/search/EnrolledCourses?name=${searchParams}&page=${page}&limit=${limit}`
          )
          commit('SET_ENROLLED_COURSES', {
            coursesLength: res.data.totalCount,
            courses: res.data.data,
            totalCount: res.data.lastPage,
          })
          return res
        } catch (error) {
          console.error('GETTING COURSES ERROR:', error.response.data.message)
        }
      } else {
        try {
          const res = await api({ requiresAuth: true }).get(
            `/course/search/EnrolledCourses?page=${page}&limit=${limit}`
          )
          commit('SET_ENROLLED_COURSES', {
            coursesLength: res.data.totalCount,
            courses: res.data.data,
            totalCount: res.data.lastPage,
          })
          return res
        } catch (error) {
          console.error('GETTING COURSES ERROR:', error.response.data.message)
        }
      }
    },
    async fetchProgressedCourses({ commit }, { searchParams, page, limit }) {
      if (searchParams) {
        try {
          const res = await api({ requiresAuth: true }).get(
            `/enroll/incompleteCourses?name=${searchParams}&page=${page}&limit=${limit}`
          )
          commit('SET_ENROLLED_COURSES', {
            courses: res.data.data,
            totalCount: res.data.lastPage,
          })
          commit('SET_PROGRESS_COUNT', {
            progressCourses: res.data.totalCount,
          })
          return res
        } catch (error) {
          console.error('GETTING COURSES ERROR:', error.response.data.message)
        }
      } else {
        try {
          const res = await api({ requiresAuth: true }).get(
            `/enroll/incompleteCourses?page=${page}&limit=${limit}`
          )
          commit('SET_ENROLLED_COURSES', {
            courses: res.data.data,
            totalCount: res.data.lastPage,
          })
          return res
        } catch (error) {
          console.error('GETTING COURSES ERROR:', error.response.data.message)
        }
      }
    },
    async fetchCompletedCourses({ commit }, { searchParams, page, limit }) {
      if (searchParams) {
        try {
          const res = await api({ requiresAuth: true }).get(
            `/enroll/completedCourses?name=${searchParams}&page=${page}&limit=${limit}`
          )
          commit('SET_ENROLLED_COURSES', {
            courses: res.data.data,
            totalCount: res.data.lastPage,
          })
          commit('SET_PROGRESS_COUNT', {
            progressCourses: res.data.totalCount,
          })
          return res
        } catch (error) {
          console.error('GETTING COURSES ERROR:', error.response.data.message)
        }
      } else {
        try {
          const res = await api({ requiresAuth: true }).get(
            `/enroll/completedCourses?page=${page}&limit=${limit}`
          )
          commit('SET_ENROLLED_COURSES', {
            courses: res.data.data,
            totalCount: res.data.lastPage,
          })
          return res
        } catch (error) {
          console.error('GETTING COURSES ERROR:', error.response.data.message)
        }
      }
    },
    async filterEnrolledCourses(
      { commit },
      { categoryId, searchParams, page, limit }
    ) {
      if (searchParams) {
        try {
          const res = await api({ requiresAuth: true }).get(
            `/course/search/EnrolledCourses?categoryId=${categoryId}&name=${searchParams}&page=${page}&limit=${limit}`
          )
          commit('SET_ENROLLED_COURSES', {
            courses: res.data.data,
            totalCount: res.data.lastPage,
          })
          return res
        } catch (error) {
          console.error('GETTING COURSES ERROR:', error.response.data.message)
        }
      } else {
        try {
          const res = await api({ requiresAuth: true }).get(
            `/course/search/EnrolledCourses?categoryId=${categoryId}&page=${page}&limit=${limit}`
          )
          commit('SET_ENROLLED_COURSES', {
            courses: res.data.data,
            totalCount: res.data.lastPage,
          })
          return res
        } catch (error) {
          console.error('GETTING COURSES ERROR:', error.response.data.message)
        }
      }
    },
    async postView(_, courseId) {
      try {
        const res = await api({ requiresAuth: true }).post(
          `/enroll/viewCourse/${courseId}`
        )
        return res.data
      } catch (error) {
        console.error('Posting view error', error.response.data.message)
        throw error
      }
    },
    async setEnrolledCourses({ commit }, courses) {
      commit('SET_ENROLLED_COURSES', {
        courses: courses,
      })
    },
    async getAllCompletedQuizzes({ commit }) {
      try {
        const res = await api({ requiresAuth: true }).get(
          '/quiz/student/countCompletedQuizzes'
        )
        commit('SET_COMPLETED_QUIZZES', res.data)
      } catch (error) {
        console.error('Getting quizzes count error')
        throw error
      }
    },
    async getAllCompletedCourses({ commit }) {
      try {
        const res = await api({ requiresAuth: true }).get(
          '/enroll/countCompletedCourses'
        )
        commit('SET_COURSES_COMPLETED', res.data)
      } catch (error) {
        console.error('Getting completed courses count error')
        throw error
      }
    },
    async getInProgressCourses({ commit }) {
      try {
        const res = await api({ requiresAuth: true }).get(
          '/enroll/countIncompleteCourses'
        )
        commit('SET_COURSES_IN_PROGRESS', res.data)
      } catch (error) {
        console.error('Getting incomplete courses count error')
        throw error
      }
    },
    async getCourseStatus({ commit }, courseId) {
      try {
        const res = await api({ requiresAuth: true }).get(
          `/enroll/isCourseCompleted/${courseId}`
        )
        commit('SET_COURSES_COMPLETED_STATUS', res.data)
      } catch (error) {
        console.error('Getting course completed status error.')
        throw error
      }
    },
    async hasCoursesCompleted() {
      try {
        const response = await api({ requiresAuth: true }).get(
          `/enroll/hasCoursesCompleted`
        )
        return response.data
      } catch (error) {
        console.error(error)
      }
    },
  },
}

export default enrolledCourses
