<template>
  <div>
    <instructorActions
      :action="$t('instructorActions.actionEnable')"
      :firstName="instructorInfo.firstName"
      :lastName="instructorInfo.lastName"
      :username="instructorInfo.username"
      :email="instructorInfo.email"
      :categoryName="instructorInfo.instructor?.instructorCategory.name"
      @actionInstructor="enableUser(instructorInfo.uuid)"
      :isDisabled="disabled"
    />
  </div>
</template>

<script>
import instructorActions from '@/components/instructorActions/instructorActions.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    instructorActions,
  },
  data() {
    return {
      disabled: false,
    }
  },
  computed: {
    ...mapGetters(['getInstrInfo'], ['getLoading']),
    instructorInfo() {
      return this.getInstrInfo
    },
    isLoading() {
      return this.getLoading
    },
  },
  async created() {
    await this.$store.dispatch('fetchInstrInfo', this.$route.params.userId)
    this.disabled = this.instructorInfo.instructor.isActive
  },
  methods: {
    async enableUser(uuid) {
      await this.$store.dispatch('enableInstructor', uuid)
      this.disabled = !this.instructorInfo.instructor?.isActive
    },
  },
}
</script>
