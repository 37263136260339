import api from '@/api/api'
import { successMessage, errorMessage } from '@/composables/useMessages'
import i18n from '@/main'

const commentSection = {
  state: {
    questions: [],
    myQuestions: [],
    questionsCurrentPage: 1,
    questionsLastPage: 1,
    questionsTotalCount: 0,
    filterLoader: false,
    filterType: 'All Comments',
    answers: [],
    answersLastPage: 1,
    answersTotalCount: 0,
    singleComment: {},
    notification_comment: false,
  },
  getters: {
    getQuestions: (state) => state.questions,
    getMyQuestions: (state) => state.myQuestions,
    getAnswers: (state) => state.answers,
    getFilter: (state) => state.filterLoader,
    getFilterType: (state) => state.filterType,
    getQuestionsLastPage: (state) => state.questionsLastPage,
    getQuestionsTotalCount: (state) => state.questionsTotalCount,
    getSingleComment: (state) => state.singleComment,
    getNotificationComment: (state) => state.notification_comment,
    getAnswersLastPage: (state) => state.answersLastPage,
    getAnswersTotalCount: (state) => state.answersTotalCount,
  },
  mutations: {
    SET_QUESTIONS(state, questions) {
      state.questions = questions
    },
    SET_MY_QUESTIONS(state, questions) {
      state.myQuestions = questions
    },
    SET_QUESTIONS_CURRENT_PAGE(state, page) {
      state.questionsCurrentPage = page
    },
    SET_QUESTIONS_LAST_PAGE(state, page) {
      state.questionsLastPage = page
    },
    SET_QUESTIONS_TOTAL_COUNT(state, totalCount) {
      state.questionsTotalCount = totalCount
    },
    SET_ANSWERS(state, answers) {
      state.answers = answers
    },
    SET_ANSWERS_TOTAL_COUNT(state, totalCount) {
      state.answersTotalCount = totalCount
    },
    SET_FILTER(state, filter) {
      state.filterLoader = filter
    },
    SET_SINGLE_COMMENT(state, comment) {
      state.singleComment = comment
    },
    SET_NOTIFICATION_COMMENT(state, notification_comment) {
      state.notification_comment = notification_comment
    },
    SET_FILTER_TYPE(state, filterType) {
      state.filterType = filterType
    },
    SET_ANSWERS_LAST_PAGE(state, totalPages) {
      state.answersLastPage = totalPages
    },
  },
  actions: {
    async fetchQuestions({ commit, state }, { courseId, page }) {
      try {
        const perPage = 10
        commit('SET_FILTER', true)
        commit('SET_NOTIFICATION_COMMENT', false)
        const res = await api({ requiresAuth: true }).get(
          `/courseQuestions/course/${courseId}?page=${page}&limit=${perPage}`
        )
        const newQuestions = res.data.data
        const filteredNewQuestions = newQuestions.filter((newQuestion) => {
          return !state.questions.some(
            (existingQuestion) => existingQuestion.id === newQuestion.id
          )
        })
        commit('SET_QUESTIONS', [...state.questions, ...filteredNewQuestions])
        commit('SET_QUESTIONS_CURRENT_PAGE', page)
        commit('SET_QUESTIONS_LAST_PAGE', res.data.lastPage)
        commit('SET_QUESTIONS_TOTAL_COUNT', res.data.totalCount)
        commit('SET_FILTER', false)
        return res.data.data
      } catch (error) {
        console.error('GETTING QUESTIONS ERROR:', error.response.data.message)
        throw error
      }
    },
    async fetchAllQuestions({ commit, state }, { commentId }) {
      try {
        const res = await api({ requiresAuth: true }).get(
          `/courseQuestions/${commentId}`
        )
        if (res.data) {
          const updatedQuestions = state.questions.filter(
            (question) => question.uuid !== commentId
          )
          commit('SET_QUESTIONS', [res.data, ...updatedQuestions])
          commit('SET_NOTIFICATION_COMMENT', true)
        } else {
          commit('SET_NOTIFICATION_COMMENT', false)
        }
      } catch (error) {
        console.error('GETTING QUESTIONS ERROR:', error.response.data.message)
        throw error
      }
    },
    async fetchMyQuestions({ commit, state }, { courseId, page }) {
      try {
        const perPage = 10
        commit('SET_FILTER', true)
        const res = await api({ requiresAuth: true }).get(
          `/courseQuestions/currentUser/${courseId}?page=${page}&limit=${perPage}`
        )
        const newQuestions = res.data.data
        const filteredNewQuestions = newQuestions.filter((newQuestion) => {
          return !state.myQuestions.some(
            (existingQuestion) => existingQuestion.id === newQuestion.id
          )
        })
        commit('SET_MY_QUESTIONS', [
          ...state.myQuestions,
          ...filteredNewQuestions,
        ])
        commit('SET_QUESTIONS_CURRENT_PAGE', page)
        commit('SET_QUESTIONS_LAST_PAGE', res.data.lastPage)
        commit('SET_QUESTIONS_TOTAL_COUNT', res.data.totalCount)
        commit('SET_FILTER', false)
        return res.data.data
      } catch (error) {
        console.error('GETTING QUESTIONS ERROR:', error.response.data.message)
        throw error
      }
    },
    async fetchAnswers({ commit }, { id, page }) {
      try {
        const res = await api({ requiresAuth: true }).get(
          `/courseAnswer/question/${id}?page=${page}`
        )
        commit('SET_ANSWERS', res.data.data)
        commit('SET_ANSWERS_TOTAL_COUNT', res.data.totalCount)
        commit('SET_ANSWERS_LAST_PAGE', res.data.lastPage)
        return res.data.data
      } catch (error) {
        console.error('GETTING ANSWERS ERROR:', error.response.data.message)
        throw error
      }
    },
    async postVote(_, questionId) {
      try {
        const res = await api({ requiresAuth: true }).post(
          `/courseQuestions/vote/${questionId}`
        )
        return res.data
      } catch (error) {
        console.error('Posting vote error', error.response.data.message)
        throw error
      }
    },
    async postReplyVote(_, answerid) {
      try {
        const res = await api({ requiresAuth: true }).post(
          `/courseAnswer/vote/${answerid}`
        )
        return res.data
      } catch (error) {
        console.error('Posting vote error', error.response.data.message)
        throw error
      }
    },
    async postQuestion({ commit, state }, { courseId, data }) {
      try {
        const res = await api({ requiresAuth: true }).post(
          `/courseQuestions/${courseId}`,
          data
        )
        commit('SET_QUESTIONS', [res.data, ...state.questions])
        commit('SET_MY_QUESTIONS', [res.data, ...state.myQuestions])
        commit('SET_QUESTIONS_TOTAL_COUNT', state.questionsTotalCount + 1)
        successMessage.call(this, i18n.t('store.add_comment_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.add_comment_failed'))
        console.error('Posting question error', error.response.data.message)
        throw error
      }
    },
    async postReply(_, { questionId, comment }) {
      try {
        const res = await api({ requiresAuth: true }).post(
          `/courseAnswer/${questionId}`,
          {
            comment,
          }
        )
        successMessage.call(this, i18n.t('store.add_reply_success'))
        return res
      } catch (error) {
        errorMessage.call(this, i18n.t('store.add_reply_success'))
        console.error('Posting reply error', error.response.data.message)
        throw error
      }
    },
    async markAsSeenNotifications() {
      try {
        await api({ requiresAuth: true }).patch(`/notification/markAsSeen`)
      } catch (error) {
        console.error('Could not mark as seen notifications')
        throw error
      }
    },
    async isQuestionVoted(_, questionId) {
      try {
        let res = await api({ requiresAuth: true }).get(
          `/courseQuestions/hasVoted/${questionId}`
        )
        return res.data
      } catch (error) {
        console.error(error)
      }
    },
    async isAnswerVoted(_, answerId) {
      try {
        let res = await api({ requiresAuth: true }).get(
          `/courseAnswer/hasVoted/${answerId}`
        )
        return res.data
      } catch (error) {
        console.error(error)
      }
    },
    async fetchCourseQuestion({ commit, state }, courseQuestionId) {
      try {
        const res = await api({ requiresAuth: true }).get(
          `/courseQuestions/${courseQuestionId}`
        )
        const newQuestion = res.data
        let updatedQuestions = null
        if (state.filterType == i18n.t('singleCourse.my_comments')) {
          updatedQuestions = state.myQuestions.map((question) => {
            if (question.uuid === newQuestion.uuid) {
              return newQuestion
            }
            return question
          })
          commit('SET_MY_QUESTIONS', updatedQuestions)
        } else {
          updatedQuestions = state.questions.map((question) => {
            if (question.uuid === newQuestion.uuid) {
              return newQuestion
            }
            return question
          })
          commit('SET_QUESTIONS', updatedQuestions)
        }
        return newQuestion
      } catch (error) {
        errorMessage.call(this, i18n.t('store.fetch_comments_failed'))
        console.error('Fetch question ERROR:', error)
        throw error
      }
    },
    async updateCourseQuestion(_, { courseQuestionId, credentials }) {
      try {
        const res = await api({ requiresAuth: true }).patch(
          `/courseQuestions/${courseQuestionId}`,
          credentials
        )
        successMessage.call(this, i18n.t('store.update_comment_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.update_comment_failed'))
        console.error('UPDATE COMMENT ERROR:', error)
        throw error
      }
    },
    async deleteCourseQuestion({ commit, state }, courseQuestionId) {
      try {
        await api({ requiresAuth: true }).delete(
          `/courseQuestions/${courseQuestionId}`
        )
        if (state.filterType == i18n.t('singleCourse.my_comments')) {
          const updatedQuestions = state.myQuestions.filter(
            (question) => question.uuid !== courseQuestionId
          )
          commit('SET_MY_QUESTIONS', updatedQuestions)
        } else {
          const updatedQuestions = state.questions.filter(
            (question) => question.uuid !== courseQuestionId
          )
          commit('SET_QUESTIONS', updatedQuestions)
        }
        state.questionsTotalCount = state.questionsTotalCount - 1
        successMessage.call(this, i18n.t('store.delete_comment_success'))
      } catch (error) {
        errorMessage.call(this, i18n.t('store.delete_comment_failed'))
        console.error('DELETE COMMENT ERROR:', error.response.data.message)
        throw error
      }
    },
    async updateCourseAnswer(_, { courseAnswerId, credentials }) {
      try {
        const res = await api({ requiresAuth: true }).patch(
          `/courseAnswer/${courseAnswerId}`,
          credentials
        )
        successMessage.call(this, i18n.t('store.update_reply_success'))
        return res.data
      } catch (error) {
        errorMessage.call(this, i18n.t('store.update_reply_failed'))
        console.error('UPDATE REPLY ERROR:', error.response.data)
        throw error
      }
    },
    async deleteCourseAnswer(_, courseAnswerId) {
      try {
        await api({ requiresAuth: true }).delete(
          `/courseAnswer/${courseAnswerId}`
        )
        successMessage.call(this, i18n.t('store.delete_reply_success'))
      } catch (error) {
        errorMessage.call(this, i18n.t('store.delete_reply_failed'))
        console.error('DELETE REPLY ERROR:', error.response.data.message)
        throw error
      }
    },
  },
}

export default commentSection
