<template>
  <div class="course layout" v-if="course">
    <div class="course__header">
      <h2 class="course__title main-title" v-if="course.name">
        {{ course.name }}
      </h2>
      <div class="course__header__buttons">
        <baseButton
          v-if="isInstructor"
          class="course__button"
          @click.prevent="navigateToUpdateCourse(courseID)"
        >
          <img src="@/assets/icons/edit-icon.svg" />
          <slot>{{ $t('singleCourse.edit_course') }}</slot>
        </baseButton>
        <baseButton
          v-if="isInstructor"
          class="course__button"
          @click.prevent="openDeleteModal(course)"
        >
          <img src="@/assets/icons/delete-icon.svg" />
          <slot>{{ $t('coursesPage.delete_course') }}</slot>
        </baseButton>
      </div>
      <threeDotsMenu
        v-if="isInstructor"
        class="course__header__responsive-buttons"
        :isVisible="true"
        :optionList="courseOptions"
        :item="course"
      ></threeDotsMenu>
    </div>
    <div class="course__content course__content--grid">
      <div class="course__inner">
        <ul class="course__tabs">
          <li
            v-for="(tab, index) in tabs"
            :key="index"
            class="course__tab"
            :class="{ 'course__tab--active': activeTab === index }"
            @click="changeTab(index)"
          >
            {{ tab }}
          </li>
        </ul>
        <!-- <div class="course__info">
          <router-link :to="`/my-courses/single/${courseID}`"
            >View course content</router-link
          >
          <div class="course__more-info">
            <div class="category">
              <strong>Category</strong>
              <div>
                <p>{{ course.category.name }}</p>
              </div>
            </div>
            <div class="review">
              <strong>Review</strong>
              <div class="stars">
                <starRating :rating="course.averageRating"></starRating>
                <p>{{ course.averageRating }}</p>
              </div>
            </div>
          </div>
        </div> -->
        <loaderElement v-if="isLoading"></loaderElement>
        <div class="course__tab-content" v-if="!isLoading && activeTab === 0">
          <div class="description" v-if="videoPath">
            <div class="course__video-header">
              <h3>{{ $t('singleCourse.course_trailer') }}</h3>
              <threeDotsMenu
                :isVisible="videoPath.length !== 0 && isInstructor"
                :optionList="videoOptions"
                :item="videoPath[0]"
              ></threeDotsMenu>
            </div>
            <div class="course__video">
              <div v-if="videoPath.length !== 0">
                <loaderElement
                  v-if="isLoadingVideo"
                  class="course__thumbnail"
                ></loaderElement>
                <iframe
                  v-if="!isLoadingVideo && videoPath[0].youtubeId"
                  width="100%"
                  height="360"
                  :src="`https://www.youtube.com/embed/${videoPath[0].youtubeId}`"
                  frameborder="0"
                  allowfullscreen
                ></iframe>
                <video
                  class="course__thumbnail"
                  v-if="!isLoadingVideo && videoPath[0].videoFile"
                  controls
                >
                  <source
                    :src="`https://api.lms.k-clients.com/public/uploads/${videoPath[0].videoFile}`"
                  />
                </video>
              </div>
              <div v-else>
                <baseButton
                  buttonClass="button--primary"
                  @click.prevent="uploadVideo"
                  v-if="isInstructor"
                  >{{ $t('singleCourse.upload_video') }}</baseButton
                >
              </div>
              <div
                class="no-content"
                v-if="!isInstructor && videoPath.length <= 0"
              >
                {{ $t('singleCourse.no_trailer_video') }}
              </div>
            </div>
          </div>
          <div class="description" v-if="course.description">
            <h3>{{ $t('singleCourse.course_desc') }}</h3>
            <p>{{ course.description }}</p>
          </div>
          <div class="description">
            <h3>{{ $t('singleCourse.what_you_will_learn') }}</h3>
            <ul>
              <li
                v-for="(courseLearning, index) in course.courseLearning"
                :key="index"
              >
                {{ courseLearning.description }}
                <img
                  src="@/assets/icons/delete-icon.svg"
                  @click="openDeleteLearningModal(courseLearning)"
                  v-if="isInstructor"
                />
              </li>
              <li class="add-learning" @click="addLearning" v-if="isInstructor">
                <span> {{ $t('singleCourse.add_orientation') }}</span>
              </li>
              <div
                class="no-content"
                v-if="!isInstructor && course.courseLearning.length <= 0"
              >
                {{ $t('singleCourse.no_orientation') }}
              </div>
              <addLearningModal
                v-show="showAddLearningModal"
                :courseID="courseID"
                @close-modal="showAddLearningModal = false"
              ></addLearningModal>
              <baseModal
                v-if="showDeleteLearningModal"
                :user-firstname="learningDescription"
                :content="showModalContent"
                @close-modal="showDeleteLearningModal = false"
                @confirm-action="deleteLearning()"
              ></baseModal>
            </ul>
          </div>
          <div class="description">
            <h3>{{ $t('singleCourse.requirements') }}</h3>
            <ul class="course__requirements">
              <li
                v-for="(courseRequirement, index) in course.courseRequirements"
                :key="index"
              >
                {{ courseRequirement.field }}
                <img
                  src="@/assets/icons/delete-icon.svg"
                  @click="openDeleteRequirementModal(courseRequirement)"
                  v-if="isInstructor"
                />
              </li>
              <li
                class="add-learning"
                @click="addRequirement"
                v-if="isInstructor"
              >
                <span> {{ $t('singleCourse.add_requirement') }}</span>
              </li>
              <div
                class="no-content"
                v-if="!isInstructor && course.courseRequirements.length <= 0"
              >
                {{ $t('singleCourse.no_requirements') }}
              </div>
            </ul>
            <addRequirementModal
              v-show="showAddRequirementModal"
              :courseID="courseID"
              @close-modal="showAddRequirementModal = false"
            ></addRequirementModal>
            <baseModal
              v-if="showDeleteRequirementModal"
              :user-firstname="requirementDescription"
              :content="showModalContent"
              @close-modal="showDeleteRequirementModal = false"
              @confirm-action="deleteRequirement()"
            ></baseModal>
          </div>
          <div
            class="course__comments"
            v-if="chapters.length > 0 && isInstructor"
          >
            <router-link :to="`/my-courses/single/${courseID}`">
              {{ $t('singleCourse.go_to_comment_section')
              }}<font-awesome-icon
                :icon="['fas', 'arrow-right']"
                class="course__icon"
            /></router-link>
          </div>
        </div>
        <div class="course__tab-content" v-if="activeTab === 1">
          <ul class="course__list" v-if="chapters.length > 0">
            <li
              v-for="(chapter, index) in chapters"
              :key="index"
              class="course__item"
              :class="{ 'course__item--active': isItemActive(index) }"
              @click="toggleChapter(chapter.uuid)"
            >
              <div class="course__item-name">
                <span
                  class="toggle-icon"
                  :class="{ minus: isChapterOpen(chapter.uuid) }"
                ></span>
                <span>{{ chapter.name }}</span>
                <div
                  style="
                    float: right;
                    margin-right: 20px;
                    display: flex;
                    column-gap: 16px;
                  "
                  v-if="isInstructor"
                >
                  <img
                    src="@/assets/icons/edit-icon.svg"
                    @click.stop
                    @click.prevent="editChapter(chapter)"
                  />
                  <img
                    src="@/assets/icons/delete-icon.svg"
                    @click.stop
                    @click.prevent="openDeleteChapterModal(chapter)"
                  />
                  <img
                    src="@/assets/icons/arrow-down.svg"
                    class="course__icon"
                  />
                </div>
              </div>
              <ul
                v-if="isChapterOpen(chapter.uuid)"
                class="dropdown-list"
                @click.stop
              >
                <li
                  v-for="(video, videoIndex) in chapter.videos"
                  :key="videoIndex"
                  class="lesson"
                >
                  <span
                    @click="goToVideo(video.uuid, chapter.uuid, courseID)"
                    >{{ video.name }}</span
                  >
                  <div
                    style="
                      float: right;
                      margin-right: 20px;
                      display: flex;
                      column-gap: 16px;
                    "
                    v-if="isInstructor"
                  >
                    <img
                      src="@/assets/icons/edit-icon.svg"
                      @click.stop
                      @click.prevent="editLesson(video)"
                    />
                    <img
                      src="@/assets/icons/delete-icon.svg"
                      @click.stop
                      @click.prevent="openDeleteLessonModal(video)"
                    />
                  </div>
                </li>
                <li
                  class="add-lesson"
                  @click.stop
                  @click.prevent="addLesson(chapter)"
                  v-if="isInstructor"
                >
                  <span>{{ $t('singleCourse.add_lesson') }}</span>
                </li>
                <li
                  class="no-content"
                  v-if="!isInstructor && chapter.videos.length <= 0"
                >
                  {{ $t('singleCourse.no_videos') }}
                </li>
              </ul>
            </li>
          </ul>
          <div class="no-content" v-if="chapters.length <= 0">
            <div>
              <img src="@/assets/pictures/no-results-found.jpg" />
            </div>
            <p>
              {{ $t('singleCourse.no_chapters') }}
            </p>
            <baseButton
              buttonClass="button--primary"
              class="course__btn"
              @click.prevent="addChapter"
              v-if="isInstructor"
            >
              {{ $t('singleCourse.add_chapter') }}</baseButton
            >
          </div>
          <baseButton
            buttonClass="button--primary"
            class="course__btn"
            @click.prevent="addChapter"
            v-if="isInstructor && chapters.length > 0"
          >
            {{ $t('singleCourse.add_chapter') }}</baseButton
          >
        </div>
        <div class="course__tab-content" v-if="activeTab === 2">
          <div class="reviews" v-if="reviews.length > 0">
            <h3>{{ $t('singleCourse.student_reviews') }}</h3>
            <ul class="dropdown-list">
              <li
                v-for="(review, reviewIndex) in reviews"
                :key="reviewIndex"
                class="review"
                :class="notificationReview ? 'review__notifications' : ''"
              >
                <div class="review-image">
                  <img
                    v-if="review.user.imageFile"
                    :src="`https://api.lms.k-clients.com/public/images/${review.user.imageFile}`"
                  />
                  <img v-else src="@/assets/pictures/user.png" />
                </div>
                <div class="review-content">
                  <div class="review-content__top">
                    <h3>{{ review.user.username }}</h3>
                    <div class="profile__ratings">
                      <starRating :rating="review.rating"></starRating>
                    </div>
                  </div>
                  <p>{{ review.title }}</p>
                  <p>{{ review.content }}</p>
                </div>
              </li>
              <li>
                <baseButton
                  v-if="loadingMore"
                  class="button--load-more button--load"
                  @click="loadMoreReviews"
                  ><div v-if="loading" class="loader"></div>
                  <div>{{ $t('eventsPage.load_more') }}</div></baseButton
                >
              </li>
            </ul>
          </div>
          <div class="no-content" v-else>
            <div>
              <img src="@/assets/pictures/no-results-found.jpg" />
            </div>
            <p>{{ $t('singleCourse.no_reviews') }}</p>
          </div>
        </div>
      </div>
      <div class="course__inner">
        <loaderElement v-if="isLoading"></loaderElement>
        <article class="course__article" v-if="!isLoading">
          <div class="course__image">
            <img
              v-if="course.imagePath"
              :src="`https://api.lms.k-clients.com/public/images/${course.imagePath}`"
              alt="Course Image"
            />
            <img
              class="course__thumbnail"
              v-else
              src="@/assets/pictures/video.png"
            />
          </div>
          <div class="course__articleInfo">
            <div class="course__prices">
              <h5 class="course__price" v-if="course?.coursePrice.price">
                <p
                  v-if="
                    course?.coursePrice.discountedPrice ||
                    course?.coursePrice.discountedPrice === 0
                  "
                >
                  ${{ course?.coursePrice.discountedPrice }}
                </p>
                <p v-else>${{ course?.coursePrice.price }}</p>
                <span
                  class="course__lastPrice"
                  v-if="
                    course?.coursePrice.discountedPrice ||
                    course?.coursePrice.discountedPrice === 0
                  "
                  >${{ course?.coursePrice.price }}</span
                >
              </h5>
              <h5 class="course__price" v-else>
                {{ $t('singleCourse.free') }}
              </h5>

              <div
                class="course__sale"
                v-if="course?.coursePrice.discountPercentage"
              >
                <p>
                  {{ course?.coursePrice.discountPercentage }}%
                  {{ $t('singleCourse.off') }}
                </p>
              </div>
            </div>
            <div class="course__btns">
              <baseButton
                v-if="hasQuiz && isInstructor"
                buttonClass="button--primary"
                @click.prevent="navigateToViewQuiz(courseID)"
              >
                {{ $t('singleCourse.view_quiz') }}</baseButton
              >
              <baseButton
                v-if="!hasQuiz && isInstructor"
                buttonClass="button--primary"
                @click.prevent="navigateToCreateQuiz(courseID)"
              >
                {{ $t('singleCourse.create_quiz') }}</baseButton
              >
              <baseButton
                v-if="
                  isInstructor &&
                  course.coursePrice.price !== 0 &&
                  !this.course.coursePrice.discountedPrice &&
                  this.course.coursePrice.discountedPrice !== 0
                "
                buttonClass="button--primary"
                style="background: #00bc57"
                @click.prevent="addDiscount"
                >{{ $t('singleCourse.add_discount') }}</baseButton
              >
              <baseButton
                v-if="
                  isInstructor && course.coursePrice.discountPercentage !== null
                "
                buttonClass="button--primary"
                style="background: #fe7979"
                @click.prevent="removeDiscount"
                >{{ $t('singleCourse.remove_discount') }}</baseButton
              >
            </div>
            <div id="hiddenDiv" class="course__details">
              <div class="course__icons">
                <img src="../assets/icons/clock.svg" alt="Clock" />
                <h5>{{ $t('singleCourse.duration') }}</h5>
              </div>
              <p v-if="course.duration">{{ course?.duration }}</p>
              <p v-else>00:00:00</p>
            </div>
            <div id="hiddenDiv" class="course__details">
              <div class="course__icons">
                <img src="../assets/icons/reader.svg" alt="Clock" />
                <h5>{{ $t('singleCourse.enrolled') }}</h5>
              </div>
              <p v-if="course.enrolled == 1">
                {{ course?.enrolled }} {{ $t('singleCourse.student') }}
              </p>
              <p v-else>
                {{ course?.enrolled }} {{ $t('singleCourse.students') }}
              </p>
            </div>
            <div id="hiddenDiv" class="course__details">
              <div class="course__icons">
                <img src="../assets/icons/translate.svg" alt="Clock" />
                <h5>{{ $t('singleCourse.language') }}</h5>
              </div>
              <p>{{ course?.language }}</p>
            </div>
            <div id="hiddenDiv" class="course__details">
              <div class="course__icons">
                <img src="../assets/icons/equalizer.svg" alt="Clock" />
                <h5>{{ $t('singleCourse.skill_level') }}</h5>
              </div>
              <p>{{ course?.level }}</p>
            </div>
            <!-- <div id="hiddenDiv" class="course__details">
              <div class="course__icons">
                <img src="../assets/icons/calendar.svg" alt="Clock" />
                <h5>{{ $t('singleCourse.deadline') }}</h5>
              </div>
              <p>{{ deadline }}</p>
            </div> -->
            <div id="hiddenDiv" class="course__details">
              <div class="course__icons">
                <img src="../assets/icons/medal.svg" alt="Clock" />
                <h5>{{ $t('singleCourse.certificate') }}</h5>
              </div>
              <p v-if="course?.certificate">{{ $t('singleCourse.yes') }}</p>
              <p v-else>{{ $t('singleCourse.no') }}</p>
            </div>
          </div>
        </article>
      </div>
    </div>
    <transition name="modal-fade"
      ><uploadVideoModal
        v-if="showUploadVideoModal"
        :courseID="courseID"
        @close-modal="showUploadVideoModal = false"
      ></uploadVideoModal
    ></transition>
    <transition name="modal-fade"
      ><baseModal
        v-if="showDeleteCourse"
        :user-firstname="selectedCourseName"
        :content="showModalContent"
        @close-modal="showDeleteCourse = false"
        @confirm-action="deleteCourse()"
    /></transition>
    <transition name="modal-fade"
      ><addChapterModal
        v-show="showChapterModal"
        @close-modal="showChapterModal = false"
      ></addChapterModal
    ></transition>
    <transition name="modal-fade"
      ><editChapterModal
        v-if="showEditChapterModal"
        @close-modal="showEditChapterModal = false"
        :chapterID="chapterID"
        :chapter="selectedChapter"
      ></editChapterModal
    ></transition>
    <transition name="modal-fade"
      ><baseModal
        v-if="showDeleteChapterModal"
        :user-firstname="chapterName"
        :content="showModalContent"
        @close-modal="showDeleteChapterModal = false"
        @confirm-action="deleteChapter()"
    /></transition>
    <transition name="modal-fade"
      ><addLessonModal
        v-show="showAddLessonModal"
        :chapterID="chapterID"
        @close-modal="showAddLessonModal = false"
      ></addLessonModal
    ></transition>
    <transition name="modal-fade"
      ><editLessonModal
        v-if="showEditLessonModal"
        :lessonID="lessonID"
        :video="lesson"
        @close-modal="showEditLessonModal = false"
      ></editLessonModal
    ></transition>
    <transition name="modal-fade"
      ><baseModal
        v-if="showDeleteLessonModal"
        :user-firstname="lessonName"
        :content="showModalContent"
        @close-modal="showDeleteLessonModal = false"
        @confirm-action="deleteLesson()"
    /></transition>
    <transition name="modal-fade"
      ><updateVideoModal
        v-if="showUpdateVideoModal"
        :courseID="courseID"
        :videoID="videoID"
        @update-video-path="updateVideoPath"
        @close-modal="showUpdateVideoModal = false"
      ></updateVideoModal
    ></transition>
    <transition name="modal-fade"
      ><baseModal
        v-if="showDeleteVideoModal"
        :user-firstname="videoName"
        :content="showModalContent"
        @close-modal="showDeleteVideoModal = false"
        @confirm-action="deleteVideo()"
    /></transition>
    <transition name="modal-fade"
      ><addDiscountModal
        v-show="showAddDiscountModal"
        :courseID="courseID"
        @close-modal="showAddDiscountModal = false"
      ></addDiscountModal
    ></transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import baseButton from '@/components/baseButton/baseButton.vue'
import uploadVideoModal from '@/components/uploadVideoModal/uploadVideoModal.vue'
import updateVideoModal from '@/components/updateVideoModal/updateVideoModal.vue'
import baseModal from '@/components/baseModal/baseModal.vue'
import addChapterModal from '@/components/addChapterModal/addChapterModal.vue'
import editChapterModal from '@/components/editChapterModal/editChapterModal.vue'
import addLessonModal from '@/components/addLessonModal/addLessonModal.vue'
import editLessonModal from '@/components/editLessonModal/editLessonModal.vue'
import starRating from '@/components/starRating/starRating.vue'
import threeDotsMenu from '@/components/threeDotsMenu/threeDotsMenu.vue'
import addLearningModal from '@/components/addLearningModal/addLearningModal.vue'
import addDiscountModal from '@/components/addDiscountModal/addDiscountModal.vue'
import addRequirementModal from '@/components/addRequirementModal/addRequirementModal.vue'
import loaderElement from '@/components/loader/loaderElement.vue'
export default {
  components: {
    baseButton,
    uploadVideoModal,
    updateVideoModal,
    baseModal,
    addChapterModal,
    editChapterModal,
    addLessonModal,
    editLessonModal,
    starRating,
    threeDotsMenu,
    addLearningModal,
    addDiscountModal,
    addRequirementModal,
    loaderElement,
  },
  data() {
    return {
      notificationReview: false,
      showUploadVideoModal: false,
      showDeleteCourse: false,
      selectedCourseID: '',
      selectedCourseName: '',
      activeTab: 0,
      tabs: [
        this.$t('singleCourse.overview'),
        this.$t('singleCourse.chapters'),
        this.$t('singleCourse.reviews'),
      ],
      showAddLearningModal: false,
      showAddDiscountModal: false,
      showDeleteLearningModal: false,
      learningID: '',
      learningDescription: '',
      showAddRequirementModal: false,
      showDeleteRequirementModal: false,
      requirementID: '',
      requirementDescription: '',
      showChapterModal: false,
      showEditChapterModal: false,
      showDeleteChapterModal: false,
      chapterID: '',
      chapterName: '',
      selectedChapter: {},
      openChapters: [],
      showAddLessonModal: false,
      showEditLessonModal: false,
      showDeleteLessonModal: false,
      lesson: {},
      lessonID: '',
      lessonName: '',
      activeItems: [],
      showModalContent: {
        message: 'delete this user',
        submit: 'DELETE',
      },
      videoOptions: [
        {
          label: this.$t('singleCourse.update_trailer'),
          action: this.updateVideo,
        },
        {
          label: this.$t('singleCourse.delete_trailer'),
          action: this.openDeleteVideoModal,
        },
      ],
      courseOptions: [
        {
          label: this.$t('singleCourse.edit_course'),
          action: this.updateCourse,
        },
        {
          label: this.$t('coursesPage.delete_course'),
          action: this.openDeleteModal,
        },
      ],
      showUpdateVideoModal: false,
      showDeleteVideoModal: false,
      videoID: '',
      videoName: '',
      isLoading: false,
      isLoadingVideo: false,
      loading: false,
      page: 1,
    }
  },
  watch: {
    '$i18n.locale': function (newLocale) {
      if (newLocale)
        this.tabs = [
          this.$t('singleCourse.overview'),
          this.$t('singleCourse.chapters'),
          this.$t('singleCourse.reviews'),
        ]
    },
    '$route.query.reviewId'(newVal) {
      if (newVal !== '' && newVal !== undefined && newVal !== null)
        location.reload()
    },
    chapters(newVal) {
      if (newVal) {
        this.$store.dispatch('fetchCourse', this.$route.params.id)
      }
    },
  },
  computed: {
    ...mapGetters(['getCourse']),
    course() {
      return this.getCourse
    },
    courseID() {
      return this.$route.params.id
    },
    ...mapGetters(['getChapters']),
    chapters() {
      return this.getChapters
    },
    ...mapGetters(['getVideos']),
    videos() {
      return this.getVideos
    },
    ...mapGetters(['getCourseReviews']),
    reviews() {
      return this.getCourseReviews
    },
    ...mapGetters(['getCourseVideo']),
    videoPath() {
      return this.getCourseVideo
    },
    ...mapGetters(['getCoursesQuizzes', 'getNoQuiz']),
    hasQuiz() {
      return !this.getNoQuiz
    },
    ...mapGetters(['hasMoreReviews']),
    loadingMore() {
      return this.hasMoreReviews
    },
    deadline() {
      var options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }
      return new Date(this.getCourse?.deadline).toLocaleDateString(
        'en-US',
        options
      )
    },
  },
  async mounted() {
    this.isLoading = true
    await this.$store.dispatch('fetchCourse', this.$route.params.id)
    await this.$store.dispatch('fetchChapters', this.$route.params.id)
    await this.$store.dispatch('fetchCourseReviews', {
      courseID: this.$route.params.id,
      page: 1,
    })
    await this.$store.dispatch('fetchCourseVideo', this.$route.params.id)
    if (this.isInstructor) {
      await this.$store.dispatch('fetchCourseQuizzes', this.$route.params.id)
    }
    if (this.$route.query.reviewId) {
      await this.$store.dispatch(
        'fetchSingleReview',
        this.$route.query.reviewId
      )
      this.notificationReview = true
      this.activeTab = 2
    }
    this.isLoading = false
  },
  methods: {
    async toggleChapter(id) {
      const index = this.openChapters.indexOf(id)
      if (index !== -1) {
        this.openChapters.splice(index, 1)
      } else {
        this.openChapters.push(id)
      }
    },
    isChapterOpen(id) {
      return this.openChapters.includes(id)
    },
    uploadVideo() {
      this.showUploadVideoModal = !this.showUploadVideoModal
    },
    navigateToUpdateCourse(id) {
      this.$router.push(`/course/${id}/update`)
    },
    updateCourse(course) {
      this.$router.push(`/course/${course.uuid}/update`)
    },
    openDeleteModal(course) {
      this.selectedCourseID = course.uuid
      this.selectedCourseName = course.name
      this.showModalContent = {
        message: this.$t('singleCourse.delete_course'),
        submit: this.$t('singleCourse.delete_btn'),
        notification: this.$t('singleCourse.delete_msg'),
      }
      this.showDeleteCourse = !this.showDeleteCourse
    },
    async deleteCourse() {
      try {
        await this.$store.dispatch('deleteCourse', this.courseID)
        this.$router.push('/courses')
      } catch (err) {
        console.error(err)
      }
    },
    navigateToViewQuiz(id) {
      this.$router.push(`/quiz/view/${id}`)
    },
    navigateToCreateQuiz(id) {
      this.$router.push(`/quiz/create/${id}`)
    },
    changeTab(index) {
      this.activeTab = index
    },
    updateVideo(videoPath) {
      this.showUpdateVideoModal = true
      this.videoID = videoPath.uuid
    },
    async updateVideoPath() {
      this.isLoadingVideo = true
      const videoTrailer = await this.$store.dispatch(
        'fetchCourseVideo',
        this.courseID
      )
      this.$set(this.videoPath, {
        ...JSON.parse(JSON.stringify(videoTrailer)),
      })
      this.isLoadingVideo = false
    },
    openDeleteVideoModal(videoPath) {
      this.videoID = videoPath.uuid
      this.videoName = videoPath.name
      this.showModalContent = {
        message: this.$t('singleCourse.delete_trailer_content'),
        submit: this.$t('singleCourse.delete_btn'),
      }
      this.showDeleteVideoModal = !this.showDeleteVideoModal
    },
    async deleteVideo() {
      try {
        if (this.videoPath[0].videoLink) {
          await this.$store.dispatch('deleteEmbedVideo', this.videoID)
        } else {
          await this.$store.dispatch('deleteVideo', this.videoID)
        }
      } catch (err) {
        console.error(err)
      }
      await this.$store.dispatch('fetchCourseVideo', this.$route.params.id)
    },
    addDiscount() {
      this.showAddDiscountModal = true
    },
    async removeDiscount() {
      await this.$store.dispatch('removeDiscount', this.courseID)
      await this.$store.dispatch('fetchCourse', this.courseID)
    },
    addLearning() {
      this.showAddLearningModal = true
    },
    openDeleteLearningModal(learning) {
      this.learningID = learning.uuid
      //this.learningDescription = learning.description
      this.showModalContent = {
        message: this.$t('singleCourse.delete_learning'),
        submit: this.$t('singleCourse.delete_btn'),
      }
      this.showDeleteLearningModal = !this.showDeleteLearningModal
    },
    async deleteLearning() {
      try {
        await this.$store.dispatch('deleteLearning', this.learningID)
      } catch (err) {
        console.error(err)
      }
      await this.$store.dispatch('fetchCourse', this.$route.params.id)
    },
    addRequirement() {
      this.showAddRequirementModal = true
    },
    openDeleteRequirementModal(requirement) {
      this.requirementID = requirement.uuid
      //this.requirementDescription = requirement.field
      this.showModalContent = {
        message: this.$t('singleCourse.delete_requirement'),
        submit: this.$t('singleCourse.delete_btn'),
      }
      this.showDeleteRequirementModal = !this.showDeleteRequirementModal
    },
    async deleteRequirement() {
      try {
        await this.$store.dispatch('deleteRequirement', this.requirementID)
      } catch (err) {
        console.error(err)
      }
      await this.$store.dispatch('fetchCourse', this.$route.params.id)
    },
    isItemActive(index) {
      return this.activeItems.includes(index)
    },
    addChapter() {
      this.showChapterModal = !this.showChapterModal
    },
    editChapter(chapter) {
      this.showEditChapterModal = true
      this.chapterID = chapter.uuid
      this.selectedChapter = chapter
    },
    openDeleteChapterModal(chapter) {
      this.chapterID = chapter.uuid
      this.chapterName = chapter.name
      this.showModalContent = {
        message: this.$t('singleCourse.delete_chapter'),
        submit: this.$t('singleCourse.delete_btn'),
      }
      this.showDeleteChapterModal = !this.showDeleteChapterModal
    },
    async deleteChapter() {
      try {
        await this.$store.dispatch('deleteChapter', this.chapterID)
      } catch (err) {
        console.error(err)
      }
      await this.$store.dispatch('fetchChapters', this.$route.params.id)
    },
    addLesson(chapter) {
      this.showAddLessonModal = true
      this.chapterID = chapter.uuid
    },
    editLesson(lesson) {
      this.showEditLessonModal = true
      this.lesson = lesson
      this.lessonID = lesson.uuid
    },
    openDeleteLessonModal(lesson) {
      this.lesson = lesson
      this.lessonID = lesson.uuid
      this.lessonName = lesson.name
      this.showModalContent = {
        message: this.$t('singleCourse.delete_lesson'),
        submit: this.$t('singleCourse.delete_btn'),
      }
      this.showDeleteLessonModal = !this.showDeleteLessonModal
    },
    async deleteLesson() {
      try {
        if (this.lesson.videoLink) {
          await this.$store.dispatch('deleteEmbedVideo', this.lessonID)
        } else {
          await this.$store.dispatch('deleteLesson', this.lessonID)
        }
      } catch (err) {
        console.error(err)
      }
      await this.$store.dispatch('fetchChapters', this.$route.params.id)
    },
    async loadMoreReviews() {
      if (this.loading) return
      this.loading = true
      this.page++
      try {
        await this.$store.dispatch('fetchCourseReviews', {
          courseID: this.$route.params.id,
          page: this.page,
        })
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    goToVideo(videoId, chapterId, courseId) {
      this.$router.push({
        name: 'studentSingleCourse',
        params: { id: courseId, videoId: videoId },
        query: { chapterId: chapterId },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/pages/singleCourse.scss';
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.3s;
}
.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}
</style>
