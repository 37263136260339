<template>
  <div class="sideMenu">
    <div
      v-if="isOpen"
      class="sideMenu-content"
      :class="{ 'sideMenu-content--open': isOpen }"
    >
      <div class="sideMenu-content__title">
        <p>{{ $t('singleCourse.course_content') }}</p>
        <div @click="toggleSidebar">
          <img src="../../assets/icons/close.svg" />
        </div>
      </div>
      <div v-if="!isLoading">
        <chapterCard @AI-popup="popupShow($event)" @emit-uuid="emitVideoUuid" />
        <div v-if="isStudent && this.getQuizInfoSt?.questions?.length">
          <div
            class="sideMenu-content--quiz"
            v-for="quiz in quizzes"
            :key="quiz.id"
            @click="initiateQuiz(quiz.uuid)"
          >
            <p>
              <span class="sideMenu-content--quiz__span"
                >{{ $t('createQuiz.title') }}:</span
              >
              {{ quiz.title }}
            </p>
          </div>
        </div>
      </div>
      <loaderElement
        :customClass="'loader--sideMenu'"
        :custom-spinner="'loader-spinner--sideMenu'"
        v-else-if="isLoading && !isMobileDevice()"
      />
    </div>

    <div class="sideMenu__arrow" v-if="isArrow" @click="toggleSidebar">
      <img src="../../assets/icons/sidebarArrow.svg" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import chapterCard from '../chapterCard/chapterCard.vue'
import loaderElement from '../loader/loaderElement.vue'
export default {
  components: {
    chapterCard,
    loaderElement,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'getCoursesQuizzes',
      'getQuizInfoSt',
      'getQuizScoreData',
      'getLastWatched',
      'getCoursesCompletedStatus',
    ]),
    isOpen() {
      return this.isMobile
        ? !this.$store.state.videos.isOpen
        : this.$store.state.videos.isOpen
    },
    isArrow() {
      return this.isMobile
        ? !this.$store.state.videos.isArrow
        : this.$store.state.videos.isArrow
    },
    quizzes() {
      return this.getCoursesQuizzes
    },
    score() {
      return this.getQuizScoreData
    },
    courseStatus() {
      return this.getCoursesCompletedStatus
    },
    lastWatched() {
      return this.getLastWatched
    },
  },
  methods: {
    popupShow(chapterId) {
      event.stopPropagation()
      this.$emit('AI-show', chapterId)
    },
    toggleSidebar() {
      this.$store.commit('toggleSidebar')
    },
    emitVideoUuid() {
      this.$emit('fetch-video')
    },
    async initiateQuiz(quizId) {
      await this.$store.dispatch('fetchQuizScore', quizId)
      await this.$store.dispatch('getCourseStatus', this.$route.params.id)
      if (this.score == null && this.courseStatus == false) {
        this.$emit('status-popup', quizId)
      } else if (this.score == null && this.courseStatus == true) {
        this.$router.push(`/quiz/student/${this.$route.params.id}`)
      } else {
        this.$emit('quiz-popup', quizId)
      }
    },
    updateIsMobileState() {
      this.$store.commit('SET_IS_MOBILE', this.isMobileDevice())
    },
    isMobileDevice() {
      return window.innerWidth < 768
    },
  },
  async created() {
    this.updateIsMobileState()
    window.addEventListener('resize', this.updateIsMobileState)
    this.isLoading = true
    await this.$store.dispatch('getLastWatched', this.$route.params.id)
    await this.$store.dispatch('fetchCourseQuizzes', this.$route.params.id)
    if (this.lastWatched) {
      for (let video of this.lastWatched?.chapter?.videos) {
        let response = await this.$store.dispatch('hasBeenWatched', video.uuid)
        this.$store.commit('UPDATE_BEEN_WATCHED', {
          uuid: video.uuid,
          boolean: response,
        })
      }
    }
    this.isLoading = false
  },
}
</script>

<style lang="scss" scoped>
@import './singleCourseSideBar';
</style>
