<template>
  <div
    class="language-dropdown"
    @mouseover="showOptions = true"
    @mouseleave="showOptions = false"
  >
    <button
      class="selected-language"
      :class="{ 'language-hover': showOptions === true }"
    >
      {{ selectedLanguage }}
      <img src="../../assets/icons/hoverArrow.svg" v-if="!showOptions" />
      <img src="../../assets/icons/whiteArrow.svg" v-else />
    </button>
    <ul class="language-options" :class="{ 'show-options': showOptions }">
      <li
        class="language-option"
        v-for="(option, index) in filteredLanguageOptions"
        :key="index"
        @click="selectLanguage(option)"
      >
        {{ option }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      languageOptions: ['al', 'en'],
      selectedLanguage: localStorage.getItem('lang')
        ? localStorage.getItem('lang')
        : 'al',
      showOptions: false,
    }
  },
  computed: {
    filteredLanguageOptions() {
      return this.languageOptions.filter(
        (option) => option !== this.selectedLanguage
      )
    },
  },
  methods: {
    selectLanguage(language) {
      this.selectedLanguage = language
      this.$store.commit('language/setCurrentLanguage', language.toLowerCase())
      this.showOptions = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import './languageDropdown.scss';
</style>
